import { Button, Grid, makeStyles, StepConnector, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import searchRFService from "../../../../services/RF";
import { FacialBiometricsStep01 } from "./Step01";
import { FacialBiometricsStep02 } from "./Step02";
import { FacialBiometricsStep03 } from "./Step03";
import { FacialBiometricsStep04 } from "./Step04";
import { FacialBiometricsStep05 } from "./Step05";
import { FacialBiometricsStep06 } from "./Step06";
import { FacialBiometricsStep07 } from "./Step07";
import { FacialBiometricsStep08 } from "./Step08";
import { IconsSource } from "../../../../images/Icons";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    active: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#612F74"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#612F74",
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};


function getSteps() {
    return ["1", "2", "3", "4", "5", "6", "7", "8"];
}

const FacialBiometrics = (props) => {
    const steps = getSteps();
    const [fileFacial, setFileFacial] = useState(null);
    const [fileDocFront, setFileDocFront] = useState(null);
    const [fileDocVerse, setFileDocVerse] = useState(null);
    const [isLoadingBio8, setIsLoadingBio8] = useState(null);

    const handleInputChangeFacial = (event) => {
        if (event) {
            setFileFacial(event)
            props.handleNext()
        }
    };

    const handleInputChangeDocFront = async (event) => {
        if (event) {
            setFileDocFront(event)
            props.handleNext()
        }
    };

    const handleInputChangeDocVerse = async (event) => {
        if (event) {
            setFileDocVerse(event)
            props.handleNext()
        }
    };

    const getStepContent = () => {
        // props.activeStep
        switch (props.activeStep) {
            case 0:
                return (
                    <>
                        <FacialBiometricsStep01 {...{
                            handleNext: props.handleNext
                        }} />

                    </>
                )
            case 1:
                return (
                    <>
                        <FacialBiometricsStep02 {...{
                            handleNext: props.handleNext,
                            handleInputChangeFacial,
                            fileFacial
                        }} />
                        <Grid style={{ textAlign: "center", width: "100%", marginTop: "130px" }}>
                            <img src={IconsSource.face}></img>
                        </Grid>
                    </>
                )
            case 2:
                return <FacialBiometricsStep03 {...{
                    handleNext: props.handleNext,
                    handleBack: props.handleBack,
                    fileFacial,
                    setFileFacial

                }} />
            case 3:
                return (
                    <>
                        <FacialBiometricsStep04  {...{
                            handleNext: props.handleNext,
                            handleBack: props.handleBack,
                        }} />

                    </>
                )
            case 4:
                return (
                    <>
                        <FacialBiometricsStep05 {...{
                            handleInputChangeDocFront,
                            handleNext: props.handleNext,
                            fileDocFront
                        }} />
                    </>
                )
            case 5:
                return <FacialBiometricsStep06 {...{
                    handleInputChangeDocVerse,
                    handleNext: props.handleNext,
                    fileDocVerse
                }} />
            case 6:
                return <FacialBiometricsStep07 {...{
                    handleNext: props.handleNext,
                    handleBack: props.handleBack,
                    handleBackTwice: props.handleBackTwice,
                    fileDocFront,
                    setFileDocFront,
                    fileDocVerse,
                    setFileDocVerse,
                }} />
            case 7:
                return <FacialBiometricsStep08 {...{
                    saveDocuments,
                    isLoading: props.isLoading,
                    fileFacial,
                    isLoadingBio8
                }} />
            default:
                break;
        }
    }

    const saveDocuments = async () => {
        setIsLoadingBio8(true)
        try {
            let dto = {
                biometria_Facial: fileFacial,
                documento: fileDocFront,
                documento_Verso: fileDocVerse,
            }
            await searchRFService.postDocuments(dto)
            props.setSnackMessage("Encaminhado com sucesso.");
            props.setSnackVariant("success");
            props.setSnackVisible({ visible: true });
            setTimeout(() => {
                props.isDoneAuthRF()
            }, 3500);
        } catch (e) {
            if (e?.response?.data?.message) {
                props.setSnackMessage(e?.response?.data?.message);
                props.setSnackVariant("error");
                props.setSnackVisible({ visible: true });
            } else if (Object.values(e?.response?.data?.errors)[0][0]) {
                props.setSnackMessage(Object.values(e?.response?.data?.errors)[0][0]);
                props.setSnackVariant("error");
                props.setSnackVisible({ visible: true });
            } else {
                props.setSnackMessage("Houve uma falha, tente novamente.");
                props.setSnackVariant("error");
                props.setSnackVisible({ visible: true });
            }
        } finally {
            setIsLoadingBio8(false)
        }
    }

    return (
        <>
            {getStepContent()}
        </>
    )
}

export default FacialBiometrics;
