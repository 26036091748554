import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useLayoutEffect, useState } from 'react';
import { EnumGenre } from '../../Enums/enum_genre';
import CustomDatePicker from '../../components/CustomDatepicker';
import CustomSelectField from '../../components/CustomSelectField';
import CustomTextFieldDark from '../../components/CustomTextFieldDark';
import CustomTextFieldMasked from '../../components/CustomTextFieldMasked';
import personService from '../../services/Person';
import searchRFService from '../../services/RF';
import countryServices from '../../services/places/Country';
import validatorCPF from '../../utils/cpfValidator';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const RegisterPersonDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [countries, setCountries] = useState([]);
    const [person, setPerson] = useState({
        cpf: null,
        birthDate: null,
        firstName: null,
        lastName: null,
        gender: null,
        id_Nationality: null,
    })
    const [personError, setPersonError] = useState({
        cpf: false,
        birthDate: false,
        firstName: false,
        lastName: false,
        gender: false,
        id_Nationality: false,
    })

    const handleClose = () => {
        props.setOpen(false)
    };

    function handleChangePerson(event) {
        setPerson({ ...person, [event.target.name]: event.target.value });
    }

    function setBirthDate(date) {
        setPerson({ ...person, birthDate: date })
    }

    function setNationality(nationality) {
        setPerson({ ...person, id_Nationality: nationality })
    }

    function verifyFields() {

        let temp = {
            cpf: false,
            birthDate: false,
            firstName: false,
            lastName: false,
            gender: false,
            id_Nationality: false,
        }

        if (person.cpf === null || person.cpf === '') {
            temp = { ...temp, cpf: true }
        } else {
            temp = { ...temp, cpf: false }
        }

        if (!person.firstName) {
            temp = { ...temp, firstName: true }
        } else {
            temp = { ...temp, firstName: false }
        }

        if (!person.lastName) {
            temp = { ...temp, lastName: true }
        } else {
            temp = { ...temp, lastName: false }
        }

        if (!person.birthDate) {
            temp = { ...temp, birthDate: true }
        } else {
            temp = { ...temp, birthDate: false }
        }

        if (!person.gender) {
            temp = { ...temp, gender: true }
        } else {
            temp = { ...temp, gender: false }
        }

        if (!person.id_Nationality) {
            temp = { ...temp, id_Nationality: true }
        } else {
            temp = { ...temp, id_Nationality: false }
        }

        setPersonError(temp)
    }

    async function handleClickSearchData() {
        if (person.cpf === null || person.cpf === "   .   .   -  " || person.cpf === undefined) {
            props.setSnackMessage("CPF não pode ser vazio.");
            props.setSnackVariant("warning");
            props.setSnackVisible({ visible: true });
            let temp = { ...temp, cpf: true }
            setPersonError(temp)
            return
        } else {
            if (!validatorCPF(person.cpf)) {
                props.setSnackMessage("CPF inválido.");
                props.setSnackVariant("warning");
                props.setSnackVisible({ visible: true });
                let temp = { ...temp, cpf: true }
                setPersonError(temp)
                return
            }
        }

        props.setIsLoading(true)
        try {
            let dto = {
                cpf: person.cpf,
                birthDate: person.birthDate
            }
            let response = await searchRFService.postSearchCPF(dto)
            setPerson({ ...person, firstName: response.data.results.firstName, lastName: response.data.results.lastName })
        } catch (error) {
            props.setSnackMessage("CPF não pode ser encontrado.");
            props.setSnackVariant("error");
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    async function handleClickRegisterPerson() {
        verifyFields()
        try {
            let response = await personService.postCreatePerson(person);
            props.setSnackMessage(response.data.message);
            props.setSnackVariant("success");
            props.setSnackVisible({ visible: true });
            props.searchListPerson();
            handleClose();
        } catch (error) {
            props.setSnackMessage("Falha ao cadastrar passageiro.");
            props.setSnackVariant("error");
            props.setSnackVisible({ visible: true });
        } finally {
        }
    }

    useLayoutEffect(() => {
        (async () => {
            try {
                let responseCountries = await countryServices.getCountries(true);
                setCountries(responseCountries.data.results)
                props.setIsLoading(true)
                if (props.idpax !== 0) {
                    let responsePerson = await personService.getPerson(props.idpax)
                    let teste = moment(responsePerson.data.results.birthDate, "DD-MM-YYYY");
                    setPerson(
                        {
                            ...person,
                            cpf: responsePerson.data.results.cpf,
                            birthDate: new Date(teste.format("YYYY-MM-DD")),
                            firstName: responsePerson.data.results.firstName,
                            lastName: responsePerson.data.results.lastName,
                            gender: EnumGenre.types.find((item) => item.value == responsePerson.data.results.id_Gender).value,
                            id_Nationality: responseCountries.data.results.find((item) => item.value == responsePerson.data.results.id_Nationality).value
                        }
                    )
                }
            } catch {
            } finally {
                props.setIsLoading(false)
            }
        })();
    }, [])

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth="sm"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"><Typography variant='h5'>Cadastro</Typography></DialogTitle>
                <DialogContent className={responsive ? classes.dialogContentCustom : null}>
                    <FormControl>
                        <Grid container  >
                            <Grid item xs={responsive ? 12 : 4} className={responsive ? classes.gridCenterMobi : classes.gridCenter}>
                                <CustomTextFieldMasked
                                    shrink={true}
                                    mask={"999.999.999-99"}
                                    error={personError.cpf}
                                    helperText={'Campo obrigatório'}
                                    className={classes.field}
                                    required
                                    name="cpf"
                                    type="text"
                                    label="CPF"
                                    value={person.cpf}
                                    onChange={(e) => handleChangePerson(e)}
                                />
                            </Grid>
                            <Grid item xs={responsive ? 12 : 6} className={responsive ? classes.gridCenterMobi : classes.gridCenter}>
                                <CustomDatePicker error={personError.birthDate} helperText={'Campo obrigatório'} {...{ date: person.birthDate, setDate: setBirthDate, label: "Data de Nascimento" }} />
                            </Grid>
                            <Grid item xs={responsive ? 12 : 2} className={responsive ? classes.gridCenterMobi : classes.gridCenter}>
                                {responsive ? (
                                    <Button onClick={() => handleClickSearchData()} className={classes.buttonSearch} startIcon={<SearchOutlined style={{ color: 'purple' }} />}>
                                        Pesquisar CPF
                                    </Button>
                                ) : (
                                    <IconButton onClick={() => handleClickSearchData()}>
                                        <SearchOutlined style={{ color: 'purple' }} />
                                    </IconButton>
                                )}
                            </Grid>
                            <Grid item xs={responsive ? 12 : 12} className={responsive ? classes.gridCenterMobi : classes.gridCenter}>
                                <CustomTextFieldDark
                                    required
                                    error={personError.firstName}
                                    helperText={personError.firstName ? 'Campo obrigatório' : ''}
                                    className={classes.field}
                                    name="firstName"
                                    type="text"
                                    label="Nome"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    value={person.firstName || ""}
                                    onChange={(e) => handleChangePerson(e)}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                />
                            </Grid>
                            <Grid item xs={responsive ? 12 : 12} className={responsive ? classes.gridCenterMobi : classes.gridCenter}>
                                <CustomTextFieldDark
                                    required
                                    error={personError.lastName}
                                    helperText={personError.lastName ? 'Campo obrigatório' : ''}
                                    className={classes.field}
                                    name="lastName"
                                    type="text"
                                    label="Sobrenome"
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    value={person.lastName || ""}
                                    onChange={(e) => handleChangePerson(e)}
                                    inputProps={{ style: { textTransform: "uppercase" } }}
                                />
                            </Grid>
                            <Grid item xs={responsive ? 12 : 6} className={responsive ? classes.gridCenter2Mobi : classes.gridCenter2}>
                                <CustomSelectField
                                    fullWidth
                                    required
                                    shrink={true}
                                    error={personError.gender}
                                    helperText={'Campo obrigatório'}
                                    {...{
                                        itens: EnumGenre.getGenreList(),
                                        value: person.gender,
                                        label: "Gênero"
                                    }}
                                    onChange={(e) => handleChangePerson(e)}
                                    name="gender"
                                />
                            </Grid>
                            <Grid item xs={responsive ? 12 : 6} className={responsive ? classes.gridCenter2Mobi : classes.gridCenter2}>
                                <CustomSelectField
                                    fullWidth
                                    required
                                    shrink={true}
                                    error={personError.id_Nationality}
                                    helperText={'Campo obrigatório'}
                                    {...{
                                        itens: countries,
                                        value: person.id_Nationality,
                                        setValue: setNationality,
                                        label: "Nacionalidade"
                                    }}
                                    name="id_Nationality"
                                />
                            </Grid>
                        </Grid>
                    </FormControl>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleClose} className={classes.buttonSearch}>
                        Cancelar
                    </Button>
                    <Button onClick={() => handleClickRegisterPerson()} className={classes.buttonSearch}>
                        Registrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}
