import React from 'react';
import clsx from 'clsx';
import { useStyles } from './styles';
import { Grid, IconButton, InputLabel, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import CustomTextFieldDark from '../CustomTextFieldDark';

export const CustomStepperCounter = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const onIconClick = (operation) => {
        if (operation === '+') {
            if (props.value >= props.maxValue) {
                return;
            }
            props.setValue.setValue({
                ...props,
                value: props.value + 1
            });
            return;
        }

        if (props.value <= props.minValue) {
            return;
        }
        props.setValue.setValue({
            ...props,
            value: props.value - 1
        });
    }
    return (
        <div className={classes.divCustom}>
            <Grid container >
                <Grid Grid item xs={responsive ? 6 : 12}>
                    <Typography variant='h6'>{props.label}</Typography>
                    <Typography variant='subtitle2'>{props.description}</Typography>
                    <br />
                </Grid>
                <Grid container item xs={responsive ? 6 : 12} className={responsive ? classes.centerIconsMobile : classes.centerIcons}>
                    <Grid item xs={2}>
                        <IconButton
                            size="small"
                            onClick={() => onIconClick('-')}
                            disabled={props.disabledSub ? props.disabledSub : false}
                        >
                            <RemoveCircleOutline
                                className={
                                    props.disabledSub ? classes.iconDisabled : classes.icon
                                }
                            />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomTextFieldDark
                            contentEditable={false}
                            disabled
                            value={props.value}
                            inputProps={{
                                disableUnderline: 'true',
                                style: {
                                    textAlign: "center",
                                    color: '',
                                    fontSize: '16pt',
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            size="small"
                            onClick={() => onIconClick('+')}
                            disabled={props.disabledAdd ? props.disabledAdd : false}
                        >
                            <AddCircleOutline
                                className={
                                    props.disabledAdd ? classes.iconDisabled : classes.icon
                                }
                            />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            {/* <div className={classes.root}>
                <InputLabel id={'test'} className={classes.label} align="left">{props.label}</InputLabel>
                <div className={classes.linha}>
                    <div className={classes.borda}>
                        <IconButton size="small" onClick={e => onIconClick('-')}>
                            <RemoveCircleOutline className={classes.icon} fontSize="inherit" />
                        </IconButton>
                    </div>
                    <TextField disabled={true}
                        value={props.value}
                        className={classes.textfield}
                        inputProps={{
                            style: { textAlign: "center" }
                        }}
                    />
                    <div className={classes.borda}>
                        <IconButton size="small" onClick={e => onIconClick('+')}>
                            <AddCircleOutline className={classes.icon} fontSize="inherit" />
                        </IconButton>
                    </div>
                </div>
            </div> */}
        </div >
    )
};

export default CustomStepperCounter;
