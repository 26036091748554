import { makeStyles } from "@material-ui/core/styles";
import { useThemeCustom } from "./theme";

export const useStyles = makeStyles((theme) => {
  theme = useThemeCustom;

  return {
    root: {
      paddingBottom: "20px",
    },

    containerDuvidas: {
      margin: "30px 20px 20px 20px",
      textAlign: "center",
    },

    titleDuvidas: {
      textAlign: "left",
      fontFamily: "Arial",
      fontSize: "18px",
    },

    gridTitle: {
      backgroundColor: "rgb(109,92,140)",
      textAlign: "center",
      color: "#FFF7FF",
      marginBottom: "20px",
      padding: "20px 20px 15px 20px",
    },

    textTitleCard: {
      fontFamily: "Arial",
      marginBottom: "5px",
    },

    textTitle: {
      fontWeight: "bold",
      fontSize: "18pt",
      fontFamily: "Arial",
    },

    text: {
      fontFamily: "Arial",
    },

    divIcon: {
      background:
        "linear-gradient(270deg, rgba(118,69,161,1) 0%, rgba(155,101,189,1) 40%, rgba(185,142,232,1) 100%)",
      borderRadius: "30px",
      padding: "8px",
      display: "flex",
      alignItems: "center",
    },
  
    icon: {
      color: "#FFF7FF",
      fontSize: "2.2rem",
    },

    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      backgroundColor: "#FFF7FF",
      margin: "10px 20px 10px 20px",
      padding: "15px 20px 15px 20px",
      boxShadow:
        "0px 3px 3px -2px rgb(109 92 140 / 80%), 0px 3px 4px 0px rgb(109 92 140 / 56%), 0px 1px 8px 0px rgb(109 92 140 / 48%)",
    },
  };
});
