import { Box, Button, Typography } from "@material-ui/core";
import { DoneOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";

export const FacialBiometricsStep07 = (props) => {
    const classes = useStyles();

    const tryPicturesAgain = () => {
        props.handleBackTwice();
        props.setFileDocFront(null);
        props.setFileDocVerse(null);
    }

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Typography variant="h6">Confirmação das fotos do documento</Typography>
            </Box>
            <Box textAlign={'center'} paddingTop={1}>
                <img
                    src={props.fileDocFront}
                    alt={'foto frontal do documento'}
                    style={{ borderRadius: '10px' }}
                    width={270}
                />
            </Box>
            <Box textAlign={'center'}>
                <img
                    src={props.fileDocVerse}
                    alt={'foto traseira do documento'}
                    style={{ borderRadius: '10px' }}
                    width={270}
                />
            </Box>
            <Box paddingTop={'1rem'} display={'flex'} justifyContent={'space-around'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => tryPicturesAgain()} className={classes.buttonOrange} startIcon={<KeyboardReturnOutlined />}>
                    Refazer fotos
                </Button>
                <Button onClick={() => props.handleNext()} className={classes.buttonOrange} startIcon={<DoneOutlined />}>
                    Confirmar
                </Button>
            </Box>
        </>
    )
}
