import { createTheme } from "@material-ui/core";

export const useThemeCustom = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 414,
      md: 630,
      lg: 1200,
      xl: 1536,
    },
  },
});
