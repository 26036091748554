import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#612F74',
        color: '#FFF7FF',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        },
    },
}))(TableRow);

export const TableRules = (props) => {
    const classes = useStyles();
    const data = props.data;

    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table" stickyHeader >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                <Typography variant="h5">{props.getInfoByIata(data.airCompanie).name}</Typography>
                            </StyledTableCell>
                            {data.bagagges.map((item) => (
                                <StyledTableCell align="center">
                                    <Typography>{item}</Typography>
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.optionsBagagge.map((row) => (
                            <StyledTableRow >
                                <StyledTableCell component="th" scope="row">
                                    {row.title}
                                </StyledTableCell>
                                {row.itens.map((item) => (
                                    <StyledTableCell align="center">
                                        <Typography>
                                            {item}
                                        </Typography>
                                    </StyledTableCell>
                                ))}
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                <Grid container>
                    <Grid item xs={12} className={classes.obsText}>
                        {data.observation.map((text) => (
                            <Typography variant="subtitle2">
                                {text}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
                <Grid container className={classes.gridFonte}>
                    <Grid item xs={12} className={classes.gridLogo}>
                        <Typography>Fonte</Typography>
                        <a href={data.link} target={'_blank'} >
                            <img src={props.getInfoByIata(data.airCompanie).logo} height={'35'}></img>
                        </a>
                    </Grid>
                </Grid>
            </TableContainer>
        </>
    )
}
