import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./styles";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import RecuperacaoSenhaMobi from "./Mobile";
import RecuperacaoSenhaDesk from "./Desk";
import emailServices from "../../services/Email";
import { useHistory } from "react-router";

export const RecuperacaoSenhaPage = (props) => {
  const token = props.match.params.forgotPassword;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const recoveryAccount = async () => {
    try {
      setIsLoading(true);

      if (values.password == null || values.password.length <= 0) {
        setSnackVariant("warning");
        setSnackMessage("A senha não pode ser vazia");
        setSnackVisible({ visible: true });
        return;
      }
      if (
        values.confirmPassword == null ||
        values.confirmPassword.length <= 0
      ) {
        setSnackVariant("warning");
        setSnackMessage("A confirmação de senha não pode ser vazia");
        setSnackVisible({ visible: true });
        return;
      }
      if (values.confirmPassword !== values.password) {
        setSnackVariant("warning");
        setSnackMessage("Senhas não coincidem.");
        setSnackVisible({ visible: true });
        return;
      }

      await emailServices.putForgotPassword(token, values.password);

      setSnackVariant("success");
      setSnackMessage("Senha atualizada com sucesso.");
      setSnackVisible({ visible: true });

      setTimeout(() => history.push("/entrar"), 2500);
    } catch (e) {
      if (e.response.data.message) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
      } else if (Object.values(e.response.data.errors)[0][0]) {
        setSnackVariant("warning");
        setSnackMessage(Object.values(e.response.data.errors)[0][0]);
        setSnackVisible({ visible: true });
      } else {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar atualizar senha.");
        setSnackVisible({ visible: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />

      {responsive ? (
        <RecuperacaoSenhaMobi
          {...{
            isLoading,
            values,
            handleChange,
            recoveryAccount,
          }}
        />
      ) : (
        <RecuperacaoSenhaDesk
          {...{
            isLoading,
            values,
            handleChange,
            recoveryAccount,
          }}
        />
      )}
    </div>
  );
};

export default RecuperacaoSenhaPage;
