import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import padlock from '../../../../images/assets/padlock.svg';
import { useStyles } from "../styles";
import serpro from '../../../../images/assets/serpro.png';

export const CCAuthStep01 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Box textAlign={'center'} paddingTop={'4rem'}>
                <img src={serpro} height={'60rem'} style={{ color: '#612f74' }} />
            </Box>
            <Box textAlign={'center'} padding={2}>
                <Typography variant="body1">
                    Solicitamos que cadastre o  cartão para assegurar a sua compra.
                </Typography>
                <Typography variant='h6'>
                    <b>Atenção</b>
                </Typography>
                <Typography variant='body1'>
                    O titular do cartão deve ser o mesmo do usuário cadastrado.
                </Typography>
            </Box>
            <Box textAlign={'-webkit-center'} paddingTop={'1rem'} display={'flex'} justifyContent={'center'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => props.handleNext()} className={classes.buttonOrange}>
                    Entendi
                </Button>
            </Box>
        </>
    )
}
