import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({


    img: {
        // maxWidth: '-webkit-fill-available',
        maxWidth: '90%',
    },

    img2: {
        maxWidth: '-webkit-fill-available',
    },

    zulminhaDiv: {

    },

    typograph_01: {
        fontSize: '10pt',
        border: '2px solid #612F74',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#716f71'
    },

    typograph_02: {
        borderRadius: '10px',
        color: '#fff7ff',
        paddingTop: '10px',
        fontWeight: 'bold',
        textAlign: '-webkit-center',
        justifyContent: 'space-around'
    },

    typograph_03: {
        // background: '#fff7ff',
        // borderRadius: '20px',
        // color: '#ff7500',
        // padding: '5px 20px 5px 20px',
        fontWeight: '800',
    },

    typograph_04: {
        color: '#612F74',
        fontWeight: '800',
    },

    typograph_05: {
        textAlign: 'center',
        color: '#a0a1a4 ',
    },

    typograph_06: {
        textAlign: 'center',
        color: '#716f71',
    },

    typograph_07: {
        textAlign: 'center',
        width: '-webkit-fill-available',
        background: '#a0a1a4',
        borderRadius: '10px',
        color: '#fff7ff',
        padding: '10px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },

    typograph_08: {
        // width: '-webkit-fill-available',
        color: '#fff7ff',
    },

    typograph_09: {
        cursor: "pointer",
        position: "absolute",
        color: '#fafafa',
        fontSize: '20pt',
        fontWeight: 'bold',
        marginTop: '6rem',
        marginLeft: '2rem',
        textShadow: "-1px -1px 3px #32323294, -1px 1px 3px #32323294, 1px -1px 3px #32323294, 1px 0px 3px #32323294"
    },
    typograph_10: {
        cursor: "pointer",
        position: "absolute",
        color: '#fafafa',
        fontSize: '16pt',
        fontWeight: 'bold',
        marginBottom: '4rem',
        marginLeft: '2rem',
        textShadow: "-1px -1px 3px #32323294, -1px 1px 3px #32323294, 1px -1px 3px #32323294, 1px 0px 3px #32323294"
    },

    typograph_11: {
        color: '#fafafa',
        position: "absolute",
        fontWeight: 'bold',
        textShadow: "-1px -1px 2px #323232, -1px 1px 2px #323232, 1px -1px 2px #323232, 1px 0px 2px #323232"

    },

    bola: {
        background: '#ff7500',
        borderRadius: '50%',
        height: '15px',
        width: '15px',
        marginLeft: '10px',
        marginRight: '10px',
    },


    customGridMain: {
        marginTop: '10px',
        marginBottom: '10px',
        background: 'none',
        maxWidth: '90vw',
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },

    customGrid02: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '20px',
    },

    customGrid03: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    customGrid04: {
        marginTop: '10px',
        marginBottom: '10px',
        background: '#ff7500',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    customGrid05: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#612F74',
        borderRadius: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
        marginTop: '20px',
    },

    customGrid06: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },


    customGridCompanies: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    customGridCompaniesWithBorder: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: '3px solid #612F74',
    },

    container: {
        display: 'flex',
        justifyContent: 'center'
    },

    boxCompanies: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        width: '-webkit-fill-available',
    },

    buttonCustom: {
        // width: '100%',
        background: '#fff7ff',
        padding: '5px 20px 5px 20px',
        color: '#ff7500',
        '&:hover': {
            background:
                'rgba(255, 247, 255, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },

    buttonCustom2: {
        position: 'absolute',
        marginBottom: '10px',
        // width: '100%',
        background: '#fff7ff',
        padding: '5px 20px 5px 20px',
        color: '#ff7500',
        '&:hover': {
            background:
                'rgba(255, 247, 255, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },

    customPaperCarousel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFF7FF'
    }
}));
