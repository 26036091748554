import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },

  textTitle: {
    fontWeight: "bold",
    fontSize: "18pt",
  },

  textTitleBold: {
    fontSize: "23pt",
    fontWeight: "bold",
  },

  paperText: {
    color: "gray",
    padding: "10px 30px 10px 30px",
    textAlign: "left",
    minHeight: "100%",
    backgroundColor: "#fceefe",
  },

  divTitle: {
    background:
      "linear-gradient(180deg, rgba(255,117,0,1) 0%, rgba(255,117,0,1) 20%, rgba(237,149,1,1) 20%, rgba(237,149,1,1) 100%)",
    textAlign: "center",
    zIndex: 1,
    color: "#fff",
    padding: "25px 0px 7px 0px",
    // borderRadius: '10px'
  },

  paperCarousel: {
    background: "none",
  },

  mainContainer: {
    width: "100%",
  },

  textBold: {
    marginLeft: "20px",
    fontSize: "19pt",
    fontWeight: 600,
  },

  gridContainer: {
    padding: "0px 15px 0px 15px",
  },

  gridText: {
    color: "gray",
    marginTop: "20px",
    padding: "0px 20px 0px 20px",
    textAlign: "justify",
  },

  gridCarousel: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },

  gridBanner: {
    textAlign: "center",
    margin: "30px 10px 0px 10px",
    borderRadius: "10px",
    padding: "8px",
  },

  textBanner: {
    fontSize: "14pt",
    fontWeight: 700,
  },
}));
