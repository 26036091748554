import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

const CustomTextFieldDark = withStyles({
    root: {
        marginTop: '0px',
        marginBottom: '0px',
        '& .MuiFormHelperText-root.Mui-error': {
            position: 'absolute',
            top: '100%'
        },
        "& .MuiInputBase-root": {
            color: "#612F74",
        },
        "& label": {
            color: "#612F74",
        },
        "& label.Mui-focused": {
            color: "#612F74",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "#612F74",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "#612F74",
            },
            "&:hover fieldset": {
                borderColor: "#ffa500",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#612F74",
            },
        },
    },
})(TextField);

export default CustomTextFieldDark;
