import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Dashboard } from "../Dashboard";
import DialogWhats from "./Dialogs/Whats";
import DialogEmail from "./Dialogs/Email";
import DialogTelefone from "./Dialogs/Telefone";
import CentralDeAjudaMobi from "./Mobile";
import { useStyles } from "./styles";
import CentralDeAjudaDesk from "./Desk";

export const CentralDeAjudaPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [openWhats, setOpenWhats] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const [openTel, setOpenTel] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      <DialogWhats
        {...{
          open: openWhats,
          setOpen: setOpenWhats,
        }}
      />
      <DialogEmail
        {...{
          open: openEmail,
          setOpen: setOpenEmail,
        }}
      />
      <DialogTelefone
        {...{
          open: openTel,
          setOpen: setOpenTel,
        }}
      />

      {responsive ? (
        <CentralDeAjudaMobi
          {...{
            openWhats,
            setOpenWhats,
            openEmail,
            setOpenEmail,
            openTel,
            setOpenTel,
          }}
        />
      ) : (
        <CentralDeAjudaDesk
          {...{
            openWhats,
            setOpenWhats,
            openEmail,
            setOpenEmail,
            openTel,
            setOpenTel,
          }}
        />
      )}
    </div>
  );
};

export const DashCentralDeAjuda = (props) => {
  return (
    <div>
      <Dashboard {...props} component={CentralDeAjudaPage} />
    </div>
  );
};
export default DashCentralDeAjuda;
