
import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Box, Step, StepConnector, StepLabel, Stepper } from "@material-ui/core";
import { Check } from "@material-ui/icons";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    active: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#612F74"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#612F74",
        zIndex: 1,
        fontSize: 18
    }
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};

function getSteps() {
    return ["1", "2", "3"];
}

export const CCAuthStepper = (props) => {
    const steps = getSteps();

    return (
        <>
            <Box style={{ width: '-webkit-fill-available', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Stepper
                    alternativeLabel
                    activeStep={props.activeStep}
                    connector={<QontoConnector />}
                    style={{ padding: '4px' }}
                >
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </>
    )
}
