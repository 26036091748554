import { Avatar, Button, Grid, List, ListItem, ListItemAvatar, ListItemText, Slide, Typography, useMediaQuery, Divider, useTheme, withStyles, Box, RadioGroup, Radio } from '@material-ui/core';
import React, { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListItemCustom = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#fceefe",
            cursor: 'pointer',
        }
    },
})(ListItem);

export const BuyingDeskStep03 = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [valueCC, setValueCC] = useState(0);

    const handleChangeCC = (event) => {
        setValueCC(event.target.value);
    };

    useLayoutEffect(() => {
        if (props.dtoCompra.idInstallment > 0) {
            setValueCC(props.dtoCompra.idInstallment)
        }
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Em quantas vezes?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <List>
                        <ListItem>
                            <ListItemAvatar style={{ width: '100px', textAlign: 'center' }}>
                                <Box className={classes.ccBox} style={{ background: `${props.getColor(props.opcoesUsadas.cartao.cardFlag).background}` }}>
                                    <img height={35} width={60} src={props.opcoesUsadas.cartao.urlFlag} />
                                </Box>
                            </ListItemAvatar>
                            <ListItemText style={{ marginLeft: '5px' }} primary={`${props.opcoesUsadas.cartao.surname}`} secondary={`${props.opcoesUsadas.cartao.cardNumber}`} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <RadioGroup aria-label="optionCC" name="optionCC" value={valueCC} onChange={handleChangeCC}>
                        <Grid container>
                            {props.optionsCreditCard.map((option) => (
                                <Grid style={{ borderBottom: '1px solid rgb(97, 47, 116)' }} item xs={12} onClick={() => { setValueCC(option.id); props.handleClickSelectOption(option) }} className={classes.gridItem}>
                                    <Box display={'flex'} alignItems={'center'}>

                                        <Radio
                                            value={option.id}
                                            checked={valueCC == option.id}
                                            onClick={() => { setValueCC(option.id) }}
                                            style={{ color: '#612F74' }}
                                        />
                                        {option.quantity === 1 ? (
                                            <Typography>
                                                {`${option.quantity}x ${option.installment}`}
                                            </Typography>
                                        ) : (
                                            <Box flexDirection={'row'}>
                                                <Typography>
                                                    {`1x de ${option.firstInstallment} + `}
                                                </Typography>
                                                <Typography>
                                                    {`${option.quantity - 1}x de ${option.installment}`}
                                                </Typography>
                                            </Box>
                                        )
                                        }
                                    </Box>
                                    {!option.hasInterest ? (
                                        <Typography>
                                            {`Sem Juros`}
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {`Total de ${option.total}`}
                                        </Typography>
                                    )
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </>
    )
}
