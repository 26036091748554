import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
        },
    },

    tipoTrechoM: {
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },

    buttonDefault: {
        borderRadius: '4px',
        background: 'rgba(255, 247, 255, 0.8)',
        border: 'solid 1px #612F74'
    },

    buttonSelected: {
        color: "white",
        background:
            "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
    },

    buttonNotSelected: {
        background: 'white',
        color: 'black'
    },

    boxcustom: {
        height: '40vh',
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center'
    },

    filtros2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        padding: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: theme.spacing(0),
        },
    },

    trecho1: {
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
        },
    },

    trecho2: {
        width: '30%',
        marginLeft: '1%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
        },
    },

    udate: {
        width: '20%',
        marginLeft: '1%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
            marginTop: theme.spacing(3),
        },
    },

    button3: {
        width: '100%',
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '15px'
    },

    passageiros: {
        width: 90,
        marginRight: '3%',
        marginLeft: '3%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            paddingTop: theme.spacing(2)

        },
    },

    add: {
        marginLeft: theme.spacing(1),
        background: 'transparent',
        borderRadius: '0px',
        color: '#c62828',
        border: 'solid 1px #c62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            paddingTop: '10px'
        },
    },

    gridFieldsSearch: {
        padding: '5px 0px 5px 0px'
    },

    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },

    videoContainerVideo: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 0,
    },

    videoContainerCaption: {
        zIndex: 1,
        position: 'relative',
        textAlign: 'center',
        color: '#dc0000',
    },


}));
