import DateFnsUtils from '@date-io/date-fns';
import { createTheme, ThemeProvider, useMediaQuery, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import locale from "date-fns/locale/pt-BR";
import React from 'react';
import { useState } from 'react';
import CustomTextFieldDark from '../CustomTextFieldDark';

const Theme = createTheme({
    palette: {
        primary: {
            main: '#612F74'
        },
        secondary: {
            main: '#ffa500'
        },
    },
    '& .MuiFormHelperText-root.Mui-error': {
        position: 'absolute',
        top: '100%'
    },
})


function CustomDatePicker(props) {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        open: false,
    });

    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    maxDate.setDate(maxDate.getDate() - 7);

    const handleDateChange = (xdate) => {
        if (props.setDate) {
            props.setDate(xdate);
        }
        if (props.setValueArray) {
            let array = [...props.valueArray];
            array[props.index][props.tipoArray] = xdate;
            props.setValueArray(array);
        }
        if (props.eventOnChange) {
            props.eventOnChange({ target: { name: props.name, value: xdate } });
        }
        if (props.onChange) {
            props.onChange(props.name, xdate);
        }
        if (props.onChangeChild) {
            props.onChangeChild();
        }

    };

    const setPickerStatus = (status) => {
        setState({
            open: status
        });
    };

    return (
        <ThemeProvider theme={Theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
                {/* <Grid container justifyContent="space-around"> */}
                {/* <Grid container> */}
                <KeyboardDatePicker
                    onClick={() => setPickerStatus(true)}
                    onClose={() => setPickerStatus(false)}
                    open={state.open}
                    autoOk
                    InputProps={props.readOnly === undefined || props.readOnly === false ? null : { readOnly: 'true' }}
                    readOnly={props.readOnly === undefined || props.readOnly === false ? null : { readOnly: 'true' }}
                    initialFocusedDate={props.initialFocusedDate}
                    disablePast={props.disablePast ? props.disablePast : false}
                    minDate={props.minDate ? props.minDate : new Date(1900, 0, 1)}
                    maxDate={maxDate}
                    minDateMessage={'A data não deve ser anterior à data mínima'}
                    required={props.required}
                    fullWidth
                    variant={responsive ? 'dialog' : 'inline'}
                    orientation="portrait"
                    cancelLabel="Cancelar"
                    inputVariant="filled"
                    margin="dense"
                    invalidDateMessage="Formato de data inválido!"
                    name={props.name}
                    id="date-picker-dialog"
                    TextFieldComponent={CustomTextFieldDark}
                    label={props.label ? props.label : 'default'}
                    format="dd/MM/yyyy"
                    value={props.date || null}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}


                    className={props.className ? props.className : null}
                    disabled={props.disabled ? props.disabled : false}
                    helperText={props.helperText ? (props.error ? props.helperText : '') : ''}
                    error={props.error ? props.error : false}
                />
                {/* </Grid> */}
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
}

export default CustomDatePicker;
