import api from '../../Api';

const airPortsServices = {

    getAllAirportsAutoComplete: async () => {
        const response = await api.get('/Airport/get-all?autocomplet=true');
        return response;
    },
}

export default airPortsServices;
