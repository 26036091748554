import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import AD from "../../../../images/Logos/AD.svg";
import G3 from "../../../../images/Logos/G3.svg";
import LA from "../../../../images/Logos/LA.svg";
import zulminha from "../../../../images/Marketing/Zulminha.svg";
import fofo from "../../../../images/Marketing/bagagem_easy.png";
import testeBoxImg from "../../../../images/Marketing/central de ajuda/como_podemos_te_ajudar.png";
import banner_AuthBio from "../../../../images/assets/Biometry/banner_AuthBio.jpg";
import sono from "../../../../images/Marketing/nao_perca_sono_524x378.png";
import quemSomos from "../../../../images/Marketing/quem_somos.png";
import { useStyles } from "./styles";

export const LandPageDesktop = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const clickGoToZulminha = () => {
    history.push("/zulminha");
  };

  const handleClickPromo = (city) => {
    props.handleClickOpenPromocoes(city);
  };

  return (
    <div className={classes.container} style={{ marginTop: "15px" }}>
      <Grid container style={{
        justifyContent: "center",
        marginBottom: "10px",
        marginTop: "10px",
        maxWidth: "1100px",
      }}>

        {props.hideCardAuthSerpro ? null : (
          <>
            <Grid item xs={12} style={{ width: "100%", cursor: "pointer" }} className={classes.customGrid02} onClick={() => props.setOpenDialogAuth(true)}>
              <img src={banner_AuthBio} width="100%" style={{ borderRadius: '10px' }} />
            </Grid>
          </>
        )}

        <Grid item xs={12} className={classes.customGrid02}>
          <Typography variant="h4" style={{ width: "100%" }} className={classes.typograph_02}>
            Exclusivo redutor de tarifa
            <Button onClick={() => clickGoToZulminha()} className={classes.buttonCustom}>
              <Typography variant="h6" className={classes.typograph_03}>
                QUERO CONHECER
              </Typography>
            </Button>
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ width: "100%", cursor: "pointer" }} className={classes.customGrid02} onClick={() => history.push("/zulminha#bagagemEasy")}>
          <img alt="" src={fofo} width="100%" />
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Grid item xs={4} className={classes.customGridCompaniesWithBorder}>
            <img src={LA} height={50} className={classes.img}></img>
            <Box className={classes.boxCompanies}>
              <Typography className={classes.typograph_04}>
                Parcelado em
              </Typography>
              <Typography className={classes.typograph_04} variant={"h5"}>
                {" "}
                &nbsp; até 4x
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.customGridCompanies}>
            <img src={AD} height={50} style={{ marginBottom: "20px" }} className={classes.img} />
            <Box className={classes.boxCompanies}>
              <Typography className={classes.typograph_04}>
                Parcelado em
              </Typography>
              <Typography className={classes.typograph_04} variant={"h5"}>
                {" "}
                &nbsp; até 10x
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.customGridCompaniesWithBorder}>
            <img src={G3} height={50} className={classes.img} />
            <Box className={classes.boxCompanies}>
              <Typography className={classes.typograph_04}>
                Parcelado em
              </Typography>
              <Typography className={classes.typograph_04} variant={"h5"}>
                {" "}
                &nbsp; até 5x
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain} style={{ margin: "-20px 0px -20px 0px" }}>
          <Box className={classes.boxCompanies}>
            <Typography variant={"h6"} className={classes.typograph_05}>
              {" "}
              Promoções com quantidades de <b>tentativa de redução</b>
            </Typography>
          </Box>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              ORIGEM: Florianópolis
            </Typography>
          </Box>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          {props.promotionalsFlights.map((item) => (
            <Grid id={item.arrivalCity} item onClick={() => handleClickPromo(item.arrivalCity)} xs={3} style={{
              padding: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <Typography className={classes.typograph_10}>
                {item.arrivalCity}
              </Typography>
              <Typography className={classes.typograph_09}>
                {item.total}
              </Typography>
              <img src={item.urlPromo} style={{ cursor: "pointer" }} className={classes.img} alt={item.arrivalCity} />
            </Grid>
          ))}
        </Grid>

        <Grid item xs={12} style={{ width: "100%", marginTop: "15px" }} className={classes.customGrid02}>
          <Typography variant="h4" className={classes.typograph_07}>
            Você garante sua tarifa e a Zulminha busca reduzir
          </Typography>
        </Grid>

        <Grid container item xs={12} style={{ width: "100%", marginTop: "15px" }} className={classes.customGrid02}>
          <Grid item xs={6} style={{ display: "flex" }}>
            <img src={sono} className={classes.img2R} alt="" />
          </Grid>
          <Grid item xs={6} className={classes.customGrid03}>
            <img src={zulminha} className={classes.img2L} alt="" />
            <Typography variant={"h5"} className={classes.typograph_11} style={{ marginBottom: "16rem" }}>
              RESTAM
            </Typography>
            <Typography className={classes.typograph_11} style={{ marginBottom: "12rem", fontSize: "33pt" }}>
              {props.vacanciesText.value} CADASTROS
            </Typography>
            <Typography className={classes.typograph_11} style={{
              color: "#FFFF00",
              marginBottom: "3em",
              fontSize: "20pt",
            }}>
              Lote {props.vacanciesText.batch}
            </Typography>
            <Button onClick={() => history.push("/registrar")} className={classes.buttonCustom2}>
              <Typography variant="h6" className={classes.typograph_03}>
                CADASTRE-SE AGORA
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ width: "100%", cursor: "pointer", marginTop: "15px" }} className={classes.customGrid02} onClick={() => history.push("/quemsomos")}>
          <img alt="" src={quemSomos} width="100%" />
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain} style={{ marginTop: "0px" }}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              Somos a primeira agência de <b>Viagens Virtual do Mundo</b>, e que
              possui inteligência artificial embarcada.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.customGrid04} style={{ marginTop: "25px" }}>
          <Grid container item xs={12} className={classes.customGridMain}>
            <Grid item xs={6} className={classes.customGrid03}>
              <Typography variant="h4" className={classes.typograph_08}>
                Precisa de ajuda?
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.customGrid03}>
              <Typography variant="h4" style={{ cursor: "pointer" }} className={classes.typograph_08} onClick={() => history.push("/central-de-ajuda")}>
                <strong>Acesse aqui.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
