import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import { useStyles } from "./styles";

export const FilterPanel = (props) => {
    const voos = props.voos;
    const stepIndex = props.stepIndex;
    const classes = useStyles();
    const la = props.la;
    const g3 = props.g3;
    const ad = props.ad;
    const all = props.all;
    const allStops = props.allStops;
    const one = props.one;
    const twoOrMore = props.twoOrMore;
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));

    useLayoutEffect(() => {
        props.setShowFilterStops({
            one: voos[stepIndex].flights.some(i => i.numberOfStops == 0),
            twoOrMore: voos[stepIndex].flights.some(i => i.numberOfStops >= 1),
        })

        props.setShowFilterStopsCounter({
            one: (voos[stepIndex].flights.filter(i => i.numberOfStops == 0)).length,
            twoOrMore: (voos[stepIndex].flights.filter(i => i.numberOfStops >= 1)).length,
        })

        props.setShowFilter({
            la: voos[stepIndex].flights.some(i => i.airCompanie.iata == 'LA' || i.airCompanie.iata == 'JJ'),
            g3: voos[stepIndex].flights.some(i => i.airCompanie.iata == 'G3'),
            ad: voos[stepIndex].flights.some(i => i.airCompanie.iata == 'AD')
        })
        props.setShowFilterCounter({
            la: (voos[stepIndex].flights.filter(i => i.airCompanie.iata == 'LA' || i.airCompanie.iata == 'JJ')).length,
            g3: (voos[stepIndex].flights.filter(i => i.airCompanie.iata == 'G3')).length,
            ad: (voos[stepIndex].flights.filter(i => i.airCompanie.iata == 'AD')).length,
        })

    }, [stepIndex])

    return (
        <>

            <Paper elevation={responsive ? 0 : 4} className={responsive ? classes.paperMobile : classes.paper}>
                <Grid container  >
                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h5"><b>FILTROS</b></Typography>
                    </Grid>
                    <Divider variant="middle" />
                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h6">Paradas</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormGroup>
                                {/* <FormControlLabel control={<Checkbox style={{ color: 'white' }} checked={all} onChange={props.handleChangeIata} name="all" />} label="Todos" /> */}
                                {props.showFilterStops.one ? (
                                    <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={one} onChange={props.handleChangeNumberOfStops} name="one" />}
                                        label={<div className={classes.formControlLabelDiv}><Typography noWrap>1 Parada (Direto)</Typography> <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{props.showFilterStopsCounter.one.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography></div>}
                                    />
                                ) : null}
                                {props.showFilterStops.twoOrMore ? (
                                    <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={twoOrMore} onChange={props.handleChangeNumberOfStops} name="twoOrMore" />}
                                        label={<div className={classes.formControlLabelDiv}><Typography noWrap>2 Paradas ou mais </Typography> <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{props.showFilterStopsCounter.twoOrMore.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography></div>}
                                    />
                                ) : null}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.gridItem}>
                        <Typography variant="h6">Cias</Typography>
                    </Grid>
                    {props.showFilter.ad || props.showFilter.g3 || props.showFilter.la ? (
                        <Grid item xs={12} className={classes.gridItem}>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={all} onChange={props.handleChangeIata} name="all" />} label={
                                        <div className={classes.formControlLabelDiv}>
                                            Todos
                                            <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{(props.showFilterCounter.ad + props.showFilterCounter.g3 + props.showFilterCounter.la).toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography>
                                        </div>}
                                    />
                                    {props.showFilter.ad ? (
                                        <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={ad} onChange={props.handleChangeIata} name="ad" />}
                                            label={<div className={classes.formControlLabelDiv}>Azul <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{props.showFilterCounter.ad.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography></div>}
                                        />
                                    ) : null}
                                    {props.showFilter.g3 ? (
                                        <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={g3} onChange={props.handleChangeIata} name="g3" />}
                                            label={<div className={classes.formControlLabelDiv}>Gol <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{props.showFilterCounter.g3.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography></div>}
                                        />
                                    ) : null}
                                    {props.showFilter.la ? (
                                        <FormControlLabel classes={{ label: classes.labelInsideFormControlLabel }} className={classes.formControlLabel} control={<Checkbox style={{ color: '#612f74' }} checked={la} onChange={props.handleChangeIata} name="la" />}
                                            label={<div className={classes.formControlLabelDiv}>Latam <Typography className={responsive ? classes.typoCounterMobile : classes.typoCounter}>{props.showFilterCounter.la.toLocaleString('pt-BR', { minimumIntegerDigits: 2 })}</Typography></div>}
                                        />
                                    ) : null}
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    ) : null}
                </Grid>
            </Paper>

        </>
    )
}

export default FilterPanel;
