import { makeStyles } from "@material-ui/core/styles";
// import Fundo from "../../images/fundoLogin.svg";
import Fundo from "../../../images/Fundos/desktop_login_1080_1920.png";
export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${Fundo})`,
    backgroundSize: "cover" /* <------ */,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    width: "60vw",
    height: "70vh",
    background: "rgba(255,255,255,0.2)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  stepper: {
    display: "flex",
    background: "none",
    width: "-webkit-fill-available",
    justifyContent: "center",
  },
  button: {
    color: "white",
    fontWeight: "800",
    background:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    margin: "5px",
  },
  gridCenter: {
    display: "grid",
    justifyContent: "center",
  },
  button3: {
    minWidth: 70,
    background:
      '#ff7500',
    color: 'white',
    '&:hover': {
      background:
        'rgba(255, 117, 0, 0.8)'
      // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
    },
    borderRadius: '5px'
  },
}));
