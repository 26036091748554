import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'flex',
        },
    },

    custompaper: {
        background: 'none',
        maxWidth: '1100px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },

    tipoTrechoM: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },

    tipoTrecho: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        }
    },

    buttonDefault: {
        borderRadius: '4px',
        background: 'rgba(255, 247, 255, 0.8)',
        border: 'solid 1px #612F74'
    },
    buttonSelected: {
        color: "white",
        background:
            "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
    },
    buttonNotSelected: {
        background: 'white',
        color: 'black'
    },

    boxcustom: {
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center'
    },

    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },

    videoContainerVideo: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 0,
    },

    videoContainerCaption: {
        zIndex: 1,
        position: 'relative',
        textAlign: 'center',
        color: '#dc0000',
    },





























    filtrosContent: {
        width: '100%',
        paddingTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    button: {
        background: 'transparent',
        borderRadius: '0px',
        color: '#c62828',
        border: 'solid 1px #c62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            width: '59%',
            marginRigth: '10%'
        },
    },

    filtros: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
    },

    filtros2: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        padding: theme.spacing(0),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            padding: theme.spacing(0),
        },
    },





    boxTipoVoo: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: '15px'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
        }
    },

    bagagem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },

    trecho1: {
        width: '30%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
        },
    },

    trecho2: {
        width: '30%',
        marginLeft: '1%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
        },
    },

    textfield: {
        width: '80%'
    },

    udate: {
        width: '20%',
        marginLeft: '1%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
            marginTop: theme.spacing(3),
        },
    },

    udate2: {
        width: '20%',
        marginLeft: '1%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
        },
    },

    button2: {
        width: '20%',
        marginLeft: '2%',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: '90%',
            marginBottom: theme.spacing(2)
        },
    },

    button3: {
        width: '100%',
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '15px'
    },

    passageiros: {
        width: 90,
        marginRight: '3%',
        marginLeft: '3%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '50%',
            paddingTop: theme.spacing(2)

        },
    },
    pacotinho: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '3%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,

        },
    },
    tourcode: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    voos: {
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '0',
            marginLeft: '0',
        }
    },

    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },

    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
                width: 200,
            },
        },
    },

    lista: {
        padding: theme.spacing(5)
    },
    add: {
        marginLeft: theme.spacing(1),
        background: 'transparent',
        borderRadius: '0px',
        color: '#c62828',
        border: 'solid 1px #c62828',
        '&:hover': {
            background: '#c62828',
            color: 'white'
        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            paddingTop: '10px'
        },
    },
    container: {
        maxHeight: 300
    },
    juntinho: {
        flexWrap: 'nowrap',
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            width: '100%',
            marginTop: theme.spacing(1),
            marginLeft: 0,
            alignItems: 'center',
            justifyContent: 'center'
        },
    },
    txtTourCode: {
        [theme.breakpoints.down('sm')]: {
            width: '90%'
        },
    },
    tudojunto: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        marginTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
        }
    }

}));
