import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const personService = {

    postCreatePerson: async (person) => {
        let response = await api.post('/Passenger/create', person, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getAllPerson: async (autocomplet = false) => {
        let response = await api.get(`/Passenger/get-all?autocomplet=${autocomplet}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getPerson: async (idpax) => {
        let response = await api.get(`/Passenger/get/${idpax}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    deletePerson: async (idpax) => {
        let response = await api.delete(`/Passenger/remove/${idpax}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    isAuthRF: async () => {
        let response = await api.get(`/Person/is-authenticated-receita-federal`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default personService;
