import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles(() => ({

    dialogTitle: {
        background: '#612F74',
    },

    typoTitle: {
        color: '#fafafa',
    },

    dialogContent: {
        width: '700px',
    },

    buttonDelete: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    buttonCancel: {
        color: '#fafafa',
        background: '#808080',
        '&:hover': {
            background: '#999999',
        },
    },

}));
