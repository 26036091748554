import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

    dialogTitle: {
        background: '#612F74',// roxo principal
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '3.5rem',
    },

    typoTitle: {
        marginLeft: '1rem',
        color: '#FFF7FF'//cor do fundo
    },

    buttonClose: {
        marginRight: '1rem',
        color: '#FFF7FF'
    },

}));
