import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import CustomTextFieldDark from '../CustomTextFieldDark';

export const CustomSelectField = (props) => {

    const handleChange = (event) => {
        if (props.setValue) {
            props.setValue(event.target.value);
        }
        if (props.onChange) {
            props.onChange(event);
        }
    }

    return (
        <div>
            <CustomTextFieldDark
                fullWidth
                InputLabelProps={{ shrink: props.shrink }}
                helperText={props.helperText ? (props.error ? props.helperText : '') : ''}
                error={props.error ? props.error : false}
                required={props.required ? props.required : false}
                variant="outlined"
                disabled={props.disabled ? props.disabled : false}
                margin="dense"
                label={props.label ? props.label : null}
                name={props.name ? props.name : 'default'}
                className={props.className}
                value={props.value}
                onChange={(e) => handleChange(e)}
                select
            >
                {props?.itens?.map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </CustomTextFieldDark>
        </div>
    )
};

export default CustomSelectField;
