import { Box, Button, Grid, Grow, Icon, IconButton, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { AddOutlined, DeleteOutline, EditOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import { DeletePersonDialog } from "../../../templates/DeletePersonDialog";
import DialogMessage from "../../../templates/DialogMessage";
import { RegisterPersonDialog } from "../../../templates/RegisterPersonDialog";

import { useStyles } from "./styles";

export const DeskPeopleList = (props) => {
    const classes = useStyles();
    const [grow, setGrow] = useState(true);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [openRegisterPerson, setOpenRegisterPerson] = useState(false);
    const [idpax, setIdpax] = useState(0);
    const [openDialogMessage, setOpenDialogMessage] = useState(false);
    const [openDeletePerson, setOpenDeletePerson] = useState(false);
    const [messageData, setMessageData] = useState({
        message: null,
        title: null,
        buttonMessage: null,
    });

    const handleClickDeletePerson = (id_Passenger) => {
        setIdpax(id_Passenger)
        setOpenDeletePerson(true)
    }

    const searchListPerson = () => {
        props.getListPerson()
    }

    function handleClickOpenRegisterPerson(id_Passenger = 0) {
        setIdpax(id_Passenger)
        setOpenRegisterPerson(true)
    }

    function handleClickOpenDialogMessage() {
        setOpenDialogMessage(true)
    }

    return (
        <Grow in={grow} timeout={1000}>
            <>
                <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />

                {openDialogMessage ? (
                    <DialogMessage  {...{ message: messageData.message, title: messageData.title, buttonMessage: messageData.buttonMessage, setOpen: setOpenDialogMessage, open: openDialogMessage }} />
                ) : null}
                {openRegisterPerson ? (
                    <RegisterPersonDialog
                        {...{
                            setIsLoading: props.setIsLoading,
                            idpax,
                            open: openRegisterPerson,
                            setOpen: setOpenRegisterPerson,
                            handleClickOpenDialogMessage,
                            searchListPerson,
                            setSnackMessage,
                            setSnackVariant,
                            setSnackVisible,
                            setMessageData,
                        }}
                    />
                ) : null}
                {openDeletePerson ? (
                    <DeletePersonDialog
                        {...{
                            setIsLoading: props.setIsLoading,
                            idpax,
                            open: openDeletePerson,
                            setOpen: setOpenDeletePerson,
                            handleClickDeletePerson,
                            searchListPerson,
                            setSnackMessage,
                            setSnackVariant,
                            setSnackVisible,
                        }}
                    />
                ) : null}
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={'25px'}>
                    <Typography variant={'h5'} style={{ color: 'rgba(97, 47, 116)' }}>Passageiros</Typography>
                </Box>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><Typography>Nome</Typography></TableCell>
                                <TableCell align="center"><Typography>Sobrenome</Typography></TableCell>
                                <TableCell align="center"><Typography>Data de Nascimento</Typography></TableCell>
                                <TableCell align="center"><Typography>Gênero</Typography></TableCell>
                                <TableCell align="center"><Typography>Nacionalidade</Typography></TableCell>
                                <TableCell align="center"><Typography>Editar</Typography></TableCell>
                                <TableCell align="center">
                                    <Button
                                        onClick={() => handleClickOpenRegisterPerson()}
                                        className={classes.buttonSearch}
                                        startIcon={<AddOutlined />}>
                                        {/* Adicionar */}
                                        Cadastrar
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data?.results?.length !== 0 ? null : (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Typography style={{ textAlign: 'center' }} variant={'h5'}> Não há passageiros registrados</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {props.data?.results?.map((item) => (
                                <TableRow key={item.firstName}>
                                    <TableCell align="center"><Typography>{item.firstName}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{item.lastName}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{item.birthDate}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{item.gender}</Typography></TableCell>
                                    <TableCell align="center"><Typography>{item.nationality}</Typography></TableCell>
                                    <TableCell align="center">
                                        <IconButton onClick={() => handleClickOpenRegisterPerson(item.id_Passenger)}>
                                            <EditOutlined style={{ color: 'purple' }} />
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center"><IconButton onClick={() => handleClickDeletePerson(item.id_Passenger)}><DeleteOutline /></IconButton></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* </Paper> */}
            </>
        </Grow >
    )
}

