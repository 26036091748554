import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import EsqueceuSenhaMobi from "./Mobile";
import { useStyles } from "./styles";
import authServices from "../../services/Auth/index";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import EsqueceuSenhaDesk from "./Desktop";

export const EsqueceuSenhaPage = (props) => {
  const [email, setEmail] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [tokenSended, setTokenSended] = useState(false);

  const sendToken = async () => {
    try {
      setIsLoading(true);

      if (email == null || email.length <= 0) {
        setSnackVariant("warning");
        setSnackMessage("Email não pode ser vazio");
        setSnackVisible({ visible: true });
        return;
      }

      await authServices.putForgotPassword(email);

      setSnackVariant("success");
      setSnackMessage("Link enviado com sucesso.");
      setSnackVisible({ visible: true });
      setTokenSended(true);
    } catch (e) {
      if (e.response.data.message) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
      } else if (Object.values(e.response.data.errors)[0][0]) {
        setSnackVariant("warning");
        setSnackMessage(Object.values(e.response.data.errors)[0][0]);
        setSnackVisible({ visible: true });
      } else {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar enviar link de recuperação de senha.");
        setSnackVisible({ visible: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />

      {responsive ? (
        <EsqueceuSenhaMobi
          {...{ email, setEmail, isLoading, sendToken, tokenSended }}
        />
      ) : (
        <EsqueceuSenhaDesk
          {...{ email, setEmail, isLoading, sendToken, tokenSended }}
        />
      )}
    </div>
  );
};

export default EsqueceuSenhaPage;
