import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Slide, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, EcoOutlined, ExpandMoreOutlined, GetAppOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import EnumCompany from '../../Enums/enum_FlightCompany';
import CustomLoader from '../../components/CustomLoader';
import CustomizedSnackbars from '../../components/CustomSnackBar';
import bookingService from '../../services/Bookings';
import { useStyles } from './styles';
import { useHistory } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CircularProgressWithLabel(props) {

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} style={{ height: '8rem', width: '8rem', color: '#008000' }} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                flexDirection={'column'}
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" style={{ color: '#008000', fontSize: '16pt', fontWeight: 'bold' }}> {props.reducoes.toLocaleString("pt-BR", { maximumFractionDigits: 0 })}</Typography>
                <Typography variant='caption' component="div" style={{ color: '#008000', fontSize: '12pt' }}>tentativas</Typography>
            </Box>
        </Box>
    );
}

export const TicketDialogMobi = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = useState(props.data.status === "Emitido" ? 100 : props.data.percentInitial);
    const [reducoes, setReducoes] = useState(props.data.reductionAttempts);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);
    const data = props.data;

    const handleClose = () => {
        if (props.isBuying === true) {
            history.push("/")
        }
        props.setOpen(false)
    };

    const getDocumentDownload = async () => {
        try {
            setIsLoading(true)
            let response = await bookingService.getDownloadContract(data.id_Booking)
            const linkSource = response.data.results;
            const link = document.createElement('a');
            link.href = linkSource;
            link.target = '_blank'
            link.download = 'contrato.pdf';
            link.click();
        } catch (error) {
            setSnackVariant("error");
            setSnackMessage("Falha ao buscar contrato.");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (progress < 100) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + data.oneUnity));
                setReducoes((prevReducoes) => (prevReducoes <= 0 ? 0 : prevReducoes - 1));
            }, 1764.7);

            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-title" style={{ padding: '0px' }}>
                    <Box width={"-webkit-fill-available"} display={'grid'} justifyContent={'end'}>
                        <IconButton onClick={() => handleClose()}>
                            <CloseOutlined />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <CustomLoader isLoading={isLoading} />
                <CustomizedSnackbars
                    {...{
                        variant: snackVariant,
                        message: snackMessage,
                        visible: snackVisible.visible,
                        setSnackbar: setSnackVisible,
                    }}
                />
                <DialogContent style={{ padding: '8px 12px' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).nome}</Typography>)}>
                                    <img
                                        src={data.airCompanie === undefined ? null : (EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).url_high)}
                                        width={80}
                                        height={80}
                                    />
                                </Tooltip>
                            </Grid>
                            <Grid container item xs={12} style={{ marginBottom: '10px', textAlign: 'center' }} >
                                {data.air.initialTotal !== null ? (
                                    <>
                                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                            <Typography variant='h5' style={{ color: 'grey', marginRight: '5px' }}>Total inicial: </Typography>
                                            <Typography variant='h5' style={{ color: '#ff7500' }}>{data.air.initialTotal}</Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography variant='body2' style={{ color: 'grey', }}>Reduziu</Typography>
                                            <Typography variant="h5" style={{ color: '#008000' }}>{data.air.reductionTotal}</Typography>
                                        </Grid>
                                    </>
                                ) : data.status === "Emitido" ? (
                                    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Typography variant='h5' style={{ color: 'grey', marginRight: '5px' }}>Valor na Emissão: </Typography>
                                        <Typography variant='h5' style={{ color: '#ff7500' }}>{data.air.totalWithTax}</Typography>
                                    </Grid>
                                ) : (
                                    <Grid item xs={12}>
                                        <Typography variant='h5' style={{ color: 'grey' }}>Buscando...</Typography>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                    <Chip label={data.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                </Typography>
                                <Typography variant={'h6'}>
                                    Localizador: {data.air.locator}
                                </Typography>
                                <Typography variant='6'>
                                    Data de partida: {data.departureDate}
                                </Typography>
                                <Typography variant='6' style={{ color: 'rgba(67, 160, 71, 0.7)', display: 'flex', alignItems: 'center' }}>
                                    Emissão CO<sub>2</sub> (kg):  <EcoOutlined style={{ color: '#rgba(67, 160, 71, 0.7)' }} />{data.air.carbonEmissionTotal}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '10px' }}>
                                <CircularProgressWithLabel
                                    variant="determinate"
                                    value={progress}
                                    reducoes={reducoes}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: 'center', marginTop: '10px' }}>
                                <Button
                                    onClick={() => getDocumentDownload()}
                                    className={classes.buttonContract}
                                    startIcon={<GetAppOutlined />}>
                                    Baixar contrato
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '20px' }}>
                                <Typography variant='h6' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                    Passageiros
                                </Typography>
                                {data.air.passengers.map((pax) => (
                                    <Accordion
                                        elevation={0}
                                        style={{
                                            border: '1px solid rgb(85, 26, 139)',
                                            borderRadius: '10px'
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreOutlined />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                {` ${pax.firstName} ${pax.lastName}`}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container>
                                                {/* <Grid item xs={12}>
                                                    <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                    {` ${pax.firstName} ${pax.lastName}`}
                                                     </Typography>
                                                </Grid> */}
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                        Tipo: {pax.type}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                        Data de nascimento: {pax.birthDate}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant='body2' style={{ color: 'rgba(67, 160, 71, 0.7)', display: 'flex', alignItems: 'center' }}>
                                                        Emissão CO<sub>2</sub> (kg):  <EcoOutlined style={{ color: '#rgba(67, 160, 71, 0.7)' }} /> {pax.carbonEmission}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Accordion elevation={0}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreOutlined />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header">
                                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                                Total: {pax.totalWithTax}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                                        Tarifa: {pax.totalWithoutTax}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                                        Encargos: {pax.totalTax}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Grid>
                                                <Grid item xs={12}>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>

                                ))}
                                <Typography variant='h6' style={{ color: 'rgba(0,0,0,0.5)', marginTop: '20px' }}>
                                    Itinerários
                                </Typography>
                                {data.air.itineraries.map((iti) => (
                                    <Grid container>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                Nº{iti.flightNumber}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                Data de saída: {iti.departureDate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                Data de chegada: {iti.arrivalDate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '2px', borderRadius: '20px', marginTop: '5px', marginBottom: '5px' }}>
                                            <Typography style={{ color: 'rgba(0,0,0,0.5)', fontSize: '10pt' }}>
                                                {iti.descriptionRoute}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={12} style={{ marginTop: '20px' }}>
                                            <Grid item xs={12}>
                                                <Typography variant='h6' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                    Informações adicionais
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                    Classe: {iti.class}
                                                </Typography>
                                                <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                    Bagagem: {iti.descriptionBagage}
                                                </Typography>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))}
                                <div style={{ marginTop: '20px' }}>
                                    {data.air.tickets ?
                                        data.air.tickets.length > 0 ?
                                            data.air.tickets.map((item, index) => (
                                                <>
                                                    {index > 0 ? (
                                                        <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                                                    ) : (null)}
                                                    <Typography variant='h6' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                        Bilhetes
                                                    </Typography>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                                Passageiro: {`${item.lastName} / ${item.firstName}`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant='body2' style={{ color: 'rgba(0,0,0,0.5)' }}>
                                                                Nº {item.code}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            ))
                                            : null
                                        : null}
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
