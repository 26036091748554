const prod = {
    url: {
        API_URL: window.location.href.includes('192.168.0.100') ? 'http://192.168.0.100:5000/api' : 'https://api-virtual-linestur.azurewebsites.net/api/v1/',
    }
};
const dev = {
    url: {
        API_URL: window.location.href.includes('localhost') ? 'https://192.168.0.19:7010/api/v1' : window.location.origin.replace(':3000', ':5000') + '/api'
    }
};

// const config = process.env.NODE_ENV === 'development' ? dev : prod;

const config = {
    url: {
        // API_URL: 'http://26.227.80.146:7010/api/v1',
        // API_URL: 'http://26.253.181.157:7010/api/v1',
        // API_URL: 'http://localhost:7010/api/v1',
        API_URL: 'https://api-virtual-linestur.azurewebsites.net/api/v1',
    }
};

export default config;
