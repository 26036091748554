import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { readyBooking } from "../../../actions/flightsAction";
import doingBioAuth from '../../../images/assets/Biometry/doingBioAuth.gif';
import { useStyles } from "./styles";
import { RegisterAuthenticate } from "../../RegisterAuthenticate";
import { useState } from "react";
import { useEffect } from "react";
import { CreditCardAuthenticate } from "../../CreditCardAuthenticate";

export const DialogBioWarning = (props) => {
    const classes = useStyles();
    const dtoBio = props.dtoBio;
    const dispatch = useDispatch();
    const [showButtomBottom, setShowButtomBottom] = useState(false);
    const [openAuthRF, setOpenAuthRF] = useState(false);
    const [openAuthCC, setOpenAuthCC] = useState(false);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const isWarning = true;

    const handleClose = () => {
        dispatch(readyBooking({
            readyBooking: {
                openBuyingDialog: false,
                selectedFlights: [],
                stateQuantityPerson: {},
                availabilities: {},
            },
        }));
        props.setOpenBuyProps(false)
        props.setOpenBuy(false)
        props.handleCloseDialogBioWarning()
    }

    const handleCliCkOK = () => {
        props.handleOpenTicket(dtoBio.ticket.open, dtoBio.ticket.response)
        handleClose()
        // if (props.formPaymentSelected != "pixpayment") {
        // }
    }

    const handleClickBioAuth = () => {
        setOpenAuthRF(true)
    }

    const handleClickCCAuth = () => {
        setOpenAuthCC(true)
    }

    const handleCloseAuthRF = () => {
        handleCliCkOK()
        setOpenAuthRF(false)
    }

    const handleCloseAuthCC = () => {
        // handleCliCkOK()
        setOpenAuthCC(false)
    }

    const getButtonBottom = () => {
        setTimeout(() => {
            setShowButtomBottom(true)
        }, 5000)
    }

    useEffect(() => {
        getButtonBottom()
    }, [])

    return (
        <>
            <Dialog
                open={props.open}
                fullWidth
                maxWidth={'md'}
                fullScreen={responsive}
            >
                <RegisterAuthenticate {...{
                    open: openAuthRF,
                    setOpen: setOpenAuthRF,
                    handleCloseAuthRF,
                    isWarning,
                }} />
                <CreditCardAuthenticate {...{
                    setOpen: handleCloseAuthCC,
                    open: openAuthCC,
                }} />
                <DialogTitle className={classes.titleBar}>
                    <Typography variant="h6">Próximo passo</Typography>
                    {/* <Typography variant="h6" onClick={() => setOpenAuthRF(false)}>Próximo passo</Typography> */}
                </DialogTitle>
                <DialogContent>
                    <Grid container >
                        <Grid item xs={12} md={6} className={classes.gridTxt}>
                            <img src={doingBioAuth} height={responsive ? '220rem' : '350rem'} />
                        </Grid>
                        <Grid item container xs={12} md={6} >
                            <Grid item xs={12} className={classes.gridTxt}>
                                <Typography variant={responsive ? 'body2' : 'body2'}>
                                    Essencial para uma compra segura.
                                </Typography>
                                <Typography variant={responsive ? 'body1' : 'h6'}>
                                    Sua tarifa está <b>garantida</b>!
                                </Typography>

                                {dtoBio.bio && dtoBio.cc ? null : (
                                    <Typography variant={responsive ? 'body1' : 'h6'}>
                                        Agora só falta realizar esse(s) item(ns):
                                    </Typography>
                                )}
                            </Grid>
                            {/* {dtoBio.bio ? ( */}
                            {!dtoBio.bio ? (
                                <Grid item xs={12} className={classes.gridTxt}>
                                    <Typography className={classes.typoMain} variant={responsive ? 'body1' : 'h6'}>
                                        <b>Reconhecimento Facial</b>
                                        {/* Seu reconhecimento facial é necessario uma <b>única vez</b>. */}
                                    </Typography>
                                    {/* {!dtoBio.bio ? (<Button className={classes.buttonDoNow} onClick={() => handleClickBioAuth()}>Realizar Agora</Button>) : null} */}
                                    <Button className={classes.buttonDoNow} onClick={() => handleClickBioAuth()}>Reconhecer face</Button>
                                    <Typography variant={responsive ? 'body2' : 'body1'}>
                                        Necessário apenas na <b>primeira</b> vez!
                                    </Typography>
                                </Grid>
                            ) : null}
                            {/* {dtoBio.cc ? ( */}
                            {!dtoBio.cc ? (
                                <Grid item xs={12} className={classes.gridTxt}>
                                    <Typography className={classes.typoMain} variant={responsive ? 'body1' : 'h6'}>
                                        <b>Autenticação do cartão</b>
                                        {/* A autenticação do cartão é efetuado uma única vez por cadastramento. */}
                                    </Typography>
                                    {/* {!dtoBio.bio ? (<Button className={classes.buttonDoNow} onClick={() => handleClickCCAuth()}>Realizar Agora CC</Button>) : null} */}
                                    <Button className={classes.buttonDoNow} onClick={() => handleClickCCAuth()}>Autenticar cartão</Button>
                                    <Typography variant={responsive ? 'body2' : 'body1'}>
                                        Somente na <b>primeira</b> vez por cartão!
                                    </Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {showButtomBottom && (
                        <>
                            <Button className={classes.buttonOrange} onClick={() => handleCliCkOK()}>Entendi</Button>
                        </>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}
