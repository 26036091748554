import React, { useCallback, useEffect, useLayoutEffect, useMemo } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Cookie from "universal-cookie";
import DashLoadingScreen from "../../pages/LoadingScreen";
import authService from "../../services/Auth";

export const Auth = (props) => {
  const [loading, setLoading] = useState(true)
  const history = useHistory();
  const cookies = useMemo(() => new Cookie(), []);

  const cleanCookies = useCallback(() => {
    cookies.remove('token')
  }, [cookies]);

  const redirectUnauthorized = useCallback(() => {
    history.push("/");
  }, [history]);

  useLayoutEffect(() => {
    (async () => {
      try {
        await authService.getIsAuthenticate();
      } catch (e) {
        cleanCookies();
        redirectUnauthorized();
      } finally {
        setLoading(false)
      }
    })();
  }, []);

  // return <>{props.children}</>;
  return loading ? <DashLoadingScreen /> : (props.children);
  // return <DashLoadingScreen />;
};

export default Auth;
