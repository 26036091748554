import pix from "./pixIcon.png"
import copyClipboard from "./copy.png"
import cookieIcon from "./cookieIcon.png"
import faceIcon from "./face-scan.png"
import carteira from "./carteira-de-identidade2.png"
import carteiratras from "./carteiraback.png"

export const IconsSource = {
    pixIcon: pix,
    copyClipboard: copyClipboard,
    cookieIcon: cookieIcon,
    face: faceIcon,
    carteira: carteira,
    carteiratras: carteiratras
}
