import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import serpro from '../../../../../images/assets/serpro.png';
import { useStyles } from "../styles";

export const FacialBiometricsStep01 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <img src={serpro} height={'60rem'} style={{ color: '#612f74' }} />
            </Box>
            <Box textAlign={'center'} padding={2}>
                <Typography >
                    Somos integrados ao Serviço Federal de Processamento de Dados
                </Typography>
            </Box>
            <Box textAlign={'center'} padding={2}>
                <Typography >
                    Agora a compra do seu bilhete tem face, além de mais segurança.<br />
                    Conforme a resolução n°595 da ANAC fica estabelecido que a partir de 29 de outubro de 2022
                    passa a ser obrigatório a inserção do CPF, data de nascimento, e-mail e telefone do passageiro.
                </Typography>
            </Box >
            <Box textAlign={'-webkit-center'} paddingTop={'1rem'} display={'flex'} justifyContent={'center'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => props.handleNext()} className={classes.buttonOrange}>
                    Entendi
                </Button>
            </Box>
        </>
    )
}
