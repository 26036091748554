import { Backdrop } from '@material-ui/core';
import React from 'react';
import Image from '../../images/travel.gif';
import { useStyles } from './styles';

export const CustomLoader = props => {
    const classes = useStyles();

    return (
        <div>
            <Backdrop className={classes.Backdrop} open={props.isLoading}>
                <img src={Image} style={{ width: 350, height: 350 }} />
            </Backdrop>
        </div>
    )
};

export default CustomLoader;
