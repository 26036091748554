import { createTheme, ThemeProvider } from '@material-ui/core';
import { ptBR } from '@material-ui/core/locale';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import App from './App';
import Flights from './reducers/flightsReducer';
import { registerServiceWorker } from './serviceWorker';

const themePT = createTheme({
    palette: {
        primary: { main: '#1976d2' },
    },
    typography: {
        fontFamily: [
            "Open Sans",
        ].join(","),
    },
}, ptBR)

const reducers = combineReducers({
    flights: Flights,
})

const store = createStore(reducers)

ReactDOM.render(
    <ThemeProvider theme={themePT}>
        <Provider store={store}>
            <App />
        </Provider>
    </ThemeProvider>
    , document.getElementById('root'));

registerServiceWorker();
