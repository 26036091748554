import api from '../../../Api';

const countryServices = {

    getCountries: async (select) => {
        const response = await api.get('/Country/get-all?select=' + select);
        return response;
    },
}

export default countryServices;
