import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    appBar: {
        position: 'relative',
        background: '#612F74',
        color: '#FFF7FF'
    },

    title: {
        flex: 1,
    },

    buttonCustom: {
        // width: '100%',
        width: '50vw',
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        color: 'white',
        '&:hover': {
            background:
                "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },

    dialogCustom: {
        background: '#FFF7FF',
    },

    dialogCustomTitle: {
        background: '#612F74',
        color: '#FFF7FF'
    },

    dialogCustomActions: {
        // background: '#612F74',
        // color: '#FFF7FF'
        justifyContent: 'center'
    },

    dialogWarning: {
        overflow: 'hidden',
        border: '3px solid #612F74',
        borderRadius: '15px',
    },

    dialogWarningLogo: {
        // border: '3px solid #612F74',
        // borderRadius: '10px 0px 0px 10px',
        backgroundColor: '#612F74',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        color: '#FFF7FF'
    },

    button3: {
        width: '100%',
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '15px'
    },


}));
