import { Typography } from '@material-ui/core';
import { ScheduleOutlined } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { useStyles } from './styles';


function CountDownTimer(props) {
    const classes = useStyles();
    const INITIAL_COUNTDOWN_TIME_IN_SECONDS = props.timeInSeconds ? props.timeInSeconds : 0;
    const colorTypography = props.colorTypography ? props.colorTypography : '#000000';
    const [secondsAmount, setSecondsAmount] = useState(INITIAL_COUNTDOWN_TIME_IN_SECONDS);

    useEffect(() => {
        if (secondsAmount == 0) {
            props.setResponseTimer(true);
            return
        }
        setTimeout(() => {
            setSecondsAmount(state => state - 1);
        }, 1000)

    }, [secondsAmount]);

    const minutes = Math.floor(secondsAmount / 60);
    const seconds = secondsAmount % 60;


    return (
        <div className={classes.typoCenter}>
            <ScheduleOutlined style={{ color: `${colorTypography}` }} />
            <Typography variant='h4' style={{ color: `${colorTypography}`, marginLeft: '5px' }}>
                {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
            </Typography>
        </div>
    )
}

export default CountDownTimer;
