import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    button: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    gridDetails: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paperCustom: {
        height: '120px',
        width: '450px',
        maxWidth: '450px',
        margin: '15px'
    },

    boxCustom: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },


}));
