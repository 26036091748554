import { makeStyles } from "@material-ui/core/styles";
import Fundo from "../../../images/Fundos/mobile_login_1080_1920.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${Fundo})`,
    backgroundSize: "cover" /* <------ */,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  box: {
    width: "100%",
    maxWidth: '450px',
    maxHeight: "75vh",
    minHeight: "552px",
    background: "rgba(255,255,255,0.2)",
    display: "flex",
  },

  gridContainer: {
    alignItems: "center",
  },

  gridButtons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "58px",
  },

  buttonEntrar: {
    minWidth: 70,
    background: "#ff7500",
    color: "white",
    "&:hover": {
      background: "rgba(255, 117, 0, 0.8)",
      // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
    },
    borderRadius: "5px",
  },

  button: {
    color: "white",
    fontWeight: "800",
    background:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    margin: "5px",
  },

  gridBody: {
    display: "flex",
    justifyContent: 'center',
    alignItems: "center",
    minHeight: "290px",
  },

  gridProgress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0px 10px 0px",
  },

  txtProgress: {
    display: "flex",
    alignItems: "center",
    color: "#612F74",
    fontSize: "1.3rem",
    fontWeight: 500,
  },
}));
