import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import CustomDatePicker from "../../../../components/CustomDatepicker";
import CustomSelectField from "../../../../components/CustomSelectField/index";
import CustomTextFieldDark from "../../../../components/CustomTextFieldDark";
import CustomTextFieldMasked from "../../../../components/CustomTextFieldMasked";
import { EnumGenre } from "../../../../Enums/enum_genre";
import { useStyles } from "./styles";

const Steps = (props) => {
  const classes = useStyles();

  function getLayout(step) {
    switch (step) {
      case 1:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item xs={12} className={classes.gridItem}>
                    <CustomTextFieldMasked
                      mask={"999.999.999-99"}
                      error={props?.dataVerified?.cpf}
                      className={classes.field}
                      required
                      name="person:cpf"
                      type="text"
                      label="CPF"
                      value={props?.dataRegister?.person?.cpf}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.gridItem}>
                    <CustomDatePicker
                      {...{
                        date: props?.dataRegister?.person?.birthDate,
                        setDate: props?.setBirthDate,
                        label: "Data de Nascimento",
                        className: classes.field,
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      case 2:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      error={props?.dataVerified?.firstName}
                      className={classes.field}
                      name="person:firstName"
                      type="text"
                      label="Nome"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.firstName || ""}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      error={props?.dataVerified?.lastName}
                      className={classes.field}
                      name="person:lastName"
                      type="text"
                      label="Sobrenome"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.lastName || ""}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={6}>
                    <CustomSelectField
                      error={props?.dataVerified?.gender}
                      required
                      className={classes.field}
                      {...{
                        itens: EnumGenre.getGenreList(),
                        value: props?.dataRegister?.person?.gender,
                        label: "Gênero",
                      }}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                      name="person:gender"
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={6}>
                    <CustomSelectField
                      error={props?.dataVerified?.nationality}
                      required
                      className={classes.field}
                      {...{
                        itens: props?.countries,
                        value: props?.dataRegister?.person?.nationality,
                        setValue: props?.setNationality,
                        label: "Nacionalidade",
                      }}
                      name="person:nationality"
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      case 3:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      error={props?.dataVerified?.email}
                      className={classes.field}
                      name="user:email"
                      type="email"
                      label="Email"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.user?.email || ""}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldMasked
                      required
                      error={props?.dataVerified?.telephone1}
                      mask={"(99) 99999-9999"}
                      className={classes.field}
                      name="person:telephone1"
                      id={1}
                      type="tel"
                      label="Telefone Principal"
                      value={props?.dataRegister?.person?.telephone1}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldMasked
                      mask={"(99) 99999-9999"}
                      className={classes.field}
                      id={3}
                      name="person:telephone2"
                      type="tel"
                      label="Telefone Opcional"
                      value={props?.dataRegister?.person?.telephone2}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      case 4:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item xs={12} className={classes.gridItem}>
                    <CustomSelectField
                      error={props?.dataVerified?.country}
                      required
                      className={classes.field}
                      {...{
                        itens: props?.countries,
                        value: props?.dataRegister?.person?.id_Country,
                        setValue: props?.setCountry,
                        label: "País",
                      }}
                      name="country"
                    />
                  </Grid>
                  <Grid
                    item
                    className={classes.gridItem}
                    xs={5}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <CustomTextFieldMasked
                      error={props?.dataVerified?.cep}
                      disabled={
                        props?.countries?.find(
                          (s) =>
                            s.value === props?.dataRegister?.person?.id_Country
                        )?.id !== 1
                      }
                      mask={"99999-999"}
                      className={classes.field}
                      id={5}
                      name="person:cep"
                      type="text"
                      label="CEP"
                      value={props?.dataRegister?.person?.cep}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                      onBlur={() =>
                        props?.dataRegister?.person.cep?.trim()?.length === 9 &&
                        props?.countries?.find(
                          (s) =>
                            s.value === props?.dataRegister?.person?.id_Country
                        )?.id === 1
                          ? props?.getDataCEP()
                          : null
                      }
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={7}>
                    <CustomSelectField
                      shrink={props?.dataRegister?.person?.id_City}
                      error={props?.dataVerified?.state}
                      required
                      className={classes.field}
                      {...{
                        itens: props?.states,
                        value: props?.dataRegister?.person?.id_State,
                        setValue: props?.setState,
                        disabled:
                          props?.dataRegister?.person?.id_Country === null,
                        label: "Estado",
                      }}
                      name="state"
                    />
                  </Grid>
                  <Grid item xs={7} className={classes.gridItem}>
                    <CustomSelectField
                      required
                      className={classes.field}
                      shrink={props?.dataRegister?.person?.id_City}
                      error={props?.dataVerified?.residenceCity}
                      {...{
                        itens: props?.cities,
                        value: props?.dataRegister?.person?.id_City,
                        disabled: props.dataRegister?.person?.id_State === null,
                        setValue: props?.setCity,
                        label: "Cidade",
                      }}
                      name="city"
                    />
                  </Grid>
                  <Grid item xs={5} className={classes.gridItem}>
                    <CustomTextFieldDark
                      required
                      InputLabelProps={{
                        shrink: props?.dataRegister?.person?.neighborhood || "",
                      }}
                      error={props?.dataVerified?.neighborhood}
                      className={classes.field}
                      name="person:neighborhood"
                      type="text"
                      label="Bairro"
                      disabled={
                        props?.dataRegister?.person?.id_ResidenceCity === null
                      }
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.neighborhood}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      InputLabelProps={{
                        shrink: props?.dataRegister?.person?.address || "",
                      }}
                      error={props?.dataVerified?.street}
                      className={classes.field}
                      disabled={
                        props?.dataRegister?.person?.neighborhood === null
                      }
                      name="person:address"
                      type="text"
                      label="Logradouro"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.address}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={4}>
                    <CustomTextFieldDark
                      className={classes.field}
                      disabled={
                        props?.dataRegister?.person?.neighborhood === null
                      }
                      name="person:number"
                      type="text"
                      label="Número"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.number}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={8}>
                    <CustomTextFieldDark
                      className={classes.field}
                      name="person:complement"
                      disabled={
                        props?.dataRegister?.person?.neighborhood === null
                      }
                      type="text"
                      label="Complemento"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.person?.complement}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      case 5:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.gridContainer}>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      className={classes.field}
                      name="user:login"
                      type="text"
                      label="Usuário"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.user?.login}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      className={classes.field}
                      name="user:password"
                      type="password"
                      label="Senha"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.user?.password}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item cclassName={classes.gridItem} xs={12}>
                    <CustomTextFieldDark
                      required
                      className={classes.field}
                      name="user:passwordConfirmation"
                      type="password"
                      label="Confirmação de senha"
                      margin="dense"
                      variant="outlined"
                      value={props?.dataRegister?.user?.passwordConfirmation || ""}
                      onChange={(e) => props?.handleChangeDataRegister(e)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      case 6:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container className={classes.gridContainer}>
                  <Grid
                    item
                    xs={12}
                    className={classes.gridItem}
                    style={{
                      color: "black",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      width={75}
                      src="https://play-lh.googleusercontent.com/D1Dz2BjPYev_oyksKXsdtAS66a_2Ql-sklpzTnwR9lqnDG_P5lAJEtfR70FudJ0XMA"
                      alt=""
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className={classes.gridItem}
                    style={{
                      color: "black",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "8px",
                    }}
                  >
                    <Typography
                      align="center"
                      variant="subtitle1"
                      style={{ fontSize: "1.2rem" }}
                      className={classes.field}
                    >
                      Verifique o seu e-mail
                    </Typography>
                    <Typography
                      align="center"
                      variant="subtitle1"
                      style={{ fontSize: "1.0rem" }}
                      className={classes.field}
                    >
                      <b>({props?.dataRegister?.user?.email})</b>
                    </Typography>
                    <Typography
                      align="center"
                      style={{ fontSize: "1.0rem" }}
                      variant="subtitle1"
                      className={classes.field}
                    >
                      Enviamos um e-mail com um link de confirmação.
                    </Typography>
                    <Typography
                      align="center"
                      style={{ fontSize: "0.7rem" }}
                      variant="subtitle1"
                      className={classes.field}
                    >
                      O link tem validade de 30 minutos.
                    </Typography>
                    <Typography
                      align="center"
                      style={{ fontSize: "0.7rem" }}
                      variant="subtitle1"
                      className={classes.field}
                    >
                      Confirme sua conta antes do link expirar{props.fastRegister ? " para continuar sua compra." : "."}
                    </Typography>
                    <Typography
                      align="center"
                      style={{ fontSize: "0.7rem" }}
                      variant="subtitle1"
                      className={classes.field}
                    >
                      Não recebeu nenhuma mensagem? Verifique a pasta de Spam.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );

      default:
        return;
    }
  }

  return <div className={classes.root}>{getLayout(props.step)}</div>;
};
export default Steps;
