import Cookies from "universal-cookie";
import api from "../../Api";

const cookies = new Cookies();

const ciasService = {

    getTaxRulesInfoByCia: async (iata) => {
        const response = await api.get(`/AirCompanie/fare-rules/${iata}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default ciasService;
