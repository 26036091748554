import { makeStyles } from "@material-ui/core/styles";
import FundoDesk from "../../../images/Fundos/desktop_login_1080_1920.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundImage: `url(${FundoDesk})`,
    backgroundSize: "cover" /* <------ */,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  box: {
    padding: 30,
    maxWidth: 400,
    maxHeight: 400,
    background: "rgba(255,255,255,0.2)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  button: {
    minHeight: "40px",
    color: "white",
    fontWeight: "800",
    background:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    marginLeft: "20px",
    marginRight: "20px",
  },

  gridCenterAligned: {
    display: "grid",
    alignItems: "center",
  },

  gridCenterAlignedLogo: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },

  field: {
    backgroundColor: "#ffffff9e",
    marginLeft: "20px",
    marginRight: "20px",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff73",
    },
  },
  
  progress: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(14),
    position: "absolute",
    zIndex: 100,
    color: "#ffffff",
    width: "20px",
    height: "20px",
  },

  buttonSecondaries: {
    color: "#612F74",
    background: " white",
  },

  button03: {
    width: "100%",
    background: "#ff7500",
    color: "white",
    "&:hover": {
      background: "rgba(255, 117, 0, 0.8)",
    },
    borderRadius: "15px",
  },
}));
