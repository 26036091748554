import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import bookingService from "../../services/Bookings";
import { TicketDialog } from "../../templates/TicketDialog";
import { TicketDialogMobi } from "../../templates/TicketDialogMobi";
import { Dashboard } from "../Dashboard";
import { DeskListHistoric } from "./DeskListHistoric";
import { MobiListHistoric } from "./MobiListHistoric";
import { useStyles } from "./styles";
import { PixPaymentDialog } from "../../templates/BuyingDialog/PixPaymentFinally";

const MyHistoric = (props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [pixs, setPixs] = useState([]);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const [dataTicket, setDataTicket] = useState([]);
    const [idTicket, setIdTicket] = useState(0);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [openPix, setOpenPix] = useState(false)
    const [idBookingSelected, setIdBookingSelected] = useState(null)

    const SearchBookings = async () => {
        let response = null;
        try {
            setIsLoading(true)
            response = await bookingService.getListBooking()
            setBookings(response.data.results)
        } catch (e) {
            if (e.response.data.message) {
                setSnackVariant(e.response.data.variant);
                setSnackMessage(e.response.data.message);
                setSnackVisible({ visible: true });
            } else {
                setSnackVariant("error");
                setSnackMessage("Falha ao consultar histórico.");
                setSnackVisible({ visible: true });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleOpenTicket = async (open, id_Booking) => {
        setIsLoading(true)
        try {
            let response = await bookingService.getById(id_Booking);
            setDataTicket(response.data.results)
            setOpenTicketDialog(open)
            setIdTicket(id_Booking)
        } catch (error) {
            setSnackVariant("erro");
            setSnackMessage("Falha ao consultar bilhete.");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        SearchBookings()
    }, [])

    return (
        <>
            {openPix && (
                <PixPaymentDialog {...{ open: openPix, setOpen: setOpenPix, id: idBookingSelected }} />
            )}
            <CustomLoader isLoading={isLoading} />
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackVisible.visible,
                    setSnackbar: setSnackVisible,
                }}
            />
            <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                {openTicketDialog ?
                    responsive ? (
                        <TicketDialogMobi
                            {...{
                                data: dataTicket,
                                open: openTicketDialog,
                                setOpen: setOpenTicketDialog,
                            }}
                        />
                    ) : (
                        <TicketDialog
                            {...{
                                data: dataTicket,
                                open: openTicketDialog,
                                setOpen: setOpenTicketDialog,
                            }}
                        />
                    )
                    : (
                        null
                    )}
                <Grid item xs={12} className={classes.gridCenter}>
                    {responsive ? (
                        <div style={{ minHeight: '400px' }}>
                            <MobiListHistoric {...{ data: bookings, handleOpenTicket, setOpenPix, setIdBookingSelected }} />
                        </div>
                    ) : (
                        // <div className={classes.grid}>
                        <div className={classes.gridWithNoPromo}>
                            {/* <div className={classes.divPromo}>
                            <img src={IMG} className={classes.img}></img>
                        </div> */}
                            <div className={classes.divPromo}>
                                <DeskListHistoric {...{ data: bookings, handleOpenTicket, setOpenPix, setIdBookingSelected }} />
                            </div>
                            {/* <div className={classes.divPromo}>
                            <img src={IMG} className={classes.img}></img>
                        </div> */}
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export const DashMyHistoric = (props) => {

    return (
        <div>
            <Dashboard {...props} component={MyHistoric} />

        </div>
    )
};
export default DashMyHistoric;
