import { useHistory } from "react-router-dom";
import { Box, Button, Chip, CircularProgress, Divider, Grid, Paper, Tooltip, Typography, useMediaQuery, useTheme, Zoom } from '@material-ui/core';
import { Alert, Pagination } from '@material-ui/lab';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import { promoSearch } from '../../actions/flightsAction';
import CountDownTimer from '../../components/CountDownTimer';
import CustomLoader from '../../components/CustomLoader';
import CustomizedSnackbars from '../../components/CustomSnackBar';
import EnumCompany from '../../Enums/enum_FlightCompany';
import authServices from '../../services/Auth';
import bookingService from '../../services/Bookings';
import searchFlightsService from '../../services/Flights/Search';
import { BuyingDialog } from '../../templates/BuyingDialog';
import { ConfigSearchPaxModal } from '../../templates/ConfigSearchPaxModal';
import PromotionsCards from '../../templates/PromotionsCards';
import { TicketDialog } from '../../templates/TicketDialog';
import { TicketDialogMobi } from '../../templates/TicketDialogMobi';
import { Dashboard } from '../Dashboard';
import { DialogAlerts } from './DialogAlerts';
import { LandPage } from './LandPage';
import ListSearchFlights from './ListFlights';
import { ListSearchFlightsMobi } from './ListFlightsMobi';
import PanelSearchFlights from './PanelSearchFlights';
import { PanelSearchFlightsMobi } from './PanelSearchFlightsMobi';
import { useStyles } from './styles';
import lV_Anuncio_Entrada_Desk from '../../images/Marketing/dialogAlert/lv_anuncio_entrada_desk.png';
import lV_Anuncio_Entrada_Mobi from '../../images/Marketing/dialogAlert/lv_anuncio_entrada_mobi.png';
import { DialogTaxRules, ModalTariffRules } from '../../templates/DialogTaxRules';
import CustomAlertDialog from '../../components/CustomDialogMedio';
import { RegisterAuthenticate } from "../../templates/RegisterAuthenticate";
import { EnumBookingStatus } from "../../Enums/enum_BookingStatus";
import { IconsSource } from "../../images/Icons";
import { PixPaymentDialog } from "../../templates/BuyingDialog/PixPaymentFinally";

export const SearchFlights = (props) => {
    const history = useHistory();
    const isBuying = true;
    const cookies = new Cookies();
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [availabilities, setAvailabilities] = useState(null);
    const [bookings, setBookings] = useState([]);
    const [dataTicket, setDataTicket] = useState([]);
    const [idTicket, setIdTicket] = useState(0);
    const [openModalTaxRules, setOpenModalTaxRules] = useState(false);
    const promoSearchList = useSelector(state => state.flights.promoSearch)
    const dispatch = useDispatch();
    const selectedFlight = useSelector(state => state.flights.readyBooking).readyBooking
    const [openTicketDialog, setOpenTicketDialog] = useState(false);
    const [openBuyingDialog, setOpenBuyingDialog] = useState(false);
    const [selectedFlights, setSelectedFlights] = useState([]);
    const [openTooltipConfigPax, setOpenTooltipConfigPax] = useState(false);
    const [openDialogAlert, setOpenDialogAlert] = useState(false);
    const [openDialogAlertCookie, setOpenDialogAlertCookie] = useState(false);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [iataSelected, setIataSelected] = useState('');
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    //2023-08-15
    const [openDialogAlertOmission, setOpenDialogAlertOmission] = useState(false);
    const [repairConfirm, setRepairConfirm] = useState(false);
    //2023-08-15
    const [promotionalsFlights, setPromotionalsFlights] = useState([]);
    const [stateQuantityPerson, setStateQuantityPerson] = useState({
        adults: 1,
        children: 0,
        babies: 0,
    });
    const [openPix, setOpenPix] = useState(false)
    const [idBookingSelected, setIdBookingSelected] = useState(null)

    const handleCloseAuthRF = () => {
        setRepairConfirm(false)
    }

    const SearchBookings = async () => {
        if (cookies.get('token')) {
            setIsLoading(true)
            let response = null;
            try {
                response = await bookingService.getListBookingActives();
                // response = await bookingService.getListBooking()
                setBookings(response.data.results)
            } catch (error) {
            } finally {
                if (promoSearchList.promoSearch?.isPromo) {
                    return
                }
                setIsLoading(false)
            }
        }
    }

    const getPromotionalFlights = async () => {
        setIsLoading(true)
        try {
            let response = await searchFlightsService.getPromotionalFlights();
            setPromotionalsFlights(response.data.results)
        } catch (error) {
        } finally {
            if (promoSearchList.promoSearch?.isPromo) {
                return
            }
            setIsLoading(false)
        }
    }

    const ConfirmClick = () => {
        setOpenDialogAlertOmission(false);
        setRepairConfirm(true);
    }

    const ConfirmCookie = () => {
        setOpenDialogAlertCookie(false)
        localStorage.setItem('isAcceptCookie', true)
    }

    const RejectCookie = () => {
        localStorage.removeItem('isAcceptCookie')
    }

    const handleClickSearchFlights = async (flight, isPromo = false) => {
        setIsLoading(true)
        dispatch(promoSearch(
            {
                promoSearch: {
                    isPromo: false,
                    promo: {},
                }
            }
        ))
        let dto = {}
        if (!isPromo) {
            let listFlight = []
            switch (flight.categoryType) {
                case "1":
                    listFlight = [{
                        departure: flight.departure,
                        arrival: flight.arrival,
                        departureDate: flight.departureDate
                    }]
                    break;
                case "2":
                    listFlight = [
                        {
                            departure: flight.departure,
                            arrival: flight.arrival,
                            departureDate: flight.departureDate
                        },
                        {
                            arrival: flight.departure,
                            departure: flight.arrival,
                            departureDate: flight.returnDate
                        }
                    ]
                    break;
                default:
                    return;
            }
            dto = {
                ageGroups: {
                    adults: flight.adults,
                    children: flight.children,
                    babies: flight.babies,
                },
                airRoutes: listFlight,
                airCompanies: null,
                businessClass: null,
                directFlights: false,
            }
        } else {
            dto = flight;
        }
        let response = null
        try {
            response = await searchFlightsService.postSearchFlights(dto);
            setAvailabilities(response.data.results)
        } catch (e) {
            if (e.response.data.message) {
                setSnackVariant(e.response.data.variant);
                setSnackMessage(e.response.data.message);
                setSnackVisible({ visible: true });
            } else if (Object.values(e.response.data.errors)[0][0]) {
                setSnackVariant("warning");
                setSnackMessage(Object.values(e.response.data.errors)[0][0]);
                setSnackVisible({ visible: true });
            } else {
                setSnackVariant("error");
                setSnackMessage("Erro ao tentar criar usuario.");
                setSnackVisible({ visible: true });
            }
        } finally {
            setIsLoading(false)
        }
    }

    const handleOpenTicket = async (open, id_Booking) => {
        setIsLoading(true)
        try {
            let response = await bookingService.getById(id_Booking);
            setDataTicket(response.data.results)
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
        setOpenTicketDialog(open)
        setIdTicket(id_Booking)
    }


    const onChangeSelectedFlights = async (flights) => {
        // setIsLoading(true)
        setSelectedFlights(flights)
        setOpenBuyingDialog(true)
        try {
            // await authServices.getIsAuthenticate();
        } catch (error) {

        } finally {
            // setIsLoading(false)
        }
    }

    const handleTooltipConfigPaxClose = () => {
        setOpenTooltipConfigPax(false);
    };

    const handleTooltipConfigPaxOpen = () => {
        setOpenTooltipConfigPax(true);
    };

    const setQuantityPerson = (item) => {
        setOpenTooltipConfigPax(false)
        setStateQuantityPerson(item)
    }

    const handleChangeStateModalTaxRules = (iata) => {
        setIataSelected(iata)
        setOpenModalTaxRules(true)


    }

    const NumberAlertOmission = (number) => {
        switch (number) {
            case EnumBookingStatus.Pending:
                return (
                    <Alert severity="warning">
                        Seu cartão está em análise.
                    </Alert>
                );
            case EnumBookingStatus.NeedSend:
                return (
                    <Alert severity="error">
                        Envie uma foto do seu <span style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => history.push('/cartao')}>cartão</span> para garantir sua tarifa.
                    </Alert>
                );
            case EnumBookingStatus.Authenticated:
                return (
                    <Alert severity="success">
                        Sua tarifa está garantida, aguarde a emissão.
                    </Alert>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        if (!selectedFlight?.openBuyingDialog) {
            getPromotionalFlights();
            SearchBookings();
        }
    }, []);

    useLayoutEffect(() => {
        let isFirstTime = localStorage.getItem('isFirstTime');
        let isAcceptCookie = localStorage.getItem('isAcceptCookie');

        if (isAcceptCookie === null || isAcceptCookie == 'false') {
            localStorage.setItem('isFirstTime', true)
            setOpenDialogAlertCookie(true)
        }

        if (isFirstTime === null || isFirstTime == 'false') {
            localStorage.setItem('isFirstTime', true)
            setOpenDialogAlert(true)
        }
        // flag 
    }, [])

    useEffect(() => {
        if (selectedFlight?.openBuyingDialog) {
            setOpenBuyingDialog(selectedFlight.openBuyingDialog);
            setSelectedFlights(selectedFlight.selectedFlights);
            setStateQuantityPerson(selectedFlight.stateQuantityPerson);
            setAvailabilities(selectedFlight.availabilities);
        }
    }, [selectedFlight]);

    useLayoutEffect(() => {
        if (promoSearchList.promoSearch?.isPromo) {
            handleClickSearchFlights(promoSearchList.promoSearch.promo, true)
        }
    }, [promoSearchList.promoSearch])

    return (
        <>
            {openPix && (
                <PixPaymentDialog {...{ open: openPix, setOpen: setOpenPix, id: idBookingSelected }} />
            )}
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackVisible.visible,
                    setSnackbar: setSnackVisible,
                }}
            />
            {/*2023-15-08*/}
            <CustomAlertDialog
                open={openDialogAlertCookie}
                setOpen={setOpenDialogAlertCookie}
                setRepairConfirm={setRepairConfirm}
                confirmClick={ConfirmCookie}
                rejectConfirm={RejectCookie}
                bio={false}
                title="Cookies"
                agree="Aceitar Cookies"
                disagree="Rejeitar"
                description="Este site utiliza cookies para garantir que você obtenha a melhor experiência em nossa plataforma. Os cookies são pequenos arquivos de texto que são armazenados no seu dispositivo para melhorar a funcionalidade e o desempenho do site."
            />
            <CustomAlertDialog
                open={openDialogAlertOmission}
                setOpen={setOpenDialogAlertOmission}
                setRepairConfirm={setRepairConfirm}
                confirmClick={ConfirmClick}
                bio={true}
                title="Verificação de segurança"
                agree="Verificar Agora"
                disagree="Mais tarde"
                description="Você precisa verificar sua Biometria!"
            />
            {/* {openDialogAlert ? ( */}
            <RegisterAuthenticate {...{
                open: repairConfirm,
                setOpen: setRepairConfirm,
                handleCloseAuthRF
            }} />
            {/*2023-15-08*/}
            <DialogAlerts
                open={openDialogAlert}
                setOpen={setOpenDialogAlert}
                allowClose={false}
                img={responsive ? lV_Anuncio_Entrada_Mobi : lV_Anuncio_Entrada_Desk}
                {...{ isLoading }}
            />
            {/* ) : null}  */}
            {openBuyingDialog && isLoading === false ? (
                <BuyingDialog {...{ readyBooking: { openBuyingDialog, selectedFlights, stateQuantityPerson, availabilities }, handleOpenTicket,setSelectedFlights, open: openBuyingDialog, setOpen: setOpenBuyingDialog, data: selectedFlights, handleTooltipConfigPaxClose, stateQuantityPerson }} />
            ) : null}
            {openTooltipConfigPax ? (
                <ConfigSearchPaxModal {...{ open: openTooltipConfigPax, setOpen: setOpenTooltipConfigPax, handleTooltipConfigPaxClose, setQuantityPerson, stateQuantityPerson }} />
            ) : null}
            {openModalTaxRules ? (
                <DialogTaxRules   {...{
                    iata: iataSelected,
                    open: openModalTaxRules,
                    setOpen: setOpenModalTaxRules,
                }} />

            ) : null}
            <CustomLoader
                isLoading={isLoading}
            />
            {responsive ? (
                <PanelSearchFlightsMobi
                    {...{
                        handleClickSearchFlights,
                        handleTooltipConfigPaxClose,
                        handleTooltipConfigPaxOpen,
                        stateQuantityPerson,
                    }} />
            ) : (
                <PanelSearchFlights
                    {...{
                        handleClickSearchFlights,
                        handleTooltipConfigPaxClose,
                        handleTooltipConfigPaxOpen,
                        stateQuantityPerson,
                    }}
                />
            )}

            {openTicketDialog ?
                responsive ? (
                    <TicketDialogMobi
                        {...{
                            isBuying,
                            data: dataTicket,
                            open: openTicketDialog,
                            setOpen: setOpenTicketDialog,
                        }}
                    />
                ) : (
                    <TicketDialog
                        {...{
                            isBuying,
                            data: dataTicket,
                            open: openTicketDialog,
                            setOpen: setOpenTicketDialog,
                        }}
                    />
                )
                : null}

            {bookings.length !== 0 ?
                responsive ? (
                    <>
                        {bookings.map((bookingItem, bookingIndex) => (
                            <>
                                <Grid container style={{ padding: '15px', marginTop: '50px' }}>
                                    <Grid container item xs={12} style={{ border: '2px solid #ff7500', borderRadius: '10px', padding: '10px 0px' }}>
                                        <Grid item xs={12} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)} style={{ display: 'grid', justifyContent: 'center', alignContent: 'center' }}>
                                            <Typography style={{ fontSize: '10pt' }}>
                                                {bookingItem.route}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)} style={{ textAlign: 'center' }}>
                                            <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(bookingItem.airCompanie).nome}</Typography>)}>
                                                <img src={bookingItem.urlAirCompanie} width={80} height={80} />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)} style={{ textAlign: 'center' }}>
                                            <Typography variant='subtitle1'>
                                                {bookingItem.departureDate}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)} style={{ textAlign: 'center' }}>
                                            <Typography variant='subtitle1'>
                                                <Chip label={bookingItem.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)} style={{ marginTop: "10px" }}>
                                            {NumberAlertOmission(bookingItem.statusAuthCC)}
                                        </Grid>
                                        {bookingItem.pix != null ? (
                                            <Grid item container xs={12} style={{ marginTop: "10px" }}>
                                                <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={IconsSource.pixIcon} className={classes.img} />
                                                </Grid>
                                                <Grid item xs={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <Typography variant="body1" style={{ textAlign: 'center' }}>Há pix disponível para pagamento</Typography>
                                                </Grid>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                    <Button onClick={() => { setIdBookingSelected(bookingItem.id_Booking); setOpenPix(true); }} style={{ textAlign: 'center', backgroundColor: "#38c4d4", color: "#ffffff" }}>Pagar agora</Button>
                                                </Grid>
                                            </Grid>
                                        ) : (null)}
                                        {bookingItem.isAuthBio ? (null) : (
                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Alert severity="error" onClick={() => setOpenDialogAlertOmission(true)}>
                                                    {bookingItem.pix != null ? (
                                                        <>
                                                            Para mais segurança, realize o   <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>reconhecimento facial</span>.
                                                        </>
                                                    ) : (
                                                        <>
                                                            Complete o{' '}
                                                            <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>reconhecimento facial</span>{' '}
                                                            para continuar com a compra das passagens aéreas.
                                                        </>
                                                    )}
                                                </Alert>
                                            </Grid>
                                        )}
                                        {/* {NumberAlertOmission(bookingItem.statusAuthCC)} */}
                                    </Grid>
                                </Grid>

                                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                            </>
                        ))}
                    </>
                ) : (
                    <>
                        {bookings.map((bookingItem, bookingIndex) => (
                            <Grid container style={{ marginBottom: '10px', marginTop: '50px' }}>
                                <Grid item xs={12} style={{ textAlign: '-webkit-center' }}>
                                    <Paper elevation={3} className={classes.custompaper}>
                                        <Grid container >
                                            <Grid item container xs={2} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)}>
                                                <Grid item xs={12}>
                                                    <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(bookingItem.airCompanie).nome}</Typography>)}>
                                                        <img
                                                            src={bookingItem.urlAirCompanie}
                                                            width={80}
                                                            height={80}
                                                        />
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={8} onClick={() => handleOpenTicket(true, bookingItem.id_Booking)}>
                                                <Grid item xs={12} style={{ display: 'grid', justifyContent: 'center', alignContent: 'center' }}>
                                                    <Typography variant='h6'>
                                                        {bookingItem.route}
                                                    </Typography>
                                                </Grid>
                                                <Divider />
                                                {/* <Grid item xs={12} style={{ display: 'grid', justifyContent: 'center', alignContent: 'center' }}>
                                                    <Typography variant='subtitle1'>
                                                        {bookingItem.status}
                                                    </Typography>
                                                </Grid> */}
                                                <Grid item xs={12} style={{ display: 'grid', justifyContent: 'center', alignContent: 'center' }}>
                                                    <Typography variant='subtitle1'>
                                                        {bookingItem.departureDate}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: 'grid', justifyContent: 'center', alignContent: 'center' }}>
                                                <Chip label={bookingItem.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                            </Grid>
                                            {/*<Grid item xs={2}>
                                                <CountDownTimer {...{ timeInSeconds: 3000, colorTypography: 'red' }} />
                                            </Grid> */}
                                            {bookingItem.pix != null ? (
                                                <Grid item container xs={12}>
                                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <img src={IconsSource.pixIcon} className={classes.img} />
                                                    </Grid>
                                                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Typography style={{ textAlign: 'center' }}>Há pix disponível para pagamento</Typography>
                                                    </Grid>
                                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                                        <Button onClick={() => { setIdBookingSelected(bookingItem.id_Booking); setOpenPix(true); }} style={{ textAlign: 'center', backgroundColor: "#38c4d4", color: "#ffffff" }}>Pagar agora</Button>
                                                    </Grid>
                                                </Grid>
                                            ) : (null)}
                                            {bookingItem.isAuthBio ? (null) : (
                                                <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                    <Alert severity="error" onClick={() => setOpenDialogAlertOmission(true)}>
                                                        {bookingItem.pix != null ? (
                                                            <>
                                                                Para mais segurança, realize o   <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>reconhecimento facial</span>.
                                                            </>
                                                        ) : (
                                                            <>
                                                                Complete o{' '}
                                                                <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>reconhecimento facial</span>{' '}
                                                                para continuar com a compra das passagens aéreas.
                                                            </>
                                                        )}
                                                    </Alert>
                                                </Grid>
                                            )}
                                            {/* {NumberAlertOmission(bookingItem.statusAuthCC)} */}
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        ))}
                    </>
                )
                : null}
            {isLoading ? (
                <Box height={'500px'} display={'flex'} alignItems={'center'} justifyContent={'center'}   >
                    <CircularProgress style={{ color: '#612F74' }} size={100} />
                </Box>
            ) : availabilities !== null ?
                responsive ? (
                    <div style={{ marginTop: '35px' }}>
                        <ListSearchFlightsMobi
                            {...{ handleChangeStateModalTaxRules, availabilities, SearchBookings, onChangeSelectedFlights, selectedFlights }} />
                    </div>
                ) : (
                    <ListSearchFlights
                        {...{ handleChangeStateModalTaxRules, availabilities, SearchBookings, onChangeSelectedFlights, selectedFlights }} />
                )
                : (
                    <LandPage {...{ promotionalsFlights }} />
                )
            }
        </>
    )
}

export const DashSearchFlights = (props) => {
    return (
        <div>
            <Dashboard {...props} component={SearchFlights} />
        </div>
    )
};
export default DashSearchFlights

