
import Resizer from "react-image-file-resizer";

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export default async function resizePhotoToPNG(base64, maxW, maxH, quality, minW, minH) {

    if (!base64) {
        return null;
    }

    const objImage = dataURLtoFile(base64, 'convert.png')
    let resizePhoto = new Promise((resolve) => {
        Resizer.imageFileResizer(
            objImage,
            maxW,
            maxH,
            "PNG",
            quality,
            0,
            (uri) => {
                resolve(uri);
            },
            "base64",
            minW,
            minH
        );
    });

    return resizePhoto
}
