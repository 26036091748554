import api from '../../../Api';

const cepServices = {

    getDataCEP: async (cep) => {
        const response = await api.get('/Cep/verification/' + cep);
        return response;
    },
}

export default cepServices;
