import {
  Button,
  Card,
  Grid,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  ContactMailOutlined,
  HomeOutlined,
  PersonOutlineOutlined,
  SearchOutlined,
} from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import CustomLoader from "../../../components/CustomLoader";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import cepServices from "../../../services/places/Cep";
import cityServices from "../../../services/places/City";
import countryServices from "../../../services/places/Country";
import stateServices from "../../../services/places/States";
import searchRFService from "../../../services/RF";
import user_registerServices from "../../../services/User/Register";
import validatorCPF from "../../../utils/cpfValidator";
import Steps from "./Steps";
import { useStyles } from "./styles";

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SearchOutlined />,
    2: <PersonOutlineOutlined />,
    3: <ContactMailOutlined />,
    4: <HomeOutlined />,
    5: <AccountCircleOutlined />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function getSteps() {
  return ["Início", "Pessoal", "Contato", "Endereço", "Usuário"];
}

const RegisterDesk = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [birthDate, setBirthDate] = useState();
  const steps = getSteps();
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dataRegister, setDataRegister] = useState({
    person: {
      firstName: null,
      lastName: null,
      gender: null,
      cpf: null,
      telephone1: null,
      telephone2: null,
      birthDate: null,
      cep: null,
      address: null,
      number: "0",
      nationality: null,
      complement: null,
      neighborhood: null,
      id_Nationality: null,
      id_Country: null,
      street: null,
      id_State: null,
      id_ResidenceCity: null,
      id_City: null,
    },
    user: {
      login: null,
      email: null,
      password: null,
      passwordConfirmation: null,
    },
  });

  var dataVerified = {
    firstName: false,
    lastName: false,
    gender: false,
    cpf: false,
    birthDate: false,
    email: false,
    telephone1: false,
    telephone2: false,
    country: false,
    state: false,
    residenceCity: false,
    neighborhood: false,
    street: false,
    nationality: false,
    login: false,
    password: false,
    passwordConfirmation: false,
  };
  const [stateDataVerified, setStateDataVerified] = useState(dataVerified);

  async function saveRegister() {
    var response;
    try {
      setIsLoading(true);
      response = await user_registerServices.postUser_register(dataRegister);
      if (response.status == 201) {
        setSnackVariant("success");
        setSnackMessage("Usuário criado com sucesso.");
        setSnackVisible({ visible: true });
        setActiveStep(steps.length);
      }
    } catch (e) {
      if (e.response.data.message) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
      } else if (Object.values(e.response.data.errors)[0][0]) {
        setSnackVariant("warning");
        setSnackMessage(Object.values(e.response.data.errors)[0][0]);
        setSnackVisible({ visible: true });
      } else {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar criar usuario.");
        setSnackVisible({ visible: true });
      }
    } finally {
      setIsLoading(false);
    }
  }

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      saveRegister();
      return;
    }
    let verify = false;

    if (activeStep === 0) {
      if (
        dataRegister.person.cpf === null ||
        dataRegister.person.cpf === "   .   .   -  " ||
        dataRegister.person.cpf === undefined
      ) {
        dataVerified = { ...dataVerified, cpf: true };
        verify = true;
        setStateDataVerified(dataVerified);
        setSnackMessage("CPF não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        if (!validatorCPF(dataRegister.person.cpf)) {
          dataVerified = { ...dataVerified, cpf: true };
          verify = true;
          setStateDataVerified(dataVerified);
          setSnackMessage("CPF inválido.");
          setSnackVariant("info");
          setSnackVisible({ visible: true });
          return;
        } else {
          dataVerified = { ...dataVerified, cpf: false };
          setStateDataVerified(dataVerified);
        }
      }

      if (dataRegister.person.birthDate === null) {
        dataVerified = { ...dataVerified, birthDate: true };
        verify = true;
        setStateDataVerified(dataVerified);
        setSnackMessage("Data de nascimento não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, birthDate: false };
        setStateDataVerified(dataVerified);
      }

      try {
        setIsLoading(true);
        let dto = {
          cpf: dataRegister.person.cpf,
          birthDate: dataRegister.person.birthDate,
        };
        let response = await searchRFService.postSearchCPF(dto);

        if (!response.data.results.valid) {
          dataVerified = { ...dataVerified, cpf: true, birthDate: true };
          verify = true;
          setStateDataVerified(dataVerified);
          setSnackMessage(response.data.message);
          setSnackVariant("warning");
          setSnackVisible({ visible: true });
          return;
        }

        setDataRegister({
          ...dataRegister,
          person: {
            ...dataRegister.person,
            lastName: response.data.results.lastName,
            firstName: response.data.results.firstName,
          },
        });
      } catch (e) {
      } finally {
        setIsLoading(false);
      }
    }

    if (activeStep === 1) {
      if (
        dataRegister.person.firstName === "" ||
        dataRegister.person.firstName === null ||
        dataRegister.person.firstName === undefined
      ) {
        dataVerified = { ...dataVerified, firstName: true };
        verify = true;
        setSnackMessage("Nome não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, firstName: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.lastName === "" ||
        dataRegister.person.lastName === null ||
        dataRegister.person.lastName === undefined
      ) {
        dataVerified = { ...dataVerified, lastName: true };
        verify = true;
        setSnackMessage("Sobrenome não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, lastName: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.gender === 0 ||
        dataRegister.person.gender === null
      ) {
        dataVerified = { ...dataVerified, gender: true };
        verify = true;
        setSnackMessage("Gênero não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, gender: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.nationality === 0 ||
        dataRegister.person.nationality === null
      ) {
        dataVerified = { ...dataVerified, nationality: true };
        verify = true;
        setSnackMessage("Nacionalidade não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, nationality: false };
        setStateDataVerified(dataVerified);
      }
    }

    if (activeStep === 2) {
      if (
        dataRegister.user.email === "" ||
        dataRegister.user.email === null ||
        dataRegister.user.email === undefined
      ) {
        dataVerified = { ...dataVerified, email: true };
        verify = true;
        setSnackMessage("Email não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, email: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.telephone1 === null ||
        dataRegister.person.telephone1 === "(  )      -    " ||
        dataRegister.person.telephone1 === undefined
      ) {
        dataVerified = { ...dataVerified, telephone1: true };
        verify = true;
        setSnackMessage("Telefone principal não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, telephone1: false };
        setStateDataVerified(dataVerified);
      }
    }

    if (activeStep === 3) {
      if (
        dataRegister.person.id_Country === null ||
        dataRegister.person.id_Country === undefined
      ) {
        dataVerified = { ...dataVerified, country: true };
        verify = true;
        setSnackMessage("Não há país selecionado.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, country: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.id_State === null ||
        dataRegister.person.id_State === undefined
      ) {
        dataVerified = { ...dataVerified, state: true };
        verify = true;
        setSnackMessage("Não há estado selecionado.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, state: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.id_ResidenceCity === null ||
        dataRegister.person.id_ResidenceCity === undefined
      ) {
        dataVerified = { ...dataVerified, residenceCity: true };
        verify = true;
        setSnackMessage("Não há cidade selecionado.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, residenceCity: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.neighborhood === "" ||
        dataRegister.person.neighborhood === null ||
        dataRegister.person.neighborhood === undefined
      ) {
        dataVerified = { ...dataVerified, neighborhood: true };
        verify = true;
        setSnackMessage("Bairro não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, neighborhood: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.person.address === "" ||
        dataRegister.person.address === null ||
        dataRegister.person.address === undefined
      ) {
        dataVerified = { ...dataVerified, street: true };
        verify = true;
        setSnackMessage("Logradouro não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, street: false };
        setStateDataVerified(dataVerified);
      }
    }

    if (activeStep === 4) {
      if (
        dataRegister.user.login === "" ||
        dataRegister.user.login === null ||
        dataRegister.user.login === undefined
      ) {
        dataVerified = { ...dataVerified, login: true };
        verify = true;
        setSnackMessage("Usuário não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, login: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.user.password === "" ||
        dataRegister.user.password === null ||
        dataRegister.user.password === undefined
      ) {
        dataVerified = { ...dataVerified, login: true };
        verify = true;
        setSnackMessage("Senha não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, login: false };
        setStateDataVerified(dataVerified);
      }

      if (
        dataRegister.user.passwordConfirmation === "" ||
        dataRegister.user.passwordConfirmation === null ||
        dataRegister.user.passwordConfirmation === undefined
      ) {
        dataVerified = { ...dataVerified, passwordConfirmation: true };
        verify = true;
        setSnackMessage("Confirmação de senha não pode ser vazio.");
        setSnackVariant("error");
        setSnackVisible({ visible: true });
        return;
      } else {
        dataVerified = { ...dataVerified, passwordConfirmation: false };
        setStateDataVerified(dataVerified);
      }
      if (
        dataRegister.user.password === dataRegister.user.passwordConfirmation
      ) {
        verify = true;
        setSnackMessage("Senhas não coincidem.");
        dataVerified = {
          ...dataVerified,
          passwordConfirmation: true,
          password: true,
        };
      } else {
        dataVerified = {
          ...dataVerified,
          passwordConfirmation: false,
          password: false,
        };
      }
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onClickRedirectLogin = () => {
    history.push("/entrar");
  };

  async function getDataCEP() {
    try {
      setIsLoading(true);
      if (
        dataRegister.person.cep !== "" &&
        dataRegister.person.cep !== "     -   " &&
        dataRegister.person.cep.length === 9
      ) {
        let response = await cepServices.getDataCEP(dataRegister.person.cep);

        setCities(response.data.results.cities);
        setDataRegister({
          ...dataRegister,
          person: {
            ...dataRegister.person,
            id_State: response.data.results.id_State,
            id_City: response.data.results.id_City,
            neighborhood: response.data.results.neighborhood,
            id_ResidenceCity: response.data.results.id_ResidenceCity,
            address: response.data.results.address,
          },
        });
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let response = await countryServices.getCountries(true);
        setCountries(response.data.results);
      } catch {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (dataRegister.person.id_State) {
        try {
          setIsLoading(true);
          let response = await cityServices.getCitiesByState(
            true,
            states?.find((s) => s.value === dataRegister.person.id_State).id
          );
          setCities(response.data.results);
        } catch {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [dataRegister.person.id_State]);

  useEffect(() => {
    (async () => {
      if (dataRegister.person.id_Country) {
        try {
          setIsLoading(true);
          let response = await stateServices.getStatesByCountry(
            true,
            countries?.find((s) => s.value === dataRegister.person.id_Country)
              .id
          );
          setStates(response.data.results);
        } catch {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [dataRegister.person.id_Country]);

  useEffect(() => {
    if (dataRegister.person.id_City) {
      try {
        setIsLoading(true);
        setDataRegister({
          ...dataRegister,
          person: {
            ...dataRegister.person,
            id_ResidenceCity: cities?.find(
              (s) => s.value === dataRegister.person.id_City
            ).id,
          },
        });
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
  }, [dataRegister.person.id_City]);

  useEffect(() => {
    try {
      setIsLoading(true);
      setDataRegister({
        ...dataRegister,
        person: {
          ...dataRegister.person,
          id_Nationality: countries?.find(
            (s) => s.value === dataRegister.person.nationality
          ).id,
        },
      });
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [dataRegister.person.nationality]);

  return (
    <div className={classes.root}>
      <CustomLoader isLoading={isLoading} />
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <Card className={classes.box}>
        <Grid container>
          {activeStep === steps.length ? null : (
            <Grid item xs={12}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
                className={classes.stepper}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          )}
          <Grid item xs={12}>
            <Steps
              {...{
                step: activeStep,
                isLoading,
                dataVerified: stateDataVerified,
                dataRegister,
                setDataRegister,
                countries,
                states,
                cities,
                getDataCEP,
                birthDate,
                setBirthDate,
                fastRegister: props.fastRegister,
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridCenter}>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Button
                    onClick={() =>
                      props.fastRegister
                        ? history.push("/aereos")
                        : onClickRedirectLogin()
                    }
                    className={classes.button3}
                  >
                    {props.fastRegister ? "Continuar Compra" : "Entrar"}
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <Button
                      onClick={() =>
                        props.fastRegister
                          ? history.push("/aereos")
                          : onClickRedirectLogin()
                      }
                      className={classes.button3}
                    >
                      {props.fastRegister ? "Continuar Compra" : "Entrar"}
                    </Button>
                    {activeStep === 0 ? null : (
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        Voltar
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNext()}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Finalizar"
                        : "Avançar"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RegisterDesk;
