import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    bola: {
        background: '#ff7500',
        borderRadius: '50%',
        height: '15px',
        width: '15px',
        marginLeft: '10px',
        marginRight: '10px',
    },

    textTitleBold: {
        fontWeight: "bold",
        fontSize: "20pt",
    },

    gridBanner: {
        textAlign: "center",
        marginTop: "5px",
        borderRadius: "10px",
        padding: "8px",
    },


    boxCompanies: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '-webkit-fill-available',
    },

    container: {
        display: 'flex',
        justifyContent: 'center'
    },

    customGridMain: {
        marginTop: '10px',
        marginBottom: '10px',
        background: 'none',
        maxWidth: '90vw',
        padding: '16px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },

    customGrid02: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px ',

        marginLeft: '20px',
        marginRight: '20px',
    },

    customGrid03: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    customGrid04: {
        marginTop: '10px',
        marginBottom: '10px',
        background: '#ff7500',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
    },

    customGrid05: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#612F74',
        borderRadius: '10px',
        marginLeft: '10px',
        marginRight: '10px',
        marginBottom: '20px',
        marginTop: '20px',
    },

    typograph_01: {
        border: '2px solid #612F74',
        borderRadius: '10px',
        padding: '10px',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#716f71',
        width: '500px',
        display: 'flex',
        justifyContent: 'space-between',
    },

    typograph_02: {
        background: '#612F74',
        borderRadius: '10px',
        color: '#fff7ff',
        padding: '10px',
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around'
    },

    typograph_05: {
        textAlign: 'center',
        color: '#a0a1a4 ',
    },

    typograph_06: {
        textAlign: 'center',
        color: '#716f71',
    },

    typograph_07: {
        textAlign: 'left',
        color: '#716f71',
    },

    typograph_08: {
        // width: '-webkit-fill-available',
        color: '#fff7ff',
    },

    customPaperCarousel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFF7FF'
    },

}));
