import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    custompaper: {
        padding: theme.spacing(2),
        // maxWidth: '70vw',
        // minWidth: '70vw',
        maxWidth: '1100px',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },
    buttonLogin: {
        color: "white",
        fontWeight: "650",
        width: '150px',
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
    },
}));
