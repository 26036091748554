import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Fab, Grid, Grow, IconButton, List, ListItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@material-ui/core";
import { AddOutlined, CheckCircleOutlined, DeleteOutline, EditOutlined, PersonAddOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import { DeletePersonDialog } from "../../../templates/DeletePersonDialog";
import DialogMessage from "../../../templates/DialogMessage";
import { RegisterPersonDialog } from "../../../templates/RegisterPersonDialog";
import { useStyles } from "./styles";


function createData(name, cpf, birthDate, gender, nationality) {
    return { name, cpf, birthDate, gender, nationality };
}

export const MobiPeopleList = (props) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [openRegisterPerson, setOpenRegisterPerson] = useState(false);
    const [grow, setGrow] = useState(true);
    const [snackVariant, setSnackVariant] = useState('error');
    const [snackMessage, setSnackMessage] = useState('');
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [openDialogMessage, setOpenDialogMessage] = useState(false);
    const [openDeletePerson, setOpenDeletePerson] = useState(false);
    const [idpax, setIdpax] = useState(0);
    const [messageData, setMessageData] = useState({
        message: null,
        title: null,
        buttonMessage: null,
    });

    // var messageData = {
    //     message: null,
    //     title: null,
    //     buttonMessage: null,
    // }

    const handleChangePanel = () => {
        setExpanded(!expanded)
    };


    const handleClickOpenRegisterPerson = (id_Passenger = 0) => {
        setIdpax(id_Passenger)
        setOpenRegisterPerson(true)
    }

    const searchListPerson = () => {
        props.getListPerson()
    }

    const handleClickDeletePerson = (id_Passenger) => {
        setIdpax(id_Passenger)
        setOpenDeletePerson(true)
    }

    function handleClickOpenDialogMessage() {
        setOpenDialogMessage(true)
    }

    return (
        <Grow in={grow} timeout={1000}>
            <>
                <CustomizedSnackbars {...{ variant: snackVariant, message: snackMessage, visible: snackVisible.visible, setSnackbar: setSnackVisible }} />
                {
                    openDialogMessage ? (
                        <DialogMessage  {...{
                            message: messageData.message, title: messageData.title, buttonMessage: messageData.buttonMessage, setOpen: setOpenDialogMessage, open: openDialogMessage
                        }} />
                    ) : null}
                {openRegisterPerson ? (
                    <RegisterPersonDialog
                        {...{
                            setIsLoading: props.setIsLoading,
                            idpax,
                            open: openRegisterPerson,
                            setOpen: setOpenRegisterPerson,
                            searchListPerson,
                            setSnackMessage,
                            setSnackVariant,
                            setSnackVisible,
                            handleClickOpenDialogMessage,
                            setMessageData,
                        }}
                    />
                ) : null}
                {openDeletePerson ? (
                    <DeletePersonDialog
                        {...{
                            setIsLoading: props.setIsLoading,
                            idpax,
                            open: openDeletePerson,
                            setOpen: setOpenDeletePerson,
                            handleClickDeletePerson,
                            searchListPerson,
                            setSnackMessage,
                            setSnackVariant,
                            setSnackVisible,
                        }}
                    />
                ) : null}
                <Grid container>
                    <Grid item xs={12} style={{ marginBottom: '25px', textAlign: 'center' }}>
                        <Typography variant={'h5'} style={{ color: 'rgba(97, 47, 116)' }}>Passageiros</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.boxRegister}>
                            <Button
                                onClick={() => handleClickOpenRegisterPerson()}
                                className={classes.buttonSearch}
                                startIcon={<PersonAddOutlined />}>
                                {/* Adicionar */}
                                Cadastrar
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ flex: '1 1 auto' }}>
                        {props.data?.results?.length !== 0 ? null : (
                            <Typography style={{ textAlign: 'center', marginTop: '20px' }} variant={'h5'}> Não há passageiros registrados</Typography>
                        )}
                        {props.data?.results?.map((item) => (
                            // <ListItem divider>
                            <Accordion className={classes.teste}>
                                <AccordionSummary classes={{ content: classes.expanded, expanded: classes.expanded }}>
                                    <Typography className={classes.alignTextToCenter}>{item.firstName} </Typography>
                                    <Box className={classes.alignTextToCenter}>
                                        {item.checkedCPF ? (
                                            <Tooltip title={"CPF verificado"}>
                                                <CheckCircleOutlined style={{ color: 'green' }} />
                                            </Tooltip>
                                        ) : null}
                                        <Tooltip title={"Editar"}>
                                            <IconButton onClick={() => handleClickOpenRegisterPerson(item.id_Passenger)}>
                                                <EditOutlined style={{ color: 'purple' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography>{`Nome: ${item.firstName} ${item.lastName}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{`Data de nascimento: ${item.birthDate}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{`Gênero: ${item.birthDate}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography>{`Nacionalidade: ${item.nationality}`}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                onClick={() => handleClickDeletePerson(item.id_Passenger)}
                                                className={classes.buttonDelete}
                                                startIcon={<DeleteOutline />}>
                                                Deletar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Fab size="medium" className={classes.fabCustomAdd} aria-label="adicionar_pessoa">
                            <PersonAddOutlined />
                        </Fab>
                    </Grid> */}
                </Grid>

            </>
        </Grow >
    )
}

export default MobiPeopleList;
