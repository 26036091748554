import Cookies from "universal-cookie";
import api from "../../Api";

const cookies = new Cookies();

const bookingService = {

    postBookingFlights: async (dto) => {
        let response = await api.post('/Air/fared-booking-flight', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getListBooking: async () => {
        const response = await api.get('/Booking/get-all', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getListBookingActives: async () => {
        const response = await api.get('/Booking/get-all-active', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getListPixActives: async (id) => {
        const response = await api.get(`/Booking/checkpixpayment/${id}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    getById: async (id_Booking) => {
        let response = await api.get(`/Booking/get/${id_Booking}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postCreateGuaranteedRate: async () => {

    },

    getDownloadContract: async (enumMethodPayment, id = '') => {
        const response = await api.get(`/Booking/get-card-debit-authorization?payment=${enumMethodPayment}&?idbooking=${id}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default bookingService;
