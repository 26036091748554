import { Dialog, DialogContent, Grow, Slide, Zoom } from "@material-ui/core";
import React, { useEffect } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogAlerts = (props) => {


    const handleCloseDialog = () => {
        props.setOpen(false)
    }

    useEffect(() => {
        setTimeout(() => {
            handleCloseDialog()
        }, 5000);
    })


    return (

        <>
            {/* <Zoom in={props.open}> */}
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                maxWidth={"null"}
                onClose={props.allowClose ? handleCloseDialog : null}
                aria-describedby="alert-dialog-slide-description"
            // fullWidth
            >
                <img src={props.img} alt={'anuncio_reducao_de_tarifa'} />

                {/* <DialogContent
                    style={{
                        // // backgroundImage: `${props.img}`
                        // backgroundImage: `url(${props.img})`,
                        // backgroundSize: "cover",
                        // backgroundRepeat: "no-repeat",
                        // backgroundPosition: "center center",
                        padding: 0,
                        width: "600px",
                        height: "400px",
                    }}> */}
                {/* </DialogContent> */}
            </Dialog>
            {/* </Zoom> */}
        </>
    )
}
