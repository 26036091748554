import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            display: 'flex',
        },
    },

    custompaper: {
        padding: theme.spacing(2),
        // maxWidth: '70vw',
        maxWidth: '90vw',
        minWidth: '70vw',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0),
        },
    },

    stepper: {
        display: "flex",
        background: "none",
        width: "-webkit-fill-available",
        justifyContent: "center",
    },

    buttonSelect: {
        width: '-webkit-fill-available',
        color: "white",
        fontWeight: "600",
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        margin: "5px",
        '&:hover': {
            background:
                "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
    },

    button03: {
        width: '100%',
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '15px'
    },

    button: {
        color: "white",
        fontWeight: "800",
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },
}));
