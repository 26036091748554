import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "390px",
  },

  box: {},

  gridContainer: {
    width: "-webkit-fill-available",
    margin: "5px",
  },

  field: {
    width: "100%",
    backgroundColor: "#ffffff9e",
  },
}));
