import { Box, Button, Typography } from "@material-ui/core";
import { DoneOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";

export const FacialBiometricsStep03 = (props) => {
    const classes = useStyles();

    const tryPictureAgain = () => {
        props.handleBack();
        props.setFileFacial(null);
    }

    return (
        <>
            <Box textAlign={'center'} paddingTop={'2rem'}>
                <img
                    src={props.fileFacial}
                    alt={'foto facial'}
                    style={{ borderRadius: '20px', maxWidth: '311.85px', maxHeight: '385px' }}
                    width={window.innerWidth * 0.81}
                    height={window.innerWidth}
                />
            </Box>
            <Box textAlign={'-webkit-center'} display={'flex'} justifyContent={'space-around'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => tryPictureAgain()} className={classes.buttonOrange} startIcon={<KeyboardReturnOutlined />}>
                    Refazer foto
                </Button>
                <Button onClick={() => props.handleNext()} className={classes.buttonOrange} startIcon={<DoneOutlined />}>
                    Confirmar
                </Button>
            </Box>
        </>
    )
}
