import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh",
  },

  dialogTitle: {
    background: "#612F74",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#fafafa',
  },

  buttonNext: {
    background: "#ff7500",
    color: "white",
    "&:hover": {
      background: "rgba(255, 117, 0, 0.8)"
    },
    borderRadius: "5px",
  },

  button: {
    border: 0,
    color: "white",
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    background:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    "&:hover": {
      background:
        "linear-gradient( 136deg, rgb(222,93,13) 0%, rgb(213,44,67) 50%, rgb(118,15,115) 100%);",
    },
    fontSize: "1rem",
    fontFamily: "Open Sans",
    fontWeight: "400",
    lineHeight: "1.5",
    margin: 0,
  },
  textFieldCopy: {
    // width: "25ch"
  }
}));
