import { Box, Divider, Grid, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Slide, Typography, useTheme, withStyles } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListItemCustom = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#fceefe",
            cursor: 'pointer',
        }
    },
})(ListItem);

export const BuyingDeskStep03 = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [valueCC, setValueCC] = useState(0);

    const handleChangeCC = (event) => {
        setValueCC(event.target.value);
    };

    useLayoutEffect(() => {
        if (props.dtoCompra.idInstallment > 0) {
            setValueCC(props.dtoCompra.idInstallment)
        }
    })

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <List>
                        <ListItem>
                            <ListItemAvatar style={{ width: '130px', textAlign: 'center' }}>
                                <Box className={classes.ccBox} style={{ background: `${props.getColor(props.opcoesUsadas.cartao.cardFlag).background}` }}>
                                    <img height={50} width={100} src={props.opcoesUsadas.cartao.urlFlag} />
                                </Box>
                            </ListItemAvatar>
                            <ListItemText style={{ marginLeft: '10px' }} primary={`${props.opcoesUsadas.cartao.cardFlag}`} secondary={`${props.opcoesUsadas.cartao.cardNumber}`} />
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <RadioGroup aria-label="optionCC" name="optionCC" value={valueCC} onChange={handleChangeCC}>
                        <Grid container>
                            {props.optionsCreditCard.map((option) => (
                                <>
                                    <Grid style={{ borderBottom: '1px solid rgb(97, 47, 116)' }} item xs={12} onClick={() => { setValueCC(option.id); props.handleClickSelectOption(option) }} className={classes.gridItem}>
                                        <Box display={'flex'} alignItems={'center'}>
                                            <Radio
                                                value={option.id}
                                                checked={valueCC == option.id}
                                                onClick={() => { setValueCC(option.id) }}
                                                style={{ color: '#612F74' }}
                                            />
                                            {option.quantity === 1 ? (
                                                <Typography>
                                                    {`${option.quantity}x ${option.installment}`}
                                                </Typography>
                                            ) : (
                                                <Typography>
                                                    {`1x de ${option.firstInstallment} + ${option.quantity - 1}x de ${option.installment}`}
                                                </Typography>
                                            )
                                            }
                                        </Box>
                                        {!option.hasInterest ? (
                                            <Typography>
                                                {`Sem Juros`}
                                            </Typography>
                                        ) : (
                                            <Typography>
                                                {`Total de ${option.total}`}
                                            </Typography>
                                        )
                                        }
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
        </>
    )
}
