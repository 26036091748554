import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

    table: {
        minWidth: 700,
    },

    obsText: {
        color: '#8c8c8c',
        padding: '1rem',
    },

    gridFonte: {
        marginTop: '1.5rem',
        marginBottom: '1.5rem',
    },

    gridLogo: {
        textAlign: 'center',
    },


}));
