import { AppBar, Button, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, SwipeableDrawer, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { AccountCircleOutlined, ApartmentOutlined, ArrowForwardIosRounded, CreditCardOutlined, ExitToAppOutlined, FlightOutlined, HelpOutlineOutlined, HistoryOutlined, InputOutlined, MenuOutlined, PeopleOutlineOutlined, VerifiedUserOutlined, WorkRounded } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Cookies from "universal-cookie";
import authServices from "../../services/Auth";
import midiaService from "../../services/Midia";
import personAccountService from "../../services/PersonAccount/index";
import { FooterCustom } from "./Footer";
import { useStyles } from "./styles";
import personService from "../../services/Person";
import { RegisterAuthenticate } from "../../templates/RegisterAuthenticate";
import CustomLoader from "../../components/CustomLoader";

export const Dashboard = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const cookies = new Cookies();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [logoDeskLandPage, setLogoDeskLandPage] = useState("");
  const [logoMobiLandPage, setLogoMobiLandPage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [openDialogAuth, setOpenDialogAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMobileMenuDrawer, setOpenMobileMenuDrawer] = useState();
  const [personAccount, setPersonAccount] = useState("");

  const handleClickExit = () => {
    cookies.remove("token");
    history.push("/");
    if (window.location.pathname === "/") {
      document.location.reload();
    }
    handleMenuClose();
  };

  const handleCloseAuthRF = () => {
    setOpenDialogAuth(false)
  }

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMobileMenuDrawer = () => {
    setOpenMobileMenuDrawer(false);
  };

  const handleOpenMobileMenuDrawer = () => {
    setOpenMobileMenuDrawer(true);
  };

  const verifyIsLoggedIn = async () => {
    try {
      await authServices.getIsAuthenticate();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
    } finally {
    }
  };

  const availableAuthRF = async (btn = false) => {
    try {
      setIsLoading(true);
      await personService.isAuthRF();
      sessionStorage.setItem("isAuthSerpro", "true")
      setIsAuth(false)
      return false
    } catch (e) {
      setIsAuth(true)
      if (e.response.status == 403) {
        if (btn === true) setOpenDialogAuth(true)
        return true
      }
    } finally {
      setIsLoading(false);
    }
  };

  const menuId = "simple-menu";
  const renderMenu = (
    <Menu
      id={menuId}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <List>
        {!isLoggedIn ? (
          <>
            <ListItem button key={"entrar"} onClick={() => history.push("/entrar")}>
              <ListItemIcon>
                <InputOutlined />
              </ListItemIcon>
              <ListItemText>Entrar</ListItemText>
            </ListItem>
            <Divider />
          </>
        ) : null}
        {isAuth ? (
          <>
            <ListItem button key={"autenticacaobio"} onClick={() => availableAuthRF(true)}>
              <ListItemIcon>
                <VerifiedUserOutlined />
              </ListItemIcon>
              <ListItemText>Autenticação</ListItemText>
            </ListItem>
            <Divider />
          </>
        ) : null}
        <ListItem button key={"historico"} onClick={() => history.push("/historico")}>
          <ListItemIcon>
            <HistoryOutlined />
          </ListItemIcon>
          <ListItemText>Histórico</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"cartao"} onClick={() => history.push("/cartao")}>
          <ListItemIcon>
            <CreditCardOutlined />
          </ListItemIcon>
          <ListItemText>Cartão</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"pessoas"} onClick={() => history.push("/passageiros")}>
          <ListItemIcon>
            <PeopleOutlineOutlined />
          </ListItemIcon>
          <ListItemText>Passageiros</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"quemsomos"} onClick={() => history.push("/quemsomos")}>
          <ListItemIcon>
            <ApartmentOutlined />
          </ListItemIcon>
          <ListItemText>Quem somos</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"comofunciona"} onClick={() => history.push("/zulminha")}>
          <ListItemIcon>
            <WorkRounded />
          </ListItemIcon>
          <ListItemText>Como funciona</ListItemText>
        </ListItem>
        <Divider />
        {isLoggedIn ? (
          <ListItem button key={"sair"} onClick={() => handleClickExit()}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText>Sair</ListItemText>
          </ListItem>
        ) : null}
      </List>
    </Menu>
  );

  const renderMobileMenu = (
    <SwipeableDrawer
      anchor="right"
      open={openMobileMenuDrawer}
      onClose={handleCloseMobileMenuDrawer}
      classes={{ paper: classes.paperDrawerCustom }}
    >
      <ListItem onClick={() => handleCloseMobileMenuDrawer()} style={{ justifyContent: "left" }}>
        <ListItemIcon>
          <ArrowForwardIosRounded />
        </ListItemIcon>
        <ListItemText>
          <Typography style={{ color: "gray" }}>
            {isLoggedIn ? "Olá " + personAccount : null}
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />
      <List>
        {!isLoggedIn ? (
          <>
            <ListItem button key={"entrar"} onClick={() => history.push("/entrar")}>
              <ListItemIcon>
                <InputOutlined />
              </ListItemIcon>
              <ListItemText>Entrar</ListItemText>
            </ListItem>
            <Divider />
          </>
        ) : (
          <>
            {isAuth ? (
              <>
                <ListItem button key={"autenticacaobio"} onClick={() => availableAuthRF(true)}>
                  <ListItemIcon>
                    <VerifiedUserOutlined />
                  </ListItemIcon>
                  <ListItemText>Autenticação</ListItemText>
                </ListItem>
                <Divider />
              </>
            ) : null}
            <ListItem button key={"historico"} onClick={() => history.push("/historico")}>
              <ListItemIcon>
                <HistoryOutlined />
              </ListItemIcon>
              <ListItemText>Histórico</ListItemText>
            </ListItem>
            <Divider />
            <ListItem button key={"cartao"} onClick={() => history.push("/cartao")}>
              <ListItemIcon>
                <CreditCardOutlined />
              </ListItemIcon>
              <ListItemText>Cartão</ListItemText>
            </ListItem>
            <Divider />
            <ListItem button key={"pessoas"} onClick={() => history.push("/passageiros")}>
              <ListItemIcon>
                <PeopleOutlineOutlined />
              </ListItemIcon>
              <ListItemText>Passageiros</ListItemText>
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem button key={"quemsomos"} onClick={() => history.push("/quemsomos")}>
          <ListItemIcon>
            <ApartmentOutlined />
          </ListItemIcon>
          <ListItemText>Quem somos</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button key={"comofunciona"} onClick={() => history.push("/zulminha")}>
          <ListItemIcon>
            <WorkRounded />
          </ListItemIcon>
          <ListItemText>Como funciona</ListItemText>
        </ListItem>
        <Divider />
        {isLoggedIn ? (
          <ListItem button key={"sair"} onClick={() => handleClickExit()}>
            <ListItemIcon>
              <ExitToAppOutlined />
            </ListItemIcon>
            <ListItemText>Sair</ListItemText>
          </ListItem>
        ) : null}
      </List>
    </SwipeableDrawer>
  );

  const getPersonAccount = async () => {
    try {
      let response = await personAccountService.getPersonAccount();
      let firstname = response.data.results?.firstName.split(" ")[0];
      firstname =
        firstname[0] + firstname.substring(1, firstname.length).toLowerCase();
      setPersonAccount(firstname);
    } catch (error) { }
  };

  useEffect(() => {
    if (personAccount) {
      availableAuthRF();
    }
  }, [personAccount])

  useEffect(() => {
    getPersonAccount();
  }, []);

  useEffect(() => {
    verifyIsLoggedIn();
    (async () => {
      let response = await midiaService.getLogoLandPage();
      setLogoDeskLandPage(response?.data?.results?.urlDesk);
      setLogoMobiLandPage(response?.data?.results?.urlMobi);
    })();
  }, []);

  return (
    <>
      <main className={classes.content}>
        <CustomLoader isLoading={isLoading} />
        {openDialogAuth ? (
          <RegisterAuthenticate {...{
            open: openDialogAuth,
            setOpen: setOpenDialogAuth,
            handleCloseAuthRF
          }} />
        ) : null}
        <div className={classes.grow}>
          <AppBar position="static" className={classes.appbarCustom}>
            <Grid container style={{ maxWidth: "1132px" }}>
              {responsive ? null : (
                <>
                  <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton onClick={() => history.push("/")} className={classes.iconButtonCustom}>
                      <img src={logoDeskLandPage} height={35} alt="" />
                    </IconButton>
                  </Grid>
                  <Grid item xs={8} style={{ display: "flex", justifyContent: "center" }}>
                    <Button onClick={() => history.push("/aereos")} className={classes.buttonTeste}>
                      <FlightOutlined style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF" }}>
                        Aéreos
                      </Typography>
                    </Button>
                    <Button onClick={() => history.push("/zulminha")} className={classes.buttonTeste}>
                      <WorkRounded style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF" }}>
                        Como funciona
                      </Typography>
                    </Button>
                    {!isLoggedIn ? (
                      <Button disabled={isLoggedIn} onClick={() => history.push("/quemsomos")} className={classes.buttonTeste}>
                        <ApartmentOutlined style={{ color: "#FFF7FF" }} />
                        <Typography style={{ color: "#FFF7FF" }}>
                          Quem somos
                        </Typography>
                      </Button>
                    ) : (
                      <>
                        <Button disabled={!isLoggedIn} onClick={() => history.push("/historico")} className={classes.buttonTeste} >
                          <HistoryOutlined style={{ color: "#FFF7FF" }} />
                          <Typography style={{ color: "#FFF7FF" }}>
                            Histórico
                          </Typography>
                        </Button>
                        <Button disabled={!isLoggedIn} onClick={() => history.push("/passageiros")} className={classes.buttonTeste}>
                          <PeopleOutlineOutlined style={{ color: "#FFF7FF" }} />
                          <Typography style={{ color: "#FFF7FF" }}>
                            Passageiros
                          </Typography>
                        </Button>
                      </>
                    )}
                    <Button onClick={() => history.push("/central-de-ajuda")} className={classes.buttonTeste}>
                      <HelpOutlineOutlined style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF" }}>
                        Ajuda
                      </Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={2} style={{
                    textAlign: "center",
                    alignSelf: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  >
                    {isLoggedIn ? (
                      <>
                        <Typography>Olá {personAccount}</Typography>
                        <IconButton
                          aria-label="account of current user"
                          aria-controls={menuId}
                          aria-haspopup="true"
                          color="inherit"
                          onClick={handleOpenMenu}
                          style={{ marginLeft: "15px" }}
                        >
                          <MenuOutlined />
                        </IconButton>
                      </>
                    ) : (
                      <div>
                        <Button onClick={() => history.push("/entrar")} className={classes.buttonTeste}>
                          <AccountCircleOutlined style={{ color: "#FFF7FF" }} />
                          <Typography style={{ color: "#FFF7FF" }}>
                            Entrar
                          </Typography>
                        </Button>
                      </div>
                    )}
                  </Grid>
                </>
              )}
              {responsive ? (
                <Grid item container xs={12} style={{ display: "flex", justifyContent: "center" }}>
                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    <IconButton onClick={() => history.push("/")} className={classes.iconButtonCustomMobile}>
                      <img src={logoMobiLandPage} height={35} alt="" />
                    </IconButton>
                    <Button onClick={() => history.push("/aereos")} className={classes.buttonMobile}>
                      <FlightOutlined style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF", fontSize: "8px" }}>
                        Aéreos
                      </Typography>
                    </Button>
                    <Button onClick={() => history.push("/zulminha")} className={classes.buttonMobile}>
                      <WorkRounded style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF", fontSize: "8px" }}>
                        Como funciona
                      </Typography>
                    </Button>
                    <Button onClick={() => history.push("/central-de-ajuda")} className={classes.buttonMobile}>
                      <HelpOutlineOutlined style={{ color: "#FFF7FF" }} />
                      <Typography style={{ color: "#FFF7FF", fontSize: "8px" }}>
                        Ajuda
                      </Typography>
                      {/*MOBILE*/}
                    </Button>
                    <Button onClick={() => handleOpenMobileMenuDrawer()} className={classes.buttonMobile}>
                      <MenuOutlined style={{ color: "#FFF7FF" }} />
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </AppBar>

          {renderMobileMenu}
          {renderMenu}

          {props.component ? <props.component {...props} /> : null}
        </div>
        <footer className={responsive?classes.footerCustom:classes.footerCustomDesk}>
          <FooterCustom />
        </footer>
      </main>
    </>
  );
};
