
export const EnumGenre = {

    types: [
        {
            value: 1,
            label: "MASCULINO"
        },
        {
            value: 2,
            label: "FEMININO"
        },
        {
            value: 3,
            label: "PREFIRO NÃO INFORMAR"
        }
    ],

    getGenreList: function () {
        return this.types;
    }

}
