import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import { useLayoutEffect } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { promoList } from "../../../actions/flightsAction";
import CustomLoader from "../../../components/CustomLoader";
import searchFlightsService from "../../../services/Flights/Search";
import userService from "../../../services/User";
import { LandPageDesktop } from "./LandPageDesktop";
import { LandPageMobile } from "./LandPageMobile";
import authServices from "../../../services/Auth";
import personService from "../../../services/Person";
import { RegisterAuthenticate } from "../../../templates/RegisterAuthenticate";

export const LandPage = (props) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hideCardAuthSerpro, setHideCardAuthSerpro] = useState(false);
  const [promotionalsFlightsList, setPromotionalsFlightsList] = useState([]);
  const [vacanciesText, setVacanciesText] = useState({});
  const [openDialogAuth, setOpenDialogAuth] = useState(false);

  const handleClickOpenPromocoes = async (city) => {
    try {
      setIsLoading(false);
      let response = await searchFlightsService.getPromotionalFlightsByCity(
        city
      );
      localStorage.setItem("city", city);
      setPromotionalsFlightsList(response.data.results);
      dispatch(
        promoList({
          promoList: response.data.results,
        })
      );
      history.push("/promocoes/" + city);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getAvailableVacancies = async () => {
    try {
      setIsLoading(true);
      let response = await userService.getAvailableVacancies();
      setVacanciesText(response.data.results);
    } catch (error) {
    } finally {
      verifyIsLoggedIn()
    }
  };

  const verifyIsLoggedIn = async () => {
    try {
      await authServices.getIsAuthenticate();
      setIsLoggedIn(true);
    } catch (error) {
      setIsLoggedIn(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAuthRF = () => {
    setOpenDialogAuth(false)
  }

  const verifyHideCardAuthSerpro = async () => {
    if (isLoggedIn === false) {
      setHideCardAuthSerpro(true)
    } else {
      let temp = sessionStorage.getItem("key");
      if (temp === null || temp === undefined || Boolean(temp) === false) {
        try {
          await personService.isAuthRF();
          sessionStorage.setItem("isAuthSerpro", "true")
          setHideCardAuthSerpro(true)
        } catch (error) {
          setHideCardAuthSerpro(false)
        } finally { }
      } else {
        setHideCardAuthSerpro(true)
      }
    }
  }

  useLayoutEffect(() => {
    verifyHideCardAuthSerpro()
    getAvailableVacancies();
  }, []);

  useLayoutEffect(() => {
    verifyHideCardAuthSerpro()
  }, [isLoggedIn]);

  return (
    <>
      <CustomLoader isLoading={isLoading} />
      {openDialogAuth ? (
        <RegisterAuthenticate {...{
          open: openDialogAuth,
          setOpen: setOpenDialogAuth,
          handleCloseAuthRF
        }} />
      ) : null}
      {responsive ? (
        <LandPageMobile
          {...{
            hideCardAuthSerpro,
            vacanciesText,
            promotionalsFlights: props.promotionalsFlights,
            handleClickOpenPromocoes,
            promotionalsFlightsList,
            setOpenDialogAuth
          }}
        />
      ) : (
        <LandPageDesktop
          {...{
            hideCardAuthSerpro,
            vacanciesText,
            promotionalsFlights: props.promotionalsFlights,
            handleClickOpenPromocoes,
            promotionalsFlightsList,
            setOpenDialogAuth
          }}
        />
      )}
    </>
  );
};
