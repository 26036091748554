import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import { Dashboard } from '../Dashboard';


const LoadingScreen = (props) => {

    return (
        <>
            <Box height={'500px'} display={'flex'} alignItems={'center'} justifyContent={'center'}   >
                <CircularProgress style={{ color: '#612F74' }} size={100} />

            </Box>
        </>
    )
}

export const DashLoadingScreen = (props) => {

    return (
        <div>
            <Dashboard {...props} component={LoadingScreen} />
        </div>
    )
};
export default DashLoadingScreen;

