import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    buttonAdd: {
        maxWidth: '10rem',
        width: '100%',
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '15px'
    },

    gridItem01: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
