import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    itemCustom: {
        root: {
            '&:hover': {
                backgroundColor: 'red',
            },
        }
    },

    buttonSave: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    ccBox: {
        borderRadius: '5px',
        padding: '5px'
    }

}));
