import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import React from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { creditCardService } from '../../../services/CreditCard';
import personAccountService from '../../../services/PersonAccount';
import { BuyingDeskStep01 } from './Step01';
import { BuyingDeskStep02 } from './Step02';
import { BuyingDeskStep03 } from './Step03';
import { BuyingDeskStep04 } from './Step04';
import { useStyles } from './styles';
import { PixPaymentDialog } from '../PixPaymentFinally';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BuyingMobi = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [optionsCreditCard, setOptionCreditCard] = useState([]);
    const [actionSelect, setActionSelect] = useState();
    const [pixValue, setPixValue] = useState("")
    const data = props.data;

    const handleSelectPax = (ids) => {
        props.setDtoCompra({ ...props.dtoCompra, idPassengers: ids })
    }

    const handleClickSelectPixPayment = async (flights) => {
        setActionSelect("pixpayment")
        let flightsTokens = flights.map(flightItem => flightItem.faresGroup[0].flightToken)
        props.setDtoCompra({ ...props.dtoCompra, flightsTokens })
    }

    const handleClickSelectCreditCard = async (id_CreditCard, flights, cc) => {
        try {
            props.setIsLoading(true);
            setActionSelect("card")
            let dto = {
                flightsTokens: flights.map(flightItem => flightItem.faresGroup[0].flightToken),
                idCreditCard: id_CreditCard,
            };
            let response = await creditCardService.postListInstallments(dto);
            props.setOpcoesUsadas({ ...props.opcoesUsadas, cartao: cc })
            props.setDtoCompra({ ...props.dtoCompra, idCreditCard: id_CreditCard, flightsTokens: dto.flightsTokens })
            setOptionCreditCard(response.data.results)
        } catch (error) {
        } finally {
            props.setIsLoading(false)
        }
    }

    const handleClickSelectOption = (value) => {
        props.setDtoCompra({ ...props.dtoCompra, idInstallment: value.id });
        props.setOpcoesUsadas({ ...props.opcoesUsadas, parcela: value });
    }

    function getLayoutByStep() {
        switch (props.stepActual) {
            case 0:
                return (
                    <BuyingDeskStep01 {...{
                        setSnackVariant: props.setSnackVariant,
                        setSnackMessage: props.setSnackMessage,
                        setSnackVisible: props.setSnackVisible,
                        getPersonAccount: props.getPersonAccount,
                        cadastreSe: props.cadastreSe,
                        personAccount: props.personAccount,
                        handleCloseMain: props.handleCloseMain,
                        setIsLoading: props.setIsLoading,
                        opcoesUsadas: props.opcoesUsadas,
                        setOpcoesUsadas: props.setOpcoesUsadas,
                        handleSelectPax,
                        dtoCompra: props.dtoCompra,
                        setStepActual: props.setStepActual,
                        handleClickNextStep: props.handleClickNextStep,
                        handleClickBackStep: props.handleClickBackStep,
                        data,
                        setIsLoading: props.setIsLoading,
                    }} />
                )
            case 1:
                return (
                    <BuyingDeskStep02 {...{
                        id_CreditCard: props.id_CreditCard,
                        openCCAuth: props.openCCAuth,
                        setOpenCCAuth: props.setOpenCCAuth,
                        getColor: props.getColor,
                        setIsLoading: props.setIsLoading,
                        opcoesUsadas: props.opcoesUsadas,
                        setOpcoesUsadas: props.setOpcoesUsadas,
                        handleSelectPax,
                        setStepActual: props.setStepActual,
                        handleClickNextStep: props.handleClickNextStep,
                        handleClickSelectPixPayment,
                        handleClickBackStep: props.handleClickBackStep,
                        data,
                        handleClickSelectCreditCard,
                        handleOpenCCAuth: props.handleOpenCCAuth,
                        setActionSelect,
                        formPaymentSelected: props.formPaymentSelected,
                        setFormPaymentSelected: props.setFormPaymentSelected,
                    }} />
                )
            case 2:
                return (
                    <BuyingDeskStep03 {...{
                        getColor: props.getColor,
                        dtoCompra: props.dtoCompra,
                        setIsLoading: props.setIsLoading,
                        opcoesUsadas: props.opcoesUsadas,
                        setOpcoesUsadas: props.setOpcoesUsadas,
                        handleClickSelectOption,
                        setStepActual: props.setStepActual,
                        handleClickNextStep: props.handleClickNextStep,
                        handleClickBackStep: props.handleClickBackStep,
                        data,
                        optionsCreditCard
                    }} />
                )
            case 3:
                return (
                    <BuyingDeskStep04 {...{
                        handleGetDocument: props.handleGetDocument,
                        getColor: props.getColor,
                        state: props.state,
                        handleChangeState: props.handleChangeState,
                        personAccount: props.personAccount,
                        setIsLoading: props.setIsLoading,
                        opcoesUsadas: props.opcoesUsadas,
                        setOpcoesUsadas: props.setOpcoesUsadas,
                        setStepActual: props.setStepActual,
                        handleClickNextStep: props.handleClickNextStep,
                        handleClickBackStep: props.handleClickBackStep,
                        data,
                        getDocumentDownload: props.getDocumentDownload,
                        setActionSelect,
                        actionSelect
                    }} />
                )
        }
    }

    return (
        <>
            {getLayoutByStep()}
        </>
    )
}
