import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableRow, Typography, withStyles } from "@material-ui/core";
import { ExpandMoreOutlined } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#612F74',
        color: '#FFF7FF',
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(0,0,0,0.1)',
        },
    },
}))(TableRow);

export const AccordionRules = (props) => {
    const classes = useStyles();

    const data = props.data;

    return (
        <div className={classes.appbarCustom}>
            {data.optionsBagagge.map((item, index) => (
                <Accordion className={clsx(classes.background)}>
                    <AccordionSummary
                        className={clsx(classes.background)}
                        expandIcon={<ExpandMoreOutlined />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography className={classes.heading}>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={clsx(classes.background)}>
                        <Grid container>
                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        {data.bagagges.map((value, index) => (
                                            <StyledTableRow>
                                                <StyledTableCell>
                                                    {value}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {item.itens[index]}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <Grid container className={classes.gridFonte}>
                <Grid item xs={12} className={classes.gridLogo}>
                    <Typography>Fonte</Typography>
                    <a href={data.link} target={'_blank'} >
                        <img src={props.getInfoByIata(data.airCompanie).logo} height={'35'}></img>
                    </a>
                </Grid>
            </Grid>
        </div>
    )
}
