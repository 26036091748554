import api from '../../../Api';

const cityServices = {

    getCitiesByState: async (select, id_State) => {
        const response = await api.get('/City/get-all?select=' + select + '&idstate=' + id_State);
        return response;
    },
}

export default cityServices;
