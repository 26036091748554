import { AppBar, Dialog, IconButton, Slide, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useLayoutEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import logo_ad from '../../images/Logos/AD.svg';
import logo_g3 from '../../images/Logos/G3.svg';
import logo_la from '../../images/Logos/LA.svg';
import ciasService from "../../services/Cias";
import { AccordionRules } from "./Accordion";
import { DefaultRules } from "./Default";
import { useStyles } from "./styles";
import { TableRules } from "./Table";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogTaxRules = (props) => {
    const classes = useStyles();
    const [dataTaxRules, setDataTaxRules] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.setOpen(false)
    }

    const getInfoByIata = (iata) => {
        switch (iata) {
            case 'AD':
                return {
                    logo: logo_ad,
                    name: 'AZUL',
                }
            case 'LA':
                return {
                    logo: logo_la,
                    name: 'LATAM',
                }
            case 'G3':
                return {
                    logo: logo_g3,
                    name: 'GOL',
                }
            default:
                break;
        }
    }

    const getData = async () => {
        try {
            setIsLoading(true)
            let response = await ciasService.getTaxRulesInfoByCia(props.iata);
            setDataTaxRules(response.data.results)
        } catch (error) {
        } finally {
            setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        getData()
    }, [])

    return (
        <>
            <CustomLoader isLoading={isLoading} />
            <Dialog
                open={props.open}
                onClose={handleClose}
                TransitionComponent={Transition}
                fullScreen={responsive}
                maxWidth={dataTaxRules === null ? 'sm' : 'md'}
                fullWidth
            >
                {dataTaxRules === null ? (
                    <AppBar position="relative" className={classes.dialogTitle}>
                        <Typography variant="h5" className={classes.typoTitle}>Regras Tarifárias</Typography>
                        <IconButton className={classes.buttonClose} onClick={handleClose}>
                            <Close style={{ color: 'inherited' }} />
                        </IconButton>
                    </AppBar>
                ) : responsive ? (
                    <AppBar position="fixed" className={classes.dialogTitle}>
                        <Typography variant="h5" className={classes.typoTitle}>{getInfoByIata(dataTaxRules.airCompanie).name}</Typography>
                        <IconButton className={classes.buttonClose} onClick={handleClose}>
                            <Close style={{ color: 'inherited' }} />
                        </IconButton>
                    </AppBar>
                ) : (null)}
                {/* <DialogContent className={classes.dialogContent}> */}
                {dataTaxRules === null ? (
                    <DefaultRules {...{ isLoading }} />
                ) : responsive ? (
                    <AccordionRules {...{ data: dataTaxRules, getInfoByIata }} />
                ) : (
                    <TableRules  {...{ data: dataTaxRules, getInfoByIata }} />
                )}
                {/* </DialogContent> */}
            </Dialog>
        </>
    )
}
