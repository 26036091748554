import axios from 'axios';
import config from './env';

const api = axios.create({
  baseURL: config.url.API_URL
});

api.defaults.timeout = 10 * 60 * 1000;

export default api;
