import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles, Step, StepLabel, Stepper, Tab, Tabs, Typography, useTheme, withStyles } from "@material-ui/core";
import { TabContext, TabPanel as TabPanelTeste } from "@material-ui/lab";
import React from "react";
import { useState } from "react";
import PropTypes from 'prop-types';
import SwipeableViews from "react-swipeable-views";
import FacialBiometrics from "./FacialBiometrics";
import { QontoConnector, QontoStepIcon, useStyles } from "./styles";
import { CloseOutlined } from "@material-ui/icons";
import { useEffect } from "react";
import CustomizedSnackbars from "../../../components/CustomSnackBar";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const StyledTabs = withStyles({
    indicator: {
        backgroundColor: "#612F74",
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

function getSteps() {
    return ["1", "2", "3", "4", "5", "6", "7", "8"];
}

export const useStyless = makeStyles((theme) => ({
    tabpanelRoot: {
        padding: 0,
        height: `calc(100vh - 52px)`,
        width: '100%'
    },
}));

const RegisterAuthenticateMobile = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const classess = useStyless();
    const [value, setValue] = useState(0);
    const steps = getSteps();
    const [activeStep, setActiveStep] = useState(0);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });

    const handleCloseAuthRF = () => {
        if (props.isWarning === true) {
            props.setOpen(false)
        } else {
            props.handleCloseAuthRF()
        }
    }

    const isDoneAuthRF = () => {
        if (props.isWarning === true) {
            props.handleCloseAuthRF()
        } else {
            props.setOpen(false)
        }
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const handleNext = () => {
        if (activeStep === 8) return
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) return
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleBackTwice = () => {
        if (activeStep === 0) return
        setActiveStep((prevActiveStep) => prevActiveStep - 2);
    };

    return (
        <>
            <Dialog
                style={{ background: '#FFF7FF' }}
                open={props.open}
                fullScreen
            >
                <CustomizedSnackbars
                    {...{
                        variant: snackVariant,
                        message: snackMessage,
                        visible: snackVisible.visible,
                        setSnackbar: setSnackVisible,
                    }}
                />
                <DialogTitle disableTypography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 12px', backgroundColor: '#612F74', color: '#fafafa' }}>
                    <Typography variant="h6">
                        Biometria
                    </Typography>
                    <IconButton onClick={() => handleCloseAuthRF()} style={{ color: '#fafafa' }}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: '0px' }}>
                    <Box height={"100%"} >
                        <FacialBiometrics {...{
                            isDoneAuthRF,
                            handleNext,
                            handleBack,
                            handleBackTwice,
                            activeStep,
                            setIsLoading: props.setIsLoading,
                            isLoading: props.isLoading,
                            setSnackVariant,
                            setSnackMessage,
                            setSnackVisible,
                            setOpen: props.setOpen
                        }} />
                    </Box>
                </DialogContent>
                {value == 0 ? (
                    <DialogActions style={{ padding: '4px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <>
                            <Stepper
                                alternativeLabel
                                activeStep={activeStep}
                                connector={<QontoConnector />}
                                style={{ padding: '4px' }}
                            >
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </>
                    </DialogActions>
                ) : null}
            </Dialog>
        </>
    )
}

export default RegisterAuthenticateMobile;
