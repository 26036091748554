

import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import { creditCardService } from "../../services/CreditCard";
import { CreditCardAuthenticate } from "../../templates/CreditCardAuthenticate";
import { Dashboard } from "../Dashboard";
import { CreditCardConfirm } from "./creditCardConfirm";
import { CreditCardRegiser } from "./creditCardRegister";
import { ListCardsDesktop } from "./listCardsDesktop";
import { ListCardMobile } from "./listCardsMobile";
import { useStyles } from "./styles";

const CreditCard = () => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [openCCAuth, setOpenCCAuth] = useState(false);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [listCard, setListCard] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({ id_CreditCard: null });
    const [id_CreditCard, setId_CreditCard] = useState(0);


    const handleClickDeleteOpenDialogConfirm = (idcartao, numeroCartao) => {
        setSelectedItem({ id_CreditCard: idcartao, cardNumber: numeroCartao })
        setOpenDialogConfirm(true);
    }

    const getListCard = async () => {
        let response = null;
        try {
            setIsLoading(true)
            response = await creditCardService.getListCreditCard();
            setListCard(response.data.results)
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    const deleteCard = async (idcard) => {
        try {
            setIsLoading(true)
            await creditCardService.deleteCreditCard(idcard);
            setSnackVariant("success");
            setSnackMessage("Cartão removido!");
            setSnackVisible({ visible: true });
            setOpenDialogConfirm(false);
            getListCard();
        } catch (e) {
            setSnackVariant("error");
            setSnackMessage("Falha ao tentar remover o cartão!");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    const getColor = (bandeira) => {
        switch (bandeira) {
            case 'AMEX':
                return {
                    background: 'linear-gradient(50deg, rgba(48,140,103,1) 0%, rgba(163,242,207,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '75px',
                }
            case 'DINERS':
                return {
                    background: 'linear-gradient(50deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '100px',
                }
            case 'ELO':
                return {
                    background: 'linear-gradient(50deg, rgba(33,28,24,1) 0%, rgba(170,167,162,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            case 'HIPERCARD':
                return {
                    background: 'linear-gradient(50deg, rgba(139,24,27,1) 0%, rgba(222,31,39,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            case 'MASTERCARD':
                return {
                    background: 'linear-gradient(50deg, rgba(251,251,251,1) 0%, rgba(232,233,229,1) 100%)',
                    color: ' #0f0f0f',
                    height: '75px',
                    width: '75px',
                }
            case 'VISA':
                return {
                    background: 'linear-gradient(50deg, rgba(15,80,158,1) 0%, rgba(19,153,205,1) 100%)',
                    color: ' #fafafa',
                    height: '75px',
                    width: '75px',
                }
            default:
                return {
                    background: '#808080',
                    color: ' #0f0f0f',
                    height: '60px',
                    width: '60px',
                }
        }
    }

    const handleOpenCCAuth = (id) => {
        setId_CreditCard(id)
        setOpenCCAuth(true)
    }

    useLayoutEffect(() => {
        getListCard();
    }, [])

    return (

        <>

            <CreditCardAuthenticate {...{
                setOpen: setOpenCCAuth,
                open: openCCAuth,
                id: id_CreditCard
            }} />

            <CustomLoader isLoading={isLoading} />
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackVisible.visible,
                    setSnackbar: setSnackVisible,
                }}
            />
            {openDialog ? (
                <CreditCardRegiser {...{
                    openDialog,
                    setOpenDialog,
                    setSnackVariant,
                    setSnackMessage,
                    setSnackVisible,
                    setIsLoading,
                    getListCard
                }} />
            ) : null}
            <CreditCardConfirm
                {...{
                    openDialogConfirm,
                    setOpenDialogConfirm,
                    deleteCard,
                    selectedItem,
                    setIsLoading,
                    isLoading,
                    setSnackMessage,
                    setSnackVariant,
                    setSnackVisible,
                }}
            />
            <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Grid item xs={12} className={classes.gridCenter}>
                    {responsive ? (
                        <ListCardMobile
                            {...{
                                isLoading,
                                setIsLoading,
                                setOpenDialog,
                                listCard,
                                getColor,
                                handleOpenCCAuth,
                                handleClickDeleteOpenDialogConfirm,
                            }}
                        />
                    ) : (
                        <div className={classes.gridWithNoPromo}>
                            <div className={classes.divPromo}>
                                <ListCardsDesktop
                                    {...{
                                        isLoading,
                                        setIsLoading,
                                        setOpenDialog,
                                        listCard,
                                        getColor,
                                        handleClickDeleteOpenDialogConfirm,
                                    }}
                                />
                            </div >
                        </div >
                    )
                    }
                </Grid >
            </Grid >
        </>
    )
}

export const DashCreditCard = (props) => {

    return (
        <div>
            <Dashboard {...props} component={CreditCard} />
        </div>
    )
};
export default DashCreditCard
