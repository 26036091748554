import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import { StepConnector } from "@material-ui/core";

export const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: "calc(-50% + 16px)",
        right: "calc(50% + 16px)"
    },
    active: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    completed: {
        "& $line": {
            borderColor: "#612F74"
        }
    },
    line: {
        borderColor: "#eaeaf0",
        borderTopWidth: 3,
        borderRadius: 1
    }
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: "#eaeaf0",
        display: "flex",
        height: 22,
        alignItems: "center"
    },
    active: {
        color: "#612F74"
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor"
    },
    completed: {
        color: "#612F74",
        zIndex: 1,
        fontSize: 18
    }
});

export function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active
            })}
        >
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
};


export const useStyles = makeStyles((theme) => ({
    tab: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },

    tabpanelRoot_01: {
        padding: 0,
        height: `calc(100vh - 86px)`,
        width: '100%'
    },

    tabpanelRoot: {
        padding: 0,
        height: `calc(100vh - 48px)`,
        width: '100%'
    },


}));
