import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Grow, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { getHelpText } from "../../../Files/help";
import { useStyles } from "./styles";

export const MobiHelp = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [grow, setGrow] = useState(true);
    const [index, setIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const testeAccc = getHelpText();

    const handleClickChangeIndex = (index) => {

        setFade(false)
        setIndex(index)
        setFade(true)
    }

    const checkIconByIndex = (item) => {
        if (index === item) {
            return (
                <ExpandLessOutlined />
            )
        } else {
            return (
                <ExpandMoreOutlined />
            )
        }
    }

    return (
        <Grow in={grow} timeout={1000}>
            <>
                <Box display={'flex'} flexDirection={'column'} width={'-webkit-fill-available'}>
                    {testeAccc.map((item, actual) => (
                        <Accordion>
                            <AccordionSummary
                                onClick={() => handleClickChangeIndex(actual)} className={actual === index ? classes.gridTitleGroupSelected : classes.gridTitleGroup}
                                expandIcon={<ExpandMoreOutlined />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <Typography>{item.titleGroup}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box display={'flex'} flexDirection={'column'} width={'-webkit-fill-available'}>
                                    {testeAccc[index].itens.map((item) => (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreOutlined />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>{item.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography align="justify">
                                                    {item.txt}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </>
        </Grow>
    )
}

