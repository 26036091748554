import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    button: {
        color: "white",
        padding: '10px 25px',
        fontWeight: "800",
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },

    container: {
        display: 'flex',
        justifyContent: 'center',
        margin: '10px 0px',
    },

    radio: {
        '&$checked': {
            color: '#612F74'
        }
    },

    root: {
        maxWidth: 400,
        flexGrow: 1,
    },

    gridContainer: {
        textAlign: '-webkit-center'
    },

    gridItem: {
        textAlign: '-webkit-center',
        alignSelf: 'center'
    }


}));
