import React from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { Box } from "@material-ui/core";
import CustomDatePicker from "../CustomDatepicker";
import { useState } from "react";



const CustomBoxVideo = ({ component: Component, style, video }) => {
    const classes = useStyles();
    const [departureDate, setDepartureDate] = useState(new Date());

    return (
        <Box
            style={style}
        >
            <div className={clsx(classes.videoContainer, classes.videoContainerCaption, classes.videoContainerVideo)}>
                <video autoPlay muted loop className={classes.videoContainerVideo}>
                    <source src={video} type="video/mp4" />
                </video>
                {Component ? (
                    <div className={classes.videoContainerCaption}>
                        <CustomDatePicker
                            className={classes.buttonDefault}
                            date={departureDate}
                            setDate={setDepartureDate}
                            label={"Data de Partida"}
                            disablePast={true}
                        />
                        <Component />
                    </div>
                ) : null}
            </div>
        </Box>
    )
}

export default CustomBoxVideo;
