import { Grid, Typography, Box } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomBoxVideo from "../../../components/CustomBoxVideo";
import { useStyles } from "./styles";
import ZulminhaVideo from "../../../images/Videos/zulminhaPage.mp4";
import p1 from "../../../images/Marketing/comofunciona_passos/1.png";
import p2 from "../../../images/Marketing/comofunciona_passos/2.png";
import p3 from "../../../images/Marketing/comofunciona_passos/3.png";
import p4 from "../../../images/Marketing/comofunciona_passos/4.png";
import p5 from "../../../images/Marketing/comofunciona_passos/5.png";
import p6 from "../../../images/Marketing/comofunciona_passos/6.png";
import p7 from "../../../images/Marketing/comofunciona_passos/7.png";
import p8 from "../../../images/Marketing/comofunciona_passos/8.png";
import fofo2 from "../../../images/Marketing/bagagem_easy_2.png";
import whats from "../../../images/Marketing/whats_so_ela-faz.png";
import { useHistory } from "react-router";

export const DescZulminha = (props) => {
  const history = useHistory();
  const classes = useStyles();

  const stepsImg = [
    {
      url: p1,
    },
    {
      url: p2,
    },
    {
      url: p3,
    },
    {
      url: p4,
    },
    {
      url: p5,
    },
    {
      url: p6,
    },
    {
      url: p7,
    },
    {
      url: p8,
    },
  ];

  useEffect(() => {
    if (window.location.href.includes("#bagagemEasy")) {
      var element = document.getElementById("bagagemEasy");
      element.scrollIntoView();
    }
  }, []);

  return (
    <div className={classes.container}>
      <Grid
        container
        style={{
          justifyContent: "center",
          marginBottom: "10px",
          maxWidth: "1100px",
        }}
      >
        <Grid item xs={12} style={{ minWidth: "98.9vw" }}>
          <CustomBoxVideo video={ZulminhaVideo} style={{ height: "425px" }} />
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          style={{ width: "100%", marginBottom: "50px", marginTop: "50px" }}
          className={classes.customGrid02}
        >
          <Typography variant="h6" className={classes.typograph_01}>
            <div className={classes.bola}></div>É MUITO FÁCIL
            <div className={classes.bola}></div>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          className={classes.customGrid02}
          style={{ marginTop: "-10px" }}
        >
          <Typography
            variant="h6"
            style={{ width: "100%", textAlign: "center" }}
            className={classes.typograph_02}
          >
            Você acompanha em tempo real as tentativas de redução
          </Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              A Linestur foi criada há mais de 12 anos com foco no turismo
              corporativo. Este nível de especialização nos desafiou a criarmos
              a nossa própria Startup, a qual proporcionou a construção da mais
              poderosa plataforma para gestão de viagens corporativas do mundo,
              onde se encontra a Zulminha, uma Inteligência Artificial
              encarregada de executar e supervisionar inúmeras funções,
              incluindo a ferramenta que tenta reduzir as tarifas. Esta
              ferramenta era disponibilizada apenas para o uso profissional, mas
              a partir de agora <b>você também pode!</b>
            </Typography>
          </Box>
        </Grid>

        <Grid item container xs={12} style={{ marginTop: "20px" }}>
          {stepsImg.map((item) => (
            <Grid item xs={3} className={classes.gridSteps}>
              <img src={item.url} width="100%" alt="" />
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          id="bagagemEasy"
          className={classes.customGrid02}
          style={{ marginTop: "30px" }}
        >
          <Typography
            variant="h6"
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#a0a1a4",
            }}
            className={classes.typograph_02}
          >
            A bagagem easy é mais uma das facilidades de nossa loja virtual
          </Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              Nesta grande novidade a Zulminha atua pré-selecionando todos os
              voos disponíveis no trecho escolhido, analisando de forma
              individual todas as possíveis variações de preços, já incluíndo em
              um único campo o valor das diferentes bagagens com as quantidades
              oferecidas, efetuando a atualização do preço final de acordo com
              sua escolha. Somente a linestur.com disponibiliza esta tecnologia
              descomplicando toda forma a atual que é ofertada na compra de
              aéreo.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ width: "100%", marginTop: "20px" }}
          className={classes.gridImg}
        >
          <img
            alt=""
            src={fofo2}
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          id="whats"
          className={classes.gridBanner}
          style={{
            color: "gray",
            marginBottom: "-20px",
          }}
        >
          <Typography className={classes.textTitleBold}>Só ela faz</Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              Nossa I.A. é integrada ao WhatsApp o que possibilita notificações
              em tempo real para qualquer ação da Zulminha na tarifa garantida,
              isto ocorre até o encerramento do ciclo das tentativas de
              reduções, e finaliza encaminhando seu ticket com a justificativa
              de quanto foi o aumento e quantas vezes tentou reduzir.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ width: "100%", marginTop: "20px" }}
          className={classes.gridImg}
        >
          <img
            alt=""
            src={whats}
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          className={classes.customGrid04}
          style={{ minWidth: "98.9vw" }}
        >
          <Grid container item xs={12} className={classes.customGridMain}>
            <Grid item xs={6} className={classes.customGrid03}>
              <Typography variant="h4" className={classes.typograph_08}>
                Precisa de ajuda?
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.customGrid03}>
              <Typography
                variant="h4"
                className={classes.typograph_08}
                onClick={() => history.push("/central-de-ajuda")}
                style={{ cursor: "pointer" }}
              >
                <strong>Acesse aqui.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          className={classes.customGridMain}
          style={{ marginTop: "25px", marginBottom: "25px" }}
        >
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              Somos a primeira agência de <b>Viagens Virtual do Mundo</b>, e que
              possui inteligência artificial embarcada.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
