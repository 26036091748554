import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { useHistory } from "react-router";
import AD from "../../../../images/Logos/AD.svg";
import G3 from "../../../../images/Logos/G3.svg";
import LA from "../../../../images/Logos/LA.svg";
import zulminha from "../../../../images/Marketing/Zulminha.svg";
import fofo from "../../../../images/Marketing/bagagem_easy.png";
import sono from "../../../../images/Marketing/nao_perca_sono_524x378.png";
import quemSomos from "../../../../images/Marketing/quem_somos.png";
import banner_AuthBio from "../../../../images/assets/Biometry/banner_AuthBio_mobi.jpg";
import { useStyles } from "./styles";

export const LandPageMobile = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const clickGoToZulminha = () => {
    history.push("/zulminha");
  };

  const handleClickPromo = (city) => {
    props.handleClickOpenPromocoes(city);
  };

  return (
    <div className={classes.container}>
      <Grid container style={{
        justifyContent: "center",
        marginBottom: "10px",
        marginTop: "10px",
        maxWidth: "1100px",
      }}>

        {props.hideCardAuthSerpro ? null : (
          <Grid item xs={12} spacing={2} style={{ width: "100%", cursor: "pointer", paddingTop: '10px' }} className={classes.customGridMain} onClick={() => props.setOpenDialogAuth(true)}>
            <img alt="" src={banner_AuthBio} style={{ borderRadius: '10px' }} width="100%" />
          </Grid>
        )}

        <Grid item container xs={12} spacing={2} className={classes.customGrid05} style={{
          marginTop: "20px",
          marginBottom: "10px",
          maxWidth: "90vw",
        }}>

          <Grid item xs={12}>
            <Typography variant="h6" style={{ width: "100%" }} className={classes.typograph_02}>
              Exclusivo redutor de tarifa
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.customGrid06}>
            <Button onClick={() => clickGoToZulminha()} className={classes.buttonCustom}>
              <Typography variant="h6" className={classes.typograph_03}>
                QUERO CONHECER
              </Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} spacing={2} style={{ width: "100%", }} className={classes.customGridMain} onClick={() => history.push("/zulminha#bagagemEasy")}>
          <img alt="" src={fofo} width="100%" />
        </Grid>
        <Grid item xs={12} className={classes.customGridMain}>
          <Carousel animation="slide" navButtonsAlwaysInvisible autoPlay interval={"7000"}          >
            <Paper elevation={0} className={classes.customPaperCarousel}>
              <img src={LA} height={50} className={classes.img}></img>
              <Box className={classes.boxCompanies}>
                <Typography className={classes.typograph_04}>
                  Parcelado em
                </Typography>
                <Typography className={classes.typograph_04} variant={"h5"}>
                  {" "}
                  &nbsp; até 4x
                </Typography>
              </Box>
            </Paper>
            <Paper elevation={0} className={classes.customPaperCarousel}>
              <img src={AD} height={50} className={classes.img}></img>
              <Box className={classes.boxCompanies}>
                <Typography className={classes.typograph_04}>
                  Parcelado em
                </Typography>
                <Typography className={classes.typograph_04} variant={"h5"}>
                  {" "}
                  &nbsp; até 10x
                </Typography>
              </Box>
            </Paper>
            <Paper elevation={0} className={classes.customPaperCarousel}>
              <img src={G3} height={50} className={classes.img}></img>
              <Box className={classes.boxCompanies}>
                <Typography className={classes.typograph_04}>
                  Parcelado em
                </Typography>
                <Typography className={classes.typograph_04} variant={"h5"}>
                  {" "}
                  &nbsp; até 5x
                </Typography>
              </Box>
            </Paper>
          </Carousel>
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <Typography variant={"h6"} className={classes.typograph_05}>
            {" "}
            Promoções com quantidades de <b>tentativa de redução</b>
          </Typography>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              ORIGEM: Florianópolis
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain} style={{ placeContent: "center" }}>
          <Carousel animation="slide" navButtonsAlwaysInvisible autoPlay interval={"7000"}>
            {props.promotionalsFlights.map((item) => (
              <Paper id={item.arrivalCity} onClick={() => handleClickPromo(item.arrivalCity)} elevation={0} className={classes.customPaperCarousel}>
                <Typography className={classes.typograph_10}>
                  {item.arrivalCity}
                </Typography>
                <Typography className={classes.typograph_09}>
                  {item.total}
                </Typography>
                <img src={item.urlPromo} style={{ cursor: "pointer" }} height={250} className={classes.img} alt={item.arrivalCity} />
              </Paper>
            ))}
          </Carousel>
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <Typography variant="h6" className={classes.typograph_07}>
            Você garante sua tarifa e a Zulminha busca reduzir
          </Typography>
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <img src={sono} className={classes.img2} style={{ width: "100%" }} />
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <Grid item xs={12} className={classes.customGrid03}>
            <img src={zulminha} className={classes.img2} />
            <Typography className={classes.typograph_11} style={{ marginBottom: "9rem", fontSize: "15pt" }}>
              RESTAM
            </Typography>
            <Typography className={classes.typograph_11} style={{ marginBottom: "5rem", fontSize: "20pt" }}>
              {props.vacanciesText.value} CADASTROS
            </Typography>
            <Typography className={classes.typograph_11} style={{
              color: "#FFFF00",
              marginBottom: "3.5rem",
              fontSize: "12pt",
            }}>
              Lote {props.vacanciesText.batch}
            </Typography>
            <Button onClick={() => history.push("/registrar")} className={classes.buttonCustom2}>
              <Typography variant="h6" className={classes.typograph_03}>
                CADASTRE-SE AGORA
              </Typography>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} spacing={2} style={{ width: "100%", marginTop: "10px", }} className={classes.customGridMain} onClick={() => history.push("/quemsomos")}>
          <img alt="" src={quemSomos} width="100%" />
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              Somos a primeira agência de <b>Viagens Virtual do Mundo</b>, e que
              possui inteligência artificial embarcada.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} spacing={2} className={classes.customGrid04}>
          <Grid container item xs={12} className={classes.customGridMain}>
            <Grid item xs={12} className={classes.customGrid03}>
              <Typography variant="h5" className={classes.typograph_08}>
                Precisa de ajuda?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.customGrid03}>
              <Typography variant="h5" className={classes.typograph_08} onClick={() => history.push("/central-de-ajuda")} style={{ cursor: "pointer" }}>
                <strong>Acesse aqui.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
