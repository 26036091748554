import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

    gridDog: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '2rem'
    },

    gridContCustom: {
        background: '#FFF7FF',
        padding: '2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '25rem'
    },

    gridLogosCias: {
        marginTop: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },

    text: {
        textIndent: '1rem',
        textAlign: 'justify',
    },

    imgCia: {
        margin: '10px'
    }
}));
