import { Box, Button, Chip, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Slide, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CloseOutlined, EcoOutlined, GetAppOutlined } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { EnumCompany } from '../../Enums/enum_FlightCompany';
import CustomLoader from '../../components/CustomLoader';
import { CustomProgressBar } from '../../components/CustomProgressBar';
import bookingService from '../../services/Bookings';
import { useStyles } from './styles';
import CustomizedSnackbars from '../../components/CustomSnackBar';
import { useHistory } from 'react-router';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const TicketDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [progress, setProgress] = useState(props.data.status === "Emitido" ? 100 : props.data.percentInitial);
    const [reducoes, setReducoes] = useState(props.data.reductionAttempts);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [isLoading, setIsLoading] = useState(false);
    const data = props.data;

    const handleClose = () => {
        if (props.isBuying === true) {
            history.push("/")
        }
        props.setOpen(false)
    };

    const getDocumentDownload = async () => {
        try {
            setIsLoading(true)
            let response = await bookingService.getDownloadContract(data.id_Booking)
            const linkSource = response.data.results;
            const link = document.createElement('a');
            link.href = linkSource;
            link.target = '_blank'
            link.download = 'contrato.pdf';
            link.click();
        } catch (error) {
            setSnackVariant("error");
            setSnackMessage("Falha ao buscar contrato.");
            setSnackVisible({ visible: true });
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (progress < 100) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + data.oneUnity));
                setReducoes((prevReducoes) => (prevReducoes <= 0 ? 0 : prevReducoes - 1));
            }, 1764.7);

            return () => {
                clearInterval(timer);
            };
        }
    }, []);

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth="lg"
                fullWidth={true}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <CustomLoader isLoading={isLoading} />
                <CustomizedSnackbars
                    {...{
                        variant: snackVariant,
                        message: snackMessage,
                        visible: snackVisible.visible,
                        setSnackbar: setSnackVisible,
                    }}
                />
                <DialogTitle id="alert-dialog-title">
                    <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography>{data.route} </Typography>
                        <IconButton>
                            <CloseOutlined onClick={handleClose} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid container style={{ marginBottom: '25px' }}>
                            <Grid container item xs={4} style={{ display: 'flex', alignItems: 'center' }} >
                                {data.air.initialTotal !== null ? (
                                    <>
                                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                            <Typography variant='h5' style={{ color: 'grey', marginRight: '5px' }}>Total inicial: </Typography>
                                            <Typography variant='h5' style={{ color: '#ff7500' }}>{data.air.initialTotal}</Typography>
                                        </Grid>
                                        <Grid item xs={12} >
                                            <Typography variant='body2' style={{ color: 'grey', }}>Reduziu</Typography>
                                            <Typography variant="h5" style={{ color: '#008000' }}>{data.air.reductionTotal}</Typography>
                                        </Grid>
                                    </>
                                ) :
                                    data.status === "Emitido" ? (
                                        <>
                                            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
                                                <Typography variant='h5' style={{ color: 'grey', marginRight: '5px' }}>Valor na Emissão: </Typography>
                                                <Typography variant='h5' style={{ color: '#ff7500' }}>{data.air.totalWithTax}</Typography>
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid item xs={12}>
                                            <Typography variant='h5' style={{ color: 'grey' }}>Buscando...</Typography>
                                        </Grid>
                                    )}
                            </Grid>
                            <Grid container item xs={2} style={{ display: 'flex', alignItems: 'center' }} >
                                <Button
                                    onClick={() => getDocumentDownload()}
                                    className={classes.buttonContract}
                                    startIcon={<GetAppOutlined />}>
                                    Baixar contrato
                                </Button>
                            </Grid>

                            <Grid item xs={6} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Typography style={{ color: '#008000', fontSize: '14pt' }}>{data.status === "Emitido" ?
                                    'Tentou reduzir ' + (data.reductionAttemptsInitial >= 1000 ?
                                        (data.reductionAttemptsInitial / 1000).toLocaleString("pt-BR", { maximumFractionDigits: 1 }) + ' mil' :
                                        data.reductionAttemptsInitial) + ' vezes' :
                                    reducoes.toLocaleString("pt-BR", { maximumFractionDigits: 0 }) + ' Tentativas de redução'}</Typography>
                                <CustomProgressBar variant="determinate" value={progress} />
                            </Grid>
                        </Grid>
                        <TableContainer style={{ marginBottom: '25px' }}>
                            <Table padding='checkbox'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{ width: '10%' }} className={classes.titulos}>Localizador</TableCell>
                                        <TableCell align="center" style={{ width: '10%' }} className={classes.titulos}>Status</TableCell>
                                        <TableCell align="center" style={{ width: '15%' }} className={classes.titulos}>Cia</TableCell>
                                        <TableCell align="center" style={{ width: '15%' }} className={classes.titulos}>Data Partida</TableCell>
                                        <TableCell align="center" style={{ width: '15%' }} className={classes.titulos}>Emissão CO<sub>2</sub> (kg)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align='center'>
                                            <Typography>{data.air.locator}</Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Chip label={data.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Tooltip title={(<Typography style={{ fontSize: '12pt' }}>{EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).nome}</Typography>)}>
                                                <img
                                                    src={data.airCompanie === undefined ? null : (EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).url_high)}
                                                    width={80}
                                                    alt={EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).nome}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography>
                                                {data.departureDate}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography style={{ color: '#43a047', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                <EcoOutlined style={{ color: '#43a047' }} />
                                                {data.air.carbonEmissionTotal}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Passageiros</Typography>
                            <Table padding='checkbox'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Tipo</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Nome</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Sexo</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Nascimento</TableCell>
                                        <TableCell align="center" style={{ width: '15%' }} className={classes.titulos}>Emissão CO<sub>2</sub> (kg)</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Tarifa</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Encargos</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Total</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.air.passengers.map((pax) => (
                                        <TableRow>
                                            <TableCell align="center" >
                                                <Typography>{pax.type}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{`${pax.firstName} ${pax.lastName}`} </Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>  {pax.gender}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{pax.birthDate}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography style={{ color: '#43a047', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <EcoOutlined style={{ color: '#43a047' }} />
                                                    {pax.carbonEmission}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography> {pax.totalWithoutTax}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>  {pax.totalTax}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{pax.totalWithTax}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            <Typography className={classes.titulo}>Itinerários</Typography>
                            <Table padding="checkbox">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.titulos}>Cia</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Voo</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Origem</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Destino</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Classe</TableCell>
                                        <TableCell align="center" className={classes.titulos}>Bagagem</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.air.itineraries.map((iti) => (
                                        <TableRow>
                                            <TableCell align="center">
                                                <img
                                                    src={iti.airCompanie === undefined ? null : (EnumCompany.getLogoCompanhiaAereaEnum(data.airCompanie).url_low)}
                                                    width={60}
                                                    alt=""
                                                />
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{iti.flightNumber}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>
                                                    {`${iti.departureCity} (${iti.departure})`}
                                                </Typography>
                                                <Typography>
                                                    {iti.departureDate}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{`${iti.arrivalCity} (${iti.arrival})`}</Typography>
                                                <Typography>
                                                    {iti.arrivalDate}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography> {iti.class}</Typography>
                                            </TableCell>
                                            <TableCell align="center" >
                                                <Typography>{iti.descriptionBagage}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                            {data.air.tickets ?
                                data.air.tickets.length > 0 ? (
                                    <React.Fragment>
                                        <Typography className={classes.titulo}>Bilhetes</Typography>
                                        <Table padding="checkbox">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" className={classes.titulos}>Passageiro</TableCell>
                                                    <TableCell align="center" className={classes.titulos}>Data de nascimento</TableCell>
                                                    <TableCell align="center" className={classes.titulos}>Número</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.air.tickets.map((item) => (
                                                    <TableRow>
                                                        <TableCell align="center">{`${item.lastName} / ${item.firstName}`}</TableCell>
                                                        <TableCell align="center">{item.birthDate}</TableCell>
                                                        <TableCell align="center">{item.code}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </React.Fragment>
                                ) : null
                                : null}

                        </TableContainer>
                        <div className={classes.info}>
                            <Typography> Informações para Embarque </Typography>
                            <Typography> Apresente-se em nosso checkin com 2 horas de antecedência em voos nacionais e com 3 horas de antecedência em voos internacionais. </Typography>
                            <Typography> Levar documento original: <b>Carteira de Identidade</b> para vôos nacionais.</Typography>
                            <Typography> Levar documento original: <b>Passaporte</b> e os vistos necessários para entrada no pais de destino para voos internacionais.</Typography>
                            <Typography>Informações sobre validade de PASSAPORTE, VACINAS e VISTOS que possam ser necessários para sua viagem devem ser consultados com as respectivas embaixadas ou despachantes de vistos.</Typography>
                            <Typography>Verifique essa necessidade para todos os países envolvidos na viagem, mesmo aqueles onde há apenas uma escala.</Typography>
                            <Typography>Lembre-se que alguns países exigem que o passaporte tenha uma validade mínima de 6 meses para embarcar.</Typography>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}
