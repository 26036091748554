import api from "../../Api";

const emailServices = {
  putForgotPassword: async (token, newPassword) => {
    let response = await api.put("/Email/reset-password", null, {
      headers: {
        token,
        newPassword,
      },
    });
    return response;
  },
};

export default emailServices;
