import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    gridCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    grid: {
        maxWidth: '-webkit-fill-available',
        display: 'grid',
        gridTemplateColumns: '15% 70% 15%'
    },
    tableContainer: {
        maxWidth: '70vw'
    },
    img: {
        height: '55px',
        width: 'auto'
    },
    tableCellCentralized: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    gridCellCustom: {
        padding: '8px',
    }
}));
