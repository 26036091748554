import { Box, Button, Divider, Grid, Grow, Paper, Step, StepConnector, StepLabel, Stepper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { EcoOutlined } from '@material-ui/icons';
import Check from "@material-ui/icons/Check";
import { Pagination } from '@material-ui/lab';
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useState } from 'react';
import CustomSelectField from "../../../components/CustomSelectField/index";
import CustomizedSnackbars from '../../../components/CustomSnackBar';
import seta from '../../../images/teste/seta.png';
import DialogNewPrice from '../DialogNewPrice';
import { useStyles } from './styles';
import FilterPanel from '../FilterPanel';

const useQontoStepIconStyles = makeStyles({
    root: { color: "#eaeaf0", display: "flex", height: 22, alignItems: "center", },
    active: { color: "#784af4", },
    circle: { width: 8, height: 8, borderRadius: "50%", backgroundColor: "currentColor", },
    completed: { color: "#784af4", zIndex: 1, fontSize: 18, },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
    return (
        <div className={clsx(classes.root, { [classes.active]: active, })}        >
            {completed ? (<Check className={classes.completed} />) : (<div className={classes.circle} />)}
        </div>
    );
}

QontoStepIcon.propTypes = { active: PropTypes.bool, completed: PropTypes.bool, };

const ColorlibConnector = withStyles({
    alternativeLabel: { top: 22, },
    active: { "& $line": { backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)", }, },
    completed: { "& $line": { backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)", }, },
    line: { height: 3, border: 0, backgroundColor: "#eaeaf0", borderRadius: 1, },
})(StepConnector);

export const ListSearchFlights = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const [grow, setGrow] = useState(true);
    const [voosSelecionados, setVoosSelecionados] = useState([]);
    const [fareItens, setFareItens] = useState([]);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });
    const [openDialogNewPrice, setOpenDialogNewPrice] = useState(false);
    const [dataBooking, setDataBooking] = useState({});
    const [bagageValue, setBagageValue] = useState(0);
    const [page, setPage] = useState(1);
    const [showFilterStops, setShowFilterStops] = useState({ one: false, twoOrMore: false });
    const [showFilterStopsCounter, setShowFilterStopsCounter] = useState({ one: 0, twoOrMore: 0 });
    const [showFilterStopsSelect, setShowFilterStopsSelect] = useState({ allStops: true, one: true, twoOrMore: true });
    const [showFilter, setShowFilter] = useState({ la: false, g3: false, ad: false });
    const [showFilterCounter, setShowFilterCounter] = useState({ la: 0, g3: 0, ad: 0 });
    const [stateIata, setStateIata] = useState({ all: true, ad: true, g3: true, la: true, });
    const { ad, g3, la, all } = stateIata;
    const { one, twoOrMore, allStops } = showFilterStopsSelect;

    const handleChangeIata = (event) => {
        if (event.target.name == 'all') { setStateIata({ all: event.target.checked, ad: event.target.checked, la: event.target.checked, g3: event.target.checked }) }
        else { setStateIata({ ...stateIata, all: false, [event.target.name]: event.target.checked }); }
    };

    const handleChangeNumberOfStops = (event) => {
        if (event.target.name == 'allStops') { setShowFilterStopsSelect({ allStops: event.target.checked, one: event.target.checked, twoOrMore: event.target.checked }) }
        else { setShowFilterStopsSelect({ ...showFilterStopsSelect, allStops: false, [event.target.name]: event.target.checked }); }
    };

    const handleSelectFligth = (stepIndex, flightItem) => {
        setBagageValue(0);
        let teste = voosSelecionados;
        teste[stepIndex] = flightItem;
        setVoosSelecionados(teste);
        if (stepIndex === 0) { let itens = [...flightItem.fareItens]; setFareItens(itens); }
        else {
            let itens = [...teste[0].fareItens];
            for (let i = 0; i < itens.length; i++) {
                let item = flightItem.fareItens.find(x => x.nameBaggage === itens[i].nameBaggage);
                if (item == null) { itens.splice(i, 1); i--; }
                else { itens[i].label = `${itens[i].quantityBaggage} | R$ ${(itens[i].data + item.data).toLocaleString("pt-BR", { maximumFractionDigits: 2 })} - ${item.nameBaggage}` }
            }
            setFareItens(itens);
        }
        handleNext();
        setPage(1);
        document.getElementById('listagemVoos').scrollIntoView({ behavior: 'smooth' })
    }

    const handleClickBooking = async () => {
        let dto = [...voosSelecionados];
        var cia = dto[0].airCompanie.iata;
        var la = ["JJ", "LA"];
        var g3 = ["SGOL", "G3"];
        if (
            (dto.some(x => !la.includes(x.airCompanie.iata)) && dto.some(x => la.includes(x.airCompanie.iata))) ||
            (dto.some(x => !g3.includes(x.airCompanie.iata)) && dto.some(x => g3.includes(x.airCompanie.iata))) ||
            (dto.some(x => x.airCompanie.iata !== cia))
        ) {
            setSnackMessage("Ida e volta não pode ser com companhias diferentes.");
            setSnackVariant("info");
            setSnackVisible({ visible: true });
            return;
        }
        dto = dto.reduce((acum, item) => {
            item.faresGroup = item.faresGroup.filter(x => x.quantityBaggage === bagageValue);
            item.fareItens = item.fareItens.filter(x => x.value === bagageValue);
            acum.push(item);
            return acum;
        }, [])
        props.onChangeSelectedFlights(dto)
    }

    const handleNext = () => { setActiveStep((prevActiveStep) => prevActiveStep + 1); };

    const handleReset = () => { setActiveStep(0); };

    function getSteps() { return [...props.availabilities.availability.map(a => a.departure + "/" + a.arrival), 'Voos selecionados']; }

    const GetStepContent = (stepIndex) => {
        let voos = JSON.parse(JSON.stringify(props.availabilities.availability));

        if (voosSelecionados?.length > 0 && stepIndex > 0) {
            let i = 0;
            voos.reduce((acum, item) => {
                if (i > 0) item.flights = item.flights.filter(x => x.airCompanie.url == voosSelecionados[0].airCompanie.url)
                acum.push(item)
                i++;
                return acum;
            }, []);
        }

        let teste = []
        if (stepIndex != voos.length) {
            if (stateIata.ad === false && stateIata.la === false && stateIata.g3 === false || (showFilterStopsSelect.one === false && showFilterStopsSelect.twoOrMore === false)) {
                teste = voos[stepIndex].flights
            } else {
                teste = voos[stepIndex].flights.filter(x =>
                    (stateIata.ad ? x.airCompanie.iata == "AD" : null) ||
                    (stateIata.la ? x.airCompanie.iata == "LA" || x.airCompanie.iata == "JJ" : null) ||
                    (stateIata.g3 ? x.airCompanie.iata == "G3" || x.airCompanie.iata == "SGOL" : null)
                );
                teste = teste.filter((item) => (showFilterStopsSelect.one ? item.numberOfStops == 0 : null) || (showFilterStopsSelect.twoOrMore ? item.numberOfStops >= 1 : null));
            }
        } else {
            teste = voos;
        }

        // const pages = (stepIndex != voos.length) ? (Math.ceil(voos[stepIndex].flights.length / 10)) : 0;
        const pages = (stepIndex != voos.length) ? (Math.ceil(teste.length / 10)) : 0;
        const s = (page - 1) * 10;
        const r = 10 + s;
        // const flightPerPage = (stepIndex != voos.length) ? (voos[stepIndex].flights.slice(s, r)) : 0;
        const flightPerPage = voos.length == 0 ? [] : (stepIndex != voos.length) ? (teste.slice(s, r)) : 0;

        const handleChange = (event, value) => {
            setPage(value);
            document.getElementById('listagemVoos').scrollIntoView({ behavior: 'smooth' })
        };

        useLayoutEffect(() => {
            if (props?.selectedFlights?.length <= 0) { setActiveStep(0) }
        }, [props.availabilities || stateIata || showFilterStopsSelect || voosSelecionados])

        return (
            <Box className={classes.custompaper}>
                <CustomizedSnackbars
                    {...{
                        variant: snackVariant,
                        message: snackMessage,
                        visible: snackVisible.visible,
                        setSnackbar: setSnackVisible,
                    }}
                />
                <Grid item xs={12} id={'listagemVoos'} style={{ margin: '0rem 1rem' }}>
                    {stepIndex == steps.length - 1 ? null : steps.length > 2 ? (
                        <>
                            {stepIndex == 0 ? (
                                <Typography style={{ fontSize: "25px", fontWeight: "100" }}>Escolha seu <b style={{ color: "#612F74" }}>voo de ida</b></Typography>
                            ) : (
                                <Typography style={{ fontSize: "25px", fontWeight: "100" }}>Escolha seu <b style={{ color: "#612F74" }}>voo de volta</b></Typography>
                            )
                            }
                        </>
                    ) : (
                        <Typography style={{ fontSize: "25px", fontWeight: "100" }}>Escolha seu <b style={{ color: "#612F74" }}>voo</b></Typography>
                    )}
                </Grid>
                {stepIndex == voos.length ? null : (
                    <Pagination
                        count={pages}
                        variant="outlined"
                        size="large"
                        page={page}
                        onChange={handleChange}
                        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}
                    />
                )}
                <div style={{ display: 'flex' }}>
                    {voos.length > 0 && (stepIndex <= voos.length - 1) && (
                        <FilterPanel {...{
                            stepIndex,
                            voos,
                            setShowFilter, showFilter,
                            setShowFilterCounter, showFilterCounter,
                            setShowFilterStops, showFilterStops,
                            setShowFilterStopsSelect, showFilterStopsSelect,
                            setShowFilterStopsCounter, showFilterStopsCounter,
                            handleChangeIata,
                            voosSelecionados,
                            handleChangeNumberOfStops,
                            ad, g3, la, all,
                            one, twoOrMore, allStops
                        }} />
                    )}
                    <div >
                        {stepIndex == voos.length ? (
                            <>
                                <Paper elevation={2} className={classes.custompaper}>
                                    {voosSelecionados.map((flightItem, flightIndex) => (
                                        <Grid container style={{ paddingTop: '15px', paddingBottom: '15px' }}>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginBottom: "15px" }}>
                                                <Typography variant='h6' ><b>DATA</b> - {props.availabilities.availability[flightIndex]?.departureDate} </Typography>
                                            </Grid>
                                            <Grid item xs={2} style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                <img height={80} src={flightItem.airCompanie.url} />
                                            </Grid>
                                            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={'3'}>
                                                <Grid item xs={12}>
                                                    <Tooltip arrow name="descricaoTrechos" title={
                                                        <React.Fragment>
                                                            <Grid container>
                                                                {flightItem.flightDescriptions.map((leg) => (
                                                                    <>
                                                                        <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                            <Typography variant='h5'><b>{leg.departureTime}</b></Typography>
                                                                            <Typography variant='b'> {leg.departure} </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={2} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                            <Grid container>
                                                                                {/* <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>direto</Grid> */}
                                                                                <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <img height={30} src={seta} />
                                                                                </Grid>
                                                                                <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                            <Typography variant='h5'> <b>{leg.arrivalTime}</b> </Typography>
                                                                            <Typography variant='b'> {leg.arrival} </Typography>
                                                                        </Grid>
                                                                    </>
                                                                ))}
                                                            </Grid>
                                                        </React.Fragment>
                                                    }>

                                                        <Grid container>
                                                            {flightItem.flightDescriptions.map((leg) => (
                                                                <>
                                                                    <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                        <Typography variant='h5'> <b>{leg.departureTime}</b> </Typography>
                                                                        <Typography variant='subtitle2'> {new Date(leg.departureDateTime).toLocaleDateString()} </Typography>
                                                                        <Typography> {leg.departureCity} ({leg.departure}) </Typography>
                                                                        <Typography style={{ fontSize: '0.7rem' }}> {leg.departureAirportCity} </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={2} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                        <Grid container>
                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                <img height={30} src={seta} />
                                                                            </Grid>
                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                        <Typography variant='h5'> <b>{leg.arrivalTime}</b> </Typography>
                                                                        <Typography variant='subtitle2'> {new Date(leg.arrivalDateTime).toLocaleDateString()} </Typography>
                                                                        <Typography> {leg.arrivalCity} ({leg.arrival}) </Typography>
                                                                        <Typography style={{ fontSize: '0.7rem' }}> {leg.arrivalAirportCity} </Typography>
                                                                    </Grid>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    </Tooltip>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={4} style={{ padding: '5px' }}>
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant='b' >Preço por adulto</Typography>
                                                            <Typography variant='h6'> <b>{flightItem.faresGroup.find(x => x.descriptionBaggage === flightItem.fareItens.find(y => y.value === bagageValue)?.nameBaggage)?.agesGroup[0].withoutTax}</b></Typography>
                                                        </Box>
                                                    </Grid>
                                                    {flightItem.faresGroup.find(x => x.descriptionBaggage === flightItem.fareItens.find(y => y.value === bagageValue)?.nameBaggage)?.agesGroup.map((type) => (
                                                        <Grid item xs={12}>
                                                            <Box display={'flex'} justifyContent={'space-between'}>
                                                                <Typography variant='b'> {type.quantityPax} {type.type} </Typography>
                                                                <Typography variant='b'>{type.totalWithoutTax}</Typography>
                                                            </Box>
                                                        </Grid>
                                                    ))}
                                                    {flightItem.faresGroup.find(x => x.descriptionBaggage === flightItem.fareItens.find(y => y.value === bagageValue)?.nameBaggage)?.totalTax ? (
                                                        <Grid item xs={12}>
                                                            <Box display={'flex'} justifyContent={'space-between'}>
                                                                <Typography variant='b'> Encargos </Typography>
                                                                <Typography variant='b'>{flightItem.faresGroup.find(x => x.descriptionBaggage === flightItem.fareItens.find(y => y.value === bagageValue)?.nameBaggage)?.totalTax}</Typography>
                                                            </Box>
                                                            <Divider />
                                                        </Grid>
                                                    ) : null}
                                                    <Grid item xs={12}>
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant='b'> Preço final </Typography>
                                                            <Typography variant='b'>{flightItem.faresGroup.find(x => x.descriptionBaggage === flightItem.fareItens.find(y => y.value === bagageValue)?.nameBaggage)?.withTax} </Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} >
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant='b'> Emissão CO<sub>2</sub> (kg) por passageiro </Typography>
                                                            <Typography variant='b' style={{ color: '#43a047', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                                <EcoOutlined style={{ color: '#43a047' }} />
                                                                {flightItem.carbonEmissionPerPerson}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    <Grid container >
                                        <Grid item xs={8}> </Grid>
                                        <Grid item container xs={4}>
                                            <>
                                                <Grid item xs={12}>
                                                    <Box style={{ borderRadius: '10px', backgroundColor: '#612F74', textAlign: 'center', color: 'white' }}>
                                                        <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '1.10rem' }}>
                                                            {voosSelecionados[0].reductionAttempts}
                                                        </Typography>
                                                        <Typography variant='subtitle2' style={{ marginTop: '-6px', fontSize: '1.175rem', fontWeight: '600' }}>
                                                            Tentativas de redução
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                        <Typography variant='h5' style={{ fontWeight: 'bold', color: '#612F74', fontSize: '1.1rem' }}>
                                                            BAGAGEM
                                                        </Typography>
                                                        <Typography variant='h6' style={{ color: '#612F74', fontSize: '1.0rem' }}>
                                                            easy
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <CustomSelectField required {...{ itens: fareItens, value: bagageValue, setValue: (e) => setBagageValue(e) }} name="bagage" />
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                    <Button className={classes.button03} onClick={() => props.handleChangeStateModalTaxRules(voosSelecionados[0].airCompanie.iata)}>
                                                        <Typography>Diferença das tarifas?</Typography>
                                                    </Button>
                                                </Grid>
                                            </>
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                                        <Grid item xs={8} />
                                        <Grid itex xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button onClick={() => setActiveStep(activeStep - 1)} className={classes.button03}> Voltar </Button>
                                        </Grid>
                                        <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                                            <Button fullWidth onClick={() => handleClickBooking()} className={classes.button}> Garantir Tarifa </Button>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </>
                        ) : flightPerPage?.map((flightItem, flightIndex) => (
                            <>
                                <Paper elevation={3} style={{ marginBottom: '20px' }} hidden={voosSelecionados?.length > 0 && stepIndex > 0 && flightItem.airCompanie.url != voosSelecionados[0].airCompanie.url}>
                                    <Grid container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                        <Grid item xs={2} style={{ background: 'none', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                            <Tooltip title={<Typography>{flightItem.airCompanie.iata}</Typography>}>
                                                <img height={80} style={{ maxWidth: 160 }} src={flightItem.airCompanie.url} />
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={6} style={{ background: 'none' }} spacing={'3'}>
                                            <Grid container style={{ minHeight: '100%' }}>
                                                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Typography variant='h6' ><b>DATA</b> - {voos[stepIndex]?.departureDate} </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Grid item xs={12}>
                                                            <Tooltip arrow name="descricaoTrechos" title={
                                                                <React.Fragment>
                                                                    <Grid container >
                                                                        {flightItem.flightDescriptions.map((leg) => (
                                                                            <>
                                                                                <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'> <b>{leg.departureTime}</b> </Typography>
                                                                                    <Typography variant='b'> {leg.departure}</Typography>
                                                                                </Grid>
                                                                                <Grid item xs={2} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                            <img height={30} src={seta} />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'> <b>{leg.arrivalTime}</b> </Typography>
                                                                                    <Typography variant='b'> {leg.arrival} </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                    </Grid>
                                                                </React.Fragment>
                                                            }>
                                                                <Grid container>
                                                                    {flightItem.flightDescriptions.map((leg) => (
                                                                        <>
                                                                            <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                <Typography variant='h5'> <b>{leg.departureTime}</b> </Typography>
                                                                                <Typography variant='subtitle2'> {new Date(leg.departureDateTime).toLocaleDateString()} </Typography>
                                                                                <Typography> {leg.departureCity} ({leg.departure}) </Typography>
                                                                                <Typography style={{ fontSize: '0.7rem' }}> {leg.departureAirportCity} </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={2} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                <Grid container>
                                                                                    <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <img height={30} src={seta} />
                                                                                    </Grid>
                                                                                    <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        {leg.flightDuration}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid item xs={5} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                <Typography variant='h5'> <b>{leg.arrivalTime}</b> </Typography>
                                                                                <Typography variant='subtitle2'> {new Date(leg.arrivalDateTime).toLocaleDateString()} </Typography>
                                                                                <Typography> {leg.arrivalCity} ({leg.arrival}) </Typography>
                                                                                <Typography style={{ fontSize: '0.7rem' }}> {leg.arrivalAirportCity} </Typography>
                                                                            </Grid>
                                                                            <Divider />
                                                                        </>
                                                                    ))}
                                                                </Grid>
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} style={{ background: 'none', padding: '5px' }}>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box display={'flex'} justifyContent={'space-between'}>
                                                        <Typography variant='b'>Preço por adulto</Typography>
                                                        <Typography variant='h6'> <b>{flightItem.faresGroup[0].agesGroup[0].withoutTax}</b></Typography>
                                                    </Box>
                                                </Grid>
                                                {flightItem.faresGroup[0].agesGroup.map((type) => (
                                                    <Grid item xs={12}>
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant='b'> {type.quantityPax} {type.type} </Typography>
                                                            <Typography variant='b'>{type.totalWithoutTax}</Typography>
                                                        </Box>
                                                    </Grid>
                                                ))}
                                                {flightItem.faresGroup[0].totalTax ? (
                                                    <Grid item xs={12}>
                                                        <Box display={'flex'} justifyContent={'space-between'}>
                                                            <Typography variant='b'> Encargos </Typography>
                                                            <Typography variant='b'>{flightItem.faresGroup[0].totalTax}</Typography>
                                                        </Box>
                                                        <Divider />
                                                    </Grid>
                                                ) : null}
                                                <Grid item xs={12}>
                                                    <Box display={'flex'} justifyContent={'space-between'}>
                                                        <Typography variant='b'> Preço final </Typography>
                                                        <Typography variant='b'>{flightItem.faresGroup[0].withTax} </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} >
                                                    <Box display={'flex'} justifyContent={'space-between'}>
                                                        <Typography variant='b'> Emissão CO<sub>2</sub> (kg) por passageiro </Typography>
                                                        <Typography variant='b' style={{ color: '#43a047', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                                            <EcoOutlined style={{ color: '#43a047' }} />
                                                            {flightItem.carbonEmissionPerPerson}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                    <Box style={{ borderRadius: '10px', backgroundColor: '#612F74', textAlign: 'center', color: 'white' }}>
                                                        <Typography variant='h6' style={{ fontWeight: 'bold', fontSize: '1.10rem' }}>
                                                            {flightItem.reductionAttempts}
                                                        </Typography>
                                                        <Typography variant='subtitle2' style={{ marginTop: '-6px', fontSize: '1.175rem', fontWeight: '600' }}>
                                                            Tentativas de redução
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: '10px' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '0px' }}>
                                                        <Typography variant='h6' style={{ color: '#612F74', fontSize: '0.9rem', marginRight: '4px' }}> Incluir </Typography>
                                                        <Typography style={{ fontWeight: 'bold', color: '#612F74', marginRight: '4px', fontSize: '1.0rem' }}> BAGAGENS </Typography>
                                                        <Typography variant='h6' style={{ color: '#612F74', fontSize: '0.9rem' }}> na próxima tela </Typography>
                                                    </div>
                                                    <Button onClick={() => handleSelectFligth(stepIndex, flightItem)} className={classes.button03}> Selecionar </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </>
                        ))}
                        {stepIndex == voos.length ? null : (
                            <Pagination
                                count={pages}
                                variant="outlined"
                                size="large"
                                page={page}
                                onChange={handleChange}
                                style={{ display: 'flex', justifyContent: 'center' }}
                            />
                        )}
                    </div>
                </div>
            </Box >
        )
    }

    useEffect(() => {
        if (props?.selectedFlights?.length > 0) {
            setVoosSelecionados(props?.selectedFlights)
            setActiveStep(props?.availabilities?.availability?.length);

            var some = props?.selectedFlights?.reduce((acum, item) => acum + item.fareItens[0].data, 0.0);
            var fareItem = props?.selectedFlights[0]?.fareItens[0];
            var fareItens = [{ label: `${fareItem.value} | R$ ${(some).toLocaleString("pt-BR", { maximumFractionDigits: 2 })}`, value: fareItem.value, data: some }];
            setFareItens(fareItens);
            setBagageValue(fareItem.value);
        }
    }, [props?.selectedFlights]);

    return (
        <div className={classes.root}>
            {openDialogNewPrice ? (
                < DialogNewPrice {...{ data: dataBooking, open: openDialogNewPrice, handleClickBooking, setOpenDialogNewPrice }} />
            ) : null}
            <Grow in={grow} timeout={1000}>
                <Grid container>
                    <Grid item xs={12} style={{ display: 'grid', justifyItems: 'center' }}>
                        <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper} connector={<ColorlibConnector />}>
                            {steps.map((label, index) => (
                                <Step onClick={() => setActiveStep(activeStep < index ? activeStep : index)} key={label} style={{ cursor: 'pointer', display: 'center', justifyContent: 'center' }}>
                                    <StepLabel >
                                        <Typography style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            {label.split('/')[0]}
                                            {steps.length - 1 > index ? (
                                                <>
                                                    <img height={30} src={seta} style={{ marginLeft: '3px', marginRight: '3px' }} />
                                                    {label.split('/')[1]}
                                                </>
                                            ) : null
                                            }
                                        </Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'grid', justifyItems: 'center' }}>
                        <div>
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography className={classes.instructions}>All steps completed</Typography>
                                    <Button onClick={handleReset}>Reset</Button>
                                </div>

                            ) : (
                                <div>
                                    <Typography className={classes.instructions}>{GetStepContent(activeStep)}</Typography>
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grow>
        </div >
    )
}

export default ListSearchFlights;
