import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: "20px",
  },

  alignContainer: {
    display: "flex",
    justifyContent: "center",
  },

  containerDuvidas: {
    margin: "30px 20px 20px 20px",
    textAlign: "center",
  },

  container: {
    maxWidth: "1100px",
    marginTop: '50px'
  },

  titleDuvidas: {
    textAlign: "left",
    fontFamily: "Arial",
    fontSize: "18px",
  },

  textTitleFloat: {
    fontWeight: "bold",
    fontSize: "18pt",
  },

  divTitleFloat: {
    backgroundColor: "rgb(109,92,140)",
    // backgroundColor: "red",
    textAlign: "center",
    position: "absolute",
    marginLeft: "20px",
    top: "150px",
    width: "300px",
    zIndex: 1,
    color: "#fff",
    padding: "10px",
  },

  gridTitle: {
    backgroundColor: "rgb(109,92,140)",
    textAlign: "center",
    color: "#FFF7FF",
    marginBottom: "20px",
    padding: "20px 20px 15px 20px",
  },

  textTitleCard: {
    fontFamily: "Arial",
    marginBottom: "5px",
  },

  textTitle: {
    fontWeight: "bold",
    fontSize: "18pt",
    fontFamily: "Arial",
  },

  text: {
    fontFamily: "Arial",
  },

  divIcon: {
    background:
      "linear-gradient(270deg, rgba(118,69,161,1) 0%, rgba(155,101,189,1) 40%, rgba(185,142,232,1) 100%)",
    borderRadius: "30px",
    padding: "8px",
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color: "#FFF7FF",
    fontSize: "2.2rem",
  },

  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "#FFF7FF",
    margin: "10px 20px 10px 20px",
    padding: "15px 20px 15px 20px",
    minHeight: "219px",
    boxShadow:
      "0px 3px 3px -2px rgb(109 92 140 / 80%), 0px 3px 4px 0px rgb(109 92 140 / 56%), 0px 1px 8px 0px rgb(109 92 140 / 48%)",
  },
}));
