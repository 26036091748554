import {
  Box,
  Button,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import CustomLoader from "../../components/CustomLoader";
import searchFlightsService from "../../services/Flights/Search";
import { Dashboard } from "../Dashboard";
import { DeskPromocoes } from "./DeskPromocoes";
import { MobiPromocoes } from "./MobiPromocoes";
import { useStyles } from "./styles";

const Promocoes = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [promoList, setPromoList] = useState(
    useSelector((state) => state.flights.promoList)
  );
  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    try {
      setIsLoading(true);
      let cidade = props.match.params.city;
      let response = await searchFlightsService.getPromotionalFlightsByCity(
        cidade
      );
      localStorage.setItem("city", cidade);

      if (!props.match.params.city || props.match.params.city.length <= 0 || response.data.results.length <= 0) {
        history.push("/");
      }

      setPromoList(response.data.results);
      window.scrollTo(0, 0);
    } catch (error) {
      history.push("/");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.match.params.city && props.match.params.city.length > 0) {
      getData();
    }
  }, []);

  return (
    <>
      <CustomLoader isLoading={isLoading} />
      {isLoading ? (
        <Box
          height={"500px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <CircularProgress style={{ color: "#612F74" }} size={100} />
        </Box>
      ) : (
        <Grid container style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Grid item xs={12} className={classes.gridCenter}>
            {responsive ? (
              <MobiPromocoes {...{ promoList }} />
            ) : (
              <div className={classes.divPromo}>
                <DeskPromocoes {...{ promoList }} />
              </div>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const DashPromocoes = (props) => {
  return (
    <div>
      <Dashboard {...props} component={Promocoes} />
    </div>
  );
};

export default DashPromocoes;
