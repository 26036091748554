import api from '../../Api';
import cookie from 'universal-cookie';

const cookies = new cookie();

const personAccountService = {

    getPersonAccount: async (autocomplet = false) => {
        let response = await api.get('/Person/get', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default personAccountService;
