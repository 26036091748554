import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    ccBox: {
        maxWidth: 'fit-content',
        borderRadius: '5px',
        padding: '5px'
    },

    buttonContract: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    }

}));
