const INITIAL_STATE = {
  searchedFlight: {
    flights: [],
    numberOfPax: 0,
  },

  promoList: [],

  promoSearch: {
    isPromo: false,
    promo: {},
  },

  readyBooking: {
    openBuyingDialog: false,
    selectedFlights: [],
    stateQuantityPerson: {},
    availabilities: {}
  },
};

const Flights = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SEARCHEDFLIGHT":
      return { ...state, searchedFlight: action.searchedFlight };
    case "PROMOLIST":
      return { ...state, promoList: action.promoList };
    case "PROMOSEARCH":
      return { ...state, promoSearch: action.promoSearch };
    case "READYBOOKING":
      return { ...state, readyBooking: action.readyBooking };
    default:
      return state;
  }
};

export default Flights;
