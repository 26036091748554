import api from '../../Api';

const PixPaymentService = {
    CreatePixPayment: async (dto) => {
        const response = await api.post('/Air/test-connection', dto);
        return response;
    },
}

export default PixPaymentService;
