import { makeStyles } from "@material-ui/core/styles";

export const UseStyles = makeStyles((theme) => ({
    p: {
        margin: 0
    },

    p2: {
        margin: '16px 0px 0px 0px'
    },
}));
