import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography, useMediaQuery, useTheme, } from "@material-ui/core";
import { CancelOutlined, CloseOutlined, SaveOutlined, } from "@material-ui/icons";
import React, { useState } from "react";
import Cards from "react-credit-cards";
import CustomLoader from "../../components/CustomLoader";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import CustomTextFieldDark from "../../components/CustomTextFieldDark";
import CustomTextFieldMasked from "../../components/CustomTextFieldMasked";
import { creditCardService } from "../../services/CreditCard";
import { useStyles } from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CreditCardDialog = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [state, setState] = useState({
    surname: "",
    cardName: "",
    cardNumber: "",
    cardValidity: "",
    cvv: "",
    cardCpf: "",
    cardFlag: "",
    focus: "",
  });

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleChange = (event) => {
    if (event.target.name === "name") {
      if (!isNaN(event.target.value) && event.target.value !== "") {
        return;
      }
    }
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const converteDataCartao = (dataCartao = "00/00") => {
    const mes = Number(dataCartao.substr(0, 2));
    const ano = Number("20" + dataCartao.substr(3, 2));
    let dataFormatada = new Date(ano, mes, 0);
    return dataFormatada;
  };

  const bandeirasCartao = [
    {
      title: "AMEX",
      regex: /^3[47][0-9]{13}$/,
      result: "AX",
    },
    {
      title: "DINERS",
      regex: /(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
      result: "DC",
    },
    {
      title: "ELO",
      regex:
        /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
      result: "EL",
    },
    {
      title: "MASTERCARD",
      regex:
        /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
      result: "CA",
    },
    {
      title: "HIPERCARD",
      regex: /^606282|^3841(?:[0|4|6]{1})0/,
      result: "HC",
    },
    {
      title: "DISCOVER",
      regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
      result: "EL",
    },
    {
      title: "VISA",
      regex: /^4[0-9]{15}$/,
      result: "VI",
    },
  ];

  const validarCartao = (nCartao) => {
    let sigla = bandeirasCartao.find((item) => item.regex.test(nCartao)) || {};
    setState({ ...state, cardFlag: sigla });
    return sigla || "";
  };

  const handleInputFocus = (e) => {
    setState({ ...state, focus: e.target.name });
  };

  const handleClickSave = async () => {
    if (!state.cardName) {
      setSnackVariant("error");
      setSnackMessage("Nome não pode ser vazio!");
      setSnackVisible({ visible: true });
      return;
    }
    if (!state.cardCpf) {
      setSnackVariant("error");
      setSnackMessage("CPF não pode ser vazio!");
      setSnackVisible({ visible: true });
      return;
    }
    if (!state.cardNumber) {
      setSnackVariant("error");
      setSnackMessage("Número não pode ser vazio!");
      setSnackVisible({ visible: true });
      return;
    }
    if (!state.cardValidity) {
      setSnackVariant("error");
      setSnackMessage("Data de validade não pode ser vazio!");
      setSnackVisible({ visible: true });
      return;
    }

    let cartaoValido = validarCartao(state.cardNumber);

    if (!cartaoValido) {
      setSnackVariant("error");
      setSnackMessage("Cartão inválido!");
      setSnackVisible({ visible: true });
      return;
    }

    let dataValida = converteDataCartao(state.cardValidity);

    try {
      let dto = {
        surname: state.surname,
        cardName: state.cardName,
        cardNumber: state.cardNumber,
        cardValidity: dataValida,
        cvv: state.cvv.trim(),
        cardFlag: cartaoValido.title,
        cardCpf: state.cardCpf,
      };

      setIsLoading(true);
      let response = await creditCardService.postCreateCreditCard(dto);
      if (response.status === 201) {
        setSnackVariant("success");
        setSnackMessage("Cartão salvo com sucesso.");
        setSnackVisible({ visible: true });
        handleClose();
        props.getListCreditCard();
      }
    } catch (e) {
      setSnackVariant("error");
      setSnackMessage("Erro ao salvar");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <Dialog
        onClose={handleClose}
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={responsive}
        maxWidth="md"
        fullWidth={true}
        classes={responsive ? null : { paper: classes.dialogPaper }}
        aria-labelledby="cadastro para cartão de crédito"
        aria-describedby="atalho para cadastro de cartão de crédito"
      >
        <CustomLoader isLoading={isLoading} />
        {responsive ? (
          <DialogTitle
            id="alert-dialog-title"
            style={{ padding: "0px" }}
            className={classes.dialogTitle}
          >
            <Box
              width={"-webkit-fill-available"}
              display={"grid"}
              justifyContent={"end"}
            >
              <IconButton
                onClick={() => handleClose()}
                style={{ color: "#fafafa  " }}
              >
                <CloseOutlined />
              </IconButton>
            </Box>
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
            <Box
              width={"-webkit-fill-available"}
              display={"grid"}
              justifyContent={"end"}
            >
              <IconButton
                onClick={() => handleClose()}
                style={{ color: "#fafafa  " }}
              >
                <CloseOutlined />
              </IconButton>
            </Box>
          </DialogTitle>
        )}
        <DialogContent style={responsive ? { padding: "8px 10px" } : null}>
          <>
            <Grid container>
              <Grid item xs={responsive ? 12 : 6} className={classes.gridCard}>
                <Cards
                  placeholders={{
                    name: "SEU NOME AQUI",
                  }}
                  locale={{ valid: "Data de Validade" }}
                  cvc={state.cvv}
                  expiry={state.cardValidity}
                  focused={state.focus}
                  name={state.cardName}
                  number={state.cardNumber}
                />
              </Grid>
              <Grid container item xs={responsive ? 12 : 6}>
                <Grid item xs={12}>
                  <Typography variant="h6">Dados do cartão</Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridItemCustom}>
                  <CustomTextFieldDark
                    name="surname"
                    variant="outlined"
                    margin="dense"
                    onChange={handleChange}
                    value={state.surname}
                    style={{ width: "100%" }}
                    label="Apelido"
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItemCustom}>
                  <CustomTextFieldDark
                    required
                    name="cardName"
                    variant="outlined"
                    margin="dense"
                    onFocus={handleInputFocus}
                    onChange={handleChange}
                    value={state.cardName}
                    style={{ width: "100%" }}
                    label="Nome do titular no cartão"
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItemCustom}>
                  <CustomTextFieldMasked
                    required
                    mask="999.999.999-99"
                    maskChar=""
                    name="cardCpf"
                    label="CPF"
                    value={state.cardCpf || ""}
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item xs={12} className={classes.gridItemCustom}>
                  <CustomTextFieldMasked
                    required
                    mask="9999999999999999"
                    maskChar={null}
                    name="cardNumber"
                    label="Nº do cartão"
                    value={state.cardNumber}
                    onChange={handleChange}
                    onFocus={handleInputFocus}
                  />
                </Grid>
                <Grid item container xs={12}>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingRight: "5px" }}
                    className={classes.gridItemCustom}
                  >
                    <CustomTextFieldMasked
                      required
                      mask="99/99"
                      maskChar=""
                      formatChars={{
                        9: "[0-9]",
                        "/": "",
                      }}
                      name="cardValidity"
                      label="Validade"
                      value={state.cardValidity}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{ paddingLeft: "5px" }}
                    className={classes.gridItemCustom}
                  >
                    <CustomTextFieldMasked
                      required
                      mask="999?"
                      formatChars={{
                        9: "[0-9]",
                        "?": "[0-9]",
                      }}
                      maskChar=""
                      name="cvv"
                      label="CVV"
                      value={state.cvv}
                      onFocus={handleInputFocus}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleClose()}
            className={classes.buttonCancel}
            startIcon={<CancelOutlined />}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => handleClickSave()}
            className={classes.buttonSave}
            startIcon={<SaveOutlined />}
          >
            Cadastrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
