import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import { useThemeCustom } from "./theme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogTelefone = (props) => {
  const classes = useStyles();
  const theme = useThemeCustom;
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.up("md"));

  const { open, setOpen } = props;

  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{
            marginBottom: "15px",
          }}
        >
          Telefone
        </DialogTitle>
        <DialogContent style={tablet ? { minWidth: "300px" } : { minWidth: "200px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            {/* <Typography style={{ display: "flex", flexDirection: "column" }}>
              Suporte:{" "}
              <Button
                className={classes.button}
                href="tel:+5548999803912"
                target="_blank"
              >
                +55 (48) 99980-3912
              </Button>
            </Typography> */}
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Atendimento:{" "}
              <Button
                className={classes.button}
                href="tel:+5548996810078"
                target="_blank"
              >
                +55 (48) 99681-0078
              </Button>
            </Typography>
            <Typography style={{ display: "flex", flexDirection: "column" }}>
              Telefone-Fixo:{" "}
              <Button
                className={classes.button}
                href="tel:+554834116695"
                target="_blank"
              >
                +55 (48) 3411-6695
              </Button>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogTelefone;
