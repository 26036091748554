import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    buttonOrange: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    titleBar: {
        background: '#612f74',
        color: '#ffffff',
    },

    gridTxt: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 0px',
        flexDirection: 'column'
    },

    buttonDoNow: {
        color: "white",
        fontWeight: "800",
        marginTop: '0.5rem',
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },

    typoMain: {
        background: '#612f74',
        color: '#ffffff',
        borderRadius: '15px',
        padding: '8px'
    }

}));
