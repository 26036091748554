import React from "react";
import CustomBoxImg from "../../../components/CustomBoxImg";
import { useStyles } from "./styles";
import headMidiaCell from "./../../../images/Marketing/central de ajuda/como_podemos_te_ajudar_410x383.png";
import headMidiaTab from "./../../../images/Marketing/central de ajuda/como_podemos_te_ajudar_410x205.png";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography, useMediaQuery, } from "@material-ui/core";
import { useThemeCustom } from "./theme";
import { EmailOutlined, ExpandMoreOutlined, Phone, WhatsApp, } from "@material-ui/icons";

export const CentralDeAjudaMobi = (props) => {
  const classes = useStyles();
  const theme = useThemeCustom;
  const tablet = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className={classes.root}>
      <CustomBoxImg
        midia={tablet ? headMidiaTab : headMidiaCell}
        style={{ height: "383px" }}
      />

      <Grid container>

        <Grid item xs={tablet ? 6 : 12}>
          <Paper className={classes.card} elevation={3} onClick={() => props.setOpenWhats(true)} style={tablet ? { marginRight: "10px" } : null}>
            <div className={classes.divIcon}>
              <WhatsApp className={classes.icon} />{" "}
            </div>
            <br />
            <Typography variant="h5" className={classes.textTitleCard}>
              Via WhatsApp
            </Typography>
            <Typography variant="p" className={classes.text}>
              Respostas rápidas.
            </Typography>
            <Typography variant="p" className={classes.text}>
              Tire dúvidas sobre o sistema com um de nossos suportes, ou se for
              sobre aéreo tente conversar com um atendente especializado.
            </Typography>
            <Typography variant="caption" className={classes.text}>
              Funcionamento das <b>8h à 00h</b>.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={tablet ? 6 : 12}>
          <Paper className={classes.card} elevation={3} onClick={() => props.setOpenEmail(true)} style={tablet ? { marginLeft: "10px" } : null}>
            <div className={classes.divIcon}>
              <EmailOutlined className={classes.icon} />{" "}
            </div>
            <br />
            <Typography variant="h5" className={classes.textTitleCard}>
              Via E-mail
            </Typography>
            <Typography variant="p" className={classes.text}>
              Respostas rápidas.
            </Typography>
            <Typography variant="p" className={classes.text}>
              Tire dúvidas sobre o sistema com um de nossos suportes, ou se for
              sobre aéreo tente conversar com um atendente especializado.
            </Typography>
            <Typography variant="caption" className={classes.text}>
              Funcionamento das <b>8h à 00h</b>.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={tablet ? 2 : 0} />

        <Grid item xs={tablet ? 8 : 12}>
          <Paper className={classes.card} elevation={3} onClick={() => props.setOpenTel(true)}>
            <div className={classes.divIcon}>
              <Phone className={classes.icon} />{" "}
            </div>
            <br />
            <Typography variant="h5" className={classes.textTitleCard}>
              Via Ligação
            </Typography>
            <Typography variant="p" className={classes.text}>
              Tire dúvidas sobre o sistema com um de nossos suportes, ou se for
              sobre aéreo tente conversar com um atendente especializado.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={tablet ? 2 : 0} />

        <Grid item xs={12} className={classes.containerDuvidas}>
          <Typography variant="h5" className={classes.textTitleCard} style={{ marginBottom: "10px" }}>
            Dúvidas frequentes
          </Typography>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Animais de estimação
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                Para viajar com animais, é necessário entrar em contato com a
                companhia aérea para verificar as condições desse transporte.
                Saiba mais.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Cancelamento e reembolso
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                Para solicitar o cancelamento de um bilhete, o titular pagante
                pode acessar um meio de comunicação acima. O valor líquido de
                reembolso é igual ao valor total pago pelo(s) bilhete(s) =
                (tarifa + taxas), subtraído do valor da multa cobrada. ATENÇÃO:
                Os reembolsos referentes a cancelamentos de voos que ocorrerem
                no período de 31 de Dezembro de 2020 a 31 de outubro de 2021,
                respeitarão o prazo disposto na Medida Provisória 1.024,
                publicada pelo Governo Federal em 31 de dezembro de 2020. Esta
                medida altera a Lei Federal n° 14.034 e determina que os
                estornos poderão ser realizados em até 12 meses a partir da data
                do embarque. Saiba mais acessando nossos termos de Cancelamento
                e Reembolso.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Check-in e horário de chegado ao aeroporto
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                O check-in poderá ser feito 48 horas antes do embarque, através
                do site ou aplicativo da companhia aérea escolhida. Procure
                apresentar-se ao embarque com antecedência de pelo menos 2 horas
                para voos nacionais e 3 horas para voos internacionais.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Documentos para embarque
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                Na hora do embarque tenha em mãos o documento com foto para voos
                nacionais e passaporte para voos internacionais. Atenção:
                destinos internacionais podem pedir comprovantes extras.
                Consulte diretamente a companhia aérea para saber mais.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Marcação de assentos
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                A marcação de assentos, é liberada no momento do check-in e pode
                ser feita diretamente no site ou aplicativo da companhia aérea.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.titleDuvidas}>
                Remarcação e reacomodação
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography align="justify" variant="p" className={classes.text}>
                A linestur.com não realiza remarcações. Caso não possa viajar na
                data e/ou horário adquirido(s), recomendamos que solicite o
                cancelamento por meio de nossa opção de atendimento acima. Se
                você está no aeroporto, recomendamos tentar a reacomodação no
                balcão da cia. aérea. Caso prefira nosso auxílio ou se seu voo
                for em uma data futura, acesse nossa opção de atendimento acima.
              </Typography>
            </AccordionDetails>
          </Accordion>

        </Grid>
      </Grid>
    </div>
  );
};

export default CentralDeAjudaMobi;
