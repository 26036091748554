import { Box, Button, Chip, Grid, Grow, List, ListItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import EnumCompany from "../../../Enums/enum_FlightCompany";
import { useStyles } from "./styles";
import { IconsSource } from "../../../images/Icons";

export const MobiListHistoric = (props) => {
    const classes = useStyles();
    const [grow, setGrow] = useState(true);

    return (
        <Grow in={grow} timeout={1000}>
            <>
                <Box display="flex" justifyContent="center" alignItems="center" marginBottom={'25px'}>
                    <Typography variant={'h6'} style={{ color: '#612F74' }}>Histórico</Typography>
                </Box>
                {props.data.length !== 0 ? null : (
                    <Typography variant="h5" style={{ textAlign: 'center', marginTop: '20px' }}>Não há histórico disponível</Typography>
                )}
                <List>
                    {props.data.map((item) => (
                        <ListItem >
                            <Grid container spacing={1} style={{ border: '1px solid #551a8b ', padding: '10px', borderRadius: '20px' }}>
                                <Grid onClick={() => props.handleOpenTicket(true, item.id_Booking)} item xs={12} style={{ display: 'grid', justifyContent: 'center' }}>
                                    <img
                                        src={item.airCompanie === undefined ? null : (EnumCompany.getLogoCompanhiaAereaEnum(item.airCompanie).url_high)}
                                        width={80}
                                        height={80}
                                    />
                                </Grid>
                                <Grid onClick={() => props.handleOpenTicket(true, item.id_Booking)} item xs={12} style={{ display: 'grid', justifyContent: 'center' }}>
                                    <Chip label={item.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                </Grid>
                                <Grid onClick={() => props.handleOpenTicket(true, item.id_Booking)} item xs={12} style={{ display: 'grid', justifyContent: 'center' }}>
                                    <Typography>
                                        Data de partida: {item.departureDate}
                                    </Typography>
                                </Grid>
                                <Grid onClick={() => props.handleOpenTicket(true, item.id_Booking)} item xs={12} style={{ display: 'grid', justifyContent: 'center' }}>
                                    <Typography variant="subtitle1">
                                        {item.route}
                                    </Typography>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img src={IconsSource.pixIcon} className={classes.img} />
                                    </Grid>
                                    <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Typography variant="body1" style={{ textAlign: 'center' }}>Há pix disponível para pagamento</Typography>
                                    </Grid>
                                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                        <Button onClick={() => { props.setIdBookingSelected(item.id_Booking); props.setOpenPix(true); }} style={{ textAlign: 'center', backgroundColor: "#38c4d4", color: "#ffffff" }}>Pagar agora</Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ListItem>
                    ))}
                </List>
            </>
        </Grow>
    )
}
