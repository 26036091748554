import Cookies from "universal-cookie";
import api from "../../Api";

const cookies = new Cookies();

export const creditCardService = {

    getListCreditCard: async () => {
        const response = await api.get('/CreditCard/get-all', {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postListInstallments: async (dto) => {
        const response = await api.post('/Air/installments', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    postCreateCreditCard: async (dto) => {
        let response = await api.post('/CreditCard/create', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    sendCardAnalysis: async (dto) => {
        let response = await api.post('/CreditCard/send-card-analysis', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

    deleteCreditCard: async (id) => {
        await api.delete(`/CreditCard/remove/${id}`, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
    },


}
