import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@material-ui/core';
import React from 'react';
import { useLayoutEffect } from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogMessage(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.setOpen(false)
    };

    return (
        <>
            <Dialog
                open={true}
                TransitionComponent={Transition}
                keepMounted
                id='id-dialog-message'
                maxWidth="xs"
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-title"><Typography>{props.title ? props.title : 'DEFAULT'}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography>
                            {props.message ? props.message : 'DEFAULT'}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()}><Typography>{props.buttonMessage}</Typography></Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
