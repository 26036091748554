import { DialogContent, DialogContentText, Typography } from "@material-ui/core";
import React from "react";

export const CCAuthDesktop = () => {

    return (
        <>
            <DialogContent>
                <DialogContentText>
                    <Typography>
                        Para realizar a autenticação do cartão de crédito, é necesario
                        acessar via celular ou tablet.
                    </Typography>
                </DialogContentText>
            </DialogContent>
        </>
    )
}
