import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from "@material-ui/core";
import React from "react";
import temp from '../../../images/assets/Biometry/temp.gif';
import doingBioAuth from '../../../images/assets/Biometry/doingBioAuth.gif';
import { useStyles } from "./styles";

const RegisterAuthenticateDesktop = (props) => {
    const classes = useStyles();

    return (
        <>
            <Dialog
                open={props.open}
                fullWidth
                maxWidth={'md'}
            >
                <DialogTitle className={classes.titleBar}>
                    <Typography>Biometria</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={6}>
                            <img src={doingBioAuth} height={`350rem`} />
                        </Grid>
                        <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography variant={'h6'}>
                                Por medida de segurança, só é possivel efetuar reconhecimento facial e autenticação de cartão de crédito, no seu celular.
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.buttonOrange} onClick={() => props.handleCloseAuthRF()}>Entendi</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RegisterAuthenticateDesktop;
