import api from '../../../Api';

const stateServices = {

    getStatesByCountry: async (select, id_Country) => {
        const response = await api.get('/State/get-all?select=' + select + '&idcountry=' + id_Country);
        return response;
    },
}

export default stateServices;
