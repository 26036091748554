import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
  bola: {
    background: "#ff7500",
    borderRadius: "50%",
    height: "15px",
    width: "15px",
    marginLeft: "10px",
    marginRight: "10px",
  },

  boxCompanies: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "-webkit-fill-available",
  },

  container: {
    display: "flex",
    justifyContent: "center",
  },

  customGridMain: {
    background: "none",
    // padding: theme.spacing(2),
    // maxWidth: '70vw',
    // minWidth: '70vw',
    maxWidth: "1100px",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },

  gridImg: {},

  customGrid02: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
    marginBottom: "10px ",
  },

  customGrid03: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },

  customGrid04: {
    marginTop: "50px",
    marginBottom: "10px",
    background: "#ff7500",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    minWidth: "98.9vw",
  },

  typograph_01: {
    border: "2px solid #612F74",
    borderRadius: "10px",
    padding: "10px",
    alignItems: "center",
    fontWeight: "bold",
    color: "#716f71",
    width: "500px",
    display: "flex",
    justifyContent: "space-between",
  },

  textTitleBold: {
    fontWeight: "bold",
    fontSize: "23pt",
  },

  gridBanner: {
    textAlign: "center",
    margin: "45px 0px 0px 0px",
    borderRadius: "10px",
    padding: "8px",
  },

  typograph_02: {
    background: "#612F74",
    borderRadius: "10px",
    color: "#fff7ff",
    padding: "10px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },

  typograph_06: {
    color: "#716f71",
  },

  typograph_08: {
    // width: '-webkit-fill-available',
    color: "#fff7ff",
  },

  gridSteps: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
