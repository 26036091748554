import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    gridCenter: {
        display: 'flex',
        justifyContent: 'center'
    },

    grid: {
        maxWidth: '-webkit-fill-available',
        display: 'grid',
        gridTemplateColumns: '15% 70% 15%'
    },


}));
