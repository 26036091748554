import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Cookies from "universal-cookie";
import { readyBooking } from "../../actions/flightsAction";
import CustomLoader from "../../components/CustomLoader";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import bookingService from "../../services/Bookings";
import personService from "../../services/Person";
import personAccountService from "../../services/PersonAccount";
import { RegisterAuthenticate } from "../RegisterAuthenticate";
import { BuyingDesk } from "./BuyingDesk";
import { BuyingMobi } from "./BuyingMobile";
import "./styles.css";
import { useStyles } from "./styles.jsx";
import { DialogBioWarning } from "./DialogBioWarning";
import PaymentMethodSelect from "../../components/01_Raname-PaymentMethod";
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { PixPaymentDialog } from "./PixPaymentFinally";
import { EnumMethodPayment } from "../../Enums/enum_MethodPayment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const BuyingDialog = (props) => {
  const classes = useStyles();
  const cookies = new Cookies();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [stepActual, setStepActual] = useState(0);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [openAuthRF, setOpenAuthRF] = useState(false);
  const [open, setOpen] = useState(props.open);
  const [personAccount, setPersonAccount] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [id_CreditCard, setId_CreditCard] = useState(0);
  const [openCCAuth, setOpenCCAuth] = useState(false);
  const [openDialogBioWarning, setOpenDialogBioWarning] = useState(true);
  const [safeGuard, setSafeGuard] = useState(undefined);
  const [formPaymentSelected, setFormPaymentSelected] = useState("");
  const [openPaymentPix, setOpenPaymentPix] = useState(false);
  const [bookingSelected, setIdBookingSelected] = useState(null);
  const [confirmPix, setConfirmPix] = useState(false)
  const [dtoBio, setDtoBio] = useState({
    bio: true,
    cc: true,
    ticket: {
      open: true,
      response: null
    }
  });
  const [state, setState] = React.useState({
    acceptTerms: false,
  })
  const [dtoCompra, setDtoCompra] = useState({
    flightsTokens: [],
    idPassengers: [],
    idCreditCard: "",
    idInstallment: 0,
  });
  const [opcoesUsadas, setOpcoesUsadas] = useState({
    pax: [],
    cartao: {},
    parcela: {},
  });

  const data = props.data;

  const handleClose = () => {
    
    dispatch(
      readyBooking({
        readyBooking: {
          openBuyingDialog: false,
          selectedFlights: [],
          stateQuantityPerson: {},
          availabilities: {},
        },
      })
    );
    props.setOpen(false);
  };

  const handleClickNextStep = async () => {
    if (stepActual + 1 == 1) {
      setStepActual(stepActual + 1);
    }

    if (formPaymentSelected == "pixpayment") {
      setStepActual(3);
    }

    if (formPaymentSelected == "pixpayment" && stepActual == 3) {
      setOpen(false)
      setOpenPaymentPix(true)
    }

    if (formPaymentSelected == "card") {
      if (stepActual + 1 == 2) {
        if (!opcoesUsadas.cartao.id_CreditCard) {
          setSnackVariant("error");
          setSnackMessage("Sem cartão selecionado");
          setSnackVisible({ visible: true });
          return;
        }
      }

      if (stepActual + 1 == 3) {
        if (dtoCompra.idInstallment === 0) {
          setSnackVariant("error");
          setSnackMessage("Sem opção de parcela selecionada");
          setSnackVisible({ visible: true });
          return;
        }
      }

      if (stepActual < 3) {
        if (
          stepActual === 0 &&
          dtoCompra.idPassengers.length !==
          data[0].faresGroup[0].totalQuantityPax - 1
        ) {
          return;
        }
        setStepActual(stepActual + 1);
      }
    }
  };

  const handleClickBackStep = () => {
    if (formPaymentSelected == "pixpayment" && stepActual == 3) {
      setStepActual(1);
      // setDtoCompra({ ...dtoCompra, idCreditCard: "", idInstallment: 0 })
      // setOpcoesUsadas({
      //   ...dtoCompra, pax: [],
      //   cartao: {},
      //   parcela: {},
      // })
      return
    }

    if (stepActual > 0) {
      setStepActual(stepActual - 1);
    }
  };

  const getSafeGuard = async () => {
    let temp = await window.createSafeGuard()
    await temp.init({
      identification: "72154ffa-dd14-4413-9140-6c4b48a42d29",
      identificationType: "id",
      authorizationKey: "1ad53743-82b1-42ef-afb5-6260a89e9661",
      onSuccess: function (data) {
        setSafeGuard(data)
      },
      onError: function (status, message) {
        return 'error'
      }
    });
  };

  const verify = () => {
    if (safeGuard === undefined) {
      setTimeout(verify, 100);
      return;
    }
  };

  const handleGetDocument = (id = null) => {

  }

  useLayoutEffect(() => {
    const fetchData = async () => {
      await getSafeGuard();
    };
    fetchData()
      .catch(console.error);
  }, []);

  const verifyBioAndCreditCard = async () => {
    let dto = {
      cc: false,
      bio: false,
    }
    try {
      if (opcoesUsadas.cartao.isAuthenticated === 3) {
        dto = { ...dto, cc: false }
      } else {
        dto = { ...dto, cc: true }
      }
      await personService.isAuthRF();
      dto = { ...dto, bio: true }
    } catch (error) {
      dto = { ...dto, bio: false }
    }
    return dto
  };


  const handleOpenDialogBioWarning = () => {
    setOpenDialogBioWarning(true)
  };

  const handleCloseDialogBioWarning = () => {
    setOpenDialogBioWarning(false)
  }

  const handleClickComprar = async () => {
    let date = [];

    for (let index = 0; index < data.length; index++) {
      for (let i = 0; i < data[index].flightDescriptions.length; i++) {
        date.push(new Date(data[index].flightDescriptions[i].arrivalDateTime))
        date.push(new Date(data[index].flightDescriptions[i].departureDateTime))
      }
    }

    if (dtoCompra.flightsTokens.length === 0) {
      return;
    }
    if (dtoCompra.idPassengers.length !== data[0].faresGroup[0].totalQuantityPax - 1) {
      return;
    }
    if (dtoCompra.idCreditCard === 0 && formPaymentSelected == "card") {
      return;
    }
    if (dtoCompra.idInstallment === 0 && formPaymentSelected == "card") {
      return;
    }
    if (safeGuard === undefined) {
      return;
    }

    let response = null;

    try {
      setIsLoading(true);

      let teste = { ...dtoCompra };
      if (formPaymentSelected == "pixpayment") {
        setDtoCompra({ ...dtoCompra, idCreditCard: "", idInstallment: 0 })
        teste = { ...teste, idCreditCard: "", idInstallment: 0 }
        // setOpcoesUsadas({
        //   ...dtoCompra, pax: [],
        //   cartao: {},
        //   parcela: {},
        // })
      }

      response = await bookingService.postBookingFlights({ ...teste, acceptUseCC: state.acceptTerms, transitionToken: safeGuard.transactionToken, userIdentification: safeGuard.identification, PaymentPix: formPaymentSelected == "pixpayment" ? true : false, date });
      let temp = await verifyBioAndCreditCard();

      if (formPaymentSelected == "pixpayment") {
        setDtoBio({
          bio: temp.bio,
          cc: temp.cc,
          ticket: {
            open: true,
            response: response.data.results.id_Booking
          }
        })
        setIdBookingSelected(response.data.results.id_Booking)
        setOpenPaymentPix(true)
        setOpen(false)
        return;
      }

      if (temp.cc === false || temp.bio === false || formPaymentSelected == "card") {
        setDtoBio({
          bio: temp.bio,
          cc: temp.cc,
          ticket: {
            open: true,
            response: response.data.results.id_Booking
          }
        })
        setIdBookingSelected(response.data.results.id_Booking)
        setOpen(false)
      } else {
        handleClose();
      }

    } catch (error) {
      if (error.response.data.message) {
        setSnackVariant(error.response.data.variant);
        setSnackMessage(error.response.data.message);
        setSnackVisible({ visible: true });
      } else {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar garantir sua tarifa");
        setSnackVisible({ visible: true });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeState = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const getColor = (bandeira) => {
    switch (bandeira) {
      case 'AMEX':
        return {
          background: 'linear-gradient(50deg, rgba(48,140,103,1) 0%, rgba(163,242,207,1) 100%)',
        }
      case 'DINERS':
        return {
          background: 'linear-gradient(50deg, rgba(255,255,255,1) 0%, rgba(238,238,238,1) 100%)'
        }
      case 'ELO':
        return {
          background: 'linear-gradient(50deg, rgba(33,28,24,1) 0%, rgba(170,167,162,1) 100%)',
        }
      case 'HIPERCARD':
        return {
          background: 'linear-gradient(50deg, rgba(139,24,27,1) 0%, rgba(222,31,39,1) 100%)',
        }
      case 'MASTERCARD':
        return {
          background: 'linear-gradient(50deg, rgba(251,251,251,1) 0%, rgba(232,233,229,1) 100%)',
        }
      case 'VISA':
        return {
          background: 'linear-gradient(50deg, rgba(15,80,158,1) 0%, rgba(19,153,205,1) 100%)',
        }
      default:
        return {
          background: '#808080',
        }
    }
  }

  const getTitle = () => {
    let response = '';
    switch (stepActual) {
      case 0:
        response = 'Quais são os passageiros?'
        break;
      case 1:
        response = 'Como você prefere pagar?'
        break;
      case 2:
        response = 'Em quantas vezes?'
        break;
      case 3:
        response = 'Revise e confirme sua tarifa garantida'
        break;
      default:
        response = 'Linestur'
        break;
    }
    return response
  }

  const cadastreSe = () => {
    dispatch(
      readyBooking({
        readyBooking: props.readyBooking,
      })
    );
    history.push("/registrar");
  };

  const handleCloseAuthRF = () => {
    setOpenAuthRF(false)
  }

  const availableAuthRF = async () => {
    try {
      setIsLoading(true);
      let response = await personService.isAuthRF();
      return false
    } catch (e) {
      if (e.response.status == 403) {
        setOpenAuthRF(true)
        return true
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getPersonAccount = async () => {
    try {
      let response = await personAccountService.getPersonAccount();
      setPersonAccount(response.data.results);
    } catch (error) { }
  }

  const handleOpenCCAuth = (id) => {
    setId_CreditCard(id)
    setOpenCCAuth(true)
  }

  const getDocumentDownload = async () => {
    try {
      setIsLoading(true)
      let response = null
      switch (formPaymentSelected) {
        case "pixpayment":
          response = await bookingService.getDownloadContract(EnumMethodPayment.pix)
          break;
        default:
          response = await bookingService.getDownloadContract(EnumMethodPayment.card)
          break;
      }
      const linkSource = response.data.results;
      const link = document.createElement('a');
      link.href = linkSource;
      link.target = '_blank'
      link.download = 'contrato.pdf';
      link.click();
    } catch (error) {
      setSnackVariant("error");
      setSnackMessage("Falha ao buscar contrato.");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getPersonAccount();
  }, []);

  useEffect(() => {
    if (dtoBio.cc === false || dtoBio.bio === false) {
      handleOpenDialogBioWarning();
    }
  }, [dtoBio]);

  // useEffect(() => {
  //   if (personAccount?.firstName) {
  //     availableAuthRF();
  //   }
  // }, [personAccount])

  return (
    <div>
      <CustomLoader isLoading={isLoading} />
      {openAuthRF ? (<RegisterAuthenticate {...{
        opcoesUsadas: opcoesUsadas.cartao.isAuthenticated,
        open: openAuthRF,
        setOpen: setOpenAuthRF,
        confirmPix,
        handleCloseAuthRF
      }} />
      ) : null}
      {openDialogBioWarning ? (<DialogBioWarning {...{
        dtoBio,
        setOpenBuy: setOpen,
        setOpenBuyProps: props.setOpen,
        open: openDialogBioWarning,
        handleCloseDialogBioWarning,
        handleOpenTicket: props.handleOpenTicket,
        formPaymentSelected: formPaymentSelected
      }} />
      ) : null}
      <Dialog
        // onClose={handleClose}
        // open={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullScreen={responsive}
        maxWidth="md"
        fullWidth={true}
        classes={responsive ? null : { paper: classes.dialogPaper }}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <CustomizedSnackbars {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }} />
        {
          responsive ? (
            <DialogTitle disableTypography id="alert-dialog-title" style={{ padding: "0.2rem" }} className={classes.dialogTitle}
            >
              <Box
                width={"-webkit-fill-available"}
                display={"grid"}
                justifyContent={"end"}
              >
                <IconButton
                  onClick={() => handleClose()}
                  style={{ color: "#fafafa" }}
                >
                  <CloseOutlined />
                </IconButton>
              </Box>
            </DialogTitle>
          ) : (
            <DialogTitle disableTypography id="alert-dialog-title" className={classes.dialogTitle}>
              {/* <Button onClick={() => setOpenDialogBioWarning(true)}>teste</Button> */}
              <Typography variant={'h5'} style={{ width: 'fit-content' }}>
                {getTitle()}
              </Typography>
              <IconButton onClick={() => handleClose()} style={{ color: "#fafafa  " }}>
                <CloseOutlined />
              </IconButton>
            </DialogTitle>
          )
        }
        <DialogContent style={responsive ? { padding: "8px 10px" } : null}>
          <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ textAlign: "center" }}>
            <Grid item>
              <VerifiedUserIcon style={{ color: "green" }} />
            </Grid>
            <Grid item>
              <Typography style={{ color: "green" }}>
                Sua informação de pagamento está segura conosco.
              </Typography>
            </Grid>
          </Grid>
          {responsive ? (
            <BuyingMobi {...{
              id_CreditCard,
              openCCAuth,
              setOpenCCAuth,
              getColor,
              cadastreSe,
              state,
              handleChangeState,
              handleCloseMain: handleClose,
              personAccount,
              setIsLoading,
              opcoesUsadas,
              setOpcoesUsadas,
              dtoCompra,
              setDtoCompra,
              data,
              handleClickBackStep,
              handleClickNextStep,
              setStepActual,
              stepActual,
              setSnackVariant,
              setSnackMessage,
              setSnackVisible,
              getPersonAccount,
              handleOpenCCAuth,
              handleGetDocument,
              getDocumentDownload,
              formPaymentSelected,
              setFormPaymentSelected
            }}
            />
          ) : (
            <BuyingDesk {...{
              openCCAuth,
              setOpenCCAuth,
              getColor,
              cadastreSe,
              state,
              handleChangeState,
              handleCloseMain: handleClose,
              personAccount,
              setIsLoading,
              opcoesUsadas,
              setOpcoesUsadas,
              dtoCompra,
              setDtoCompra,
              data,
              handleClickBackStep,
              handleClickNextStep,
              setStepActual,
              stepActual,
              setSnackVariant,
              setSnackMessage,
              setSnackVisible,
              getPersonAccount,
              handleGetDocument,
              getDocumentDownload,
              formPaymentSelected,
              setFormPaymentSelected
            }}
            />
          )}
        </DialogContent>
        <DialogActions>
          {stepActual !== 0 ? (
            <Button onClick={() => handleClickBackStep()}>
              <Typography>voltar</Typography>
            </Button>
          ) : null}
          {stepActual === 3 ? (
            <button disabled={!state.acceptTerms && formPaymentSelected != "pixpayment" || isLoading == true} onClick={() => handleClickComprar()} class="buttonConfirm">
              Confirmar
            </button>
          ) : (
            <Button onClick={() => handleClickNextStep()} className={classes.buttonNext}>
              <Typography>Continuar</Typography>
            </Button>
          )
          }
        </DialogActions >
      </Dialog >
      {
        openPaymentPix ? (
          <PixPaymentDialog {...{ open: openPaymentPix, setOpen: setOpenPaymentPix, id: bookingSelected }} />
        ) : (
          null
        )
      }
    </div >
  );
};
