import { useStyles } from "./styles";
import headMidia from "../../../images/Marketing/header_quem_somos_coracao1_1820x1080.png";
import CustomBoxImg from "../../../components/CustomBoxImg";
import { Grid, Paper, Typography, useMediaQuery } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import passo1 from "../../../images/Marketing/tres_passo_1.png";
import passo2 from "../../../images/Marketing/tres_passo_2.png";
import passo3 from "../../../images/Marketing/tres_passo_3.png";
import { useThemeCustom } from "./theme";

export const MobiQuemSomos = (props) => {
  const classes = useStyles();
  const theme = useThemeCustom;
  const tablet = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.root}>
      <CustomBoxImg midia={headMidia} style={{ height: "383px" }} />

      <div className={classes.container}>
        <main className={classes.mainContainer}>
          <div className={classes.divTitle}>
            <Typography className={classes.textTitle}>EXCLUSIVO</Typography>
            <Typography className={classes.textTitle}> REDUTOR DE TARIFA</Typography>
          </div>

          <Grid container className={classes.gridContainer}>
            <Grid item xs={12} className={classes.gridText}>
              <Typography className={classes.text}>
                Somos a <b>única</b> empresa capaz de reduzir sua tarifa, isto
                só é possível em virtude de nossa inteligência artificial que
                foi desenvolvida e embarcada na linestur.com, resultando na
                primeira agente virtual do mundo efetuando <b>34 tentativas</b>{" "}
                de redução por minuto.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                color: "gray",
              }}
            >
              <Typography className={classes.textTitleBold}>É fácil</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                marginTop: "5px",
                backgroundColor: "#612F74",
                color: "#fff",
              }}
            >
              <Typography className={classes.textBanner}>
                Você acompanha em tempo real as tentativas de redução
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridCarousel}>
              {tablet ? (
                <Carousel
                  animation="slide"
                  navButtonsAlwaysInvisible
                  autoPlay
                  interval={"10000"}
                >
                  <Paper elevation={0} className={classes.paperCarousel}>
                    <img
                      src={passo1}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                    <img
                      src={passo2}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                  </Paper>
                  <Paper elevation={0} className={classes.paperCarousel}>
                    <img
                      src={passo2}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                    <img
                      src={passo3}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                  </Paper>
                </Carousel>
              ) : (
                <Carousel
                  animation="slide"
                  navButtonsAlwaysInvisible
                  autoPlay
                  interval={"10000"}
                >
                  <Paper elevation={0} className={classes.paperCarousel}>
                    <img
                      src={passo1}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                  </Paper>
                  <Paper elevation={0} className={classes.paperCarousel}>
                    <img
                      src={passo2}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                  </Paper>
                  <Paper elevation={0} className={classes.paperCarousel}>
                    <img
                      src={passo3}
                      height={200}
                      className={classes.img}
                      alt=""
                    />
                  </Paper>
                </Carousel>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                color: "gray",
              }}
            >
              <Typography className={classes.textTitleBold}>
                Princípios
              </Typography>
            </Grid>

            <Grid
              item
              xs={tablet ? 6 : 12}
              className={classes.gridText}
              style={
                tablet ? { marginBottom: "100px" } : { marginBottom: "20px" }
              }
            >
              <Paper elevation={3} className={classes.paperText}>
                <Typography className={classes.textBold}>Visão.</Typography>
                <Typography className={classes.text}>
                  Revolucionar o segmento do turismo, empregando tecnologia
                  focada para gerar economia e bem-estar aos nossos clientes.
                </Typography>
              </Paper>
            </Grid>

            <Grid
              item
              xs={tablet ? 6 : 12}
              className={classes.gridText}
              style={{ marginBottom: "100px" }}
            >
              <Paper elevation={3} className={classes.paperText}>
                <Typography className={classes.textBold}>
                  Missão.
                </Typography>
                <Typography className={classes.text}>
                  Como desenvolvedores de tecnologia, nossa missão é sempre
                  harmonizar nossos sistemas a rotina das pessoas, construindo
                  nossas plataformas alicerçadas em três pilares:
                  <ol style={{ marginBottom: 0 }}>
                    <li>Mobile First;</li>
                    <li>Experiência do usuário;</li>
                    <li>Usabilidade.</li>
                  </ol>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
    </div>
  );
};

export default MobiQuemSomos;
