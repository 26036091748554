import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DashHelp from "./pages/Help";
import Auth from "./components/Auth/index";
import DashCreditCard from "./pages/CreditCard";
import Login from "./pages/Login/index";
import DashMyHistoric from "./pages/MyHistoric";
import DashPeople from "./pages/People";
import RegisterPage from "./pages/Register";
import DashSearchFlights from "./pages/SearchFlights";
import DashPromocoes from "./pages/Promocoes";
import DashZulminha from "./pages/Zulminha";
import DashQuemSomosPage from "./pages/QuemSomos";
import EsqueceuSenhaPage from "./pages/Esqueceu a senha";
import RecuperacaoSenhaPage from "./pages/RecuperacaoSenha";
import { DashCentralDeAjuda } from "./pages/CentralDeAjuda";
import whatsapp128 from './images/Logos/whatsapp_128.png';
import { Button, Fab, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  desk: {
    left: '93vw',
    top: '88vh',
    position: 'fixed',
    background: '#50b356'
  },
  mobi: {
    left: '83vw',
    top: '90vh',
    position: 'fixed',
    background: '#50b356'
  }
}));

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Auth>
          <Component {...{ ...props }} />
        </Auth>
      )}
    />
  );
};

export const Routes = () => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={(props) => <DashSearchFlights {...props} />} />
          <Route exact path="/entrar" component={(props) => <Login {...props} />} />
          <Route exact path="/registrar" component={(props) => <RegisterPage {...props} />} />
          <Route exact path="/aereos" component={(props) => <DashSearchFlights {...props} />} />
          <Route exact path="/ajuda" component={(props) => <DashHelp {...props} />} />
          <Route exact path="/zulminha" component={(props) => <DashZulminha {...props} />} />
          <Route exact path="/promocoes/:city" component={(props) => <DashPromocoes {...props} />} />
          <Route exact path="/quemsomos" component={(props) => <DashQuemSomosPage {...props} />} />
          <Route exact path="/esqueceusenha" component={(props) => <EsqueceuSenhaPage {...props} />} />
          <Route exact path="/forgotPassword/:forgotPassword" component={(props) => <RecuperacaoSenhaPage {...props} />} />
          <Route exact path="/central-de-ajuda" component={(props) => <DashCentralDeAjuda {...props} />} />
          <PrivateRoute exact path="/passageiros" component={(props) => <DashPeople {...props} />} />
          <PrivateRoute exact path="/cartao" component={(props) => <DashCreditCard {...props} />} />
          <PrivateRoute exact path="/historico" component={(props) => <DashMyHistoric {...props} />} />
          {/* Tem que ficar por ultimo */}
          <Route
            exact
            path="*"
            component={(props) => <DashSearchFlights {...props} />}
          />
          {/* Tem que ficar por ultimo */}
        </Switch>
      </BrowserRouter>
      {window.location.href.includes('entrar') ? null : (
        <Fab
          aria-label="whatsapp"
          href="https://wa.me/554896810078?text=Oi,%20pode%20me%20ajudar%20em%20uma%20dúvida%20sobre%20o%20sistema?"
          target="_blank"
          className={responsive ? classes.mobi : classes.desk}>
          <img src={whatsapp128} style={{ maxHeight: '40px', maxWidth: '40px' }}></img>
        </Fab>
      )}
    </>
  );
};

export default Routes;
