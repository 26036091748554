import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  img: {
    height: "30px",
    width: "auto",
    margin: "10px",
  },

  img2: {
    height: "20px",
    width: "auto",
    margin: "10px",
  },

  img3: {
    height: "45px",
    width: "auto",
    // margin: '20px'
  },

  img4: {
    height: "25px",
    width: "auto",
  },

  gridContainerMain: {
    background: "#fceefe",
    justifyContent: "center",
    marginBottom: "10px",
    marginTop: "10px",
  },

  customGrid01: {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    // maxWidth: '70vw',
    // minWidth: '70vw',
    marginTop: "20px",
  },

  customGrid02: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "70vw",
    minWidth: "70vw",
  },

  customGrid03: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    // maxWidth: '70vw',
    // minWidth: '70vw',
    marginTop: "20px",
  },

  customGrid04: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "left",
    marginLeft: "10px",
    marginRight: "10px",
    alignItems: "center",
  },

  typograph01: {
    color: "#716f71",
  },

  typograph02: {
    color: "#716f71",
    fontSize: "11pt",
  },

  typograph03: {
    color: "#716f71",
    fontSize: "16pt",
  },

  imageIcon: {
    // height: '100%',
    width: "40px",
    height: "auto",
  },

  iconRoot: {
    textAlign: "center",
    height: "auto",
    width: "auto",
  },
}));
