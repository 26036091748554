import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

export const useStyles = makeStyles((theme) => ({
    gridTitleGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5px',
        marginLeft: '0px',
        marginRight: '10px',
        marginBottom: '5px',
    },

    gridTitleGroupSelected: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #612F74',
        borderRadius: '10px',
        marginTop: '5px',
        marginLeft: '0px',
        marginRight: '10px',
        marginBottom: '5px',
    },


}));
