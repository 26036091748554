import { Box, Button, Divider, FormControlLabel, Grid, List, ListItem, ListItemText, Paper, Slide, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import React from 'react';
import CustomCheckBox from '../../../../components/CustomCheckbox';
import seta from '../../../../images/teste/seta.png';
import { useStyles } from './styles';
import { IconsSource } from '../../../../images/Icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BuyingDeskStep04 = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const data = props.data;

    function extractMonetaryValue(inputString) {
        const match = inputString.match(/R\$[\s]?(\d{1,3}(?:\.\d{3})*(?:,\d+)?)/);
        if (match) {
            const monetaryValue = match[1].replace(/\./g, "").replace(",", ".");
            return `R$ ${monetaryValue}`;
        }
        return "Valor não encontrado";
    }

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant='h6'>Revise e confirme sua tarifa garantida</Typography>
                </Grid>
                <Divider />
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant='h6'>Passageiros</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography>{`${props.personAccount.firstName} ${props.personAccount.lastName}`}</Typography>}>
                            </ListItemText>
                            {props.opcoesUsadas.pax.map((item) => (
                                <ListItemText
                                    primary={<Typography>{`${item.firstName} ${item.lastName}`}</Typography>}
                                />
                            ))}
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Detalhes do pagamento
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {props.actionSelect == "pixpayment" ? (
                        <Grid container>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Box>
                                    <img width={60} src={IconsSource.pixIcon} />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography style={{ textAlign: 'center' }}>
                                    {`Você pagará ${extractMonetaryValue(data[0].fareItens[0].label)}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: '-webkit-center', paddingTop: '1rem' }} >
                                <Button
                                    onClick={() => props.getDocumentDownload()}
                                    className={classes.buttonContract}
                                    startIcon={<GetAppOutlined />}>
                                    Baixar contrato
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box style={{
                                    width: '125px',
                                    height: '125px',
                                    border: '3px solid #612F74',
                                    borderRadius: '999px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Typography variant={'h6'} style={{ color: '#612F74', fontWeight: 'bold' }}>
                                        {data[0].reductionAttempts}
                                    </Typography>
                                    <Typography>tentativas de</Typography>
                                    <Typography style={{ fontWeight: '600' }}>redução</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Bagagem
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {`Classe: ${data[0].faresGroup[0].class[0]} (${data[0].faresGroup[0].classCode[0]})`}
                                </Typography>
                                <Typography>
                                    {` Possui babagem? ${data[0].faresGroup[0].isIncludeBaggage ? ('Sim') : ('Não')}`}
                                </Typography>
                                <Typography>
                                    {`Quantidade: ${data[0].faresGroup[0].quantityBaggage}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.map((flightItem, index) => (
                                    <Paper elevation={1} style={{ marginBottom: '20px' }}>
                                        <Grid container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                            <Grid item xs={12} style={{ background: 'none', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                <img height={80} src={flightItem.airCompanie.url} />
                                            </Grid>
                                            <Grid item xs={12} style={{ background: 'none' }} spacing={'3'}>
                                                <Grid container style={{ minHeight: '100%' }}>
                                                    <Grid item xs={12}>
                                                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Grid item xs={12}>
                                                                <Tooltip arrow name="descricaoTrechos" title={
                                                                    <React.Fragment>
                                                                        <Grid container >
                                                                            {flightItem.flightDescriptions.map((leg) => (
                                                                                <>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Typography variant='h5'>
                                                                                            <b>{leg.departureTime}</b>
                                                                                        </Typography>
                                                                                        <Typography variant='b'>
                                                                                            {leg.departure}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                                <img height={30} src={seta} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Typography variant='h5'>
                                                                                            <b>{leg.arrivalTime}</b>
                                                                                        </Typography>
                                                                                        <Typography variant='b'>
                                                                                            {leg.arrival}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            ))}
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                }>
                                                                    <Grid container>
                                                                        {flightItem.flightDescriptions.map((leg) => (
                                                                            <>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'>
                                                                                        <b>{leg.departureTime}</b>
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        {leg.departureCity}
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        ({leg.departure})
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: '0.7rem', textAlign: 'center' }}>
                                                                                        {leg.departureAirportCity}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                            <img height={30} src={seta} />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                            <Typography>
                                                                                                {leg.flightDuration}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'>
                                                                                        <b>{leg.arrivalTime}</b>
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        {leg.arrivalCity}
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        ({leg.arrival})
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: '0.7rem', textAlign: 'center' }} >
                                                                                        {leg.arrivalAirportCity}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                    </Grid>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                <Box className={classes.ccBox} style={{ background: `${props.getColor(props.opcoesUsadas.cartao.cardFlag).background}` }}>
                                    <img height={35} width={60} src={props.opcoesUsadas.cartao.urlFlag} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: '10px', textAlign: 'center', }}>
                                <Typography>
                                    {`${props.opcoesUsadas.cartao.cardFlag} ${props.opcoesUsadas.cartao.cardNumber}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    props.opcoesUsadas.parcela.quantity === 1 ? (
                                        <Typography style={{ textAlign: 'center' }}>
                                            {`Você pagará ${props.opcoesUsadas.parcela.quantity}x ${props.opcoesUsadas.parcela.installment}`}
                                        </Typography>
                                    ) : (
                                        <Box flexDirection={'row'} textAlign={'center'}>
                                            <Typography>
                                                {`Você pagará 1x de ${props.opcoesUsadas.parcela.firstInstallment} +`}
                                            </Typography>
                                            <Typography>
                                                {`${props.opcoesUsadas.parcela.quantity - 1}x de ${props.opcoesUsadas.parcela.installment}`}
                                            </Typography>
                                        </Box>
                                    )}
                            </Grid>
                            <Grid item xs={12} style={{ paddingTop: '1rem' }} >
                                <FormControlLabel
                                    control={
                                        <CustomCheckBox
                                            checked={props.state.acceptTerms}
                                            onChange={props.handleChangeState}
                                            name="acceptTerms"
                                            color="primary"
                                        />
                                    }
                                    label="Autorizo débito o no cartão selecionado conforme o contrato"
                                />

                            </Grid>
                            <Grid item xs={12} style={{ textAlign: '-webkit-center', paddingTop: '1rem' }} >
                                <Button
                                    onClick={() => props.getDocumentDownload()}
                                    className={classes.buttonContract}
                                    startIcon={<GetAppOutlined />}>
                                    Baixar contrato
                                </Button>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px', flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Box style={{
                                    width: '125px',
                                    height: '125px',
                                    border: '3px solid #612F74',
                                    borderRadius: '999px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                    <Typography variant={'h6'} style={{ color: '#612F74', fontWeight: 'bold' }}>
                                        {data[0].reductionAttempts}
                                    </Typography>
                                    <Typography>tentativas de</Typography>
                                    <Typography style={{ fontWeight: '600' }}>redução</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h6'>
                                    Bagagem
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography>
                                    {`Classe: ${data[0].faresGroup[0].class[0]} (${data[0].faresGroup[0].classCode[0]})`}
                                </Typography>
                                <Typography>
                                    {` Possui babagem? ${data[0].faresGroup[0].isIncludeBaggage ? ('Sim') : ('Não')}`}
                                </Typography>
                                <Typography>
                                    {`Quantidade: ${data[0].faresGroup[0].quantityBaggage}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {data.map((flightItem, index) => (
                                    <Paper elevation={1} style={{ marginBottom: '20px' }}>
                                        <Grid container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                                            <Grid item xs={12} style={{ background: 'none', display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                                <img height={80} src={flightItem.airCompanie.url} />
                                            </Grid>
                                            <Grid item xs={12} style={{ background: 'none' }} spacing={'3'}>
                                                <Grid container style={{ minHeight: '100%' }}>
                                                    <Grid item xs={12}>
                                                        <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
                                                            <Grid item xs={12}>
                                                                <Tooltip arrow name="descricaoTrechos" title={
                                                                    <React.Fragment>
                                                                        <Grid container >
                                                                            {flightItem.flightDescriptions.map((leg) => (
                                                                                <>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Typography variant='h5'>
                                                                                            <b>{leg.departureTime}</b>
                                                                                        </Typography>
                                                                                        <Typography variant='b'>
                                                                                            {leg.departure}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Grid container>
                                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                                <img height={30} src={seta} />
                                                                                            </Grid>
                                                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                        <Typography variant='h5'>
                                                                                            <b>{leg.arrivalTime}</b>
                                                                                        </Typography>
                                                                                        <Typography variant='b'>
                                                                                            {leg.arrival}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </>
                                                                            ))}
                                                                        </Grid>
                                                                    </React.Fragment>
                                                                }>
                                                                    <Grid container>
                                                                        {flightItem.flightDescriptions.map((leg) => (
                                                                            <>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'>
                                                                                        <b>{leg.departureTime}</b>
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        {leg.departureCity}
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        ({leg.departure})
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: '0.7rem', textAlign: 'center' }}>
                                                                                        {leg.departureAirportCity}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Grid container>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                            <img height={30} src={seta} />
                                                                                        </Grid>
                                                                                        <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                            <Typography>
                                                                                                {leg.flightDuration}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                                    <Typography variant='h5'>
                                                                                        <b>{leg.arrivalTime}</b>
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        {leg.arrivalCity}
                                                                                    </Typography>
                                                                                    <Typography variant='subtitle2'>
                                                                                        ({leg.arrival})
                                                                                    </Typography>
                                                                                    <Typography style={{ fontSize: '0.7rem', textAlign: 'center' }} >
                                                                                        {leg.arrivalAirportCity}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                        ))}
                                                                    </Grid>
                                                                </Tooltip>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
