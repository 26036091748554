import { Box, Button, Grid, Paper, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect } from "react";
import CustomBoxVideo from "../../../components/CustomBoxVideo";
import { useStyles } from "./styles";
import ZulminhaVideoMobi from "../../../images/Videos/zulminhaPageMobi.mp4";
import ZulminhaVideoTab from "../../../images/Videos/zulminhaPageMobi2.mp4";
import p1 from "../../../images/Marketing/comofunciona_passos/1.png";
import p2 from "../../../images/Marketing/comofunciona_passos/2.png";
import p3 from "../../../images/Marketing/comofunciona_passos/3.png";
import p4 from "../../../images/Marketing/comofunciona_passos/4.png";
import p5 from "../../../images/Marketing/comofunciona_passos/5.png";
import p6 from "../../../images/Marketing/comofunciona_passos/6.png";
import p7 from "../../../images/Marketing/comofunciona_passos/7.png";
import p8 from "../../../images/Marketing/comofunciona_passos/8.png";
import fofo2 from "../../../images/Marketing/bagagem_easy_2.png";
import whats from "../../../images/Marketing/whats_so_ela-faz.png";
import Carousel from "react-material-ui-carousel";
import { useThemeCustom } from "./theme";
import { useHistory } from "react-router";
import clsx from "clsx";

export const MobiZulminha = (props) => {
  const videoBackground = ZulminhaVideoMobi;
  const classes = useStyles();
  const theme = useThemeCustom;
  const history = useHistory();
  const tablet = useMediaQuery(theme.breakpoints.up("md"));

  const stepsImgTab = [
    {
      url1: p1,
      url2: p2,
    },
    {
      url1: p3,
      url2: p4,
    },
    {
      url1: p5,
      url2: p6,
    },
    {
      url1: p7,
      url2: p8,
    },
  ];

  const stepsImg = [
    {
      url: p1,
    },
    {
      url: p2,
    },
    {
      url: p3,
    },
    {
      url: p4,
    },
    {
      url: p5,
    },
    {
      url: p6,
    },
    {
      url: p7,
    },
    {
      url: p8,
    },
  ];

  useEffect(() => {
    if (window.location.href.includes("#bagagemEasy")) {
      var element = document.getElementById("bagagemEasy");
      element.scrollIntoView();
    }
  }, []);

  const handleTeste = () => {
    alert('inicio')
    const rVideo = document.getElementById("landingvideo");
    if (rVideo) {
      rVideo.pause();
      rVideo
        .play()
        .then((res) => {
          alert("playing start", res);
        })
        .catch((err) => {
          alert("error playing", err);
        });
    }
  }

  return (
    <div className={classes.container}>
      <Grid
        container
        style={{
          justifyContent: "center",
          marginBottom: "10px",
          maxWidth: "1100px",
        }}
      >
        <Box style={{ height: "383px", zIndex: 0, position: 'absolute' }}>
          <video
            autoPlay
            controls={false}
            muted
            playsInline
            webkit-playsinline={true}
            loop
            // autoplay="autoplay"
            // playsinline
            // preload="auto"
            // poster={videoBackground}
            poster={'https://linesturdiag.blob.core.windows.net/linestur-virtual/LinesturMidia/zulminhaPageMobi.mp4'}
            style={{ height: "383px" }}
          >
            <source
              // src={videoBackground}
              src={'https://linesturdiag.blob.core.windows.net/linestur-virtual/LinesturMidia/zulminhaPageMobi.mp4'}
              type="video/mp4"
            />
          </video>
        </Box>


        {/* {tablet ? (
          <Grid item xs={12} style={{ minWidth: "100vw" }}>
            <CustomBoxVideo
              video={ZulminhaVideoTab}
              style={{ height: "450px" }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} style={{ minWidth: "100vw" }}>
            <CustomBoxVideo
              video={ZulminhaVideoMobi}
              style={{ height: "383px" }}
            />
          </Grid>
        )} */}

        <Grid
          onClick={() => alert('3')}
          item
          xs={12}
          spacing={2}
          style={{ width: "100%", paddingTop: '383px' }}
          className={classes.customGrid02}
        >
          <Typography
            variant="h6"
            className={classes.typograph_01}
            style={{ margin: "20px 20px 0px 20px" }}
          >
            <div className={classes.bola}></div>É MUITO FÁCIL
            <div className={classes.bola}></div>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          className={classes.customGrid05}
          style={{ marginTop: "5px" }}
        >
          <Typography
            variant="h6"
            style={{ width: "100%", textAlign: "center" }}
            className={classes.typograph_02}
          >
            Você acompanha em tempo real as tentativas de redução
          </Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Typography className={classes.typograph_06}>
            A Linestur foi criada há mais de 12 anos com foco no turismo
            corporativo. Este nível de especialização nos desafiou a criarmos a
            nossa própria Startup, a qual proporcionou a construção da mais
            poderosa plataforma para gestão de viagens corporativas do mundo,
            onde se encontra a Zulminha, uma Inteligência Artificial encarregada
            de executar e supervisionar inúmeras funções, incluindo a ferramenta
            que tenta reduzir as tarifas. Esta ferramenta era disponibilizada
            apenas para o uso profissional, mas a partir de agora{" "}
            <b>você também pode!</b>
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ margin: "20px 0px 20px 0px" }}>
          <Carousel
            animation="slide"
            navButtonsAlwaysInvisible
            autoPlay
            interval={"7000"}
          >
            {tablet
              ? stepsImgTab.map((item) => (
                <Paper elevation={0} className={classes.customPaperCarousel}>
                  <img
                    src={item.url1}
                    height={200}
                    className={classes.img}
                    alt=""
                  />
                  <img
                    src={item.url2}
                    height={200}
                    className={classes.img}
                    alt=""
                  />
                </Paper>
              ))
              : stepsImg.map((item) => (
                <Paper elevation={0} className={classes.customPaperCarousel}>
                  <img
                    src={item.url}
                    height={200}
                    className={classes.img}
                    alt=""
                  />
                </Paper>
              ))}
          </Carousel>
        </Grid>

        <Grid
          item
          xs={12}
          spacing={2}
          className={classes.customGrid05}
          style={{ marginTop: "5px", marginBottom: "3px" }}
        >
          <Typography
            variant="h6"
            id="bagagemEasy"
            style={{
              width: "100%",
              textAlign: "center",
              backgroundColor: "#a0a1a4",
            }}
            className={classes.typograph_02}
          >
            A bagagem easy é mais uma das facilidades de nossa loja virtual
          </Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Typography className={classes.typograph_06}>
            Nesta grande novidade a Zulminha atua pré-selecionando todos os voos
            disponíveis no trecho escolhido, analisando de forma individual
            todas as possíveis variações de preços, já incluíndo em um único
            campo o valor das diferentes bagagens com as quantidades oferecidas,
            efetuando a atualização do preço final de acordo com sua escolha.
            Somente a linestur.com disponibiliza esta tecnologia descomplicando
            toda forma a atual que é ofertada na compra de aéreo.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ width: "100%", marginTop: "10px" }}
          className={classes.customGrid05}
        >
          <img
            alt=""
            src={fofo2}
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          id="whats"
          className={classes.gridBanner}
          style={{
            color: "gray",
            marginBottom: "-20px",
          }}
        >
          <Typography className={classes.textTitleBold}>Só ela faz</Typography>
        </Grid>

        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              Nossa I.A. é integrada ao WhatsApp o que possibilita notificações
              em tempo real para qualquer ação da Zulminha na tarifa garantida,
              isto ocorre até o encerramento do ciclo das tentativas de
              reduções, e finaliza encaminhando seu ticket com a justificativa
              de quanto foi o aumento e quantas vezes tentou reduzir.
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          style={{ width: "100%", marginTop: "10px" }}
          className={classes.customGrid05}
        >
          <img
            alt=""
            src={whats}
            width="100%"
            style={{ borderRadius: "10px" }}
          />
        </Grid>

        {/* <Grid item xs={12} spacing={2} style={{ width: '100%' }} className={classes.customGrid02} > */}
        {/* <Grid item xs={12} spacing={2} style={{ width: '100%' }} className={classes.customGrid02} >
                    <ul>
                        <li>
                            <Typography className={classes.typograph_07}>
                                A tarifa que você escolheu já está garantida;
                            </Typography>
                        </li>
                        <li>
                            <Typography className={classes.typograph_07}>
                                Escolha o método de pagamento;
                            </Typography>
                        </li>
                        <li>
                            <Typography className={classes.typograph_07}>
                                Você acompanha em tempo real a Zulminha na busca para reduzir;
                            </Typography>
                        </li>
                        <li>
                            <Typography className={classes.typograph_07}>
                                Caso encontre uma menor tarifa ela reduz a que esta garantia;
                            </Typography>
                        </li>
                        <li>
                            <Typography className={classes.typograph_07}>
                                Ela fica buscando e emite sua tarifa quando houver aumento de preço;
                            </Typography>
                        </li>
                        <li>
                            <Typography className={classes.typograph_07}>
                                Se houver redução no:
                            </Typography>
                            <ul>
                                <li>
                                    <Typography className={classes.typograph_07}>
                                        Cartão de credito será debitado o menor valor.
                                    </Typography>
                                </li>
                                <li>
                                    <Typography className={classes.typograph_07}>
                                        No Pix é cobrado o valor da tarifa garantida e havendo redução será efetuado cashback em até 24 horas.
                                    </Typography>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </Grid> */}

        <Grid item xs={12} spacing={2} className={classes.customGrid04}>
          <Grid container item xs={12} className={classes.customGridMain}>
            <Grid item xs={12} className={classes.customGrid03}>
              <Typography variant="h5" className={classes.typograph_08}>
                Precisa de ajuda?
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.customGrid03}>
              <Typography
                variant="h5"
                className={classes.typograph_08}
                onClick={() => history.push("/central-de-ajuda")}
              >
                <strong>Acesse aqui.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} className={classes.customGridMain}>
          <Box className={classes.boxCompanies}>
            <Typography className={classes.typograph_06}>
              {" "}
              Somos a primeira agência de <b>Viagens Virtual do Mundo</b>, e que
              possui inteligência artificial embarcada.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
