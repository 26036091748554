import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({

    background: {
        background: '#FFF7FF',
        color: '#612f74'
    },

    gridLogo: {
        textAlign: 'center'
    },

    gridFonte: {
        marginTop: '2.5rem'
    },

    appbarCustom: {
        marginTop: '4rem',
    },

}));
