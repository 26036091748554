
import Cookies from "universal-cookie";
import api from "../../Api";

const cookies = new Cookies();

const searchRFService = {

    postSearchCPF: async (dto) => {
        const response = await api.post('/ReceitaFederal/search-cpf', dto);
        return response;
    },

    postDocuments: async (dto) => {
        const response = await api.post('/ReceitaFederal/check-documents', dto, {
            headers: {
                Authorization: 'Bearer ' + cookies.get('token')
            }
        });
        return response;
    },

}

export default searchRFService;
