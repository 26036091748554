import {
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import logo from "./../../../images/Logos/logotipo_ff7500_fceefe_70x267.png";
import CustomTextFieldDark from "../../../components/CustomTextFieldDark";
import { LockOutlined } from "@material-ui/icons";

export const RecuperacaoSenhaDesk = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridCenterAlignedLogo}>
            <img src={logo} width="200px" alt="" />
          </Grid>

          <Grid item xs={12} className={classes.gridCenterAligned}>
            <CustomTextFieldDark
              disabled={props.isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
              className={classes.field}
              name="password"
              type="password"
              label="Nova senha"
              margin="normal"
              variant="filled"
              value={props.values.password || ""}
              onChange={(e) => props.handleChange(e)}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridCenterAligned}>
            <CustomTextFieldDark
              disabled={props.isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
              className={classes.field}
              name="confirmPassword"
              type="password"
              label="Confirmação de Senha"
              margin="normal"
              variant="filled"
              value={props.values.confirmPassword || ""}
              onChange={(e) => props.handleChange(e)}
            />
          </Grid>

          <Grid
            item
            xs={12}
            className={classes.gridCenterAligned}
            style={{ marginBottom: 0 }}
          >
            <Button
              disabled={props.isLoading}
              onClick={() => props.recoveryAccount()}
              className={classes.button}
            >
              {props.isLoading ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                <Typography>Atualizar senha</Typography>
              )}
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default RecuperacaoSenhaDesk;
