import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    buttonOrange: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    titleBar: {
        background: '#612f74',
        color: '#ffffff',
    }


}));
