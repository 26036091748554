import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
    },

    videoContainerVideo: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 0,
    },

    /* Just styling the content of the div, the magic in the previous rules */
    videoContainerCaption: {
        zIndex: 1,
        position: 'relative',
        textAlign: 'center',
        color: '#dc0000',
    }
}));
