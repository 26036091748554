import { Box, Button, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Slide, Typography, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { VerifiedUserOutlined } from '@material-ui/icons';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { creditCardService } from '../../../../services/CreditCard';
import { CreditCardAuthenticate } from '../../../CreditCardAuthenticate';
import { CreditCardDialog } from '../../../CreditCardDialog';
import { useStyles } from './styles';
import PaymentMethodSelect from '../../../../components/01_Raname-PaymentMethod';
import { IconsSource } from '../../../../images/Icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ListItemCustom = withStyles({
    root: {
        "&:hover": {
            backgroundColor: "#fceefe",
            cursor: 'pointer',
        }
    },
})(ListItem);

export const BuyingDeskStep02 = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [listCreditCard, setListCreditCard] = useState([]);
    const [valueCC, setValueCC] = useState('');
    const [openDialogCC, setOpenDialogCC] = useState(false);
    const [id_CreditCard, setId_CreditCard] = useState(props?.id_CreditCard ? props.id_CreditCard : 0);
    const [openCCAuth, setOpenCCAuth] = useState(false);
    const data = props.data;

    const handleChangeCC = (event) => {
        setValueCC(event.target.value);
    };

    const getListCreditCard = async () => {
        try {
            props.setIsLoading(true)
            let response = await creditCardService.getListCreditCard();
            setListCreditCard(response.data.results)
        } catch (error) {
        } finally {
            props.setIsLoading(false)
        }
    }

    const handleOpenCreditCardDialog = () => {
        setOpenDialogCC(true)
    }

    const attData = () => {
        getListCreditCard()
        setValueCC('')
        props.setOpcoesUsadas({ ...props.opcoesUsadas, cartao: {} })
    }

    const handleOpenCCAuth = (id) => {
        setOpenCCAuth(true)
        setId_CreditCard(id)
    }

    useLayoutEffect(() => {
        if (props.opcoesUsadas.cartao.id_CreditCard) {
            setValueCC(props.opcoesUsadas.cartao.id_CreditCard)
        }
        getListCreditCard()
    }, [])

    useEffect(() => {
        if (props.openCCAuth === true) {
            setOpenCCAuth(props.openCCAuth)
            props.setOpenCCAuth(false)
        }
    }, [props.openCCAuth])

    return (
        <>
            <CreditCardAuthenticate {...{
                setOpen: setOpenCCAuth,
                open: openCCAuth,
                id: valueCC,
                attData
            }} />
            <CreditCardDialog {...{ open: openDialogCC, setOpen: setOpenDialogCC, getListCreditCard }} />
            <Grid container>
                <Grid item xs={12} style={{ textAlign: 'center', marginTop: "10px" }}>
                    <Button className={classes.buttonSave} onClick={() => handleOpenCreditCardDialog()}> Cadastrar cartão</Button>
                </Grid>

                <Grid item xs={12}>
                    <Grid item xs={12} style={{ marginTop: "19px" }}>
                        <Typography style={{ color: "#555555" }}>Métodos de pagamento: </Typography>
                    </Grid>
                    <List aria-label={'lista de cartao'}>
                        <RadioGroup aria-label="creditCard" name="creditCard" value={valueCC} onChange={handleChangeCC}>
                            <ListItemCustom style={{ border: "1px solid #612F74", borderRadius: "6px", padding: "18px", marginTop: "14px", maxHeight: "100px" }} onClick={() => { setValueCC("pixpayment"); props.handleClickSelectPixPayment(data); props.setFormPaymentSelected("pixpayment") }}>
                                <Radio
                                    value={"pixpayment"}
                                    checked={valueCC === "pixpayment"}
                                    // onClick={() => { setValueCC(cc.id_CreditCard) }}
                                    onClick={() => { setValueCC("pixpayment"); props.handleClickSelectPixPayment(data); props.setFormPaymentSelected("pixpayment") }}
                                    style={{ color: '#612F74' }}
                                />
                                <ListItemAvatar style={{ width: '20px', textAlign: 'center' }}>
                                    <Box className={classes.ccBox} >
                                        <img height={50} width={50} src={IconsSource.pixIcon} />
                                    </Box>
                                </ListItemAvatar>
                                <ListItemText primary={<b>Pix</b>} secondary={"O Poder e a Simplicídade do Pix."} style={{ marginLeft: '10px' }} />
                            </ListItemCustom>
                            {listCreditCard.map((cc) => (
                                <>
                                    <ListItemCustom onClick={() => { setValueCC(cc.id_CreditCard); props.handleClickSelectCreditCard(cc.id_CreditCard, data, cc); props.setFormPaymentSelected("card") }} style={{ border: "1px solid #612F74", borderRadius: "6px", padding: "22px", maxHeight: "100px", marginTop: "13px" }}>
                                        <Radio value={cc.id_CreditCard} checked={valueCC === cc.id_CreditCard} onClick={() => { setValueCC(cc.id_CreditCard) }} style={{ color: '#612F74' }} />
                                        <ListItemAvatar style={{ width: '20px', textAlign: 'center' }}>
                                            <Box className={classes.ccBox} style={{ background: `${props.getColor(cc.cardFlag).background}` }}>
                                                <img height={25} width={50} src={cc.urlFlag} />
                                            </Box>
                                        </ListItemAvatar>
                                        <ListItemText primary={<b>{cc.surname}</b>} secondary={cc.cardNumber} style={{ marginLeft: '14px' }} />
                                        <Grid item xs={2}>
                                            <IconButton
                                                disabled={cc.isAuthenticated === 1 || cc.isAuthenticated === 2}
                                                onClick={() => handleOpenCCAuth(cc.id_CreditCard)}
                                                style={cc.isAuthenticated === 1 ?
                                                    { color: 'green' } :
                                                    { color: 'black' }}
                                            >
                                                <VerifiedUserOutlined />
                                            </IconButton>
                                        </Grid>
                                        {/* <Grid container>
                                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>

                                            </Grid>
                                            <Grid item container xs={12} style={{ textAlign: 'center' }}>
                                                <Grid item xs={10}>
                                                    <Typography>
                                                        {`${cc.surname}`}
                                                    </Typography>
                                                    <Typography>
                                                        {`${cc.cardNumber}`}
                                                    </Typography>
                                                </Grid>

                                            </Grid>
                                        </Grid> */}
                                    </ListItemCustom>
                                </>
                            ))}
                        </RadioGroup>
                    </List>
                </Grid>
            </Grid>
        </>
    )
}
