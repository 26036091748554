import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { creditCardService } from "../../../services/CreditCard";
import { useStyles } from "./styles";
import Cards from "react-credit-cards";
import 'react-credit-cards/es/styles-compiled.css';
import CustomTextFieldMasked from "../../../components/CustomTextFieldMasked";
import CustomTextFieldDark from "../../../components/CustomTextFieldDark";
import { CancelOutlined, SaveOutlined } from "@material-ui/icons";

export const CreditCardRegiser = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [state, setState] = useState({
        surname: "",
        cardName: "",
        cardNumber: "",
        cardValidity: "",
        cvv: "",
        cardCpf: "",
        cardFlag: "",
        focus: "",
    });

    const handleCloseDialog = () => {
        props.setOpenDialog(false);
    }

    const handleChange = (event) => {
        if (event.target.name === "name") {
            if (!isNaN(event.target.value) && event.target.value !== "") {
                return;
            }
        }
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
    };

    const converteDataCartao = (dataCartao = "00/00") => {
        const mes = Number(dataCartao.substr(0, 2));
        const ano = Number("20" + dataCartao.substr(3, 2));
        let dataFormatada = new Date(ano, mes, 0);
        return dataFormatada;
    };

    const bandeirasCartao = [
        {
            title: "AMEX",
            regex: /^3[47][0-9]{13}$/,
            result: "AX",
        },
        {
            title: "DINERS",
            regex: /'(36[0-8][0-9]{3}|369[0-8][0-9]{2}|3699[0-8][0-9]|36999[0-9])/,
            result: "DC",
        },
        {
            title: "DISCOVER",
            regex: /^6(?:011|5[0-9]{2})[0-9]{12}/,
            result: "EL",
        },
        {
            title: "ELO",
            regex: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
            result: "EL",
        },
        {
            title: "HIPERCARD",
            regex: /^606282|^3841(?:[0|4|6]{1})0/,
            result: "HC",
        },
        {
            title: "MASTERCARD",
            regex: /^((5(([1-2]|[4-5])[0-9]{8}|0((1|6)([0-9]{7}))|3(0(4((0|[2-9])[0-9]{5})|([0-3]|[5-9])[0-9]{6})|[1-9][0-9]{7})))|((508116)\\d{4,10})|((502121)\\d{4,10})|((589916)\\d{4,10})|(2[0-9]{15})|(67[0-9]{14})|(506387)\\d{4,10})/,
            result: "CA",
        },
        {
            title: "VISA",
            regex: /^4[0-9]{15}$/,
            result: "VI",
        },
    ];

    const validarCartao = (nCartao) => {
        let sigla = bandeirasCartao.find((item) => item.regex.test(nCartao)) || {};
        setState({ ...state, cardFlag: sigla })
        return sigla || "";
    };

    const handleInputFocus = (e) => {
        setState({ ...state, focus: e.target.name });
    };

    const handleClickSave = async () => {

        if (!state.cardName) {
            props.setSnackVariant("error");
            props.setSnackMessage("Nome não pode ser vazio!");
            props.setSnackVisible({ visible: true });
            return

        }
        if (!state.cardCpf) {
            props.setSnackVariant("error");
            props.setSnackMessage("CPF não pode ser vazio!");
            props.setSnackVisible({ visible: true });
            return
        }
        if (!state.cardNumber) {
            props.setSnackVariant("error");
            props.setSnackMessage("Número não pode ser vazio!");
            props.setSnackVisible({ visible: true });
            return
        }
        if (!state.cardValidity) {
            props.setSnackVariant("error");
            props.setSnackMessage("Data de validade não pode ser vazio!");
            props.setSnackVisible({ visible: true });
            return
        }

        let cartaoValido = validarCartao(state.cardNumber);

        if (!cartaoValido) {
            props.setSnackVariant("error");
            props.setSnackMessage("Cartão inválido!");
            props.setSnackVisible({ visible: true });
            return;
        }

        let dataValida = converteDataCartao(state.cardValidity);

        try {
            let dto = {
                surname: state.surname,
                cardName: state.cardName,
                cardNumber: state.cardNumber,
                cardValidity: dataValida,
                cvv: state.cvv.trim(),
                cardFlag: cartaoValido.title,
                cardCpf: state.cardCpf,
            };
            props.setIsLoading(true);
            let response = await creditCardService.postCreateCreditCard(dto);
            if (response.status === 201) {
                props.setSnackVariant("success");
                props.setSnackMessage("Cartão salvo com sucesso.");
                props.setSnackVisible({ visible: true });
                handleCloseDialog();
                props.getListCard();
            }
        } catch (e) {
            props.setSnackVariant("error");
            props.setSnackMessage("Erro ao salvar");
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <>
            <Dialog
                open={props.openDialog}
                onClose={handleCloseDialog}
                maxWidth={'md'}
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Typography className={classes.typoTitle}>Cadastrar cartão</Typography>
                </DialogTitle>
                <DialogContent className={responsive ? null : classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={responsive ? 12 : 6} className={classes.gridCard}>
                            <Cards
                                placeholders={{
                                    name: 'SEU NOME AQUI',
                                }}
                                locale={{ valid: 'Data de Validade' }}
                                cvc={state.cvv}
                                expiry={state.cardValidity}
                                focused={state.focus}
                                name={state.cardName}
                                number={state.cardNumber}
                            />
                        </Grid>
                        <Grid container item xs={responsive ? 12 : 6}>
                            <Grid item xs={12} >
                                <Typography variant="h6">Dados do cartão</Typography>
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemCustom}>
                                <CustomTextFieldDark
                                    name="surname"
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChange}
                                    value={state.surname}
                                    style={{ width: "100%" }}
                                    label="Apelido"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemCustom}>
                                <CustomTextFieldDark
                                    required
                                    name="cardName"
                                    variant="outlined"
                                    margin="dense"
                                    onFocus={handleInputFocus}
                                    onChange={handleChange}
                                    value={state.cardName}
                                    style={{ width: "100%" }}
                                    label="Nome do titular no cartão"
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemCustom}>
                                <CustomTextFieldMasked
                                    required
                                    mask="999.999.999-99"
                                    maskChar=""
                                    name="cardCpf"
                                    label="CPF"
                                    value={state.cardCpf || ""}
                                    onChange={handleChange}
                                    onFocus={handleInputFocus}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.gridItemCustom}>
                                <CustomTextFieldMasked
                                    required
                                    mask="9999999999999999"
                                    maskChar={null}
                                    name="cardNumber"
                                    label="Nº do cartão"
                                    value={state.cardNumber}
                                    onChange={handleChange}
                                    onFocus={handleInputFocus}
                                />
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={6} style={{ paddingRight: '5px' }} className={classes.gridItemCustom}>
                                    <CustomTextFieldMasked
                                        required
                                        mask="99/99"
                                        maskChar=""
                                        formatChars={{
                                            9: "[0-9]",
                                            "/": "",
                                        }}
                                        name="cardValidity"
                                        label="Validade"
                                        value={state.cardValidity}
                                        onChange={handleChange}
                                        onFocus={handleInputFocus}
                                    />
                                </Grid>
                                <Grid item xs={6} style={{ paddingLeft: '5px' }} className={classes.gridItemCustom}>
                                    <CustomTextFieldMasked
                                        required
                                        mask="999?"
                                        formatChars={{
                                            9: "[0-9]",
                                            "?": "[0-9]",
                                        }}
                                        maskChar=""
                                        name="cvv"
                                        label="CVV"
                                        value={state.cvv}
                                        onFocus={handleInputFocus}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    <Button onClick={() => handleClickSave()} className={classes.buttonSave} startIcon={<SaveOutlined />}>Salvar</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
