import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  InputAdornment,
  Slide,
} from "@material-ui/core";
import { AccountCircleOutlined, LockOutlined } from "@material-ui/icons";
import CustomTextFieldDark from "../../../../../components/CustomTextFieldDark";
import CustomizedSnackbars from "../../../../../components/CustomSnackBar";
import authServices from "../../../../../services/Auth";
import React from "react";
import { useStyles } from "./styles";
import { useState } from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogFastLogin = (props) => {
  const classes = useStyles();
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [values, setValues] = useState({
    user: "",
    password: "",
  });

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      await logar();
    }
  };

  const logar = async () => {
    if (
      values?.user === "" ||
      values?.user === null ||
      values?.user === undefined
    ) {
      setSnackVariant("info");
      setSnackMessage("Campo usuário não pode ser vazio.");
      setSnackVisible({ visible: true });
      return;
    }
    if (
      values?.password === "" ||
      values?.password === null ||
      values?.password === undefined
    ) {
      setSnackVariant("info");
      setSnackMessage("Campo senha não pode ser vazio.");
      setSnackVisible({ visible: true });
      return;
    }

    let dto = null;
    let response = null;
    try {
      dto = {
        login: values.user,
        password: values.password,
      };
      response = await authServices.postLogin(dto);
      props.setOpen(false);
      props.getPersonAccount();
    } catch (e) {
      if (e.response.status === 401) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
        return;
      }
      setSnackVariant("error");
      setSnackMessage("Erro tentar entrar.");
      setSnackVisible({ visible: true });
    }
  };

  const handleClose = () => {
    props.handleCloseMain(false);
    props.setOpen(false);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <Dialog
        open={props.open}
        // open={true}
        TransitionComponent={Transition}
        keepMounted
        id="id-dialog-fastlogin"
        maxWidth="xs"
        fullWidth
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle id="alert-dialog-title"><Typography>Entrar</Typography></DialogTitle> */}
        <DialogContent>
          <Grid container spacing={2} className={classes.gridCustom}>
            <Grid item xs={12}>
              <CustomTextFieldDark
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleOutlined />
                    </InputAdornment>
                  ),
                }}
                className={classes.field}
                name="user"
                onKeyPress={(e) => onKeyPress(e)}
                label="Usuário ou E-mail"
                margin="dense"
                variant="filled"
                value={values.user || ""}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomTextFieldDark
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined />
                    </InputAdornment>
                  ),
                }}
                className={classes.field}
                name="password"
                onKeyPress={(e) => onKeyPress(e)}
                type="password"
                label="Senha"
                margin="dense"
                variant="outlined"
                value={values.password || ""}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button onClick={() => logar()} className={classes.button}>
                Entrar
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Button
                onClick={() => props.cadastreSe()}
                className={classes.button}
              >
                Cadastra-se
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
                    <Button onClick={() => handleClose()}><Typography>{props.buttonMessage}</Typography></Button>
                </DialogActions> */}
      </Dialog>
    </>
  );
};
