import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {},

  button: {
    borderRadius: "15px",
    marginBottom: "10px",
    border: "1px solid black",
    textTransform: "lowercase",
  },
}));
