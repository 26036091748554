import { UseStyles } from "./styles";

export const getHelpText = () => {
  const classes = UseStyles();

  let result = [
    {
      titleGroup: "Utilização da Zulminha",
      itens: [
        {
          title: "Escolha seu voo",
          txt: "Para plena utilização da inteligência artifical é necessário que tenha em mente sua origem, destino e data, para realizar então uma busca pelos voos disponíveis e escolher um dentre estes.",
        },
        {
          title: "Cadastre-se",
          txt: "Após decidido qual o voo você deseja realizar, será necessário se cadastrar/entrar no sistema.",
        },
        {
          title: "Escolha o método de pagamento",
          txt: "Escolha/Cadastre o método de pagamento que será utilizado na tarifa escolhida.",
        },
        {
          title: "Garantir Tarifa",
          txt: "A partir da visualização da tela do bilhete, a tarifa que você escolheu já está garantida e buscando uma menor tarifa.",
        },
        {
          title: "Acompanhamento",
          txt: "Você pode acompanhar em tempo real a Zulminha na busca para reduzir a sua tarifa na tela do bilhete.",
        },
        {
          title: "Tentando reduzir",
          txt: "A I.A. fica buscando uma menor tarifa até encerrar o ciclo descrito na tela do bilhete.",
        },
        {
          title: "Reduziu",
          txt: "Caso encontre uma menor tarifa ela reduz o valor que estava garantido, gerando uma nova tarifa garantida.",
        },
        {
          title: "Reduziu no cartão",
          txt: "Quando o método de pagamento ser via cartão de crédito será debitado o valor da menor tarifa.",
        },
        {
          title: "Reduziu no PIX",
          txt: (
            <p className={classes.p}>
              No caso da forma de pagamento via PIX será cobrado o valor da
              tarifa inicial, e ao final do ciclo descrito na tela do bilhete se
              ocorrer redução, será realizado um <b>cashback</b> em até 24 horas
              para a <b>conta de origem</b>.
            </p>
          ),
        },
        {
          title: "Expansão",
          txt: "Ao final do ciclo descrito na tela do bilhete, existe a possiblidade de expansão do ciclo da tarifa garantida, totalizando no máximo noventa e sete mil novecentos e vinte (97.920) tentativas de redução.",
        },
        {
          title: "Ticket com variação",
          txt: "No final de todo o ciclo descrito na tela do bilhete, se ocorrer aumento na tarifa a Zulminha gera seu Ticket e aponta para quanto a tarifa subiu, justificando sua economia.",
        },
        {
          title: "Ticket sem variação",
          txt: "Seu ticket também será gerado caso não ocorra variação da tarifa garantida, quando totalizar o máximo de tentativas disponíveis.",
        },
        {
          title: "Tentativas minímas",
          txt: "Garantimos no mínimo o número de tentativas de redução descrito na tela do bilhete, podendo ter um aumento ao fim de cada ciclo.",
        },
        {
          title: "Notificações",
          txt: "Todas as ações efetudas na sua tarifa garantida serão notificadas via whats e/ou e-mail de forma personaliza, inclusive o encaminhamento de seu ticket.",
        },
      ],
    },
    {
      titleGroup: "Política de privacidade",
      itens: [
        {
          title: "Nossa política e a proteção dos seus dados",
          txt: (
            <>
              <p className={classes.p}>
                A Linestur, alinhada à cultura mundial de Proteção de Dados
                Pessoais, está comprometida em resguardar a privacidade de dados
                de seus clientes, consumidores, fornecedores, colaboradores e de
                todos aqueles com os quais possui relacionamento (“Usuários”),
                garantindo a transparência, a não discriminação e o livre
                acesso, adotando medidas de prevenção de ocorrência de danos,
                bem como adequando seus processos de tratamento de dados às
                necessidades e finalidades exigidas para a realização de seu
                propósito como empresa.{" "}
              </p>
              <p className={classes.p2}>
                Este documento tem por finalidade proporcionar um claro
                entendimento de como é feito o tratamento de dados pessoais,
                seja no momento da coleta, utilização, armazenamento,
                compartilhamento, descarte e quaisquer outras atividades
                consideradas em nosso contexto operacional interno e externo.{" "}
              </p>
              <p className={classes.p2}>
                A nossa Política de Privacidade abarcará todos os produtos e
                serviços que podem ser encontrados no site da Linestur, ou seja,
                você pode consultá-la para tirar suas dúvidas sobre como seus
                dados são utilizados.{" "}
              </p>
              <p className={classes.p2}>
                A nossa razão social é Linestur Viagens e Turismo LTDA, nosso
                CNPJ é 11.784.358/0001-79 e o endereço Rua Itajai, 410, sala 04
                – Centro em Criciúma / SC.
              </p>
            </>
          ),
        },
        {
          title: "Conceitos Iniciais",
          txt: (
            <>
              <p className={classes.p}>
                Antes de apresentar o modo como cuidamos dos seus dados, é
                importante deixar claro os principais conceitos que serão
                utilizados nesta política:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    <b>Agente de Tratamento</b>
                  </p>
                  <p className={classes.p}>
                    O nosso papel enquanto agente de tratamento de dados é,
                    predominantemente, de Controlador, nos termos do artigo 5º,
                    VI, da Lei nº 13.709/2018 - Lei Geral de Proteção de Dados
                    Pessoais (“LGPD”).
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Titulares dos Dados</b>
                  </p>
                  <p className={classes.p}>
                    Os titulares dos dados pessoais, conforme o artigo 5º, V, da
                    LGPD, são todas as pessoas naturais (pessoas físicas) de
                    cujos dados tratamos.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Tratamento dos Dados</b>
                  </p>
                  <p className={classes.p}>
                    São consideradas como tratamento de dados pessoais, de
                    maneira exemplificativa, as atividades de coleta, produção,
                    recepção, classificação, utilização, acesso, reprodução,
                    transmissão, distribuição, processamento, arquivamento,
                    armazenamento, eliminação, avaliação ou controle da
                    informação, modificação, comunicação, transferência, difusão
                    ou extração de dados pessoais das pessoas consideradas como
                    titulares, conforme artigo 5º, X, da LGPD.
                  </p>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Princípios",
          txt: (
            <>
              <p className={classes.p}>
                Atendendo ao disposto no artigo 6º da LGPD, todo o tratamento de
                dados pessoais realizado pela Linestur é feito em estrita
                conformidade com os seguintes princípios:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    <b>Finalidade: </b>todo o tratamento de dados que promovemos
                    tem propósitos legítimos, específicos e constantes desta
                    Política;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Adequação: </b>todo o tratamento de dados que promovemos
                    é compatível com as finalidades informadas nesta Política;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Necessidade: </b>limitamos o tratamento de dados
                    realizado ao mínimo necessário para a realização das
                    finalidades constantes desta política, abrangendo apenas os
                    dados pertinentes, proporcionais e não excessivos;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Livre Acesso: </b>os Usuários, por meio do próprio site,
                    têm acesso à consulta facilitada e gratuita sobre a forma e
                    a duração do tratamento de seus dados pessoais, bem como
                    sobre a integralidade de seus dados pessoais.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Qualidade de Dados: </b>garantimos, aos Usuários, a
                    exatidão, clareza, relevância e atualização dos dados, de
                    acordo com a necessidade e para o cumprimento da finalidade
                    do tratamento realizado;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Transparência: </b>garantimos aos Usuários informações
                    claras, precisas e facilmente acessíveis acerca da
                    realização do tratamento e dos respectivos agentes de
                    tratamento;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Segurança: </b>utilizamos medidas técnicas e
                    administrativas aptas a proteger os dados pessoais de
                    acessos não autorizados e de situações acidentais ou
                    ilícitas de destruição, perda, alteração, comunicação ou
                    difusão;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Prevenção: </b>por meio de programas de compliance,
                    treinamentos e da utilização de ferramentas tecnológicas,
                    adotamos medidas capazes de prevenir a ocorrência de danos
                    em virtude do tratamento de dados pessoais;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Não Discriminação: </b>a Linestur jamais realiza
                    tratamento de dados para fins discriminatórios ilícitos ou
                    abusivos.
                  </p>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Dados pessoais tratados",
          txt: "Para que possamos executar nossos serviços, é necessário que coletemos alguns dos seus dados pessoais, como nome, número do documento de identificação, endereço, dados do navegador, entre outros. Os dados coletados são fundamentais para que a Linestur continue prestando com qualidade os serviços a que se propõe, conferindo segurança de todas as informações que trata.",
        },
        {
          title: "Como, Quando e Quais Dados Coletamos",
          txt: (
            <>
              <ul className={classes.p}>
                <li className={classes.p}>
                  <p className={classes.p}>
                    <b>Informações Solicitadas: </b>Ao realizar cadastro em
                    nosso website, o usuário deve fornecer informações pessoais
                    essenciais para que seja possível realizar transações
                    comerciais conosco. No caso de pessoa física, é obrigatório
                    o fornecimento das informações corretas de nome, sobrenome,
                    data de nascimento, sexo, CPF, telefone fixo, celular,
                    e-mail, senha para login e dados de pagamento em caso de
                    finalização de pedido de compra. Se pessoa jurídica, é
                    necessário fornecer nome do responsável pela empresa,
                    sobrenome, data de nascimento, sexo, razão social da
                    empresa, nome fantasia, CNPJ, telefone fixo, celular,
                    e-mail, senha para login e dados de pagamento em caso de
                    finalização de pedido de compra. Caso as informações
                    solicitadas para cadastro não sejam fornecidas, o usuário
                    não poderá dar prosseguimento a processos de cadastro e
                    compra em nosso website. Ao fornecer as informações
                    necessárias e consequentemente concordar com a presente
                    Política de Privacidade, o usuário autoriza o uso desses
                    dados para efetuar transações solicitadas por ele mesmo,
                    fazer reservas de passagens aéreas emitidas por milhas,
                    enviar e-mails de notificação de compra, processar
                    faturamentos e para que possamos entrar em contato quando
                    necessário, através de nosso serviço de atendimento ao
                    consumidor;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Informações Coletadas: </b>Dentro do que é permitido em
                    lei, a Linestur poderá obter informações adicionais sobre
                    seus usuários fornecidas através de bases de dados públicas,
                    informações coletadas durante ligações telefônicas ao nosso
                    serviço de atendimento ao consumidor ou através de
                    aplicações interativas. Toda informação coletada será
                    utilizada única e exclusivamente para traçar o perfil de
                    cada usuário e assim adaptar nossas ações de marketing e
                    desenvolvimento de novos produtos e serviços, às suas
                    necessidades e interesses. O armazenamento e uso dessas
                    informações adicionais também obedecem às disposições desta
                    Política de Privacidade.
                  </p>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Visitantes do site",
          txt: "Ao visitar o nosso Site, nós coletamos seus dados por meio de cookies que são instalados em seu computador para personalizar sua navegação e oferecer a experiência adequada aos seus interesses e necessidades. Esses dados se referem ao tipo de sistema operacional, ao navegador e aos equipamentos que utiliza, à sua localização, ao seu endereço de IP, ao tempo de visita e ao percurso da sua navegação. Além disso, caso opte por se inscrever no nosso sistema de mailing , também coletaremos seu nome e seu e-mail.",
        },
        {
          title: "Clientes da Linestur",
          txt: (
            <>
              <p className={classes.p}>
                Se você é nosso cliente, teremos algumas fases diferentes
                durante o processo.
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    <b>Cadastro no Site: </b>Durante o cadastro, para que
                    possamos prosseguir com a utilização dos nossos serviços, é
                    necessário conhecer você um pouco melhor. Para isso,
                    solicitamos que preencha seu nome; sua data de nascimento; o
                    gênero com o qual se identifica; o número do seu CPF; seu
                    endereço; número de telefone fixo e celular; seu e-mail e
                    uma senha para login.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Compra de Produtos e Serviços: </b>Quando você decide
                    adquirir uma passagem, fazer a reserva em uma hospedagem ou
                    reservar um pacote, será necessário identificar todos
                    aqueles que utilizarão os produtos ou serviços, bem como
                    identificar os dados da forma de pagamento, como os dados do
                    cartão, por exemplo.
                  </p>
                  <p className={classes.p2}>
                    Já para o aluguel de carro, será necessário o nome completo,
                    e-mail e CPF. Por fim, para o seguro- viagem, vamos
                    solicitar os nomes de todos que utilizarão o serviço, assim
                    como a data de nascimento e um documento de identificação.
                    <p />
                    <p className={classes.p2}>
                      Para que sua compra seja feita em segurança, nós
                      utilizamos mecanismos de confirmação da titularidade do
                      comprador. Ou seja, para evitar que aconteçam fraudes, nós
                      fazemos a verificação dos dados de quem está promovendo o
                      pagamento, evitando que seus dados sejam utilizados de
                      maneira inadequada por terceiros.
                    </p>
                    <p className={classes.p2}>
                      É importante deixar claro que nós sabemos que é comum que
                      a compra seja feita para terceiros. Pode ser uma viagem de
                      família ou para presentear um amigo. Contudo, para manter
                      nossa relação transparente, precisamos te alertar que é
                      sua responsabilidade garantir que os envolvidos, que
                      tiveram suas informações passadas para nós, estejam
                      cientes da sua ação e de acordo com nossa Política de
                      Privacidade.
                    </p>
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Atendimento: </b>Caso você queira entrar em contato com a
                    Linestur, o nosso setor de atendimento coleta seus dados de
                    cadastro; os dados dos serviços que você utilizou conosco; o
                    perfil da sua rede social (caso tenha entrado em contato
                    através dela); e a mensagem, o ID e a cidade, caso o contato
                    ocorra pela plataforma Reclame Aqui. Nos casos em que você
                    decida nos contatar por telefone, sempre será feita a
                    gravação do atendimento, a fim de resguardar os direitos do
                    Usuário da melhor forma possível. Essa gravação também
                    ocorrerá nas conversas decorrentes de contato pela internet.
                  </p>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Para quais fins coletamos",
          txt: "A Linestur está sempre comprometida com a regularidade dos procedimentos de tratamento de dados que realiza. Assim, para nós, é muito importante que você possa compreender de forma clara para quais finalidades coletamos seus dados pessoais, bem como quais são as bases legais utilizadas nos termos da LGPD.",
        },
        {
          title: "Bases legais",
          txt: "A grande maioria dos dados tratados pela Linestur são obtidos por meio de fornecimento do consentimento, da execução de contratos e do legítimo interesse, nos termos do artigo 7º, incisos I, V e IX, respectivamente, da LGPD.",
        },
        {
          title: "Consentimento",
          txt: (
            <>
              <p className={classes.p}>
                O consentimento livre, informado e inequívoco, é fornecido pelo
                Usuário quando este fornece os dados pessoais diretamente à
                Linestur, cadastrando-se no site, utilizando a plataforma
                (logs), e entrando em contato conosco.
              </p>
              <p className={classes.p2}>
                Os dados são indispensáveis, posto que, sem o cadastro e sem
                informar os dados relativos aos usuários do serviço e à forma de
                pagamento, a Linestur não poderia prosseguir com nenhum dos seus
                serviços, tampouco com a comercialização dos seus produtos.
              </p>
            </>
          ),
        },
        {
          title: "Legítimo interesse",
          txt: (
            <>
              <p className={classes.p}>
                Tendo em vista a impossibilidade de se obter o consentimento do
                Usuário nas situações em que a Linestur verifica a autenticidade
                e a titularidade do pagador, para conduzir os processos de
                reclamações, na condução dos dados dos colaboradores e em outras
                situações próprias da atuação da Linestur, faz-se necessário que
                nos valhamos do Legítimo Interesse.
              </p>
              <p className={classes.p2}>
                Nestas hipóteses, apenas os dados estritamente essenciais para
                os fins a que se destinam são tratados. De todo modo, o
                consentimento do Usuário será a forma primordial de tratamento,
                sendo o legítimo interesse restrito à necessidade premente da
                Linestur. Em caso de negativa do consentimento por parte do
                Usuário, este não poderá desempenhar a maioria das
                funcionalidades da Linestur, uma vez que, para atender às
                expectativas de nossos clientes, é necessário que tenhamos um
                registro sólido dos dados pessoais e financeiros no sistema.
              </p>
              <p className={classes.p2}>
                Assim, como forma de demonstrar o legítimo interesse da
                Linestur, desenvolvemos a seguinte metodologia:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    <b>Legitimidade do nosso interesse: </b>o tratamento dos
                    dados dos clientes que se relacionam conosco, em
                    determinados casos, decorre de interesse legítimo e
                    essencial para nossa atividade. Além disso, trata-se de
                    situação concreta de necessidade de realizar o tratamento
                    dos dados daquele Usuário.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Necessidade: </b>conforme já salientado, tratamos com
                    base no legítimo interesse os dados estritamente necessários
                    a situações ímpares vivenciadas pela Linestur a fim de
                    conferir legitimidade às respectivas condutas da empresa.
                    Daí em diante, seu consentimento permanece sendo necessário.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Balanceamento: </b>são assegurados aos Usuários todos os
                    direitos constantes da LGPD, bem como permanecemos à
                    disposição para sanar quaisquer dúvidas acerca desta
                    Política.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    <b>Salvaguardas: </b>todos os Usuários que tenham dados
                    tratados com base no legítimo interesse têm a opção de
                    entrar em contato com a Linestur e esclarecer o modo de
                    tratamento de seus dados pessoais.
                  </p>
                </li>
              </ul>
            </>
          ),
        },
        {
          title: "Execução de contrato",
          txt: (
            <>
              <p className={classes.p}>
                O tratamento em razão da execução de contrato ocorre, por
                exemplo, para gerenciar sua reserva, processar pagamentos ou
                criar uma conta sob sua solicitação. Portanto, ao finalizar a
                aquisição de algum produto ou serviço da Linestur, seus dados
                serão utilizados com a contraparte do serviço ou produto para
                que o contrato possa ser efetivado. Assim como no caso das
                demais bases não serão utilizados dados desnecessários para o
                tratamento, reforçando o compromisso da Linestur com esta
                Política de Privacidade e com a regulação legal da LGPD.
              </p>
              <p className={classes.p2}>
                Para além das bases mencionadas, o tratamento de dados que se
                ampare em alguma outra base legal será prontamente notificado ao
                titular. Em caso de dúvidas quanto às bases de tratamento, o
                e-mail thommas@linestur.com está disponível para
                esclarecimentos.
              </p>
            </>
          ),
        },
        {
          title: "Compartilhamento de terceiros",
          txt: (
            <>
              <p className={classes.p}>
                A Linestur compartilha seus dados pessoais com terceiros em
                estrita observância da LGPD e nos limites do que for realmente
                necessário para a atingimento dos seus fins.
              </p>
              <p className={classes.p2}>
                O compartilhamento de dados pessoais é feito com:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    Gateways de pagamento e instituições financeiras para
                    viabilizar a aquisição dos produtos e serviços oferecidos
                    por nós;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Escritório de advocacia terceirizado para defesa dos
                    interesses da Linestur em qualquer tipo de conflito,
                    mediante decisão judicial ou requisição de autoridade
                    competente;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Órgão ou entidade pública oficial, autoridade regulatória,
                    policial ou governamental para cumprir obrigações legais e
                    regulatórias, para fins de prevenção à lavagem de dinheiro e
                    outros ilícitos, em resposta a solicitações realizadas no
                    contexto de fiscalizações, auditorias, entre outros;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Empresa provedora de infraestrutura tecnológica e
                    operacional para fins de armazenamento/backup na nuvem, com
                    o intuito de garantir uma maior capacidade de armazenamento,
                    tempo de resposta e segurança para os dados tratados em
                    nossa organização;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Empresas parceiras comerciais como agências de turismo,
                    locação de veículos, passagens aéreas e hotéis, que
                    comercializam nossos serviços.
                  </p>
                </li>
              </ul>
              <p className={classes.p2}>
                Esclarecemos que nossos parceiros comerciais não podem utilizar
                os seus dados pessoais para outros fins que não sejam os
                necessários à execução dos serviços firmados com a Linestur por
                meio de contratos rigorosos.
              </p>
            </>
          ),
        },
        {
          title: "Segurança dos dados",
          txt: (
            <>
              <p className={classes.p}>
                A Linestur, para conseguir atender seus Usuários da melhor forma
                possível, armazena as informações coletadas em suas páginas em
                servidores próprios ou por ela contratados.
              </p>
              <p className={classes.p2}>
                Sempre pensando na segurança do armazenamento, nós tomamos as
                medidas adequadas e legalmente requeridas para preservar a
                privacidade dos dados coletados, implantando os sistemas de
                segurança necessários para tanto.
              </p>
              <p className={classes.p2}>
                Dentre outros pontos, a Linestur possui proteção contra acesso
                não autorizado a seus sistemas; somente autoriza o acesso de
                pessoas previamente cadastradas; e adota os melhores esforços
                para evitar vazamentos de dados. Contudo, por mais que façamos o
                possível para garantir a segurança, nós incentivamos os Usuários
                a tomarem as medidas apropriadas para se proteger, como, por
                exemplo, manter confidenciais todos os nomes de usuário e
                senhas.
              </p>
            </>
          ),
        },
        {
          title: "Direitos dos usuários da Linestur",
          txt: (
            <>
              <p className={classes.p}>
                Em cumprimento à regulamentação aplicável, no que diz respeito
                ao tratamento de dados pessoais, a Linestur respeita e garante
                ao Usuário a possibilidade de apresentação de solicitações
                baseadas nos seguintes direitos:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>
                    Confirmação da existência de tratamento;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>O acesso aos dados;</p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A correção de dados incompletos, inexatos ou desatualizados;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A anonimização, bloqueio ou eliminação de dados
                    desnecessários, excessivos ou tratados em desconformidade;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A portabilidade de seus dados a outro fornecedor de serviço
                    ou produto, mediante requisição expressa pelo Usuário;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A eliminação dos dados tratados com consentimento do
                    Usuário;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A obtenção de informações sobre as entidades públicas ou
                    privadas com as quais a Linestur compartilhou seus dados;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    A informação sobre a possibilidade de não fornecer o seu
                    consentimento, bem como de ser informado sobre as
                    consequências, em caso de negativa;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>A revogação do consentimento.</p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Informamos a utilização dos serviços de Cloud da AWS. Nessas
                    nuvens são armazenados alguns arquivos e dados em
                    Datacenters localizados fora do país.
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Para atendimento de seus direitos acesse este Central de
                    atendimento disponível em nosso site.
                  </p>
                </li>
              </ul>
              <p className={classes.p2}>
                Parte destes direitos poderá ser exercida diretamente pelo
                Usuário, a partir da gestão de informações sobre sua conta,
                enquanto outros dependerão do envio de solicitação para
                posterior avaliação e adoção das providências pela Linestur.
              </p>
            </>
          ),
        },
        {
          title: "Atualização da política",
          txt: "A nossa Política de Privacidade busca esclarecer o modo como atuamos e te deixar a par de todos os dados que tratamos, respeitando as disposições legais aplicáveis. Para manter a política sempre em dia, seja por fatores legais, comerciais ou tecnológicos, podemos atualizá-la quando necessário. Caso a alteração seja materialmente relevante, entraremos em contato para mantê-los informados. De todo modo, sugerimos que consultem a página habitualmente para que não percam nenhuma alteração.",
        },
        {
          title: "Fale conosco",
          txt: "Caso tenha ficado com alguma dúvida, ou queira esclarecer algum ponto, estamos inteiramente à disposição através de nossos canais de atendimento informados em nosso site. Outros assuntos relacionados à privacidade e dados pessoais podem ser tratados diretamente neste Central de atendimento ou através do e-mail thommas@linestur.com.",
        },
        {
          title: "Informação de contato",
          txt: (
            <ul>
              <li>
                <p ClassName={classes.p}>Encarregado de dados:</p>
                <p ClassName={classes.p}>Nome: Thommas Modesto Delluca</p>
                <p ClassName={classes.p}>E-mail: thommas@linestur.com</p>
              </li>
            </ul>
          ),
        },
      ],
    },
    {
      titleGroup: "Remarcação/Alteração",
      itens: [
        {
          title: "Solicitação",
          txt: (
            <>
              <p className={classes.p}>
                O procedimento de solicitação de remarcação/alteração depende
                das regras do bilhete adquirido. Para verificar se seu voo é
                elegível para esse procedimento, favor entrar em contato com
                nossa Central de atendimento via e-mail ou WhatsApp.
              </p>
              <p className={classes.p2}>
                <b>
                  “Importante: a remarcação está sujeita a cobrança de taxas,
                  conforme regra da companhia aérea”.
                </b>
              </p>
            </>
          ),
        },
      ],
    },
    {
      titleGroup: "Cancelamento/Reembolso",
      itens: [
        {
          title: "Solicitação",
          txt: (
            <>
              <p className={classes.p}>
                <b>
                  Para solicitar o cancelamento de um bilhete, o titular deve
                  acessar o Central de atendimento em nossa plataforma e
                  informar o código do pedido de compra e o e-mail do pagante.
                </b>
              </p>
            </>
          ),
        },
        {
          title: "Como é estipulado o valor da multa?",
          txt: "O valor líquido de reembolso é igual ao valor tarifa + taxas de embarque, subtraído do valor da multa cobrada. Em pedidos realizados com boleto parcelado, os valores da entrada e das demais parcelas já pagas serão somados e estornados para a conta bancária que o cliente informar, subtraído o valor da multa por cancelamento. O valor da multa pode ser consultado durante a finalização do seu pedido em nossa plataforma.",
        },
        {
          title: "Como funciona o processo de cancelamento?",
          txt: (
            <>
              <p className={classes.p}>
                <b>1. </b>Solicitações de cancelamentos feitas até 31/12/2021
                seguem as regras da Lei nº 14.174 e, portanto, contam com a
                regra dos 12 meses para reembolso, contados a partir da data do
                voo;
              </p>
              <p className={classes.p2}>
                <b>2. </b>Solicitações de cancelamentos feitas a partir de
                01/01/2022 seguem as regras conforme Medida Provisória nº 1.101
                da ANAC, que prevê o reembolso até 31 de dezembro de 2023.
              </p>
            </>
          ),
        },
        {
          title: "O reembolso não é corrigido pelo INPC.",
          txt: (
            <>
              <p className={classes.p}>
                Em caso de recebimento de reembolso,{" "}
                <b>
                  o valor é creditado de acordo com a forma de pagamento adotada
                  no pedido:
                </b>
              </p>
              <p className={classes.p2}>
                1. <b>Cartão de crédito:</b> em pedidos realizados via cartão de
                crédito, o valor referente ao reembolso é creditado para o mesmo
                cartão utilizado na compra. O prazo segue a Medida Provisória nº
                1.101, de 21 de fevereiro de 2022.
              </p>
              <p className={classes.p2}>
                2. <b>Pix:</b> para <b>pedidos cancelados</b> devido a não
                emissão do bilhete, o valor é devolvido para a mesma conta
                utilizada no ato do pagamento em nossa plataforma.
              </p>
              <p className={classes.p2}>
                3. <b>Pix:</b> para <b>pedidos já emitidos,</b> o valor é
                reembolsado para a conta bancária do titular pagante/cadastro. O
                prazo segue a Medida Provisória nº 1.101, de 21 de fevereiro de
                2022. Para saber mais, clique aqui. Para o recebimento dos
                valores, é necessário que nos encaminhe os dados bancários do
                titular pagante por meio de nossa central de ajuda.
              </p>
              <p className={classes.p2}>
                <b>Como é feito o reembolso / estorno via Pix?</b>
              </p>
              <p className={classes.p2}>
                Os dados bancários fornecidos para reembolso devem ser, da mesma
                titularidade do responsável pagante (quem realizou o pagamento
                do pedido de compra), contendo:
              </p>
              <ul>
                <li>
                  <p className={classes.p2}>Nome completo;</p>
                </li>
                <li>
                  <p className={classes.p2}>CPF;</p>
                </li>
                <li>
                  <p className={classes.p2}>Banco;</p>
                </li>
                <li>
                  <p className={classes.p2}>Agência;</p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Conta: ( ) Corrente ( ) Poupança;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>
                    Foto do comprovante de pagamento;
                  </p>
                </li>
                <li>
                  <p className={classes.p2}>Código do pedido da Linestur.</p>
                </li>
              </ul>
              <p className={classes.p2}>
                As informações devem ser encaminhadas via Central de atendimento
                em nosso site na área de “Solicitações Financeiras”, conforme o
                produto adquirido em nossa plataforma.
              </p>
              <p className={classes.p2}>
                <b>
                  As taxas referentes à inclusão de bagagens não são
                  reembolsadas em caso de cancelamento de bilhetes já emitidos.
                </b>
              </p>
            </>
          ),
        },
        {
          title: "Regras de cancelamento por Companhia Aérea",
          txt: "O usuário declara conhecer e concordar com o funcionamento das regras estipuladas acerca do cancelamento e reembolso dos bilhetes emitidos.",
        },
        {
          title: "Política de cancelamento e reembolso",
          txt: (
            <>
              <p className={classes.p}>
                Para cancelamentos solicitados que já tenham sido emitidas, o
                reembolso dependerá exclusivamente de cada companhia aérea e
                cada bilhete adquirido.
              </p>
            </>
          ),
        },
      ],
    },
  ];
  return result;
};
