import {
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { AccountCircleOutlined } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router";
import CustomTextFieldDark from "../../../components/CustomTextFieldDark";
import logo from "../../../images/Logos/logotipo_ff7500_fceefe_70x267.png";
import { useStyles } from "./styles";

export const EsqueceuSenhaDesk = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const handleChange = (event) => {
    props.setEmail(event.target.value);
  };

  return (
    <div className={classes.root}>
      <Card className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridCenterAlignedLogo}>
            <img src={logo} width="200px" alt="" />
          </Grid>

          {props.tokenSended ? (
            <>
              <Grid
                item
                xs={12}
                className={classes.gridItem}
                style={{
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  width={85}
                  src="https://play-lh.googleusercontent.com/D1Dz2BjPYev_oyksKXsdtAS66a_2Ql-sklpzTnwR9lqnDG_P5lAJEtfR70FudJ0XMA"
                  alt=""
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.gridItem}
                style={{
                  color: "black",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "8px",
                }}
              >
                <Typography
                  align="center"
                  variant="subtitle1"
                  style={{ fontSize: "1.2rem" }}
                  className={classes.field}
                >
                  Verifique o seu e-mail
                </Typography>
                <Typography
                  align="center"
                  variant="subtitle1"
                  style={{ fontSize: "1.0rem" }}
                  className={classes.field}
                >
                  <b>({props?.email})</b>
                </Typography>
                <Typography
                  align="center"
                  style={{ fontSize: "1.0rem" }}
                  variant="subtitle1"
                  className={classes.field}
                >
                  Enviamos um e-mail com um link de recuperação de senha.
                </Typography>
                <Typography
                  align="center"
                  style={{ fontSize: "0.7rem" }}
                  variant="subtitle1"
                  className={classes.field}
                >
                  O link tem validade de 30 minutos.
                </Typography>
                <Typography
                  align="center"
                  style={{ fontSize: "0.7rem" }}
                  variant="subtitle1"
                  className={classes.field}
                >
                  Troque sua senha antes do link expirar.
                </Typography>
                <Typography
                  align="center"
                  style={{ fontSize: "0.7rem" }}
                  variant="subtitle1"
                  className={classes.field}
                >
                  Não recebeu nenhuma mensagem? Verifique a pasta de Spam.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} className={classes.gridCenterAligned}>
                <CustomTextFieldDark
                  disabled={props.isLoading}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleOutlined />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.field}
                  name="email"
                  label="E-mail"
                  margin="normal"
                  variant="filled"
                  value={props.email || ""}
                  onChange={(e) => handleChange(e)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.gridCenterAligned}
                style={{ marginBottom: 0 }}
              >
                <Button
                  disabled={props.isLoading}
                  onClick={() => props.sendToken()}
                  className={classes.button}
                >
                  {props.isLoading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    <Typography>Enviar link</Typography>
                  )}
                </Button>
              </Grid>
            </>
          )}

          <Grid
            item
            container
            xs={12}
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
            className={classes.gridCenterAligned}
          >
            <Button
              onClick={() => history.push("/")}
              style={{ width: "40%" }}
              className={classes.button03}
            >
              Retornar
            </Button>
            <Button
              onClick={() => history.push("/entrar")}
              style={{ width: "40%" }}
              className={classes.button03}
            >
              Entrar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default EsqueceuSenhaDesk;
