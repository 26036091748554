import Cookies from "universal-cookie";
import api from "../../Api";

const cookies = new Cookies();

const authServices = {
  postLogin: async (dto) => {
    let cookies = new Cookies();
    let response = await api.get("/Auth/login", {
      headers: {
        login: dto.login,
        password: dto.password,
      },
    });
    cookies.set("token", response.data.results.token);
    return response;
  },

  getIsAuthenticate: async () => {
    let response = await api.get("/Auth/is-authenticate", {
      headers: {
        Authorization: "Bearer " + cookies.get("token"),
      },
    });
    return response;
  },

  putForgotPassword: async (email) => {
    let response = await api.put("/Auth/forgot-password", null, {
      headers: {
        email,
      },
    });
    return response;
  },
};

export default authServices;

export const getIsAuthenticate = async () => {
  return await api.get("/Auth/is-authenticate", {
    headers: {
      Authorization: "Bearer " + cookies.get("token"),
    },
  });
};
