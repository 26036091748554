import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    gridCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    grid: {
        maxWidth: '-webkit-fill-available',
        display: 'grid',
        gridTemplateColumns: '15% 70% 15%'
    },
    gridWithNoPromo: {
        maxWidth: '1100px',
        minHeight: '450px'
    },
    img: {
        maxWidth: '-webkit-fill-available',
    },
    divCentral: {
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    divPromo: {
        padding: '10px',
    }

}));
