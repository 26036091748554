import { LinearProgress, withStyles } from "@material-ui/core";

export const CustomProgressBar = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === "light" ? 200 : 700]
    },
    bar: {
        borderRadius: 5,
        backgroundColor: "#008000"
    }
}))(LinearProgress);
