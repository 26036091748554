import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },

    gridCenter: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        padding: '0px 8px 35px 8px',
    },
    gridCenterMobi: {
        display: 'grid',
        alignItems: 'center',
        justifyItems: 'center',
        padding: '10px 0px 10px 0px',
    },
    gridCenter2: {
        display: 'grid',
        alignItems: 'center',
        padding: '0px 8px 35px 8px',
    },
    gridCenter2Mobi: {
        display: 'grid',
        alignItems: 'center',
        padding: '10px 0px 10px 0px',
    },
    buttonSearch: {
        width: '100%',
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        color: 'white',
        '&:hover': {
            background:
                "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },
    dialogActions: {
        padding: '8px 24px',
    },
    dialogContentCustom: {
        padding: '0px',
        margin: '0px 20px 0px 20px',
        overflowX: 'hidden'
    },
}));
