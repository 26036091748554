import React from 'react';
import Routes from './routes';
import './styles.css';
import 'moment-timezone';
import { LicenseInfo } from '@material-ui/x-grid';
import currencyFormatter from 'currency-formatter';

LicenseInfo.setLicenseKey(
  '72dd8038d22d7f8a2514302cf2c7dc3bT1JERVI6MjIwMzcsRVhQSVJZPTE2NDY0MjY3MjkwMDAsS0VZVkVSU0lPTj0x',
);

Number.prototype.toFormatDefault = function () {
  return currencyFormatter.format(this, {
    symbol: '',
    decimal: ',',
    thousand: '.',
    precision: 2,
    format: '%v' // %s is the symbol and %v is the value
  });
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

function App(props) {
  return (
    <Routes {...props} />
  );
}

export default App;
