import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Fade, Grid, Grow, Paper, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import { getHelpText } from "../../../Files/help";
import { useStyles } from "./styles";

export const DeskHelp = (props) => {
    const classes = useStyles();
    const [grow, setGrow] = useState(true);
    const [fade, setFade] = useState(true);
    const [index, setIndex] = useState(0);

    const handleClickChangeIndex = (index) => {

        setFade(false)
        setIndex(index)
        setFade(true)
    }

    const testeAccc = getHelpText();

    const checkIconByIndex = (item) => {
        if (index === item) {
            return (
                <ExpandLessOutlined />
            )
        } else {
            return (
                <ExpandMoreOutlined />
            )
        }
    }



    return (
        <Grow in={grow} timeout={1000}>
            <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Grid item xs={12} className={classes.gridCenter}>
                    <Grid container>
                        <Grid container item xs={4} style={{ maxHeight: '296px' }}>
                            {testeAccc.map((item, actual) => (
                                <Grid item xs={12} onClick={() => handleClickChangeIndex(actual)} className={actual === index ? classes.gridTitleGroupSelected : classes.gridTitleGroup}>
                                    <Typography variant="h6">
                                        {item.titleGroup}
                                    </Typography>
                                    {checkIconByIndex(actual)}
                                </Grid>
                            ))}
                        </Grid>
                        <Grid container item xs={8}>
                            <Fade in={fade} timeout={1000} >
                                <Grid item xs={12}>
                                    {testeAccc[index].itens.map((item) => (
                                        <Accordion>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreOutlined />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header">
                                                <Typography>{item.title}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography align="justify">
                                                    {item.txt}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grow>
    )
}

