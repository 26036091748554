import { Box, Button, Chip, Grow, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./styles";
import { IconsSource } from "../../../images/Icons";

export const DeskListHistoric = (props) => {
    const classes = useStyles();
    const [grow, setGrow] = useState(true);

    return (
        <Grow in={grow} timeout={1000}>
            <>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={'25px'}>
                    <Typography variant={'h5'} style={{ color: 'rgba(97, 47, 116)' }}>Histórico</Typography>
                </Box>
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center"><Typography>Cia</Typography></TableCell>
                                <TableCell align="center"><Typography>Data do voo</Typography></TableCell>
                                <TableCell align="center"><Typography>Trechos</Typography></TableCell>
                                <TableCell align="center"><Typography>Status</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.data.length !== 0 ? null : (
                                <TableRow>
                                    <TableCell colSpan={7}>
                                        <Typography style={{ textAlign: 'center' }} variant={'h5'}> Não há histórico disponível</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                            {props.data.map((item) => (
                                <>
                                    <TableRow key={item.id_Booking} onClick={() => props.handleOpenTicket(true, item.id_Booking)} style={{ cursor: 'pointer' }}>
                                        <TableCell align="center" className={classes.gridCellCustom}>
                                            <img src={item.urlAirCompanie} className={classes.img} />
                                        </TableCell>
                                        <TableCell align="center" className={classes.gridCellCustom}>
                                            <Typography>{item.departureDate}</Typography>
                                        </TableCell>
                                        <TableCell align="center" className={classes.gridCellCustom}>
                                            <Typography>{item.route}</Typography>
                                        </TableCell>
                                        <TableCell align="center" className={classes.gridCellCustom}>
                                            <Chip label={item.status} style={{ background: '#ff7500', color: '#fff7ff' }} />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key={item.id_Booking} style={{ cursor: 'pointer' }}>
                                        {item.pix != null ? (
                                            <>
                                                <TableCell align="center" className={classes.gridCellCustom}>
                                                    <img src={IconsSource.pixIcon} className={classes.img} />
                                                </TableCell>
                                                <TableCell colSpan={2} align="center" className={classes.gridCellCustom}>
                                                    <Typography style={{ textAlign: 'center' }}>Há pix disponível para pagamento</Typography>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Button onClick={() => { props.setOpenPix(true); props.setIdBookingSelected(item.id_Booking) }} style={{ textAlign: 'center', backgroundColor: "#38c4d4", color: "#ffffff" }}>Pagar agora</Button>
                                                </TableCell>
                                            </>
                                        ) : (
                                            null
                                        )}
                                    </TableRow>
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </>
        </Grow >
    )
}
