import api from '../../../Api';

const searchFlightsService = {

    postSearchFlights: async (dto) => {
        const response = await api.post('/Air/search-flights', dto);
        return response;
    },

    getPromotionalFlights: async () => {
        let response = await api.get('/Air/promotional-flights');
        return response;
    },

    getPromotionalFlightsByCity: async (city) => {
        let response = await api.get(`/Air/promotional-flights?arrival=${city}`);
        return response;
    }

}

export default searchFlightsService;
