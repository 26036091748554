import { makeStyles } from "@material-ui/core/styles";


export const useStyles = makeStyles((theme) => ({
    buttonOrange: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    // large: {
    //     cursor: 'pointer',
    //     width: '4rem',
    //     height: '4rem',
    //     background: '#612F74',
    // },

    divScan: {
        transition: 'all .5s ease-in-out',
        width: '100px',
        height: '100px',
        backgroundColor: 'red',
        position: 'absolute',
        top: '50px',
        left: '50px',
    },

    div_circle01: {
        height: '50px',
        width: '50px',
        border: '3px solid #612F74',
        borderRadius: '1000px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    div_circle02: {
        height: '40px',
        width: '40px',
        border: '3px solid #612F74',
        borderRadius: '1000px',
    },

    button: {
        color: "white",
        fontWeight: "800",
        width: '160px',
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },


}));
