import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({

    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },

    img: {
        height: '70px',
        width: 'auto',
        margin: '10px'
    },

    img2: {
        height: '30px',
        width: 'auto',
        margin: '20px',
    },


    gridContainerMain: {
        background: '#fceefe',
        justifyContent: 'center',
        marginBottom: '10px',
        marginTop: '10px',
    },

    customGrid01: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        maxWidth: '70vw',
        minWidth: '70vw',
        marginTop: '20px'
    },

    customGrid02: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '70vw',
        minWidth: '70vw',
    },

    customGrid03: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        maxWidth: '70vw',
        minWidth: '70vw',
        marginTop: '20px'
    },
    typograph01: {
        color: '#716f71'
    },

    typograph02: {
        color: '#716f71',
        fontSize: '11pt'
    },

    typograph03: {
        color: '#716f71',
        fontSize: '16pt'
    }


}));
