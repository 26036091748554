import { Avatar, Box, IconButton } from "@material-ui/core";
import { DoneOutlined } from "@material-ui/icons";
import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import faceFixed from "../../../../../images/assets/face-fixed.gif";
import resizePhotoToPNG from "../../../../../utils/resizePhoto";
import { useStyles } from "../styles";

export const FacialBiometricsStep02 = (props) => {
    const classes = useStyles();
    const webcamRef = useRef(null);

    const videoConstraints = {
        width: window.innerWidth,
        height: window.innerWidth * 0.81,
        facingMode: "user"
        // facingMode: { exact: "environment" }
    };

    const CompressImg = async (imageSrc) => {
        let temp = await resizePhotoToPNG(
            imageSrc,
            450,
            550,
            80,
            450,
            550
        )
        props.handleInputChangeFacial(temp)
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot({ width: 450, height: 550 },);
            CompressImg(imageSrc)
        },
        [webcamRef]
    );

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'} >
                <img
                    src={faceFixed}
                    alt="Mold"
                    width={window.innerWidth * 0.81}
                    height={window.innerWidth}
                    style={{
                        maxWidth: '400px',
                        position: "absolute",
                        zIndex: '9999',
                        borderRadius: '5px'
                    }}
                />
                <Webcam
                    mirrored
                    ref={webcamRef}
                    audio={false}
                    screenshotFormat="image/png"
                    screenshotQuality={1}
                    forceScreenshotSourceSize
                    videoConstraints={videoConstraints}
                    style={{ maxWidth: '400px', borderRadius: '5px' }}
                >
                </Webcam>

            </Box>
            <Box textAlign={'-webkit-center'} paddingTop={'1rem'}>
                {!!props.fileFacial ? (
                    <Avatar className={classes.large}>
                        <DoneOutlined fontSize="large" style={{ color: 'white', height: '2rem', width: '2rem' }} />
                    </Avatar>
                ) : (
                    <IconButton onClick={capture}>
                        <div className={classes.div_circle01}>
                            <div className={classes.div_circle02} />
                        </div>
                    </IconButton>
                )}
            </Box>
        </>
    )
}
