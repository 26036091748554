import ReactInputMask from "react-input-mask";
import CustomTextFieldDark from "../CustomTextFieldDark";

export default function CustomTextFieldMasked(props) {
    return (
        <ReactInputMask
            formatChars={props.formatChars}
            mask={props.mask}
            value={props.value ? props.value : null}
            disabled={props.disabled ? props.disabled : false}
            maskChar=" "
            onChange={props.onChange ? props.onChange : null}
            onBlur={props.onBlur ? props.onBlur : null}
            id={props.id + 1}
        >
            {() => <CustomTextFieldDark
                // InputLabelProps={{
                //     shrink: props.shrink ? props.shrink : null,
                // }}
                id={props.id}
                error={props.error ? props.error : false}
                helperText={props.helperText ? (props.error ? props.helperText : '') : ''}
                required={props.required ? props.required : false}
                name={props.name ? props.name : 'DEFAULT'}
                type={props.type ? props.type : "text"}
                label={props.label ? props.label : 'DEFAULT'}
                margin={props.margin ? props.margin : "dense"}
                variant={props.variant ? props.variant : "outlined"}
                className={props.className ? props.className : null}
                fullWidth
            />}
        </ReactInputMask>
    )
}

