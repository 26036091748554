import api from '../../Api';

const userService = {

    getAvailableVacancies: async () => {
        const response = await api.get('/User/available-vacancies');
        return response;
    },
}

export default userService;
