import React from "react";
import { useState } from "react";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import { creditCardService } from "../../../services/CreditCard";
import { CCAuthStep01 } from "./Step01";
import { CCAuthStep02 } from "./Step02";
import { CCAuthStep03 } from "./Step03";

export const CCAuthMobile = (props) => {
    const [cCFront, setCCFront] = useState(null);
    const [snackVariant, setSnackVariant] = useState("error");
    const [snackMessage, setSnackMessage] = useState("");
    const [snackVisible, setSnackVisible] = useState({ visible: false });

    const handleNext = () => {
        if (props.activeStep === 2) return
        props.setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (props.activeStep === 0) return
        props.setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleInputChangeCCFront = (event) => {
        if (event) {
            setCCFront(event);
            handleNext();
        }
    };

    const saveCCAuth = async () => {
        try {
            props.setIsLoading(true)
            let dto = {

                cardBase64: cCFront,
                idCard: props.id
            }
            await creditCardService.sendCardAnalysis(dto)
            setSnackMessage("Cartão de crédito enviado com sucesso.");
            setSnackVariant("success");
            setSnackVisible({ visible: true });
            props.attData()
            props.setOpen(false)
        } catch (e) {
            if (e?.response?.data?.message) {
                setSnackMessage(e?.response?.data?.message);
                setSnackVariant("error");
                setSnackVisible({ visible: true });
            } else if (Object.values(e?.response?.data?.errors)[0][0]) {
                setSnackMessage(Object.values(e?.response?.data?.errors)[0][0]);
                setSnackVariant("error");
                setSnackVisible({ visible: true });
            } else {
                setSnackMessage("Houve uma falha, tente novamente.");
                setSnackVariant("error");
                setSnackVisible({ visible: true });
            }
        } finally {
            props.setIsLoading(false)
        }
    }

    const getStepContent = () => {
        switch (props.activeStep) {
            case 0:
                return <CCAuthStep01 {...{
                    handleNext
                }} />
            case 1:
                return <CCAuthStep02 {...{
                    handleNext,
                    handleInputChangeCCFront
                }} />
            case 2:
                return <CCAuthStep03 {...{
                    handleNext,
                    handleBack,
                    cCFront,
                    saveCCAuth,
                    isLoading: props.isLoading
                }} />
            default:
                break;
        }
    }

    return (
        <>
            <CustomizedSnackbars
                {...{
                    variant: snackVariant,
                    message: snackMessage,
                    visible: snackVisible.visible,
                    setSnackbar: setSnackVisible,
                }}
            />
            {getStepContent()}
        </>
    )
}
