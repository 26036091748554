import api from '../../../Api';

const user_registerServices = {

    postUser_register: async (dto) => {
        const response = await api.post('/Auth/register', dto);
        return response;
    },
}

export default user_registerServices;
