import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import logo_ad from '../../../images/Logos/AD.svg';
import logo_g3 from '../../../images/Logos/G3.svg';
import logo_la from '../../../images/Logos/LA.svg';
import teste from '../../../images/Logos/logo_default.png';
import { useStyles } from "./styles";

export const DefaultRules = (props) => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.gridContCustom}>
                {props.isLoading ? (
                    <CircularProgress style={{ color: '#612F74', height: '10rem', width: '10rem' }} />
                ) : (
                    <>
                        <Grid item xs={12} className={classes.gridDog}>
                            <img src={teste} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography paragraph={true} className={classes.text}>
                                Me desculpe, porém no momento não foi possível estabelecer a
                                regras tarifárias atualizadas, mas fique a vontade para conferir
                                diretamente nas cias aéreas. É só clicar na loga da cia que deseja.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.gridLogosCias}>
                            <img src={logo_ad} width={'100'} height={'auto'} className={classes.imgCia} />
                            <a href="https://www.voegol.com.br/tarifas" target={'_blank'}>
                                <img src={logo_g3} width={'60'} height={'auto'} className={classes.imgCia} />
                            </a>
                            <a href="https://www.latamairlines.com/us/pt/central-ajuda/perguntas/compras/assistencia/tarifas-passagem-domestico" target={'_blank'}>
                                <img src={logo_la} width={'100'} height={'auto'} className={classes.imgCia} />
                            </a>
                        </Grid>
                    </>
                )}
            </Grid >
        </>
    )
}
