import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  useTheme,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import SwipeableViews from "react-swipeable-views";
import { promoSearch } from "../../../actions/flightsAction";
import seta from "../../../images/teste/seta.png";
import { useStyles } from "./styles";
import tagPromo from "../../../images/Marketing/price-tag.svg";

const CssRadio = withStyles({
  colorSecondary: {
    color: "#FFF7FF",
    "&$checked": {
      color: "#FFF7FF",
    },
  },
  checked: {},
})(Radio);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#612F74",
    color: "#FFF7FF",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

const StyledTabs = withStyles({
  indicator: {
    backgroundColor: "#612F74",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    // textTransform: 'none',
    color: "#612F74",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

export const DeskPromocoes = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [typeFlight, setTypeFlight] = useState("ida");
  const promoSearchList = useSelector((state) => state.flights.promoSearch);
  const [valueTab, setValueTab] = useState(0);
  const theme = useTheme();

  const promoList = props.promoList;

  const handleChangeTypeFlight = (event) => {
    setTypeFlight(event.target.value);
  };

  const headCells = [
    { field: "cia", headerName: "CIA", width: "5%" },
    { field: "origem", headerName: "ORIGEM", width: "20%" },
    { field: "seta", headerName: "", width: "5%" },
    { field: "destino", headerName: "DESTINO", width: "20%" },
    { field: "ida", headerName: "IDA", width: "15%" },
    { field: "volta", headerName: "VOLTA", width: "15%" },
    {
      field: "ntentativas",
      headerName: "REDUÇÃO",
      width: "5%",
      color: "#008000",
      image: true,
    },
    { field: "garantirTarifa", headerName: "", width: "15%" },
  ];

  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setValueTab(index);
  };

  const handleClickSearchPromo = (promo) => {
    dispatch(
      promoSearch({
        promoSearch: {
          isPromo: true,
          promo: promo,
        },
      })
    );
    history.push("/aereos");
  };

  return (
    <div style={{ minWidth: "1100px", maxWidth: "1500px" }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{ fontWeight: "bold", color: "#612F74", fontSize: "1.5rem" }}
          >
            {promoList[0][0].arrivalCity}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            style={{
              background: "#612F74",
              display: "flex",
              alignItems: "center",
            }}
            padding={"10px"}
            borderRadius={"10px"}
          >
            <Typography style={{ color: "#FFF7FF", marginRight: "10px" }}>
              {" "}
              Saindo de: <b>{promoList[0][0].departureCity}</b>
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                style={{ flexDirection: "row" }}
                aria-label="typeFlight"
                name="typeFlight"
                value={typeFlight}
                defaultValue="ida"
                onChange={handleChangeTypeFlight}
              >
                <FormControlLabel
                  style={{ color: "#FFF7FF" }}
                  value="ida"
                  control={<CssRadio />}
                  label={"Somente Ida"}
                />
                <FormControlLabel
                  style={{ color: "#FFF7FF" }}
                  value="idavolta"
                  control={<CssRadio />}
                  label={"Ida e Volta"}
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <StyledTabs
            value={valueTab}
            onChange={handleChangeTab}
            variant="fullWidth"
          >
            {promoList.map((item, index) => (
              <StyledTab
                label={index === 0 ? "Resumo" : item[0].departureDate}
                {...a11yProps(index)}
              />
            ))}
          </StyledTabs>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={valueTab}
            onChangeIndex={handleChangeIndex}
          >
            {promoList.map((item, index) => (
              <TabPanel
                value={valueTab}
                index={index}
                dir={theme.direction}
                style={{ marginTop: "58px" }}
              >
                {/* <Box
                  id="popover"
                  style={{
                    marginTop: "20px",
                    position: "relative",
                    display: "inline-block",
                  }}
                >
                  <Typography
                    style={{
                      color: "#008000",
                      position: "absolute",
                      left: "2px",
                      top: "7px",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: "1.0rem",
                    }}
                  >
                    Número tentativas de
                  </Typography>
                  <img src={tagPromo} height={87} width={103} />
                </Box> */}
                <price-tag>
                  <div
                    class="price-tag"
                    style={{
                      animation: "1.2s ease-out 0s 1 slideInFromRight",
                      backgroundImage:
                        "url('https://linesturdiag.blob.core.windows.net/linestur-virtual/Promos/price-tag.svg')",
                      backgroundSize: "cover",
                      textAlign: "center",
                      position: "absolute",
                      top: "-8px",
                      right: "220px",
                      width: "50px",
                      height: "42px",
                      zIndex: 1,
                      color: "#2b900b",
                      padding: "34px",
                      transition: "color 1s",
                    }}
                  >
                    <p
                      style={{
                        marginTop: "-13px",
                        fontWeight: 600,
                        marginLeft: "-14px",
                        width: "80px",
                      }}
                    >
                      Número tentativas de
                    </p>
                  </div>
                </price-tag>

                <Table style={{ minWidth: "100%", maxWidth: "100%" }}>
                  <TableHead>
                    <TableRow>
                      {headCells.map((item) => (
                        <StyledTableCell
                          style={{ width: `${item.width}` }}
                          align="center"
                        >
                          {item.headerName}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {promoList[valueTab].map((item) => (
                      <StyledTableRow key={item.departureCity}>
                        <StyledTableCell align="center">
                          <img src={item.urlAirCompanie} height={80} />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>
                            {item.departureCity} ({item.departure})
                          </Typography>
                          <Typography style={{ fontSize: "0.7rem" }}>
                            {item.departureAirportCity}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <img src={seta} height={30} />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>
                            {item.arrivalCity} ({item.arrival})
                          </Typography>
                          <Typography style={{ fontSize: "0.7rem" }}>
                            {item.arrivalAirportCity}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography>{item.departureDate}</Typography>
                          <Typography>{item.departureTime}</Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {typeFlight === "idavolta" ? (
                            <>
                              <Typography>{item.arrivalDate}</Typography>
                              <Typography>{item.arrivalTime}</Typography>
                            </>
                          ) : (
                            <Typography> -</Typography>
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Typography
                            style={{ color: "#008000", fontWeight: "bold" }}
                          >
                            {item.reductionAttempts}
                          </Typography>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            onClick={() =>
                              handleClickSearchPromo(
                                typeFlight === "idavolta"
                                  ? item.reqAvailabilityRoundTrip
                                  : item.reqAvailabilityGoing
                              )
                            }
                            className={classes.button}
                          >
                            <Typography>
                              {typeFlight === "idavolta"
                                ? item.roundTripTotal
                                : item.goingTotal}
                            </Typography>
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TabPanel>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
    </div>
  );
};
