import React from 'react';
import QRCode from 'react-qr-code';
import { Grid, Typography } from '@material-ui/core';

const QRCodeComponent = ({ data }) => {
  return (
      <Grid item>
        <QRCode value={data} size={128} />
      </Grid>
  );
};

export default QRCodeComponent;