import { AppBar, Backdrop, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Modal, Slide, Toolbar, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Close, Warning, WarningOutlined } from '@material-ui/icons';
import React, { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CustomStepperCounter from '../../components/CustomStepperCounter';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const ConfigSearchPaxModal = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [limitPax, setLimitPax] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const [state, setState] = useState({
        adults: 1,
        children: 0,
        babies: 0,
    });
    const [stepperAdults, setStepperAdults] = useState({
        value: 1,
        label: 'Adulto(s)',
        minValue: 1,
        maxValue: 9
    });
    const [stepperChildren, setStepperChildren] = useState({
        value: 0,
        label: 'Criança(s)',
        minValue: null,
        maxValue: 9
    });
    const [stepperBabies, setStepperBabies] = useState({
        value: 0,
        label: 'Bebê(s)',
        minValue: null,
        maxValue: 9
    });

    const handleClose = () => {
        props.handleTooltipConfigPaxClose();
    };

    const setAdults = (value) => {
        if (value.value < state.babies) {
            setStepperAdults(value)
            setStepperBabies({ ...stepperBabies, value: value.value })
            setState({ ...state, babies: value.value, adults: value.value })
            return
        }
        setStepperAdults(value)
        setState({
            ...state,
            adults: value.value
        })
    }

    const setChildren = (value) => {
        setStepperChildren(value)
        setState({
            ...state,
            children: value.value
        })
    }

    const setBabies = (value) => {
        if (value.value > state.adults) {
            return
        }
        setStepperBabies(value)
        setState({
            ...state,
            babies: value.value
        })
    }

    const handleClickAplicar = () => {
        props.setQuantityPerson(state);
    }

    useLayoutEffect(() => {
        if ((state.adults + state.children + state.babies) >= 9) {
            setDisabled(true);
            setLimitPax(true)
        }
        else {
            setDisabled(false);
            setLimitPax(false);
        }
    }, [state])

    useLayoutEffect(() => {
        setState(props.stateQuantityPerson);
        setStepperAdults({ ...stepperAdults, value: props.stateQuantityPerson.adults })
        setStepperChildren({ ...stepperChildren, value: props.stateQuantityPerson.children })
        setStepperBabies({ ...stepperBabies, value: props.stateQuantityPerson.babies })
    }, [])

    return (
        <Dialog on fullScreen={responsive} open={props.open} onClose={handleClose} TransitionComponent={Transition}>
            <DialogTitle className={classes.dialogCustomTitle}>
                <Typography variant='h5'>Passageiros</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogCustom}>
                <Grid container style={{ textAlign: 'center' }}>
                    <Grid item xs={responsive ? 12 : 4} style={{ padding: '10px' }} >
                        <CustomStepperCounter
                            {...{ ...stepperAdults, disabled }}
                            description={'acima de 12 anos'}
                            setValue={{ setValue: setAdults }}
                            disabledAdd={disabled}
                            disabledSub={state.adults <= 1}
                        />
                    </Grid>
                    <Grid item xs={responsive ? 12 : 4} style={{ padding: '10px' }}>
                        <CustomStepperCounter
                            {...{ ...stepperChildren, disabled }}
                            description={'entre 2 e 11 anos'}
                            setValue={{ setValue: setChildren }}
                            disabledAdd={disabled}
                            disabledSub={state.children <= 0}
                        />
                    </Grid>
                    <Grid item xs={responsive ? 12 : 4} style={{ padding: '10px' }} >
                        <CustomStepperCounter
                            {...{ ...stepperBabies, disabled }}
                            description={'entre 0 e 2 anos'}
                            setValue={{ setValue: setBabies }}
                            disabledAdd={disabled || stepperBabies.value === stepperAdults.value}
                            disabledSub={state.babies <= 0}
                        />
                    </Grid>
                    <br />
                    {limitPax ? (
                        <Grid item xs={12}>
                            <Typography>
                                <b>
                                    Limite de passageiros atingida.
                                </b>
                            </Typography>
                        </Grid>
                    ) : null}
                    <br />
                    <Grid item container xs={12} className={classes.dialogWarning} style={responsive ? limitPax ? { marginTop: '24px' } : { marginTop: '48px' } : limitPax ? null : { marginTop: '24px' }}>
                        <Grid item xs={responsive ? 2 : 1} className={classes.dialogWarningLogo}>
                            <WarningOutlined fontSize='large' />
                        </Grid>
                        <Grid item xs={responsive ? 10 : 11}>
                            <Typography>
                                <b>O número de bebês</b> deve ser menor ou igual ao número de adultos e eles podem acarretar taxas adicionais por parte da cia aérea em voos internacionais
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogCustomActions}>
                <Grid container>
                    <Grid item xs={12} style={{ padding: '0px 10px 0px 10px' }}>
                        <Button onClick={() => handleClickAplicar()} className={classes.button3}>
                            <Typography>Aplicar</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )

}
