import {
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import CustomDatePicker from "../../../../components/CustomDatepicker";
import CustomSelectField from "../../../../components/CustomSelectField/index";
import CustomTextFieldDark from "../../../../components/CustomTextFieldDark";
import CustomTextFieldMasked from "../../../../components/CustomTextFieldMasked";
import { EnumGenre } from "../../../../Enums/enum_genre";
import { useStyles } from "./styles";

export default function Steps(props) {
  const classes = useStyles();

  // const [gender, setGender] = useState({});

  function handleChangeDataRegister(event) {
    let objectcamp = event.target.name.split(/:/);
    props.setDataRegister({
      ...props.dataRegister,
      [objectcamp[0]]: {
        ...props.dataRegister[objectcamp[0]],
        [objectcamp[1]]: event.target.value,
      },
    });
  }

  function setCountry(e) {
    props.setDataRegister({
      ...props.dataRegister,
      person: { ...props.dataRegister.person, id_Country: e },
    });
  }
  function setState(e) {
    props.setDataRegister({
      ...props.dataRegister,
      person: { ...props.dataRegister.person, id_State: e },
    });
  }
  function setCity(e) {
    props.setDataRegister({
      ...props.dataRegister,
      person: { ...props.dataRegister.person, id_City: e },
    });
  }
  function setNationality(e) {
    props.setDataRegister({
      ...props.dataRegister,
      person: { ...props.dataRegister.person, nationality: e },
    });
  }
  function setBirthDate(date) {
    props.setDataRegister({
      ...props.dataRegister,
      person: { ...props.dataRegister.person, birthDate: date },
    });
  }

  function setStepsLayout() {
    switch (props.step) {
      case 0:
        return (
          <>
            {props.isLoading ? (
              <Box
                className={classes.box}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2} className={classes.box}>
                  <Grid item xs={2}></Grid>
                  <Grid
                    item
                    xs={8}
                    style={{ display: "grid", alignItems: "end" }}
                  >
                    <CustomTextFieldMasked
                      mask={"999.999.999-99"}
                      error={props.dataVerified.cpf}
                      className={classes.field}
                      required
                      name="person:cpf"
                      type="text"
                      label="CPF"
                      value={props.dataRegister.person.cpf}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8}>
                    <CustomDatePicker
                      {...{
                        date: props.dataRegister.person.birthDate,
                        setDate: setBirthDate,
                        label: "Data de Nascimento",
                        className: classes.field,
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </Box>
            )}
          </>
        );
      case 1:
        return (
          <>
            <Box className={classes.box}>
              <Grid container spacing={2} className={classes.box}>
                <Grid item className={classes.gridCenterAligned} xs={12}>
                  <CustomTextFieldDark
                    required
                    error={props.dataVerified.firstName}
                    className={classes.field}
                    name="person:firstName"
                    type="text"
                    label="Nome"
                    margin="dense"
                    variant="outlined"
                    value={props.dataRegister.person.firstName || ""}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
                <Grid item className={classes.gridCenterAligned} xs={12}>
                  <CustomTextFieldDark
                    required
                    error={props.dataVerified.lastName}
                    className={classes.field}
                    name="person:lastName"
                    type="text"
                    label="Sobrenome"
                    margin="dense"
                    variant="outlined"
                    value={props.dataRegister.person.lastName || ""}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
                <Grid item className={classes.gridCenterAligned} xs={6}>
                  <CustomSelectField
                    error={props.dataVerified.gender}
                    required
                    className={classes.field}
                    {...{
                      itens: EnumGenre.getGenreList(),
                      value: props.dataRegister.person.gender,
                      label: "Gênero",
                    }}
                    onChange={(e) => handleChangeDataRegister(e)}
                    name="person:gender"
                  />
                </Grid>
                <Grid item className={classes.gridCenterAligned} xs={6}>
                  <CustomSelectField
                    error={props.dataVerified.nationality}
                    required
                    className={classes.field}
                    {...{
                      itens: props.countries,
                      value: props.dataRegister.person.nationality,
                      setValue: setNationality,
                      label: "Nacionalidade",
                    }}
                    name="person:nationality"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Box className={classes.box}>
              <Grid container spacing={2} className={classes.box}>
                <Grid item className={classes.gridCenterAligned} xs={12}>
                  <CustomTextFieldDark
                    required
                    error={props.dataVerified.email}
                    className={classes.field}
                    name="user:email"
                    type="email"
                    label="Email"
                    margin="dense"
                    variant="outlined"
                    value={props.dataRegister.user.email || ""}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
                <Grid item className={classes.gridCenterAligned} xs={12}>
                  {props.step === 2 ? (
                    <CustomTextFieldMasked
                      required
                      error={props.dataVerified.telephone1}
                      mask={"(99) 99999-9999"}
                      className={classes.field}
                      name="person:telephone1"
                      id={1}
                      type="tel"
                      label="Telefone Principal"
                      value={props.dataRegister.person.telephone1}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  ) : null}
                </Grid>
                <Grid item className={classes.gridCenterAligned} xs={12}>
                  <CustomTextFieldMasked
                    mask={"(99) 99999-9999"}
                    className={classes.field}
                    id={3}
                    name="person:telephone2"
                    type="tel"
                    label="Telefone Opcional"
                    value={props.dataRegister.person.telephone2}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        );
      case 3:
        return (
          <>
            {props.isLoading ? (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                className={classes.box}
              >
                <CircularProgress style={{ color: "#612F74" }} size={100} />
              </Box>
            ) : (
              <Box className={classes.box}>
                <Grid container spacing={2}>
                  <Grid item className={classes.gridCenterAligned} xs={6}>
                    <CustomSelectField
                      error={props.dataVerified.country}
                      required
                      className={classes.field}
                      {...{
                        itens: props.countries,
                        value: props.dataRegister.person.id_Country,
                        setValue: setCountry,
                        label: "País",
                      }}
                      name="country"
                    />
                  </Grid>
                  <Grid
                    item
                    className={classes.gridCenterAligned}
                    xs={6}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    {props.step === 3 ? (
                      <CustomTextFieldMasked
                        error={props.dataVerified.cep}
                        disabled={
                          props.countries?.find(
                            (s) =>
                              s.value === props.dataRegister.person.id_Country
                          )?.id !== 1
                        }
                        mask={"99999-999"}
                        className={classes.field}
                        id={5}
                        name="person:cep"
                        type="text"
                        label="CEP"
                        value={props.dataRegister.person.cep}
                        onChange={(e) => handleChangeDataRegister(e)}
                        onBlur={() =>
                          props.dataRegister.person.cep?.trim()?.length === 9 &&
                          props.countries?.find(
                            (s) =>
                              s.value === props.dataRegister.person.id_Country
                          )?.id === 1
                            ? props.getDataCEP()
                            : null
                        }
                      />
                    ) : null}
                    {/* <IconButton onClick={() => props.getDataCEP()}>
                      <SearchOutlined style={{ color: '#fafafa' }} />
                    </IconButton> */}
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={4}>
                    <CustomSelectField
                      shrink={props.dataRegister.person.id_City}
                      error={props.dataVerified.state}
                      required
                      className={classes.field}
                      {...{
                        itens: props.states,
                        value: props.dataRegister.person.id_State,
                        setValue: setState,
                        disabled: props.dataRegister.person.id_Country === null,
                        label: "Estado",
                      }}
                      name="state"
                    />
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={4}>
                    <CustomSelectField
                      required
                      className={classes.field}
                      shrink={props.dataRegister.person.id_City}
                      error={props.dataVerified.residenceCity}
                      {...{
                        itens: props.cities,
                        value: props.dataRegister.person.id_City,
                        disabled: props.dataRegister.person.id_State === null,
                        setValue: setCity,
                        label: "Cidade",
                      }}
                      name="city"
                    />
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={4}>
                    <CustomTextFieldDark
                      required
                      InputLabelProps={{
                        shrink: props.dataRegister.person.neighborhood || "",
                      }}
                      error={props.dataVerified.neighborhood}
                      className={classes.field}
                      name="person:neighborhood"
                      type="text"
                      label="Bairro"
                      disabled={
                        props.dataRegister.person.id_ResidenceCity === null
                      }
                      margin="dense"
                      variant="outlined"
                      value={props.dataRegister.person.neighborhood}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={12}>
                    <CustomTextFieldDark
                      required
                      InputLabelProps={{
                        shrink: props.dataRegister.person.address || "",
                      }}
                      error={props.dataVerified.street}
                      className={classes.field}
                      disabled={props.dataRegister.person.neighborhood === null}
                      name="person:address"
                      type="text"
                      label="Logradouro"
                      margin="dense"
                      variant="outlined"
                      value={props.dataRegister.person.address}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={2}>
                    <CustomTextFieldDark
                      className={classes.field}
                      disabled={props.dataRegister.person.neighborhood === null}
                      name="person:number"
                      type="text"
                      label="Número"
                      margin="dense"
                      variant="outlined"
                      value={props.dataRegister.person.number}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  </Grid>
                  <Grid item className={classes.gridCenterAligned} xs={10}>
                    <CustomTextFieldDark
                      className={classes.field}
                      name="person:complement"
                      disabled={props.dataRegister.person.neighborhood === null}
                      type="text"
                      label="Complemento"
                      margin="dense"
                      variant="outlined"
                      value={props.dataRegister.person.complement}
                      onChange={(e) => handleChangeDataRegister(e)}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </>
        );
      case 4:
        return (
          <>
            <Box className={classes.box}>
              <Grid container spacing={2}>
                <Grid item xs={2}></Grid>
                <Grid item className={classes.gridCenterAligned} xs={8}>
                  <CustomTextFieldDark
                    required
                    className={classes.field}
                    name="user:login"
                    type="text"
                    label="Usuário"
                    margin="dense"
                    variant="outlined"
                    value={props.dataRegister.user.login}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item className={classes.gridCenterAligned} xs={8}>
                  <CustomTextFieldDark
                    required
                    className={classes.field}
                    name="user:password"
                    type="password"
                    label="Senha"
                    margin="dense"
                    variant="outlined"
                    value={props.dataRegister.user.password}
                    onChange={(e) => handleChangeDataRegister(e)}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={2}></Grid>
                <Grid item className={classes.gridCenterAligned} xs={8}>
                  <CustomTextFieldDark
                    required
                    className={classes.field}
                    name="confirmPassword"
                    type="password"
                    label="Confirmação de senha"
                    margin="dense"
                    variant="outlined"
                    // value={props.values?. || ""}
                    // onChange={(e) => handleChange(e)}
                  />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
            </Box>
          </>
        );
      case 5:
        return (
          <Grid container style={{ marginBottom: "35px" }}>
            <Grid
              item
              xs={12}
              style={{
                color: "black",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <img
                width={110}
                src="https://play-lh.googleusercontent.com/D1Dz2BjPYev_oyksKXsdtAS66a_2Ql-sklpzTnwR9lqnDG_P5lAJEtfR70FudJ0XMA"
                alt=""
              />
            </Grid>
            <Grid item xs={2} />
            <Grid
              item
              xs={8}
              style={{
                color: "black",
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",
              }}
            >
              <Typography
                align="center"
                variant="subtitle1"
                style={{ fontSize: "1.3rem" }}
                className={classes.field}
              >
                Verifique o seu e-mail
              </Typography>
              <Typography
                align="center"
                variant="subtitle1"
                style={{ fontSize: "1.0rem" }}
                className={classes.field}
              >
                 <b>({props.dataRegister.user.email})</b>
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: "1.0rem" }}
                variant="subtitle1"
                className={classes.field}
              >
                Enviamos um e-mail com um link de confirmação.
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: "0.7rem" }}
                variant="subtitle1"
                className={classes.field}
              >
                O link tem validade de 30 minutos.
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: "0.7rem" }}
                variant="subtitle1"
                className={classes.field}
              >
                Confirme sua conta antes do link expirar{props.fastRegister ? " para continuar sua compra." : "."}
              </Typography>
              <Typography
                align="center"
                style={{ fontSize: "0.7rem" }}
                variant="subtitle1"
                className={classes.field}
              >
                Não recebeu nenhuma mensagem? Verifique a pasta de Spam.
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        );
      default:
        break;
    }
  }

  return (
    <>
      <Grid container className={classes.gridCenter}>
        {setStepsLayout(props)}
      </Grid>
    </>
  );
}
