import { Box, Button, Typography } from "@material-ui/core";
import { CameraAltOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";

export const FacialBiometricsStep04 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Box textAlign={'center'} paddingTop={'2rem'}>
                <CameraAltOutlined fontSize="large" />
            </Box>
            <Box textAlign={'center'} padding={2}>
                <Typography >
                    Por gentileza tirar foto do seu RG ou CNH.
                </Typography>
                <br />
                <Typography >
                    Coloque o documento sobre uma superfície plana, e em local bem iluminado.
                </Typography>
            </Box>
            <Box textAlign={'center'} display={'flex'} justifyContent={'center'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => props.handleNext()} className={classes.buttonOrange}>
                    Confirmar
                </Button>
            </Box>
        </>
    )
}
