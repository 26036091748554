import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import { CCAuthDesktop } from "./desktop";
import { CCAuthMobile } from "./mobile";
import { CCAuthStepper } from "./mobile/Stepper";
import { useStyles } from "./mobile/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const CreditCardAuthenticate = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.setOpen(false)
        props.attData()
        // if (responsive) {
        // }
    };

    useEffect(() => {
        if (props.open === true) {
            setActiveStep(0)
        }
    }, [props.open])

    return (
        <>
            <Dialog
                open={props.open}
                fullScreen={responsive}
                TransitionComponent={Transition}
                onClose={handleClose}
                maxWidth={responsive ? 'md' : 'sm'}
                id='dialog-cc-auth'
            >
                <CustomLoader isLoading={isLoading} />
                <DialogTitle disableTypography style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px 12px', backgroundColor: '#612F74', color: '#fafafa' }}>
                    <Typography variant="h6">
                        Autenticação
                    </Typography>
                    <IconButton onClick={() => handleClose()} style={{ color: '#fafafa' }}>
                        <CloseOutlined />
                    </IconButton>
                </DialogTitle>
                {responsive ? (
                    <>
                        <DialogContent style={{ padding: '0px' }}>
                            <Box height={"100%"}>
                                <CCAuthMobile {...{
                                    id: props.id,
                                    setIsLoading,
                                    isLoading,
                                    setActiveStep,
                                    activeStep,
                                    attData: props?.attData,
                                    setOpen: props.setOpen
                                }} />
                            </Box>
                        </DialogContent>
                    </>
                ) : (
                    <>
                        <CCAuthDesktop />
                    </>
                )}
                <DialogActions>
                    {responsive ? (
                        <CCAuthStepper {...{
                            activeStep
                        }} />
                    ) : (
                        <Button className={classes.buttonOrange} onClick={() => handleClose()}>Entendi</Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}
