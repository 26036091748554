import React from "react";
import clsx from "clsx";
import { useStyles } from "./styles";
import { Box } from "@material-ui/core";

const CustomBoxImg = ({ style, midia }) => {
  const classes = useStyles();

  return (
    <Box style={style}>
      <div
        className={clsx(
          classes.imgContainer,
          classes.imgContainerCaption,
          classes.imgContainerMidia
        )}
      >
        <img alt="" className={classes.imgContainerMidia}src={midia} />
      </div>
    </Box>
  );
};

export default CustomBoxImg;
