import { Box, Grid, Icon, IconButton, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router";
import { useStyles } from "./styles";

export const FooterCustomMobile = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    return (
        <Grid container className={classes.gridContainerMain}>
            <Grid item xs={12} container className={classes.customGrid01}>
                <img src={props.linesturVirtualAssets.logoDark} className={classes.img} />
                <Box>
                    <Typography className={classes.typograph01}><b>Linestur Viagens e Turismo Ltda.</b></Typography>
                    <Typography className={classes.typograph01}>CNPJ: 11.784.358/0001-79</Typography>
                    <Typography className={classes.typograph01}>Rua Itajai, 410, Bloco B, Sala 4 (Centro)</Typography>
                    <Typography className={classes.typograph01}>Criciúma, Santa Catarina, CEP 88801-150</Typography>
                </Box>
                {/* <Box>
                    <Typography className={classes.typograph01}><b>Linestur Viagens e Turismo Ltda.</b></Typography>
                    <Typography className={classes.typograph01}>CNPJ: 11.784.358/0001-79</Typography>
                    <Typography className={classes.typograph01}>Rua Itajai, 410 / Bloco B / Sala 4 - Centro</Typography>
                    <Typography className={classes.typograph01}>CEP 88801-150</Typography>
                </Box> */}
            </Grid>
            <Grid item xs={12} container className={classes.customGrid01}>
                <Grid item xs={12} className={classes.customGrid04}>
                    <Typography className={classes.typograph01}><b>Linestur Viagens</b></Typography>
                    <Typography className={classes.typograph01} onClick={() => history.push('/quemsomos')} style={{cursor: 'pointer'}}>Quem somos</Typography>
                </Grid>
                <Grid item className={classes.customGrid04} style={{ marginTop: '20px'}}>
                    <Typography className={classes.typograph01} onClick={() => history.push('/ajuda')}><b>Termos</b></Typography>
                    <Typography className={classes.typograph01} onClick={() => history.push('/ajuda')}>Utilização da Zulminha</Typography>
                    <Typography className={classes.typograph01} onClick={() => history.push('/ajuda')}>Política de privacidade</Typography>
                    <Typography className={classes.typograph01} onClick={() => history.push('/ajuda')}>Cancelamento e reembolso</Typography>
                    <Typography className={classes.typograph01} onClick={() => history.push('/ajuda')}>Regras de remarcação</Typography>
                    {/* <Typography className={classes.typograph01}>Compra segura</Typography> */}
                </Grid>
                <Grid item className={classes.customGrid04} style={{ marginTop: '20px'}}>
                    <Typography className={classes.typograph01}><b>Atendimento</b></Typography>
                    <Typography className={classes.typograph01}>(48) 3411-6695</Typography>
                    <Typography className={classes.typograph01}>Segunda a Sexta:</Typography>
                    <Typography className={classes.typograph01}>08:15 às 18:00</Typography>
                    <Typography className={classes.typograph01}>WhatsApp 24h/7dias</Typography>
                    <Typography className={classes.typograph01}>Precisa de ajuda?</Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} container className={classes.customGrid03}>
                <img src={props.linesturVirtualAssets.seguro} className={classes.img3} />
                <img src={props.linesturVirtualAssets.cadastur} className={classes.img4} />
            </Grid>
            <Grid item xs={12} container className={classes.customGrid01}>
                <Typography className={classes.typograph01}><b>Formas de pagamento</b></Typography>
            </Grid>
            <Grid item xs={12} container className={classes.customGrid01}>
                {props.bankingFlag.map((item) => (
                    <img src={item.src} className={classes.img2}></img>
                ))}
            </Grid>
            <Grid item xs={12} container className={classes.customGrid01} style={{ marginLeft: '20px', marginRight: '10px' }}>
                <Typography className={classes.typograph02}>O número de parcelas com cartão de crédito é estabelecido por cada companhia aérea.</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.customGrid01}>
                <Grid item xs={12} className={classes.customGrid02}>
                    <Typography className={classes.typograph03}><b>Siga!</b></Typography>
                </Grid>
                <Grid item xs={12} className={classes.customGrid02}>
                    {props.redesSociais.map((item) => (
                        <IconButton color="inherit" aria-label={item.label} onClick={() => window.open(item?.url, '_blank').focus()}>
                            <Icon className={classes.iconRoot}>
                                <img className={classes.imageIcon} src={item.src} />
                            </Icon>
                        </IconButton>
                    ))}

                </Grid >
            </Grid >
        </Grid >

    )
}
