
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, Paper, Slide, Snackbar, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core"
import { CloseOutlined, ContentCopy, FileCopyOutlined } from "@material-ui/icons"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from "./styles.jsx";
import React from "react";
import QRCodeComponent from "../../../components/01_Rename-QRCodeComponent/index.jsx";
import PixPaymentService from "../../../services/01_PixPayment/index.jsx";
import { useState } from "react";
import { useEffect } from "react";
import bookingService from "../../../services/Bookings/index.js";
import { Alert, Skeleton } from "@material-ui/lab";
import { IconsSource } from "../../../images/Icons/index.js";

export const PixPaymentDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down("sm"));
    const [pixPayment, setPixPayment] = useState([]);
    const [copy, setCopy] = useState(false);

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const handleClose = () => {
        props.setOpen(false)
    }

    const PixPaymentCheck = async () => {
        try {
            let response = await bookingService.getListPixActives(props.id);
            setPixPayment(response.data.results)
        } catch {
        } finally {
        }
    }

    useEffect(() => {
        PixPaymentCheck();
    }, [props.id])

    return (
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            fullScreen={responsive}
            maxWidth="md"
            fullWidth={true}
            classes={responsive ? null : { paper: classes.dialogPaper }}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle disableTypography id="alert-dialog-title" style={{ padding: "0.2rem" }} className={classes.dialogTitle}
            >
                <Box
                    width={"-webkit-fill-available"}
                    display={"grid"}
                    justifyContent={"end"}
                >
                    <IconButton
                        onClick={() => handleClose()}
                        style={{ color: "#fafafa  " }}
                    >
                        <CloseOutlined />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent style={responsive ? { padding: "8px 10px" } : null}>
                <Grid container>
                    <Grid item xs={12}>
                        <Paper style={{ padding: "20px" }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: "70px", margin: "20px" }} elevation={1}>
                            <Grid container alignItems="center">
                                <CheckCircleIcon style={{ color: "green", width: "40px", height: "40px" }} />
                                <Grid>
                                    <Typography style={{ fontSize: "15px", fontWeight: "600", marginLeft: "10px" }}>
                                        {pixPayment.length == 0 ? <Skeleton /> : 'Pedido aceito, o pagamento será confirmado em 3 minutos de espera'}
                                    </Typography>
                                    <Typography style={{ fontSize: "15px", fontWeight: "500", marginLeft: "10px" }}>
                                        {pixPayment.length == 0 ? <Skeleton /> : `Seu código expira em ${new Date(pixPayment.expiration).toLocaleString()}, faça o pagamento dentro do prazo, recarregue caso já tenha feito o pagamento`}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid container item xs={12} style={{ marginTop: "30px" }}>
                        <Paper style={{ padding: "20px" }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: "70px", margin: "20px" }} elevation={1}>
                            <Grid container>
                                <Grid item xs={responsive ? 12 : 6}>
                                    <Typography style={{ fontSize: "23px", fontWeight: "600" }}>Guia de pagamento com Pix</Typography>
                                    <Typography>1 - Abra o app do seu banco e encontre a opção de pagamento "Pix".</Typography>
                                    <Typography>2 - Abra o app do seu banco e escaneie o QR code ou você pode copiar o código de pagamento Pix e colar diretamente no app do seu banco.</Typography>
                                    <Typography>3 - Verifique os detalhes e finalize o pedido.</Typography>
                                </Grid>
                                <Grid item xs={responsive ? 12 : 6} style={{ textAlign: "center" }}>
                                    {pixPayment.length == 0 ?
                                        <Skeleton style={{ marginLeft: "25%" }} variant="rect" width={210} height={118} /> :
                                        <QRCodeComponent data={pixPayment ? pixPayment?.qrCode : "qrData"} />}
                                    <Typography style={{ fontSize: "13px", margin: "30px" }}>
                                        Escaneie o QR code usando um aplicativo bancário compatível com Pix.
                                    </Typography>
                                    {pixPayment.length == 0 ?
                                        <Skeleton style={{ marginLeft: "36%" }} variant="rect" width={110} height={20} />
                                        :
                                        <>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                className={classes.textFieldCopy}
                                                onClick={() => { const textToCopy = pixPayment ? pixPayment?.qrCode : "qrData"; navigator.clipboard.writeText(textToCopy); setCopy(true); }}
                                                label="Pix copia e cola"
                                                defaultValue={pixPayment?.qrCode}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Tooltip title="Copiar Pix">
                                                                <IconButton onClick={() => { const textToCopy = pixPayment ? pixPayment?.qrCode : "qrData"; navigator.clipboard.writeText(textToCopy); setCopy(true); }}>
                                                                    <FileCopyOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>)
                                                }}
                                                InputLabelProps={{ shrink: true }}
                                            />
                                            {copy?(
                                                <Alert severity="success">Copiado com sucesso</Alert>
                                            ):(
                                                null
                                            )}
                                        </>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid xs={12} style={{ textAlign: "center", marginTop: "10px" }}>
                        <Typography style={{ color: "#27f", fontSize: "13px" }}>
                            <a>Pagemento realizado com sucesso! Confira os detalhes</a> | <a>Teve problemas com o pagamento? Visite a central de ajuda</a>
                        </Typography>
                    </Grid>
                    <Grid xs={12} style={{ marginTop: "30px" }}>
                        <Paper style={{ padding: "20px" }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, padding: "70px", margin: "20px" }} elevation={1}>
                            <Typography style={{ fontWeight: "700" }}>{pixPayment.length == 0 ? <Skeleton /> : "Informações do Pedido"}</Typography>
                            <Grid container alignItems="center">
                                <Typography style={{ fontSize: "13px", marginTop: "8px" }}>
                                    Data do Pedido: {pixPayment.length == 0 ? <Skeleton /> : new Date(pixPayment?.createDate).toLocaleString()}
                                </Typography>
                                <Typography style={{ fontSize: "13px", marginTop: "8px", marginLeft: "15px" }}>
                                    Valor:BRL {pixPayment.length == 0 ? <Skeleton /> : pixPayment?.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </Typography>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => handleClose()}
                    className={classes.buttonNext}
                >
                    <Typography>Pagar Depois</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}
