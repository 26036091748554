import { makeStyles } from '@material-ui/core/styles';
import { purple, deepPurple, red } from '@material-ui/core/colors';

const drawerWidth = 240;



export const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    tools: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    divTools: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    navigation: {
        bottom: '0px',
        position: 'fixed',
        zIndex: 1200,
        width: '100vw'
    },
    speedDial: {
        zIndex: 2000,
        position: 'absolute',
        bottom: theme.spacing(5),
        right: '-50%',
        left: '-50%',
        whiteSpace: 'nowrap'
    },
    speedDial4: {
        zIndex: 2000,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        whiteSpace: 'nowrap'
    },
    speedDial44: {
        zIndex: 2000,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        bottom: theme.spacing(-4),
        whiteSpace: 'nowrap'
    },
    speedDial444: {
        zIndex: 2000,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        whiteSpace: 'nowrap'
    },
    speedDial4444: {
        zIndex: 2000,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        whiteSpace: 'nowrap'
    },
    speedDial2: {
        bottom: 0,
        position: 'absolute',
        paddingBottom: '0px !important'

    },
    speedDial3: {
        zIndex: 2000,
        position: 'absolute',
        right: '22.5%',
        whiteSpace: 'nowrap'
    },
    bottomNav: {
        minWidth: '20vw',
        '&.MuiBottomNavigationAction-root.Mui-selected': {
            color: '#c62828'
        }
    },
    appb: {
        zIndex: 1100,
    },
    dialogContent: {
        spacing: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '110px',
        backgroundColor: '#e6e6e6',
    },
    buttonExit: {
        color: '#ffffff',
    },
    typographyTitle: {
        fontSize: '14pt',
    },
    dialogTitle: {
        backgroundColor: '#c62828',

        color: '#ffffff',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    toolbarCustom: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#c62828'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: red[800],
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    texto: {
        marginLeft: theme.spacing(1)
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: drawerWidth,
        background: '#464646',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        background: '#464646',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        borderColor: 'white',
        background: '#464646',
        ...theme.mixins.toolbar,
    },
    icone: {
        color: 'white'
    },
    modalPoliticaDoc: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    content: {
        minHeight: '100vh',
        position: 'relative',
    },
    menuIcon: {
        background: red[500],
        color: 'white'
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    home: {
        cursor: 'pointer'
    },
    selectOrganizacao: {
        minWidth: 130,
        '& .MuiSelect-icon': {
            color: 'white'
        },
        '& .MuiInputBase-root': {
            color: 'white'
        },
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'silver',
            },
            '&:hover fieldset': {
                borderColor: 'silver',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },

    buttonTeste: {
        display: 'table',
    },

    buttonMobile: {
        display: 'table',
        padding: '5px 10px 5px 10px'
    },

    footerCustomDesk: {
        textAlign: 'center',
        position: 'absolute',
        padding: 0,
        bottom: 0,
        background: '#fceefe',
        left: 0,
        height: '700px',
        width: '100%',
        boxShadow: '0px -10px 40px -5px rgba(0,0,0,0.15)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    footerCustom: {
        textAlign: '-webkit-center',
        position: 'absolute',
        padding: 0,
        bottom: 0,
        background: '#fceefe',

        left: 0,
        height: '700px',
        // height: 'auto',
        width: '-webkit-fill-available',
        boxShadow: '0px -10px 40px -5px rgba(0,0,0,0.15)',
    },





    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    grow: {
        flexGrow: 1,
        // paddingBottom: '220px',
        paddingBottom: '700px',

    },
    growIcons: {
        flexGrow: 1,
        justifyContent: 'center',
        display: 'flex',
    },
    buttonLogin: {
        color: "white",
        fontWeight: "600",
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        margin: "5px",
    },
    appbarCustom: {
        // maxHeight: '8vh',
        display: 'flex',
        alignItems: 'center',
        boxShadow: "0px 10px 45px 0px rgba(0,0,0,0.4)",
        // marginBottom: '15px',
        background:
            "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(104,104,172,1) 100%);",
    },
    appbarCustomMenu: {
        // height: '12vh',
        // marginBottom: '15px',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background:
            "#FFF7FF"
    },
    iconCustom: {
        color: '#FFF7FF'
    },
    iconButtonCustom: {
        padding: '0px',
        borderRadius: '0px',
    },
    iconButtonCustomMobile: {
        padding: '10px',
        borderRadius: '0px',
    },
    toolbarCustom: {
        minHeight: '0px',
        maxHeight: '8vh'
    },



    footerNav: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(1)
    },
    footerLink: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },

    icons: {
        alignSelf: 'center'
    },

    paperDrawerCustom: {
        background: '#FFF7FF',
    }



}));
