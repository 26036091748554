import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { Dashboard } from "../Dashboard";
import { DeskHelp } from "./DeskHelp";
import { MobiHelp } from "./MobiHelp";
import { useStyles } from "./styles";

export const Help = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Grid item xs={12} className={classes.gridCenter}>
                    {responsive ? (
                        <MobiHelp />
                    ) : (
                        <div className={classes.grid}>
                            <div></div>
                            <div style={{minHeight: '80vh'}}>
                                <Box>
                                    <Typography variant="h5" style={{ color: 'rgba(97, 47, 116)' }}>Informações</Typography>
                                </Box>
                                <DeskHelp />
                            </div>
                            <div></div>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export const DashHelp = (props) => {

    return (
        <div>
            <Dashboard {...props} component={Help} />

        </div>
    )
};
export default DashHelp;
