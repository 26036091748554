import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import personService from "../../services/Person";
import { Dashboard } from "../Dashboard";
import { DeskPeopleList } from "./DeskPeopleList";
import MobiPeopleList from "./MobiPeopleList";
import { useStyles } from "./styles";

export const People = (props) => {
    const classes = useStyles();
    const [dataPerson, setDataPerson] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const getListPerson = async () => {
        setIsLoading(true)
        try {
            let response = await personService.getAllPerson();
            setDataPerson(response.data)
        } catch (e) {

        } finally {
            setIsLoading(false)
        }
    }

    useLayoutEffect(() => {
        getListPerson()
    }, [])

    return (
        <>
            <CustomLoader isLoading={isLoading} />
            <Grid container style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Grid item xs={12} className={classes.gridCenter}>
                    {responsive ? (
                        <div style={{ minHeight: '400px' }}>
                            <MobiPeopleList {...{ setIsLoading, data: dataPerson, getListPerson }} />
                        </div>
                    ) : (
                        // <div className={classes.grid}>
                        <div className={classes.gridWithNoPromo}>
                            {/* <div>
                            <img src={IMG} className={classes.img} />
                        </div> */}
                            <div className={classes.divPromo}>
                                <DeskPeopleList {...{ setIsLoading, data: dataPerson, getListPerson }} />
                            </div >
                            {/* <div className={classes.divPromo} >
                            <img src={IMG} className={classes.img}></img>
                        </div> */}
                        </div >
                    )
                    }
                </Grid >
            </Grid >
        </>
    )
}

export const DashPeople = (props) => {
    return (
        <div>
            <Dashboard {...props} component={People} />
        </div>
    )
};
export default DashPeople;
