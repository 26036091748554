import {
  Button,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AccountCircleOutlined, LockOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useHistory } from "react-router";
import CustomizedSnackbars from "../../components/CustomSnackBar";
import CustomTextFieldDark from "../../components/CustomTextFieldDark";
import logo from "../../images/Logos/logotipo_ff7500_fceefe_70x267.png";
import authServices from "../../services/Auth";
import { useStyles } from "./styles";

export const Login = (props) => {
  const [values, setValues] = useState({
    user: "",
    password: "",
  });
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));

  const onKeyPress = async (e) => {
    if (e.key === "Enter") {
      await logar();
    }
  };

  const logar = async () => {
    if (
      values?.user === "" ||
      values?.user === null ||
      values?.user === undefined
    ) {
      setSnackVariant("info");
      setSnackMessage("Campo usuário não pode ser vazio.");
      setSnackVisible({ visible: true });
      return;
    }
    if (
      values?.password === "" ||
      values?.password === null ||
      values?.password === undefined
    ) {
      setSnackVariant("info");
      setSnackMessage("Campo senha não pode ser vazio.");
      setSnackVisible({ visible: true });
      return;
    }

    let dto = null;
    let response = null;
    try {
      setIsLoading(true);
      dto = {
        login: values.user,
        password: values.password,
      };
      response = await authServices.postLogin(dto);

      props.history.push("/");
    } catch (e) {
      if (e.response.status === 401) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
        return;
      }
      setSnackVariant("error");
      setSnackMessage("Erro tentar entrar.");
      setSnackVisible({ visible: true });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickButtonSecondaries = (local) => {
    props.history.push(`${local}`);
  };

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className={classes.root}>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />

      <Card className={classes.box}>
        <Grid container spacing={2}>
          <Grid item xs={12} className={classes.gridCenterAlignedLogo}>
            <img src={logo} width="200px" alt="" />
          </Grid>

          <Grid item xs={responsive ? 0 : 1}></Grid>
          <Grid
            item
            xs={responsive ? 12 : 10}
            className={classes.gridCenterAligned}
          >
            <CustomTextFieldDark
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleOutlined />
                  </InputAdornment>
                ),
              }}
              className={classes.field}
              name="user"
              onKeyPress={(e) => onKeyPress(e)}
              label="Usuário ou E-mail"
              margin="normal"
              variant="filled"
              value={values.user || ""}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={responsive ? 0 : 1}></Grid>

          <Grid item xs={responsive ? 0 : 1}></Grid>
          <Grid
            item
            xs={responsive ? 12 : 10}
            className={classes.gridCenterAligned}
          >
            <CustomTextFieldDark
              disabled={isLoading}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlined />
                  </InputAdornment>
                ),
              }}
              className={classes.field}
              name="password"
              onKeyPress={(e) => onKeyPress(e)}
              type="password"
              label="Senha"
              margin="normal"
              variant="filled"
              value={values.password || ""}
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={responsive ? 0 : 1}></Grid>

          <Grid item xs={responsive ? 0 : 1}></Grid>

          <Grid
            item
            xs={responsive ? 12 : 10}
            className={classes.gridCenterAligned}
            style={{ marginBottom: 0 }}
          >
            <Button
              disabled={isLoading}
              onClick={() => logar()}
              className={classes.button}
            >
              {isLoading ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                <Typography>Entrar</Typography>
              )}
            </Button>
          </Grid>

          <Grid item xs={responsive ? 0 : 1}></Grid>

          <Grid item xs={responsive ? 0 : 1}></Grid>
          <Grid
            item
            container
            xs={responsive ? 12 : 10}
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: responsive ? "-15px" : "0px",
            }}
            className={classes.gridCenterAligned}
          >
            <Button
              onClick={() => handleClickButtonSecondaries("/")}
              style={{ width: "40%" }}
              className={classes.button03}
            >
              Retornar
            </Button>
            <Button
              onClick={() => handleClickButtonSecondaries("/registrar")}
              style={{ width: "40%" }}
              className={classes.button03}
            >
              Registrar
            </Button>
            {/* <Grid item xs={6} className={classes.gridCenterAligned}>
            </Grid>
            <Grid item xs={6} className={classes.gridCenterAligned}>
            </Grid> */}
          </Grid>
          <Grid item xs={responsive ? 0 : 1}></Grid>

          <Grid item xs={12} className={classes.gridCenterAlignedRight}>
            <Typography
              style={{
                fontSize: responsive ? "13px" : "16px",
                cursor: "pointer",
              }}
              onClick={() => history.push("/esqueceusenha")}
            >
              Esqueceu a senha
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Login;
