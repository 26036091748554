import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { DoneOutline, DoneOutlined, KeyboardReturnOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";

export const CCAuthStep03 = (props) => {
    const classes = useStyles();

    const tryPictureAgain = () => {
        props.handleBack();
        props.setCCFront(null);
    }

    return (
        <>

            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Typography variant="h6">Confirmação da foto do cartão</Typography>
            </Box>
            <Box textAlign={'center'} paddingTop={1}>
                <img
                    src={props.cCFront}
                    alt={'foto do cartão'}
                    style={{ borderRadius: '10px' }}
                    width={270} />
            </Box>
            <Box display={'flex'} justifyContent={'space-around'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button onClick={() => tryPictureAgain()} className={classes.buttonOrange} startIcon={<KeyboardReturnOutlined />}>
                    Refazer foto
                </Button>
                <Button
                    size="large"
                    onClick={() => props.saveCCAuth()}
                    className={classes.button}
                    disabled={props.isLoading}
                    style={{
                        height: '2.5rem'
                    }}
                    startIcon={props.isLoading ? null : <DoneOutline />}
                >
                    {props.isLoading ? (
                        <CircularProgress style={{ color: 'white', height: '2rem', width: '2rem' }} />
                    ) : (
                        '  Finalizar'
                    )}
                </Button>
            </Box>
        </>
    )
}
