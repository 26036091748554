import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    dados: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%'
    },
    titulos: {
        background: 'DimGray',
        color: 'white',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'left',
        fontFamily: 'arial',
        fontSize: '1%',
        marginTop: theme.spacing(2)
    },
    titulo: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'left',
        marginTop: theme.spacing(3)
    },
    buttonContract: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    }
}));
