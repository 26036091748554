import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({


    img: {
        height: '70px',
        width: 'auto',
        margin: '10px'
    },

    img2: {
        height: '30px',
        width: 'auto',
        margin: '20px',
    },


    gridContainerMain: {
        maxWidth: '1100px',
        background: '#fceefe',
        justifyContent: 'center',
        marginBottom: '10px',
        marginTop: '10px',
    },

    customGrid01: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        maxWidth: '1366px',
        marginTop: '20px'
    },

    customGrid02: {
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1366px',
    },

    customGrid03: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '1366px',
        marginTop: '20px'
    },

    customGrid04: {
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        flexWrap: 'wrap',
        textAlign: 'left'
    },

    typograph01: {
        color: '#716f71'
    },

    typograph02: {
        color: '#716f71',
        fontSize: '11pt'
    },

    typograph03: {
        color: '#716f71',
        fontSize: '16pt'
    },

    imageIcon: {
        // height: '100%',
        width: '40px',
        height: 'auto'
    },

    iconRoot: {
        textAlign: 'center',
        height: 'auto',
        width: 'auto',
    },


}));
