import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import RegisterAuthenticateDesktop from "./Desktop";
import RegisterAuthenticateMobile from "./Mobile";

export const RegisterAuthenticate = (props) => {
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <CustomLoader isLoading={isLoading} />
            {props.open ? (
                responsive ? (
                    <>
                        <Box height={"100%"}>
                            <RegisterAuthenticateMobile {...{
                                open: props.open,
                                isWarning: props.isWarning,
                                setOpen: props.setOpen,
                                isLoading,
                                setIsLoading,
                                handleCloseAuthRF: props.handleCloseAuthRF,
                            }} />
                        </Box>
                    </>
                ) : (
                    <>
                        <RegisterAuthenticateDesktop {...{
                            open: props.open,
                            handleCloseAuthRF: props.handleCloseAuthRF
                        }} />
                    </>
                )
            ) : null
            }
        </>
    )
}
