import { Box, Button, Grid, IconButton, Paper, Tooltip, Typography } from "@material-ui/core";
import { AddRounded, DeleteOutline, VerifiedUserOutlined } from "@material-ui/icons";
import React from "react";
import { useStyles } from "./styles";
import { Alert } from "@material-ui/lab";

export const ListCardMobile = (props) => {
    const classes = useStyles();

    const handleOpenRegisterCartao = () => {
        props.setOpenDialog(true);
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant={'h5'} style={{ color: 'rgba(97, 47, 116)' }}>Cartões</Typography>
                <Tooltip title="Adicionar cartão">
                    <Button onClick={() => handleOpenRegisterCartao()} className={classes.button} endIcon={<AddRounded style={{ color: 'inherited' }} />}>
                        Adicionar Cartão
                    </Button>
                </Tooltip>
            </Box>
            {props.isLoading ? (
                null
            ) : (
                <Box mt={1} className={classes.boxCustom}>
                    {props.listCard.length === 0 ? (<Typography variant={'h5'} style={{ textAlign: 'center', marginTop: '20px' }}> Não há cartões registrados</Typography>) : props.listCard.map((item) => (
                        <Paper elevation={3} className={classes.paperCustom} style={{ background: `${props.getColor(item.cardFlag).background}` }}>
                            <Grid container style={{ height: '100%' }}>
                                <Grid item container xs={12}>
                                    <Grid item xs={12} className={classes.gridDetails}>
                                        <Tooltip title={item.cardFlag}>
                                            <img src={item.urlFlag} height={`${props.getColor(item.cardFlag).height}`} width={`${props.getColor(item.cardFlag).width}`} />
                                        </Tooltip>
                                    </Grid>
                                    <Grid item xs={10} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                        <Typography variant={'h5'} style={{ color: `${props.getColor(item.cardFlag).color}` }}>{item.surname}</Typography>
                                    </Grid>
                                    {/* <Grid item xs={2} className={classes.gridDetails}>
                                        <IconButton
                                            disabled={item.isAuthenticated === 1 || item.isAuthenticated === 2}
                                            onClick={() => props.handleOpenCCAuth(item.id_CreditCard)}
                                            style={item.isAuthenticated === 2 ?
                                                { color: 'green' } :
                                                { color: `${props.getColor(item.cardFlag).color}` }}>
                                            <VerifiedUserOutlined />
                                        </IconButton>
                                    </Grid> */}
                                    <Grid item xs={10} className={classes.gridDetails}>
                                        <Typography style={{ color: `${props.getColor(item.cardFlag).color}` }}>{item.cardNumber}</Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.gridDetails}>
                                        <IconButton onClick={() => props.handleClickDeleteOpenDialogConfirm(item.id_CreditCard, item.cardNumber)} style={{ color: `${props.getColor(item.cardFlag).color}` }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                                {item.isAuthenticated === 1 || item.isAuthenticated === 2 ? (null) : (
                                    <Grid item xs={12} style={{ marginTop: "10px" }}>
                                        <Alert severity="info" onClick={() => props.handleOpenCCAuth(item.id_CreditCard)}>
                                            <>
                                                Este cartão ainda não foi autenticado,  <span style={{ fontWeight: 'bold', cursor: 'pointer' }}>CLIQUE AQUI</span>{' '} para autenticar agora
                                            </>
                                        </Alert>
                                    </Grid>
                                )}
                            </Grid>
                        </Paper>
                    ))}
                </Box>
            )
            }
        </div >
    )
}
