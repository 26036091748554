import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },

    dialogTitle: {
        background: '#612F74'
    },

    buttonNext: {
        background:
            '#ff7500',
        color: 'white',
        '&:hover': {
            background:
                'rgba(255, 117, 0, 0.8)'
            // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '5px'
    },

    button: {
        color: "white",
        fontWeight: "800",
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },

    buttonCancel: {
        color: '#fafafa',
        background: '#808080',
        '&:hover': {
            background: '#999999',
        },
    },

    buttonSave: {
        background: '#FF7500',
        color: '#ffffff',
        '&:hover': {
            background: 'rgba(255, 117, 0, 0.8)',
        },
    },

    dialogContent: {
        width: '700px',
    },

    dialogTitle: {
        background: '#612F74',
    },

    gridCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    gridItemCustom: {
        padding: '5px',
    },

    typoTitle: {
        color: '#ffffff',
    },
}));
