import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Grow, InputAdornment, Paper, Typography, useMediaQuery, useTheme, } from "@material-ui/core";
import { AccountCircleOutlined, Add, DeleteOutline, SearchOutlined, } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/CustomDatepicker";
import CustomSearchField from "../../../components/CustomSeachField";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import CustomTextFieldDark from "../../../components/CustomTextFieldDark";
import airPortsServices from "../../../services/Airport";
import midiaService from "../../../services/Midia";
import { useStyles } from "./styles";

export const PanelSearchFlights = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [grow, setGrow] = useState(true);
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [categoryType, setCategoryType] = useState("2");
  const [multipleFlights, setMultipleFlights] = useState([]);
  const [airportArrival, setAirportArrival] = useState(null);
  const [airportDeparture, setAirportDeparture] = useState(null);
  const [departureDate, setDepartureDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [airPortsList, setAirPortsList] = useState([]);
  const [backgroundVideoSearchFlights, setBackgroundVideoSearchFlights] = useState("");
  const [onlyDirectFlighs, setOnlyDirectFlighs] = useState(false);
  const [state, setState] = useState({
    categoryType: "",
    departure: "",
    arrival: "",
    departureDate: new Date(),
    returnDate: new Date(),
    adults: 1,
    children: 0,
    babies: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  function onConsultarClick() {
    setState(Object.assign(
      state,
      props.stateQuantityPerson,
      (state.departureDate = new Date(departureDate.getFullYear(), departureDate.getMonth(), departureDate.getDate())),
      (state.returnDate = new Date(returnDate.getFullYear(), returnDate.getMonth(), returnDate.getDate()))
    ));
    if (state.departure === "") {
      setSnackVariant("error");
      setSnackMessage("Selecione uma origem para buscar seu voo..");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.arrival === "") {
      setSnackVariant("error");
      setSnackMessage("Selecione um destino para buscar seu voo.");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.departure === state.arrival) {
      setSnackVariant("error");
      setSnackMessage("Origem não pode ser igual ao destino");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.departureDate > state.returnDate && categoryType === "2") {
      setSnackVariant("error");
      setSnackMessage("Data de ida não pode ser menor que a de volta");
      setSnackVisible({ visible: true });
      return;
    }

    let newstate = {
      ...state,
      departure: airPortsList.find((a) => a.value === state.departure).iata,
      arrival: airPortsList.find((a) => a.value === state.arrival).iata,
      categoryType: categoryType,
    };

    props.handleClickSearchFlights(newstate);
  }

  const handleChangeAuto = (campo, valor) => {
    setState({
      ...state,
      [campo]: valor,
    });
  };

  const handleClickDeleteMulti = (index) => {
    setMultipleFlights(multipleFlights.filter((_, i) => i !== index));
  };

  const addVooMultiTrecho = (item, index) => {
    if (!item.origem) {
      setSnackVariant("error");
      setSnackMessage("Selecione uma origem para buscar seu voo..");
      setSnackVisible({ visible: true });
      return;
    }
    if (!item.destino) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Selecione um destino para garantir a tarifa");
      return;
    }
    if (item.origem === item.destino) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Origem não pode ser igual ao destino");
      return;
    }
    if (!item.departureDate) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Data de Partida não pode ser vazia");
      return;
    }

    let havePastDate = multipleFlights.find(
      (v) => item.departureDate < v.departureDate
    );

    if (havePastDate) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage(
        "Data de volta não pode ser menor que as datas anteriores"
      );
      return;
    }

    const dto = {
      origem: item.destino,
      destino: null,
      departureDate: item.departureDate,
    };

    setMultipleFlights((oldValues) => [...oldValues, dto]);
  };

  const handleChangeDirectFlights = (event) => {
    setState({
      ...state,
      [event.target.name]: !state.apenasVoosDiretos,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let response = await airPortsServices.getAllAirportsAutoComplete();
        setAirPortsList(response.data.results);

        let responsevideo = await midiaService.getBackgroundVideoSearchFlights(
          responsive
        );
        setBackgroundVideoSearchFlights(responsevideo?.data?.results?.url);
      } catch {
        setSnackVariant("error");
        setSnackMessage("Falha ao buscar aeroportos.");
        setSnackVisible({ visible: true });
      } finally {
        setGrow(true);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className={classes.root}>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <Grow in={grow} timeout={1000}>
        <Grid container>
          <Grid item xs={12}>
            <>
              {backgroundVideoSearchFlights != "" ? (
                <Box style={{ height: "383px" }}>
                  <div
                    className={clsx(
                      classes.videoContainer,
                      classes.videoContainerCaption,
                      classes.videoContainerVideo
                    )}
                  >
                    <video
                      autoPlay
                      muted
                      loop
                      className={classes.videoContainerVideo}
                    >
                      <source
                        src={backgroundVideoSearchFlights}
                        type="video/mp4"
                      />
                    </video>
                    <Typography
                      style={{
                        fontSize: "0.65rem",
                        marginBottom: "10px",
                        top: "387px",
                        position: "absolute",
                        color: "#000000",
                      }}
                    >
                      * vídeo ilustrativo
                    </Typography>
                    {/* {Component ? ( */}
                    <div className={classes.videoContainerCaption}>
                      <Box color={"red"} className={classes.boxcustom}>
                        <Paper elevation={3} className={classes.custompaper}>
                          <Grid container spacing={2} style={{ padding: "10px" }}>
                            <Grid item xs={12}>
                              <div className={responsive ? classes.tipoTrechoM : classes.tipoTrecho}>
                                <ButtonGroup aria-label="outlined secondary button group">
                                  <Button
                                    className={clsx(
                                      classes.buttonDefault,
                                      categoryType === "2"
                                        ? classes.buttonSelected
                                        : classes.buttonNotSelected
                                    )}
                                    onClick={() => setCategoryType("2")}
                                  >
                                    Ida e Volta
                                  </Button>
                                  <Button
                                    className={clsx(
                                      classes.buttonDefault,
                                      categoryType === "1"
                                        ? classes.buttonSelected
                                        : classes.buttonNotSelected
                                    )}
                                    onClick={() => setCategoryType("1")}
                                  >
                                    Ida
                                  </Button>
                                  {/* <Button
                                                                        className={clsx(classes.buttonDefault,
                                                                            categoryType === '3' ? classes.buttonSelected : classes.buttonNotSelected
                                                                        )}
                                                                        onClick={() => setCategoryType('3')}>Multidestinos
                                                                    </Button> */}
                                </ButtonGroup>
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              {categoryType === "3" ? (
                                <>
                                  {multipleFlights.map((item, index) => (
                                    <Box className={classes.filtros2}>
                                      <div className={classes.trecho1}>
                                        <CustomSearchField
                                          {...{
                                            itens: airPortsList,
                                            value:
                                              multipleFlights[index]["origem"],
                                            label: "Origem",
                                            setValueArray: setMultipleFlights,
                                            valueArray: multipleFlights,
                                            index,
                                            tipoArray: "origem",
                                          }}
                                          name="origem"
                                        />
                                      </div>
                                      <div className={classes.trecho2}>
                                        <CustomSearchField
                                          {...{
                                            itens: airPortsList,
                                            value:
                                              multipleFlights[index]["destino"],
                                            label: "Destino",
                                            setValueArray: setMultipleFlights,
                                            valueArray: multipleFlights,
                                            index,
                                            tipoArray: "destino",
                                          }}
                                          name="destino"
                                        />
                                      </div>
                                      <div className={classes.udate}>
                                        <CustomDatePicker
                                          {...{
                                            date: multipleFlights[index][
                                              "departureDate"
                                            ],
                                            label: "Data De Partida",
                                            setValueArray: setMultipleFlights,
                                            valueArray: multipleFlights,
                                            index,
                                            tipoArray: "departureDate",
                                            disablePast: true,
                                          }}
                                        />
                                      </div>
                                      {index === multipleFlights.length - 1 ? (
                                        <div>
                                          <Grow
                                            in={
                                              categoryType === "3"
                                                ? true
                                                : false
                                            }
                                            timeout={1000}
                                          >
                                            <Button
                                              className={classes.add}
                                              onClick={() =>
                                                addVooMultiTrecho(item, index)
                                              }
                                            >
                                              <Add />
                                            </Button>
                                          </Grow>
                                          {index > 0 ? (
                                            <Grow
                                              in={
                                                categoryType === "3"
                                                  ? true
                                                  : false
                                              }
                                              timeout={1000}
                                            >
                                              <Button
                                                className={classes.add}
                                                onClick={() =>
                                                  handleClickDeleteMulti(index)
                                                }
                                              >
                                                <DeleteOutline />
                                              </Button>
                                            </Grow>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </Box>
                                  ))}
                                </>
                              ) : (
                                <>
                                  <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                      <Grid container>
                                        <Grid item container spacing={2} xs={8}>
                                          {/* <Grid container spacing={2}> */}
                                          <Grid item xs={12}>
                                            <CustomSearchField
                                              {...{
                                                itens: airPortsList,
                                                value: airportDeparture,
                                                setValue: setAirportDeparture,
                                                label: "Origem",
                                              }}
                                              name="departure"
                                              onChange={handleChangeAuto}
                                              className={classes.buttonDefault}
                                            />
                                          </Grid>
                                          <Grid item xs={12}>
                                            <CustomSearchField
                                              {...{
                                                itens: airPortsList,
                                                value: airportArrival,
                                                setValue: setAirportArrival,
                                                label: "Destino",
                                              }}
                                              name="arrival"
                                              className={classes.buttonDefault}
                                              onChange={handleChangeAuto}
                                            />
                                          </Grid>
                                          {/* </Grid> */}
                                        </Grid>
                                        <Grid item container xs={4} spacing={2}>
                                          {/* <Grid container spacing={2}> */}
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <CustomDatePicker
                                              className={classes.buttonDefault}
                                              date={departureDate}
                                              setDate={setDepartureDate}
                                              label={"Data de Partida"}
                                              disablePast={true}
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={12}
                                            style={{ marginLeft: "5px" }}
                                          >
                                            <CustomDatePicker
                                              disabled={categoryType === "1"}
                                              className={classes.buttonDefault}
                                              {...{
                                                date: returnDate,
                                                setDate: setReturnDate,
                                                label: "Data de Retorno",
                                                minDate: departureDate,
                                                disablePast: true,
                                              }}
                                            />
                                          </Grid>
                                          {/* </Grid> */}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                    <Grid container item xs={3}>
                                      <Grid item xs={12}>
                                        <CustomTextFieldDark
                                          onClick={() =>
                                            props.handleTooltipConfigPaxOpen()
                                          }
                                          InputProps={{
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                <AccountCircleOutlined />
                                              </InputAdornment>
                                            ),
                                          }}
                                          value={`${props.stateQuantityPerson?.adults + props.stateQuantityPerson?.children + props.stateQuantityPerson?.babies} Passageiros`}
                                          contentEditable={false}
                                          disabled
                                          fullWidth
                                          className={classes.buttonDefault}
                                          name="user"
                                          label="Passageiros"
                                          margin="dense"
                                          variant="filled"
                                        />
                                      </Grid>
                                      <Grid item xs={12} style={{ display: "flex", alignItems: "center", }}>
                                        <Button onClick={() => onConsultarClick()} fullWidth className={classes.button3}>
                                          <SearchOutlined />
                                          <Typography>Buscar</Typography>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                    </div>
                    {/* ) : null} */}
                  </div>
                </Box>
              ) : // <CustomBoxVideo
                //     video={backgroundVideoSearchFlights}
                //     style={{ height: '383px' }}
                //     component={() =>

                //     }
                null}
            </>
          </Grid>
        </Grid>
      </Grow>
    </div>
  );
};

export default PanelSearchFlights;
