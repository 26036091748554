import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    gridItem: {
        padding: '1rem'
    },
    paper: {
        background: 'white',
        color: '#612F74',
        margin: '0rem 1rem',
        width: '50vw'
    },
    paperMobile: {
        background: 'white',
        color: '#612F74',
        margin: '0rem 1rem',
        // width: '50vw'
    },
    checkbox: {
        color: '#612f74'
    },
    checkboxMobile: {
        color: '#612f74'
    },
    checkboxChecked: {
        backgroundColor: '#612f74',
        color: 'white'
    },
    formControlLabelDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '8rem',
        width: '-webkit-fill-available',
    },
    formControl: {
        width: '-webkit-fill-available'
    },
    formControlLabel: {
        marginLeft: 0,
        marginRight: 0
    },
    typoCounter: {
        border: '2px solid #612f74',
        borderRadius: '6px',
        padding: '2px',
        marginLeft: '20px'
    },
    typoCounterMobile: {
        border: '2px solid #612f74',
        borderRadius: '6px',
        padding: '2px',
        marginLeft: '20px'
    },
    labelInsideFormControlLabel: {
        width: '-webkit-fill-available',
    },


}));
