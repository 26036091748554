import { Box, Button, CircularProgress } from "@material-ui/core";
import { DoneOutline } from "@material-ui/icons";
import React from "react";
import { useStyles } from "../styles";
import './teste.css';
import biometry from "../../../../../images/assets/biometry.gif";
import teste_03 from "../../../../../images/assets/teste_03.gif";
import teste_04 from "../../../../../images/assets/teste_gif_01.gif";
import faceEffect from "../../../../../images/assets/face-effect.gif";

export const FacialBiometricsStep08 = (props) => {
    const classes = useStyles();

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'}  >
                {props.isLoadingBio8 ? (
                    <img
                        src={faceEffect}
                        alt="Mold"
                        width={window.innerWidth * 0.81 - 30}
                        height={window.innerWidth - 30}
                        style={{
                            maxWidth: '311.85px',
                            maxHeight: '385px',
                            borderRadius: '5px',
                            position: "absolute",
                        }}
                    />
                ) : null}
                <img
                    src={props.fileFacial}
                    alt={'foto facial'}
                    width={window.innerWidth * 0.81 - 30}
                    height={window.innerWidth - 30}
                    style={{
                        maxWidth: '311.85px',
                        maxHeight: '385px',
                        borderRadius: '5px'
                    }}
                />
            </Box>
            <Box textAlign={'center'} paddingTop={'0.1rem'}  >
                {props.isLoadingBio8 ? (
                    <img
                        src={teste_04}
                        alt="Mold"
                        width={window.innerWidth - 30}
                        height={window.innerWidth * 0.41 - 30}
                        style={{
                            maxWidth: '350px',
                            maxHeight: '155px',
                        }}
                    />
                ) : null}

            </Box>
            <Box justifyContent={'center'} display={'flex'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                <Button
                    size="large"
                    onClick={() => props.saveDocuments()}
                    className={classes.button}
                    disabled={props.isLoadingBio8}
                    style={{
                        height: '2.5rem'
                    }}
                    startIcon={props.isLoadingBio8 ? null : <DoneOutline />}
                >
                    {props.isLoadingBio8 ? (
                        <CircularProgress style={{ color: 'white', height: '2rem', width: '2rem' }} />
                    ) : (
                        'Confirmar'
                    )}
                </Button>
            </Box>
        </>
    )
}
