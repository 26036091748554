import { useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useLayoutEffect } from "react";
import { Dashboard } from "../Dashboard";
import { DescZulminha } from "./DescZulminha";
import { MobiZulminha } from "./MobiZulminha";
import { useStyles } from "./styles";

export const Zulminha = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));


    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            {responsive ? (
                <MobiZulminha />
            ) : (
                <DescZulminha />
            )}
        </>
    )
}

export const DashZulminha = (props) => {

    return (
        <div>
            <Dashboard {...props} component={Zulminha} />
        </div>
    )
};
export default DashZulminha;
