import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { searchedFlight } from '../../../../actions/flightsAction';
import CustomSelectField from '../../../../components/CustomSelectField';
import authServices from '../../../../services/Auth';
import personService from '../../../../services/Person';
import { RegisterPersonDialog } from '../../../RegisterPersonDialog';
import { DialogFastLogin } from './DialogFastLogin';
import { useStyles } from './styles';

export const BuyingDeskStep01 = (props) => {
    const classes = useStyles();
    const [openDialogFastLogin, setOpenDialogFastLogin] = useState(false);
    const dispatch = useDispatch();
    const [personList, setPersonList] = useState([]);
    const [openRegisterPerson, setOpenRegisterPerson] = useState(false);
    const [personListValue, setPersonListValue] = useState([]);
    const [idpax, setIdpax] = useState(0);
    const data = props.data;

    const getPersonList = async () => {
        try {
            props.setIsLoading(true);
            let response = await personService.getAllPerson(true);
            setPersonList(response.data.results);
        } catch (error) {

        } finally {
            props.setIsLoading(false)
        }
    }

    const handleChangePassenger = async (e, i) => {
        let list = personListValue;
        list[i - 1] = e;
        setPersonListValue(list);

        let persons = personList.filter(x => personListValue.includes(x.value))

        props.handleSelectPax(persons.map(x => x.id));
        props.setOpcoesUsadas({ ...props.opcoesUsadas, pax: (persons.map(x => x.label)) })
    }

    const listTablePerson = () => {
        var indents = [];
        for (var i = 0; i < data[0].faresGroup[0].totalQuantityPax - 1; i++) {
            indents.push(
                <TableRow>
                    <TableCell>
                        <CustomSelectField
                            {...{
                                itens: personList,
                                value: personListValue[i - 1],
                                setValue: (e) => handleChangePassenger(e, i),
                                label: "Passageiro",
                            }}
                            name="pax"
                        />
                    </TableCell>
                </TableRow>
            );
        }
        return indents;
    }

    const handleClickOpenRegisterPerson = (id_Passenger = 0) => {
        setIdpax(id_Passenger)
        setOpenRegisterPerson(true)
    }

    useLayoutEffect(() => {
        dispatch(searchedFlight(
            {
                searchedFlight: props.data
            }
        ))
    }, [])

    useLayoutEffect(() => {
        getPersonList();
        (async () => {
            try {
                props.setIsLoading(true)
                await authServices.getIsAuthenticate();
            } catch (error) {
                setOpenDialogFastLogin(true)
            } finally {
                props.setIsLoading(false)
            }
        })();
    }, [openDialogFastLogin])

    return (
        <>
            <DialogFastLogin
                {...{ open: openDialogFastLogin, getPersonAccount: props.getPersonAccount, cadastreSe: props.cadastreSe, setOpen: setOpenDialogFastLogin, handleCloseMain: props.handleCloseMain }}
            />
            {openRegisterPerson ? (
                <RegisterPersonDialog
                    {...{
                        setIsLoading: props.setIsLoading,
                        idpax,
                        open: openRegisterPerson,
                        setOpen: setOpenRegisterPerson,
                        searchListPerson: getPersonList,
                        setSnackVariant: props.setSnackVariant,
                        setSnackMessage: props.setSnackMessage,
                        setSnackVisible: props.setSnackVisible,
                    }}
                />
            ) : null}
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>Quais são os passageiros?</Typography>
                </Grid>
                {data[0].faresGroup[0].totalQuantityPax > 1 ? (
                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                        <Button
                            onClick={() => handleClickOpenRegisterPerson()}
                            className={classes.buttonAdd}
                            startIcon={<AddOutlined />}>
                            Cadastrar
                        </Button>
                    </Grid>
                ) : null}
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'><Typography variant={'h6'}>Passageiro(s)</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.personAccount.firstName ? (
                                <TableRow>
                                    <TableCell align='center'><Typography>{`${props.personAccount.firstName} ${props.personAccount.lastName}`}</Typography></TableCell>
                                </TableRow>
                            ) : (
                                <TableRow>
                                    <TableCell align='center'><Typography>Não há conta ativa</Typography></TableCell>
                                </TableRow>
                            )}
                            {listTablePerson()}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </>
    )
}
