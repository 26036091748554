import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import amex from '../../../images/BandeirasBancarias/roda_pé_amex.svg';
import cadastur from '../../../images/BandeirasBancarias/roda_pé_cadastur.svg';
import diners from '../../../images/BandeirasBancarias/roda_pé_diners.svg';
import elo from '../../../images/BandeirasBancarias/roda_pé_elo.svg';
import hiper from '../../../images/BandeirasBancarias/roda_pé_hiper.svg';
import master from '../../../images/BandeirasBancarias/roda_pé_master.svg';
import pix from '../../../images/BandeirasBancarias/roda_pé_pix.svg';
import seguro from '../../../images/BandeirasBancarias/roda_pé_seguro.svg';
import visa from '../../../images/BandeirasBancarias/roda_pé_visa.svg';
import logoDark from '../../../images/Logos/logo_escura.svg';
import email from '../../../images/Redes/email.svg';
import face from '../../../images/Redes/facebook.svg';
import insta from '../../../images/Redes/instagram.svg';
import whats from '../../../images/Redes/whatsapp.svg';
import youtube from '../../../images/Redes/youtube.svg';
import midiaService from "../../../services/Midia";
import { FooterCustomDesktop } from "./FooterDesktop";
import { FooterCustomMobile } from "./FooterMobile";
import { useStyles } from "./styles";


export const FooterCustom = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));
    const [logoLines, setLogoLines] = useState('');

    const searchLogo = async () => {
        try {
            let response = await midiaService.getLogoLandPage();
            setLogoLines(response.data.results.url)
        } catch (error) {

        }
    }

    //https://www.instagram.com/linesturturismo/
    //https://www.facebook.com/people/Linestur/100065178833266/
    //https://www.linkedin.com/company/linestur-viagens-e-turismo

    useLayoutEffect(() => {
        searchLogo()
    }, [])

    const content = {
        'brand': { image: searchLogo(), width: 110 },
        'address': 'R. Itajaí, 410 - Centro, Criciúma - SC, 88801-150',
        'copy': '© 2022 Linestur Viagens e Turismo LTDA',
        'link1': 'First Link',
        'link2': 'Second Link',
        'link3': 'Third Link',
        'link4': 'Fourth Link',
        ...props.content
    };

    const bankingFlag = [
        { src: elo },
        { src: visa },
        { src: amex },
        { src: master },
        { src: hiper },
        { src: diners },
        { src: pix },
    ]

    const redesSociais = [
        // {
        //     src: email,
        //     label: 'Email'
        // },
        {
            src: face,
            label: 'Facebook',
            url: 'https://www.facebook.com/profile.php?id=100065178833266',
        },
        {
            src: insta,
            label: 'Instagram',
            url: 'https://www.instagram.com/linesturviagens/',
        },
        // {
        //     src: whats,
        //     label: 'Whatsapp'
        // },
        // {
        //     src: youtube,
        //     label: 'Youtube'
        // }
    ]

    const linesturVirtualAssets = {
        seguro: seguro,
        logoDark: logoDark,
        cadastur: cadastur,
    }

    return responsive ? (
        <FooterCustomMobile {...{ redesSociais, bankingFlag, linesturVirtualAssets }} />
    ) : (
        <FooterCustomDesktop {...{ redesSociais, bankingFlag, linesturVirtualAssets }} />
    )
}
