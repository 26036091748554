import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { CancelOutlined, DeleteOutline } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { useStyles } from './styles';

export const CreditCardConfirm = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleCloseDialogConfirm = () => {
        props.setOpenDialogConfirm(false);
    }

    const confirmDelete = () => {
        props.deleteCard(props.selectedItem.id_CreditCard)
    }

    return (
        <>
            <Dialog
                open={props.openDialogConfirm}
                maxWidth="sm"
                onClose={handleCloseDialogConfirm}
                fullWidth
                fullScreen={responsive}
            >
                <DialogTitle className={classes.dialogTitle}><Typography className={classes.typoTitle}>Confirmação</Typography></DialogTitle>
                <DialogContent >
                    {props.isLoading ? (
                        <Skeleton variant='rect' height={'150px'} />
                    ) : (
                        <>
                            <Typography>Deseja realmente excluir o cartão com o final: &nbsp;{props.selectedItem.cardNumber}? </Typography>

                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => handleCloseDialogConfirm()} className={classes.buttonCancel} startIcon={<CancelOutlined />}>Cancelar</Button>
                    )}
                    {props.isLoading ? (
                        <Skeleton variant='rect' />
                    ) : (
                        <Button onClick={() => confirmDelete()} className={classes.buttonDelete} startIcon={<DeleteOutline />}>Deletar</Button>
                    )}
                </DialogActions>
            </Dialog >
        </>
    );
}
