import { useStyles } from "./style";
import CustomLoader from "../../../components/CustomLoader";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import { useState } from "react";
import validatorCPF from "../../../utils/cpfValidator";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import Steps from "./Steps";
import { useHistory } from "react-router";
import searchRFService from "../../../services/RF";
import { useEffect } from "react";
import countryServices from "../../../services/places/Country";
import cityServices from "../../../services/places/City";
import stateServices from "../../../services/places/States";
import user_registerServices from "../../../services/User/Register";
import cepServices from "../../../services/places/Cep";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ height: "5.0rem", width: "5.0rem", color: "#612F74" }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection={"column"}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          style={{ color: "#612F74", fontSize: "12pt" }}
        >
          {props.step} de 6
        </Typography>
      </Box>
    </Box>
  );
}

function getStepString(step) {
  switch (step) {
    case 1:
      return "Início";
    case 2:
      return "Pessoal";
    case 3:
      return "Contato";
    case 4:
      return "Endereço";
    case 5:
      return "Usuário";
    case 6:
      return "Finalizado";

    default:
      return "";
  }
}

const RegisterMobile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [step, setStep] = useState(1);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [dataRegister, setDataRegister] = useState({
    person: {
      firstName: null,
      lastName: null,
      gender: null,
      cpf: null,
      telephone1: null,
      telephone2: null,
      birthDate: null,
      cep: null,
      address: null,
      number: "0",
      nationality: null,
      complement: null,
      neighborhood: null,
      id_Nationality: null,
      id_Country: null,
      street: null,
      id_State: null,
      id_ResidenceCity: null,
      id_City: null,
    },
    user: {
      login: null,
      email: null,
      password: null,
      passwordConfirmation: null,
    },
  });
  const [stateDataVerified, setStateDataVerified] = useState({
    firstName: false,
    lastName: false,
    gender: false,
    cpf: false,
    birthDate: false,
    email: false,
    telephone1: false,
    telephone2: false,
    country: false,
    state: false,
    residenceCity: false,
    neighborhood: false,
    street: false,
    nationality: false,
    login: false,
    password: false,
    passwordConfirmation: false,
  });

  async function saveRegister() {
    try {
      setIsLoading(true);
      var response = await user_registerServices.postUser_register(
        dataRegister
      );
      if (response.status == 201) {
        setSnackVariant("success");
        setSnackMessage("Usuário criado com sucesso.");
        setSnackVisible({ visible: true });
        setStep((prevStep) => prevStep + 1);
      }
    } catch (e) {
      if (e.response.data.message) {
        setSnackVariant(e.response.data.variant);
        setSnackMessage(e.response.data.message);
        setSnackVisible({ visible: true });
      } else if (Object.values(e.response.data.errors)[0][0]) {
        setSnackVariant("warning");
        setSnackMessage(Object.values(e.response.data.errors)[0][0]);
        setSnackVisible({ visible: true });
      } else {
        setSnackVariant("error");
        setSnackMessage("Erro ao tentar criar usuario.");
        setSnackVisible({ visible: true });
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataCEP() {
    try {
      setIsLoading(true);
      if (
        dataRegister.person.cep !== "" &&
        dataRegister.person.cep !== "     -   " &&
        dataRegister.person.cep.length === 9
      ) {
        let response = await cepServices.getDataCEP(dataRegister.person.cep);

        setCities(response.data.results.cities);
        setDataRegister({
          ...dataRegister,
          person: {
            ...dataRegister.person,
            id_State: response.data.results.id_State,
            id_City: response.data.results.id_City,
            neighborhood: response.data.results.neighborhood,
            id_ResidenceCity: response.data.results.id_ResidenceCity,
            address: response.data.results.address,
          },
        });
      }
    } catch {
    } finally {
      setIsLoading(false);
    }
  }

  //Setar no data register
  function handleChangeDataRegister(event) {
    let objectcamp = event.target.name.split(/:/);
    setDataRegister({
      ...dataRegister,
      [objectcamp[0]]: {
        ...dataRegister[objectcamp[0]],
        [objectcamp[1]]: event.target.value,
      },
    });
  }
  function setCountry(e) {
    setDataRegister({
      ...dataRegister,
      person: { ...dataRegister.person, id_Country: e },
    });
  }
  function setState(e) {
    setDataRegister({
      ...dataRegister,
      person: { ...dataRegister.person, id_State: e },
    });
  }
  function setCity(e) {
    setDataRegister({
      ...dataRegister,
      person: { ...dataRegister.person, id_City: e },
    });
  }
  function setNationality(e) {
    setDataRegister({
      ...dataRegister,
      person: { ...dataRegister.person, nationality: e },
    });
  }
  function setBirthDate(date) {
    setDataRegister({
      ...dataRegister,
      person: { ...dataRegister.person, birthDate: date },
    });
  }
  //Setar no data register

  const handleNext = async () => {
    switch (step) {
      case 1:
        if (
          dataRegister.person.cpf === null ||
          dataRegister.person.cpf === "   .   .   -  " ||
          dataRegister.person.cpf === undefined
        ) {
          let dataVerified = { ...stateDataVerified, cpf: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("CPF não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          if (!validatorCPF(dataRegister.person.cpf)) {
            let dataVerified = { ...stateDataVerified, cpf: true };
            setStateDataVerified(dataVerified);
            setSnackMessage("CPF inválido.");
            setSnackVariant("info");
            setSnackVisible({ visible: true });
            return;
          } else {
            let dataVerified = { ...stateDataVerified, cpf: false };
            setStateDataVerified(dataVerified);
          }
        }

        if (dataRegister.person.birthDate === null) {
          let dataVerified = { ...stateDataVerified, birthDate: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Data de nascimento não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, birthDate: false };
          setStateDataVerified(dataVerified);
        }

        try {
          setIsLoading(true);
          let dto = {
            cpf: dataRegister.person.cpf,
            birthDate: dataRegister.person.birthDate,
          };
          let response = await searchRFService.postSearchCPF(dto);

          if (!response.data.results.valid) {
            let dataVerified = {
              ...stateDataVerified,
              cpf: true,
              birthDate: true,
            };
            setStateDataVerified(dataVerified);
            setSnackMessage(response.data.message);
            setSnackVariant("warning");
            setSnackVisible({ visible: true });
            return;
          }

          setDataRegister({
            ...dataRegister,
            person: {
              ...dataRegister.person,
              lastName: response.data.results.lastName,
              firstName: response.data.results.firstName,
            },
          });
        } catch (e) {
          if (e.response.data.message) {
            setSnackVariant(e.response.data.variant);
            setSnackMessage(e.response.data.message);
            setSnackVisible({ visible: true });
          } else if (Object.values(e.response.data.errors)[0][0]) {
            setSnackVariant("warning");
            setSnackMessage(Object.values(e.response.data.errors)[0][0]);
            setSnackVisible({ visible: true });
          } else {
            setSnackVariant("error");
            setSnackMessage("Erro ao tentar criar usuario.");
            setSnackVisible({ visible: true });
          }
        } finally {
          setIsLoading(false);
        }
        break;

      case 2:
        if (
          dataRegister.person.firstName === "" ||
          dataRegister.person.firstName === null ||
          dataRegister.person.firstName === undefined
        ) {
          let dataVerified = { ...stateDataVerified, firstName: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Nome não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, firstName: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.lastName === "" ||
          dataRegister.person.lastName === null ||
          dataRegister.person.lastName === undefined
        ) {
          let dataVerified = { ...stateDataVerified, lastName: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Sobrenome não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, lastName: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.gender === 0 ||
          dataRegister.person.gender === null
        ) {
          let dataVerified = { ...stateDataVerified, gender: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Gênero não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, gender: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.nationality === 0 ||
          dataRegister.person.nationality === null
        ) {
          let dataVerified = { ...stateDataVerified, nationality: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Nacionalidade não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, nationality: false };
          setStateDataVerified(dataVerified);
        }
        break;

      case 3:
        if (
          dataRegister.user.email === "" ||
          dataRegister.user.email === null ||
          dataRegister.user.email === undefined
        ) {
          let dataVerified = { ...stateDataVerified, email: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Email não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, email: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.telephone1 === null ||
          dataRegister.person.telephone1 === "(  )      -    " ||
          dataRegister.person.telephone1 === undefined
        ) {
          let dataVerified = { ...stateDataVerified, telephone1: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Telefone principal não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, telephone1: false };
          setStateDataVerified(dataVerified);
        }
        break;

      case 4:
        if (
          dataRegister.person.id_Country === null ||
          dataRegister.person.id_Country === undefined
        ) {
          let dataVerified = { ...stateDataVerified, country: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Não há país selecionado.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, country: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.id_State === null ||
          dataRegister.person.id_State === undefined
        ) {
          let dataVerified = { ...stateDataVerified, state: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Não há estado selecionado.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, state: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.id_ResidenceCity === null ||
          dataRegister.person.id_ResidenceCity === undefined
        ) {
          let dataVerified = { ...stateDataVerified, residenceCity: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Não há cidade selecionado.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, residenceCity: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.neighborhood === "" ||
          dataRegister.person.neighborhood === null ||
          dataRegister.person.neighborhood === undefined
        ) {
          let dataVerified = { ...stateDataVerified, neighborhood: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Bairro não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, neighborhood: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.person.address === "" ||
          dataRegister.person.address === null ||
          dataRegister.person.address === undefined
        ) {
          let dataVerified = { ...stateDataVerified, street: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Logradouro não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, street: false };
          setStateDataVerified(dataVerified);
        }
        break;

      case 5:
        if (
          dataRegister.user.login === "" ||
          dataRegister.user.login === null ||
          dataRegister.user.login === undefined
        ) {
          let dataVerified = { ...stateDataVerified, login: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Usuário não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, login: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.user.password === "" ||
          dataRegister.user.password === null ||
          dataRegister.user.password === undefined
        ) {
          let dataVerified = { ...stateDataVerified, login: true };
          setStateDataVerified(dataVerified);
          setSnackMessage("Senha não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = { ...stateDataVerified, login: false };
          setStateDataVerified(dataVerified);
        }

        if (
          dataRegister.user.passwordConfirmation === "" ||
          dataRegister.user.passwordConfirmation === null ||
          dataRegister.user.passwordConfirmation === undefined
        ) {
          let dataVerified = {
            ...stateDataVerified,
            passwordConfirmation: true,
          };
          setStateDataVerified(dataVerified);
          setSnackMessage("Confirmação de senha não pode ser vazio.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        } else {
          let dataVerified = {
            ...stateDataVerified,
            passwordConfirmation: false,
          };
          setStateDataVerified(dataVerified);
        }
        if (
          dataRegister.user.password === dataRegister.user.passwordConfirmation
        ) {
          let dataVerified = {
            ...stateDataVerified,
            passwordConfirmation: false,
            password: false,
          };
          setStateDataVerified(dataVerified);
        } else {
          let dataVerified = {
            ...stateDataVerified,
            passwordConfirmation: true,
            password: true,
          };
          setStateDataVerified(dataVerified);
          setSnackMessage("Senhas não coincidem.");
          setSnackVariant("error");
          setSnackVisible({ visible: true });
          return;
        }
        break;
      default:
        break;
    }

    if (step === 5) {
      saveRegister();
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let response = await countryServices.getCountries(true);
        setCountries(response.data.results);
      } catch {
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  //Setar cidade estado e pais
  useEffect(() => {
    (async () => {
      if (dataRegister.person.id_State) {
        try {
          setIsLoading(true);
          let response = await cityServices.getCitiesByState(
            true,
            states?.find((s) => s.value === dataRegister.person.id_State).id
          );
          setCities(response.data.results);
        } catch {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [dataRegister.person.id_State]);

  useEffect(() => {
    (async () => {
      if (dataRegister.person.id_Country) {
        try {
          setIsLoading(true);
          let response = await stateServices.getStatesByCountry(
            true,
            countries?.find((s) => s.value === dataRegister.person.id_Country)
              .id
          );
          setStates(response.data.results);
        } catch {
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [dataRegister.person.id_Country]);

  useEffect(() => {
    if (dataRegister.person.id_City) {
      try {
        setIsLoading(true);
        setDataRegister({
          ...dataRegister,
          person: {
            ...dataRegister.person,
            id_ResidenceCity: cities?.find(
              (s) => s.value === dataRegister.person.id_City
            ).id,
          },
        });
      } catch {
      } finally {
        setIsLoading(false);
      }
    }
  }, [dataRegister.person.id_City]);

  useEffect(() => {
    try {
      setIsLoading(true);
      setDataRegister({
        ...dataRegister,
        person: {
          ...dataRegister.person,
          id_Nationality: countries?.find(
            (s) => s.value === dataRegister.person.nationality
          ).id,
        },
      });
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [dataRegister.person.nationality]);
  //Setar cidade estado e pais

  return (
    <div className={classes.root}>
      <CustomLoader isLoading={isLoading} />
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />

      <Card className={classes.box}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={6} className={classes.gridProgress}>
            <CircularProgressWithLabel
              variant="determinate"
              value={step === 6 ? 100 : 16.67 * step}
              step={step}
            />
          </Grid>

          <Grid item xs={6} className={classes.gridProgress}>
            <Typography className={classes.txtProgress}>
              {getStepString(step)}
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.gridBody}>
            <Steps
              {...{
                step,
                isLoading,
                handleChangeDataRegister,
                dataRegister,
                dataVerified: stateDataVerified,
                setCountry,
                setState,
                setCity,
                setNationality,
                setBirthDate,
                countries,
                states,
                cities,
                getDataCEP,
                fastRegister: props.fastRegister,
              }}
            />
          </Grid>

          <Grid item xs={12} className={classes.gridButtons}>
            {props.fastRegister && props.miniCell ? null : (
              <Button
                onClick={() =>
                  props.fastRegister
                    ? history.push("/aereos")
                    : history.push("/entrar")
                }
                className={classes.buttonEntrar}
              >
                {props.fastRegister ? "Continuar Compra" : "Entrar"}
              </Button>
            )}

            {step === 6 ? null : (
              <>
                {step === 1 ? null : (
                  <Button
                    disabled={step === 1}
                    onClick={() => setStep((prevStep) => prevStep - 1)}
                    className={classes.button}
                  >
                    Voltar
                  </Button>
                )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNext()}
                  className={classes.button}
                >
                  {step === 5 ? "Finalizar" : "Avançar"}
                </Button>
              </>
            )}
          </Grid>

          {props.fastRegister && props.miniCell ? (
            <Grid item xs={12} className={classes.gridButtons}>
              <Button
                onClick={() => history.push("/aereos")}
                className={classes.buttonEntrar}
              >
                Continuar Compra
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Card>
    </div>
  );
};
export default RegisterMobile;
