import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    gridItem: {
        "&:hover": {
            backgroundColor: "#fceefe",
            cursor: 'pointer',
        },
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '4rem',
        alignItems: 'center'
    },

    ccBox: {
        minWidth: 'min-content',
        borderRadius: '5px',
        padding: '5px'
    }
}));
