import { useMediaQuery, useTheme } from "@material-ui/core";
import { useEffect } from "react";
import { Dashboard } from "../Dashboard";
import DeskQuemSomos from "./Desktop";
import MobiQuemSomos from "./Mobile";
import { useStyles } from "./styles";

export const QuemSomosPage = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.root}>
      {responsive ? <MobiQuemSomos /> : <DeskQuemSomos />}
    </div>
  );
};

export const DashQuemSomosPage = (props) => {
  return (
    <div>
      <Dashboard {...props} component={QuemSomosPage} />
    </div>
  );
};

export default DashQuemSomosPage;
