import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    icon: {
        color: '#612F74',
    },
    iconDisabled: {
        color: 'grey',
    },
    divCustom: {
        background: '#FFF7FF',
        borderRadius: '4px',
    },
    borda: {
        height: '50%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    centerIcons: {
        display: 'flex',
        alignItems: 'center',
    },

    centerIconsMobile: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 10px'
    }

}));
