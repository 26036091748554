import { useMediaQuery } from "@material-ui/core";
import { useSelector } from "react-redux";
import RegisterDesk from "./Desktop";
import RegisterMobile from "./Mobile";
import { useStyles } from "./style";
import { useThemeCustom } from "./theme";

const RegisterPage = (props) => {
  const classes = useStyles();
  const theme = useThemeCustom;
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const miniCell = useMediaQuery(theme.breakpoints.down("xs"));
  const fastRegister = useSelector((state) => state.flights.readyBooking)?.readyBooking;
  
  return (
    <div className={classes.root}>
      {responsive ? (
        <RegisterMobile {...{ fastRegister: fastRegister?.openBuyingDialog, miniCell }} />
      ) : (
        <RegisterDesk {...{ fastRegister: fastRegister?.openBuyingDialog }} />
      )}
    </div>
  );
};
export default RegisterPage;
