import { Box, Button, FormControl, FormControlLabel, Grid, MobileStepper, Radio, RadioGroup, StepConnector, Typography, useTheme, withStyles } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { promoSearch } from "../../../actions/flightsAction";
import seta from '../../../images/teste/seta.png';
import { useStyles } from "./styles";

const CssRadio = withStyles({
    colorSecondary: {
        color: '#FFFFFF',
        '&$checked': {
            color: '#612F74',
        },
    },
    checked: {}
})(Radio)

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

export const MobiPromocoes = (props) => {
    const promoList = props.promoList;
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const [activeStepToTab, setActiveStepToTab] = useState(activeStep - 1);
    const [typeFlight, setTypeFlight] = useState("ida");

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleChangeTypeFlight = (event) => {
        setTypeFlight(event.target.value);
    };

    const handleClickSearchPromo = (promo) => {
        dispatch(promoSearch(
            {
                promoSearch: {
                    isPromo: true,
                    promo: promo,
                }
            }
        ))
        history.push("/aereos");
    }

    return (
        <div className={classes.container}>
            <Grid container>
                <Grid item xs={12} style={{ margin: '10px' }}>
                    <Typography style={{ fontWeight: 'bold', color: '#612F74', fontSize: '1.5rem' }}>
                        {promoList[0][0].arrivalCity}
                    </Typography>
                    <Typography style={{ fontWeight: 'bold', color: '#612F74', fontSize: '1.0rem' }}>
                        {promoList[0][0].arrivalAirportCity}
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{ margin: '10px' }}>
                    <Box style={{ background: '#a0a1a4' }} padding={'10px'} borderRadius={'10px'}>
                        <Typography style={{ color: "#FFF7FF", marginRight: '10px' }}>
                            Saindo de: <b>{promoList[0][0].departureCity}</b>
                        </Typography>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="typeFlight" name="typeFlight" value={typeFlight} defaultValue="ida" onChange={handleChangeTypeFlight}>
                                <FormControlLabel value="ida" control={<CssRadio />} label={"Somente Ida"} />
                                <FormControlLabel value="idavolta" control={<CssRadio />} label={"Ida e Volta"} />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} style={{ margin: '10px 0px' }}>
                    <MobileStepper
                        variant="dots"
                        steps={promoList.length}
                        position="static"
                        activeStep={activeStep}
                        className={classes.root}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === promoList.length - 1}>
                                Avançar
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Voltar
                            </Button>
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {promoList[activeStep].map((item, index) => (
                            <Grid item container style={index % 2 === 0 ? { background: '#DFD5E3' } : null}>
                                <Grid item xs={12} style={{ marginTop: '10px' }} className={classes.gridContainer}>
                                    <img src={item.urlAirCompanie} height={80} />
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={5} className={classes.gridItem}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {item.arrivalCity}
                                        </Typography>
                                        <Typography style={{ fontSize: '0.8rem' }}>
                                            ({item.arrivalAirportCity})
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} className={classes.gridItem}>
                                        <img src={seta} height={30} />
                                    </Grid>
                                    <Grid item xs={5} className={classes.gridItem}>
                                        <Typography style={{ fontWeight: 'bold' }}>
                                            {item.departureCity}
                                        </Typography>
                                        <Typography style={{ fontSize: '0.8rem' }}>
                                            ({item.departureAirportCity})
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} className={classes.gridContainer}>
                                    <Grid item xs={typeFlight === "idavolta" ? 6 : 12}>
                                        <Typography>Ida:</Typography>
                                        <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                            {item.arrivalDate}
                                        </Typography>
                                    </Grid>
                                    {typeFlight === "idavolta" ? (
                                        <Grid item xs={6} className={classes.gridContainer}>
                                            <Typography>Volta:</Typography>
                                            <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                                                {item.arrivalDate}
                                            </Typography>
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <Box style={{ marginLeft: '10px', marginRight: '10px', borderRadius: '10px', border: '2px solid #008000', textAlign: 'center' }}>
                                        <Typography variant='h6'
                                            style={{ fontWeight: 'bold', color: '#008000' }}>
                                            {item.reductionAttempts}
                                        </Typography>
                                        <Typography variant='subtitle2'>
                                            Tentativas de redução
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: '10px' }} className={classes.gridContainer}>
                                    <Button onClick={() => handleClickSearchPromo(typeFlight === "idavolta" ? item.reqAvailabilityRoundTrip : item.reqAvailabilityGoing)} className={classes.button}>
                                        <Typography>{typeFlight === "idavolta" ? item.roundTripTotal : item.goingTotal}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
