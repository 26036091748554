import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import personService from "../../services/Person";
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DeletePersonDialog = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.setOpen(false)
    };

    const handleClickConfirm = async () => {
        props.setIsLoading(true)
        try {
            await personService.deletePerson(props.idpax)
            props.setSnackMessage("Passageiro excluído.");
            props.setSnackVariant("success");
            props.setSnackVisible({ visible: true });
            props.searchListPerson()
            handleClose()
        } catch (error) {
            props.setSnackMessage("Falha ao excluir passageiro.");
            props.setSnackVariant("error");
            props.setSnackVisible({ visible: true });
        } finally {
            props.setIsLoading(false)
        }
    }

    return (
        <>
            <Dialog
                onClose={handleClose}
                open={props.open}
                TransitionComponent={Transition}
                keepMounted
                fullScreen={responsive}
                maxWidth="sm"
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title"><Typography variant='h5'>Confirmação</Typography></DialogTitle>
                <DialogContent className={responsive ? classes.dialogContentCustom : null}>
                    <DialogContentText>
                        <Typography>
                            Por favor confirmar a exclusão da pessoa.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={() => handleClose()} className={classes.buttonSearch}>
                        Cancelar
                    </Button>
                    <Button onClick={() => handleClickConfirm()} className={classes.buttonSearch}>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )


}
