import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Typography, makeStyles, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import doingBioAuth from '../../images/assets/Biometry/doingBioAuth.gif';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Alert } from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import clsx from 'clsx';
import { IconsSource } from '../../images/Icons';

const styles = (theme) => ({
  customDialogPaper: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
});

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function CustomAlertDialog(props) {
  const theme = useTheme();
  const responsive = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: props.open,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClose = () => {
    if (props.bio) {
      props.rejectConfirm()
    }
    props.setOpen(false)
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      style={{ borderRadius: "0px 30px 0px 30px", width: "auto" }}
    >
      <Grid style={{ borderRadius: "30px 30px 0px 0px", backgroundColor: "white" }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%', borderRadius: "300px" }}>
          <div id="alert-dialog-description" style={{ borderRadius: "300px" }}>
            <div style={{ textAlign: "right" }}>
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ textAlign: 'center' }}>
              {props.bio ? (
                <>
                  <img src={doingBioAuth} alt="Biometric" width={`250rem`} />
                  <Grid style={{ margin: "10px" }}>
                    <Alert variant="outlined" severity="error">
                      {props.description}
                    </Alert>
                  </Grid>
                </>
              ) : (
                null
              )}
            </div>
            <Grid style={{ margin: "10px", marginBottom: "30px", textAlign: "justify", padding: "10px" }}>
              <Typography style={{ fontSize: "16px" }}>
                {props.description} 
              </Typography>
            </Grid>
            <Grid style={{ textAlign: "center", marginBottom: "20px", padding: "10px" }} container>
              <Grid xs={12}>
                <Button style={{ backgroundColor: "#ff7500", color: "#fff", height: "50px" }} fullWidth onClick={() => props.confirmClick()}><b>{props.agree}</b></Button>
              </Grid>
              <Grid xs={12}>
                <Button onClick={() => handleClose()} autoFocus><b>{props.disagree}</b></Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );

  return (
    <>
      <div>
        {responsive ? (
          <div style={{ width: "auto" }}>
            <SwipeableDrawer
              anchor={'bottom'}
              open={props.open}
              onClose={() => props.setOpen(false)}
            >
              {list('bottom')}
            </SwipeableDrawer>
          </div>
        ) : (
          <Dialog
            open={props.open}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>
              {props.title}
            </DialogTitle>
            <DialogContent>
              {props.bio ? (
                <>
                  <DialogContentText id="alert-dialog-slide-description">
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginLeft: "40px", marginRight: "40px", marginTop: "8%", marginBottom: "8%" }}>
                      <div id="alert-dialog-description">
                        <div style={{ textAlign: 'center' }}>
                          <img src={doingBioAuth} alt="Biometric" width={`350rem`} />
                        </div>
                      </div>
                    </div>
                  </DialogContentText>
                  <Alert variant="outlined" severity="error">
                    {props.description}
                  </Alert>
                </>
              ) : (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: "1%" }}>
                    <div id="alert-dialog-description">
                      <div style={{ textAlign: 'center' }}>
                        {/* <img src={IconsSource.cookieIcon} width={`350rem`}></img> */}
                      </div>
                    </div>
                  </div>
                  <Grid style={{ margin: "10px", marginBottom: "30px", textAlign: "justify", padding: "10px" }}>
                    <Typography style={{ fontSize: "16px" }}>
                      {props.description}
                    </Typography>
                  </Grid>
                </>
              )}
            </DialogContent>
            <DialogActions>
              {props.disagree ? (
                <Button fullWidth onClick={() => handleClose()}>{props.disagree}</Button>
              ) : (
                null
              )}
              {props.agree ? (
                <Button style={{ backgroundColor: "#ff7500", color: "#fff" }} fullWidth onClick={() => props.confirmClick()} autoFocus>{props.agree}</Button>
              ) : (
                null
              )}
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}
