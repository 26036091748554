import { Box, Button, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { useCallback } from "react";
import { useRef } from "react";
import Webcam from "react-webcam";
import { useStyles } from "../styles";
import resizePhotoToPNG from "../../../../utils/resizePhoto";

export const CCAuthStep02 = (props) => {

    const classes = useStyles();
    const webcamRef = useRef(null);
    const videoConstraints = {
        width: 1000,
        height: 1440,
        facingMode: { exact: "environment" }
        // facingMode: 'user'
    };

    const CompressImg = async (imageSrc) => {
        let temp = await resizePhotoToPNG(
            imageSrc,
            1440,
            1000,
            100,
            1440,
            1000
        );
        props.handleInputChangeCCFront(temp)
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot({ width: 1440, height: 1000 });
            props.handleInputChangeCCFront(imageSrc)
            // CompressImg(imageSrc)
        },
        [webcamRef]
    );

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'} >
                <Typography variant='h6'>Foto frontal do cartão.</Typography>
            </Box>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    width={window.innerWidth}
                    screenshotFormat="image/png"
                    screenshotQuality={1}
                    forceScreenshotSourceSize
                    videoConstraints={videoConstraints}
                // style={{ maxWidth: '400px' }}
                >
                </Webcam>
            </Box>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Typography variant="h6">Encaixe o seu cartão aqui!</Typography>
            </Box>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <IconButton onClick={capture}>
                    <div className={classes.div_circle01}>
                        <div className={classes.div_circle02} />
                    </div>
                </IconButton>
            </Box>
        </>
    )
}
