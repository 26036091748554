import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Grow,
  InputAdornment,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  AccountCircleOutlined,
  Add,
  Check,
  DeleteOutline,
  SearchOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import CustomDatePicker from "../../../components/CustomDatepicker";
import CustomSearchField from "../../../components/CustomSeachField";
import CustomizedSnackbars from "../../../components/CustomSnackBar";
import CustomTextFieldDark from "../../../components/CustomTextFieldDark";
import airPortsServices from "../../../services/Airport";
import midiaService from "../../../services/Midia";
import { useStyles } from "./styles";

export const PanelSearchFlightsMobi = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [snackVariant, setSnackVariant] = useState("error");
  const [snackMessage, setSnackMessage] = useState("");
  const [snackVisible, setSnackVisible] = useState({ visible: false });
  const [grow, setGrow] = useState(true);
  const responsive = useMediaQuery(theme.breakpoints.down("sm"));
  const [categoryType, setCategoryType] = useState("2");
  const [multipleFlights, setMultipleFlights] = useState([]);
  const [airportArrival, setAirportArrival] = useState(null);
  const [airportDeparture, setAirportDeparture] = useState(null);
  const [departureDate, setDepartureDate] = useState(new Date());
  const [returnDate, setReturnDate] = useState(new Date());
  const [airPortsList, setAirPortsList] = useState([]);
  const [onlyDirectFlighs, setOnlyDirectFlighs] = useState(false);
  const [backgroundVideoSearchFlights, setBackgroundVideoSearchFlights] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState({
    categoryType: "",
    departure: "",
    arrival: "",
    departureDate: new Date(),
    returnDate: new Date(),
    adults: 1,
    children: 0,
    babies: 0,
  });
  const [openFilters, setOpenFilters] = useState(false);
  const [filters, setFilters] = useState({
    directFlights: false,
    typeClass: null,
    companies: [],
  });

  function onConsultarClick() {
    setState(
      Object.assign(
        state,
        props.stateQuantityPerson,
        (state.departureDate = new Date(
          departureDate.getFullYear(),
          departureDate.getMonth(),
          departureDate.getDate()
        )),
        (state.returnDate = new Date(
          returnDate.getFullYear(),
          returnDate.getMonth(),
          returnDate.getDate()
        ))
      )
    );
    if (state.departure === "") {
      setSnackVariant("error");
      setSnackMessage("Selecione uma origem para buscar seu voo..");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.arrival === "") {
      setSnackVariant("error");
      setSnackMessage("Selecione um destino para buscar seu voo.");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.departure === state.arrival) {
      setSnackVariant("error");
      setSnackMessage("Origem não pode ser igual ao destino");
      setSnackVisible({ visible: true });
      return;
    }
    if (state.departureDate > state.returnDate && categoryType === "2") {
      setSnackVariant("error");
      setSnackMessage("Data de ida não pode ser menor que a de volta");
      setSnackVisible({ visible: true });
      return;
    }

    // let achouDataMenor = temDataMenor.find(item => item);

    // if (achouDataMenor) {
    //     setMensagemValidacao('Data de partida deve ser maior que a data anterior');
    //     setValidacao({ visible: true });
    //     return;
    // }

    let newstate = {
      ...state,
      departure: airPortsList.find((a) => a.value === state.departure).iata,
      arrival: airPortsList.find((a) => a.value === state.arrival).iata,
      categoryType: categoryType,
    };
    props.handleClickSearchFlights(newstate);
  }

  const handleChangeAuto = (campo, valor) => {
    setState({
      ...state,
      [campo]: valor,
    });
  };

  const handleClickDeleteMulti = (index) => {
    setMultipleFlights(multipleFlights.filter((_, i) => i !== index));
  };

  const addVooMultiTrecho = (item, index) => {
    if (!item.origem) {
      setSnackVariant("error");
      setSnackMessage("Selecione uma origem para buscar seu voo..");
      setSnackVisible({ visible: true });
      return;
    }
    if (!item.destino) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Selecione um destino para garantir a tarifa");
      return;
    }
    if (item.origem === item.destino) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Origem não pode ser igual ao destino");
      return;
    }
    if (!item.departureDate) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage("Data de Partida não pode ser vazia");
      return;
    }

    let havePastDate = multipleFlights.find(
      (v) => item.departureDate < v.departureDate
    );

    if (havePastDate) {
      setSnackVariant("error");
      setSnackVisible({ visible: true });
      setSnackMessage(
        "Data de volta não pode ser menor que as datas anteriores"
      );
      return;
    }

    const dto = {
      origem: item.destino,
      destino: null,
      departureDate: item.departureDate,
    };

    setMultipleFlights((oldValues) => [...oldValues, dto]);
  };

  const handleChangeDirectFlights = (event) => {
    setState({
      ...state,
      [event.target.name]: !state.apenasVoosDiretos,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        let response = await airPortsServices.getAllAirportsAutoComplete();
        setAirPortsList(response.data.results);
        let responsevideo = await midiaService.getBackgroundVideoSearchFlights(
          true
        );
        setBackgroundVideoSearchFlights(responsevideo?.data?.results?.url);
      } catch {
        setSnackVariant("error");
        setSnackMessage("Falha ao buscar aeroportos.");
        setSnackVisible({ visible: true });
      } finally {
        setGrow(true);
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className={classes.root}>
      <CustomizedSnackbars
        {...{
          variant: snackVariant,
          message: snackMessage,
          visible: snackVisible.visible,
          setSnackbar: setSnackVisible,
        }}
      />
      <Grow in={grow} timeout={1000}>
        <Grid container>
          <Grid item xs={12}>
            <>
              {backgroundVideoSearchFlights != "" ? (
                <Box style={{ height: "383px" }}>
                  <div
                    className={clsx(
                      classes.videoContainer,
                      classes.videoContainerCaption,
                      classes.videoContainerVideo
                    )}
                  >
                    <video
                      autoPlay
                      controls={false}
                      muted
                      playsInline
                      webkit-playsinline={true}
                      loop
                      poster={backgroundVideoSearchFlights}
                      className={classes.videoContainerVideo}
                    >
                      <source
                        src={backgroundVideoSearchFlights}
                        type="video/mp4"
                      />
                    </video>
                    <Typography
                      style={{
                        fontSize: "0.8rem",
                        marginBottom: "50px",
                        top: "387px",
                        position: "absolute",
                        color: "#000000",
                      }}
                    >
                      * vídeo ilustrativo
                    </Typography>
                    {/* {Component ? ( */}
                    <div className={classes.videoContainerCaption}>
                      <Box color={"red"} className={classes.boxcustom}>
                        <Grid container spacing={2} style={{ padding: "10px" }}>
                          <Grid item xs={12}>
                            <div className={classes.tipoTrechoM}>
                              <ButtonGroup aria-label="outlined secondary button group">
                                <Button
                                  className={clsx(
                                    classes.buttonDefault,
                                    categoryType === "2"
                                      ? classes.buttonSelected
                                      : classes.buttonNotSelected
                                  )}
                                  onClick={() => setCategoryType("2")}
                                >
                                  Ida e Volta
                                </Button>
                                <Button
                                  className={clsx(
                                    classes.buttonDefault,
                                    categoryType === "1"
                                      ? classes.buttonSelected
                                      : classes.buttonNotSelected
                                  )}
                                  onClick={() => setCategoryType("1")}
                                >
                                  Ida
                                </Button>
                                {/* <Button
                                                                    className={clsx(classes.buttonDefault,
                                                                        categoryType === '3' ? classes.buttonSelected : classes.buttonNotSelected
                                                                    )}
                                                                    onClick={() => setCategoryType('3')}>Multidestinos
                                                                </Button> */}
                              </ButtonGroup>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            {categoryType === "3" ? (
                              <>
                                {multipleFlights.map((item, index) => (
                                  <Box className={classes.filtros2}>
                                    <div className={classes.trecho1}>
                                      <CustomSearchField
                                        {...{
                                          itens: airPortsList,
                                          value:
                                            multipleFlights[index]["origem"],
                                          label: "Origem",
                                          setValueArray: setMultipleFlights,
                                          valueArray: multipleFlights,
                                          index,
                                          tipoArray: "origem",
                                        }}
                                        name="origem"
                                      />
                                    </div>
                                    <div className={classes.trecho2}>
                                      <CustomSearchField
                                        {...{
                                          itens: airPortsList,
                                          value:
                                            multipleFlights[index]["destino"],
                                          label: "Destino",
                                          setValueArray: setMultipleFlights,
                                          valueArray: multipleFlights,
                                          index,
                                          tipoArray: "destino",
                                        }}
                                        name="destino"
                                      />
                                    </div>
                                    <div className={classes.udate}>
                                      <CustomDatePicker
                                        {...{
                                          date: multipleFlights[index][
                                            "departureDate"
                                          ],
                                          label: "Data De Partida",
                                          setValueArray: setMultipleFlights,
                                          valueArray: multipleFlights,
                                          index,
                                          tipoArray: "departureDate",
                                          disablePast: true,
                                        }}
                                      />
                                    </div>
                                    {index === multipleFlights.length - 1 ? (
                                      <div>
                                        <Grow
                                          in={
                                            categoryType === "3" ? true : false
                                          }
                                          timeout={1000}
                                        >
                                          <Button
                                            className={classes.add}
                                            onClick={() =>
                                              addVooMultiTrecho(item, index)
                                            }
                                          >
                                            <Add />
                                          </Button>
                                        </Grow>
                                        {index > 0 ? (
                                          <Grow
                                            in={
                                              categoryType === "3"
                                                ? true
                                                : false
                                            }
                                            timeout={1000}
                                          >
                                            <Button
                                              className={classes.add}
                                              onClick={() =>
                                                handleClickDeleteMulti(index)
                                              }
                                            >
                                              <DeleteOutline />
                                            </Button>
                                          </Grow>
                                        ) : null}
                                      </div>
                                    ) : null}
                                  </Box>
                                ))}
                              </>
                            ) : (
                              <>
                                <Grid container>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.gridFieldsSearch}
                                  >
                                    <CustomSearchField
                                      {...{
                                        itens: airPortsList,
                                        value: airportDeparture,
                                        setValue: setAirportDeparture,
                                        label: "Origem",
                                      }}
                                      name="departure"
                                      onChange={handleChangeAuto}
                                      className={classes.buttonDefault}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.gridFieldsSearch}
                                  >
                                    <CustomSearchField
                                      {...{
                                        itens: airPortsList,
                                        value: airportArrival,
                                        setValue: setAirportArrival,
                                        label: "Destino",
                                      }}
                                      name="arrival"
                                      className={classes.buttonDefault}
                                      onChange={handleChangeAuto}
                                    />
                                  </Grid>
                                  <Grid container item xs={12}>
                                    <Grid
                                      item
                                      xs={6}
                                      style={{ padding: "5px 5px 5px 0px" }}
                                    >
                                      <CustomDatePicker
                                        className={classes.buttonDefault}
                                        {...{
                                          date: departureDate,
                                          setDate: setDepartureDate,
                                          label: "Ida",
                                          disablePast: true,
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      xs={6}
                                      style={{ padding: "5px 0px 5px 5px" }}
                                    >
                                      <CustomDatePicker
                                        disabled={categoryType === "1"}
                                        className={classes.buttonDefault}
                                        {...{
                                          date: returnDate,
                                          setDate: setReturnDate,
                                          label: "Volta",
                                          minDate: departureDate,
                                          disablePast: true,
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.gridFieldsSearch}
                                  >
                                    <CustomTextFieldDark
                                      onClick={() =>
                                        props.handleTooltipConfigPaxOpen()
                                      }
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <AccountCircleOutlined />
                                          </InputAdornment>
                                        ),
                                      }}
                                      value={`${props.stateQuantityPerson?.adults +
                                        props.stateQuantityPerson?.children +
                                        props.stateQuantityPerson?.babies
                                        } Passageiros`}
                                      contentEditable={false}
                                      disabled
                                      fullWidth
                                      className={classes.buttonDefault}
                                      name="user"
                                      label="Passageiros"
                                      margin="dense"
                                      variant="filled"
                                    // value={values.user || ""}
                                    />
                                  </Grid>
                                  <Grid item xs={12} className={classes.gridFieldsSearch}>
                                    <Button onClick={() => onConsultarClick()} fullWidth className={classes.button3}>
                                      <SearchOutlined />
                                      <Typography>Buscar</Typography>
                                    </Button>
                                  </Grid>
                                  {/* <Grid item xs={12} className={classes.gridFieldsSearch}>
                                    <FormControl component="fieldset">
                                      <FormGroup aria-label="apenas_voos_diretos" name="apenas_voos_diretos" value={onlyDirectFlighs} onChange={() => setOnlyDirectFlighs(true)} row>
                                        <FormControlLabel
                                          value="Apenas Vôos diretos"
                                          control={<Checkbox checked={onlyDirectFlighs} />}
                                          label="Vôos diretos"
                                          name="apenas_voos_diretos"
                                          labelPlacement="end"
                                        />
                                      </FormGroup>
                                    </FormControl>
                                  </Grid> */}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </div>
                    {/* ) : null} */}
                  </div>
                </Box>
              ) : null}
            </>
          </Grid>
        </Grid>
      </Grow>
    </div>
  );
};
