import { makeStyles } from "@material-ui/core/styles";
import FundoDesk from "../../images/Fundos/desktop_login_1080_1920.png";
import FundoMobi from "../../images/Fundos/mobile_login_1080_1920.png";
// import Fundo from "../../images/teste.svg";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundSize: "cover" /* <------ */,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url(${FundoMobi})`,
    [theme.breakpoints.up("sm")]: {
      backgroundImage: `url(${FundoDesk})`,
    },
  },
  svg: {
    width: "100px",
    height: "100px",
  },
  box: {
    padding: 30,
    maxWidth: 400,
    maxHeight: 400,
    background: "rgba(255,255,255,0.2)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    minHeight: "40px",
    color: "white",
    fontWeight: "800",
    background:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    marginLeft: "20px",
    marginRight: "20px",
  },
  gridCenterAligned: {
    display: "grid",
    alignItems: "center",
    // justifyContent: 'center',
  },

  gridCenterAlignedLogo: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },

  gridCenterAlignedRight: {
    display: "flex",
    justifyContent: "center",
    marginTop: "-28px",
    marginBottom: "0px",
    color: "white",
    [theme.breakpoints.up("md")]: {
      marginTop: "-5px",
      textShadow:
        "1px 1px 1px rgb(84,64,53), 1px -1px 1px rgb(84,64,53), " +
        "-1px 1px 1px rgb(84,64,53), -1px -1px 1px rgb(84,64,53)"
    },
  },

  field: {
    backgroundColor: "#ffffff9e",
    [theme.breakpoints.up("md")]: {
      backgroundColor: "#ffffff73",
    },
    // // width: "-webkit-fill-available",
    // marginX: '20px'
  },
  progress: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(14),
    position: "absolute",
    zIndex: 100,
    color: "#ffffff",
    width: "20px",
    height: "20px",
  },
  buttonSecondaries: {
    color: "#612F74",
    background: " white",
  },
  button03: {
    width: "100%",
    background: "#ff7500",
    color: "white",
    "&:hover": {
      background: "rgba(255, 117, 0, 0.8)",
      // "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
    },
    borderRadius: "15px",
  },
}));
