import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({

    button: {
        color: "white",
        fontWeight: "800",
        background:
            "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%);",
    },

    radio: {
        '&$checked': {
            color: '#612F74'
        }
    },
}));
