import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    expanded: {
        justifyContent: 'space-between',
    },
    teste: {
        background: '#FFF7FF',
        marginBottom: '10px',
        boxShadow: 'none',
    },
    boxRegister: {
        margin: '0px 10px 0px 10px'
    },
    fabCustomAdd: {
        color: '#FFF7FF',
        background: 'purple',
        position: 'absolute',
    },
    buttonSearch: {
        width: '100%',
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        color: 'white',
        '&:hover': {
            background:
                "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },
    buttonDelete: {
        width: '100%',
        background:
            "linear-gradient(90deg, rgba(255,165,0,1) 0%, rgba(255,174,25,1) 100%);",
        color: 'white',
        '&:hover': {
            background:
                "linear-gradient(90deg, rgba(97,47,116,1) 0%, rgba(112,67,129,1) 100%);",
        },
        borderRadius: '25px'
    },

    alignTextToCenter: {
        display: 'flex',
        alignItems: 'center'
    },
}));
