import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogNewPrice = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        props.setOpenDialogNewPrice(false);
    };

    const handleClickOption = (cod) => {
        handleClose()
        if (cod === 1) {
            props.handleClickBooking()
        }
    }

    return (
        <>
            <Dialog fullScreen={responsive} open={props.open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle className={classes.dialogTitle}>
                    <Typography variant={'h5'} style={{ color: '#ffffff' }}>Aviso</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <Typography variant='h6'>{props.data.message}</Typography>
                    <Typography>Valor atual: {props.data.results.fareGroup.withTax}</Typography>
                    <Typography>Deseja garantir a nova tarifa?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClickOption(0)} className={classes.button}>
                        Não
                    </Button>
                    <Button onClick={() => handleClickOption(1)} className={classes.button}>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default DialogNewPrice;
