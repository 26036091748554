import { Box, Button, Divider, FormControlLabel, Grid, List, ListItem, ListItemAvatar, ListItemText, Slide, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { GetAppOutlined } from '@material-ui/icons';
import React from 'react';
import CustomCheckBox from '../../../../components/CustomCheckbox';
import seta from '../../../../images/teste/seta.png';
import { useStyles } from './styles';
import { IconsSource } from '../../../../images/Icons';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const BuyingDeskStep04 = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const responsive = useMediaQuery(theme.breakpoints.down('sm'));

    const data = props.data;

    return (
        <>
            <Grid container>
                <Divider />
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography variant='h6' >Passageiros</Typography>
                    <List>
                        <ListItem>
                            <ListItemText primary={<Typography>{`${props.personAccount.firstName} ${props.personAccount.lastName}`}</Typography>}>
                            </ListItemText>
                            {props.opcoesUsadas.pax.map((item) => (
                                <ListItemText
                                    primary={<Typography>{`${item.firstName} ${item.lastName}`}</Typography>}
                                />
                            ))}
                        </ListItem>
                    </List>
                </Grid>
                <Divider />
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Detalhes do pagamento
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container style={{ alignItems: 'center' }}>
                        <Grid item xs={8}>
                            {props.formPaymentSelected == "pixpayment" ? (
                                <List>
                                    <ListItem>
                                        <ListItemAvatar style={{ width: '130px', textAlign: 'center' }}>
                                            <img height={50} width={50} src={IconsSource.pixIcon} />
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{ marginLeft: '10px' }}
                                            primary={"Pix"}
                                            secondary={"Aprovação imediata"}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={() => props.getDocumentDownload()}
                                            className={classes.buttonContract}
                                            startIcon={<GetAppOutlined />}>
                                            <Typography noWrap>
                                                Baixar contrato
                                            </Typography>
                                        </Button>
                                    </ListItem>
                                </List>
                            ) : (
                                <List>
                                    <ListItem>
                                        <ListItemAvatar style={{ width: '130px', textAlign: 'center' }}>
                                            <Box className={classes.ccBox} style={{ background: `${props.getColor(props.opcoesUsadas.cartao.cardFlag).background}` }}>
                                                <img height={50} width={100} src={props.opcoesUsadas.cartao.urlFlag} />
                                            </Box>
                                        </ListItemAvatar>
                                        <ListItemText
                                            style={{ marginLeft: '10px' }}
                                            primary={`${props.opcoesUsadas.cartao.cardFlag} ${props.opcoesUsadas.cartao.cardNumber}`}
                                            secondary={
                                                props.opcoesUsadas.parcela.quantity === 1 ? (
                                                    <Typography>
                                                        {`Você pagará ${props.opcoesUsadas.parcela.quantity}x ${props.opcoesUsadas.parcela.installment}`}
                                                    </Typography>
                                                ) : (
                                                    <Typography>
                                                        {`Você pagará 1x de ${props.opcoesUsadas.parcela.firstInstallment} + ${props.opcoesUsadas.parcela.quantity - 1}x de ${props.opcoesUsadas.parcela.installment}`}
                                                    </Typography>
                                                )}
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText style={{ width: 'min-content' }}>
                                            <FormControlLabel
                                                control={
                                                    <CustomCheckBox
                                                        checked={props.state.acceptTerms}
                                                        onChange={props.handleChangeState}
                                                        name="acceptTerms"
                                                        color="primary"
                                                    />
                                                }
                                                label="Autorizo o débito no cartão selecionado conforme o contrato"
                                            />
                                        </ListItemText>
                                        <Button
                                            onClick={() => props.getDocumentDownload()}
                                            className={classes.buttonContract}
                                            startIcon={<GetAppOutlined />}>
                                            <Typography noWrap>
                                                Baixar contrato
                                            </Typography>
                                        </Button>
                                    </ListItem>
                                </List>

                            )}
                        </Grid>
                        <Grid item xs={4} style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <Box style={{
                                width: '125px',
                                height: '125px',
                                border: '3px solid #612F74',
                                borderRadius: '999px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                <Typography variant={'h6'} style={{ color: '#612F74', fontWeight: 'bold' }}>
                                    {data[0].reductionAttempts}
                                </Typography>
                                <Typography>tentativas de</Typography>
                                <Typography style={{ fontWeight: '600' }}>redução</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                        Bagagem
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align='center'><Typography>Classe</Typography></TableCell>
                                <TableCell align='center'><Typography>Possui Bagagem</Typography></TableCell>
                                <TableCell align='center'><Typography>Quantidade de Bagagem</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align='center'><Typography>{`${data[0].faresGroup[0].class[0]} (${data[0].faresGroup[0].classCode[0]})`}</Typography></TableCell>
                                <TableCell align='center'>
                                    <Typography>
                                        {data[0].faresGroup[0].isIncludeBaggage ? ('Sim') : ('Não')}
                                    </Typography>
                                </TableCell>
                                <TableCell align='center'><Typography>{data[0].faresGroup[0].quantityBaggage}</Typography></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item container xs={12}>
                    <Typography variant='h6'>
                        Aéreo
                    </Typography>
                    {data.map((flightItem) => (
                        <Grid container style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                            <Grid item xs={2} style={{ display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                <img height={80} src={flightItem.airCompanie.url} />
                            </Grid>
                            <Grid item xs={10} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} spacing={'3'}>
                                <Grid item xs={12}>
                                    <Tooltip arrow name="descricaoTrechos" title={
                                        <React.Fragment>
                                            <Grid container>
                                                {flightItem.flightDescriptions.map((leg) => (
                                                    <>
                                                        <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                            <Typography variant='h5'>
                                                                <b>{leg.departureTime}</b>
                                                            </Typography>
                                                            <Typography variant='b'>
                                                                {leg.departure}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                            <Grid container>
                                                                {/* <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>direto</Grid> */}
                                                                <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                    <img height={30} src={seta} />
                                                                </Grid>
                                                                <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>{leg.flightDuration}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                            <Typography variant='h5'>
                                                                <b>{leg.arrivalTime}</b>
                                                            </Typography>
                                                            <Typography variant='b'>
                                                                {leg.arrival}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                ))}
                                            </Grid>
                                        </React.Fragment>
                                    }>
                                        <Grid container>
                                            {flightItem.flightDescriptions.map((leg) => (
                                                <>
                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                        <Typography variant='h5'>
                                                            <b>{leg.departureTime}</b>
                                                        </Typography>
                                                        <Typography>
                                                            {leg.departureCity} ({leg.departure})
                                                        </Typography>
                                                        <Typography style={{ fontSize: '0.7rem' }}>
                                                            {leg.departureAirportCity}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                        <Grid container>
                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                <img height={30} src={seta} />
                                                            </Grid>
                                                            <Grid item xs={12} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                                <Typography>
                                                                    {leg.flightDuration}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ display: 'grid', alignItems: 'center', justifyItems: 'center' }}>
                                                        <Typography variant='h5'>
                                                            <b>{leg.arrivalTime}</b>
                                                        </Typography>
                                                        <Typography>
                                                            {leg.arrivalCity} ({leg.arrival})
                                                        </Typography>
                                                        <Typography style={{ fontSize: '0.7rem' }}>
                                                            {leg.arrivalAirportCity}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                            ))}
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    )
}
