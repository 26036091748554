import api from '../../Api';

const midiaService = {

    getLogoLandPage: async () => {
        const response = await api.get('/Midia/LogoLandPage');
        return response;
    },
    getBackgroundVideoSearchFlights: async (mobile) => {
        const response = await api.get('/Midia/BackGroundVideoSearchFlights?mobile=' + mobile);
        return response;
    },
}

export default midiaService;
