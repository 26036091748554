import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },

  mainContainer: {
    width: "1100px",
  },

  gridContainer: {},

  gridItem: {},

  gridText: {
    color: "gray",
    marginTop: "20px",
    padding: "0px 20px 0px 20px",
    textAlign: "justify",
  },

  gridImg: {
    marginTop: "30px",
  },

  gridBanner: {
    textAlign: "center",
    margin: "45px 0px 0px 0px",
    borderRadius: "10px",
    padding: "8px",
  },

  text: {
    fontSize: "17pt",
  },

  textTitleFloat: {
    fontWeight: "bold",
    fontSize: "18pt",
  },

  textTitleBold: {
    fontWeight: "bold",
    fontSize: "23pt",
  },

  paperText: {
    color: "gray",
    padding: "10px 30px 10px 30px",
    textAlign: "left",
    minHeight: "100%",
    backgroundColor: "#fceefe",
  },

  textBanner: {
    fontSize: "16pt",
    fontWeight: 700,
  },

  textBold: {
    marginLeft: "20px",
    fontSize: "19pt",
    fontWeight: 600,
  },

  img: {
    width: "100%",
  },

  divTitleFloat: {
    background:
      "linear-gradient(180deg, rgba(255,117,0,1) 0%, rgba(255,117,0,1) 20%, rgba(237,149,1,1) 20%, rgba(237,149,1,1) 100%)",
    textAlign: "center",
    position: "absolute",
    marginLeft: "750px",
    top: "150px",
    width: "300px",
    // height: "120px",
    zIndex: 1,
    color: "#fff",
    padding: "25px 0px 7px 0px",
    borderRadius: '10px',
  },
}));
