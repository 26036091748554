import { Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import headMidia from "../../../images/Marketing/header_quem_somos_coracao1_425x1080.png";
import CustomBoxImg from "../../../components/CustomBoxImg";
import passo1 from "../../../images/Marketing/tres_passo_1.png";
import passo2 from "../../../images/Marketing/tres_passo_2.png";
import passo3 from "../../../images/Marketing/tres_passo_3.png";

export const DeskQuemSomos = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CustomBoxImg midia={headMidia} style={{ height: "425px" }} />

      <div className={classes.container}>
        <main className={classes.mainContainer}>
          <div className={classes.divTitleFloat}>
            <Typography className={classes.textTitleFloat}>
              EXCLUSIVO
            </Typography>
            <Typography className={classes.textTitleFloat}>
              REDUTOR DE TARIFA
            </Typography>
          </div>

          <Grid
            container
            className={classes.gridContainer}
            style={{ marginTop: "50px" }}
          >
            <Grid item xs={12} className={classes.gridText}>
              <Typography className={classes.text}>
                Somos a <b>única</b> empresa capaz de reduzir sua tarifa, isto
                só é possível em virtude de nossa inteligência artificial que
                foi desenvolvida e embarcada na linestur.com, resultando na
                primeira agente virtual do mundo efetuando <b>34 tentativas</b>{" "}
                de redução por minuto.
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                color: "gray",
              }}
            >
              <Typography className={classes.textTitleBold}>É fácil</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                marginTop: "5px",
                backgroundColor: "#612F74",
                color: "#fff",
              }}
            >
              <Typography className={classes.textBanner}>
                Você acompanha em tempo real as tentativas de redução
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.gridImg}>
              <Grid container className={classes.gridContainer} spacing={3}>
                <Grid item xs={4} className={classes.gridItem}>
                  <img alt="" src={passo1} className={classes.img} />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <img alt="" src={passo2} className={classes.img} />
                </Grid>
                <Grid item xs={4} className={classes.gridItem}>
                  <img alt="" src={passo3} className={classes.img} />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              className={classes.gridBanner}
              style={{
                color: "gray",
              }}
            >
              <Typography className={classes.textTitleBold}>
                Princípios
              </Typography>
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.gridText}
              style={{ marginBottom: "100px" }}
            >
              <Paper elevation={3} className={classes.paperText}>
                <Typography className={classes.textBold}>Visão.</Typography>
                <Typography className={classes.text}>
                  Revolucionar o segmento do turismo, empregando tecnologia
                  focada para gerar economia e bem-estar aos nossos clientes.
                </Typography>
              </Paper>
            </Grid>

            <Grid
              item
              xs={6}
              className={classes.gridText}
              style={{ marginBottom: "100px" }}
            >
              <Paper elevation={3} className={classes.paperText}>
                <Typography className={classes.textBold}>
                  Missão.
                </Typography>
                <Typography className={classes.text}>
                  Como desenvolvedores de tecnologia, nossa missão é sempre
                  harmonizar nossos sistemas a rotina das pessoas, construindo
                  nossas plataformas alicerçadas em três pilares:
                  <ol style={{ marginBottom: 0 }}>
                    <li>Mobile First;</li>
                    <li>Experiência do usuário;</li>
                    <li>Usabilidade.</li>
                  </ol>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </main>
      </div>
    </div>
  );
};

export default DeskQuemSomos;
