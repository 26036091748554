import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
    width: "90%",
    height: 45,
    color: "white",
    background: "transparent",
    border: "solid 1px silver",
    "&:hover": {
      border: "solid 1px white",
    },
    "&.MuiButton-root:hover.Mui-disabled": {
      border: "solid 1px white",
    },
  },

  box: {
    minWidth: '30vw',
    maxWidth: '50vw',
    minHeight: '35vh',

  },
  field: {
    width: "-webkit-fill-available",
    backgroundColor: '#ffffff73'
  },
  gridCenter: {
    display: "grid",
    justifyContent: "center",
  },
  gridCenterAligned: {
    display: 'grid',
    alignItems: 'center'
  }
}));
