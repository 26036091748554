import { Checkbox, withStyles } from '@material-ui/core';
import React from 'react';

const CustomCheckBox = withStyles({
    root: {
        color: '#612F74',
        '&$checked': {
            color: '#612F74',
        },
    },
    checked: {},
})((props) => <Checkbox   {...props} />);

export default CustomCheckBox;
