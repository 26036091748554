import { Avatar, Box, Grid, IconButton, Typography } from "@material-ui/core";
import { DoneOutlined } from "@material-ui/icons";
import React, { useCallback, useRef } from "react";
import Webcam from "react-webcam";
import resizePhotoToPNG from "../../../../../utils/resizePhoto";
import { useStyles } from "../styles";
import { IconsSource } from "../../../../../images/Icons";

export const FacialBiometricsStep05 = (props) => {
    const classes = useStyles();
    const webcamRef = useRef(null);

    const videoConstraints = {
        // height: window.innerWidth,
        // width: window.innerWidth * 0.69,
        width: 520,
        height: 750,
        facingMode: { exact: "environment" }
        // facingMode: 'user'
    };

    const CompressImg = async (imageSrc) => {
        let temp = await resizePhotoToPNG(
            imageSrc,
            1045,
            725,
            60,
            1045,
            725
        );
        props.handleInputChangeDocFront(temp);
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot({ width: 1045, height: 725 });
            props.handleInputChangeDocFront(imageSrc);
            // CompressImg(imageSrc)
        },
        [webcamRef]
    );

    return (
        <>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Typography variant="h6">Foto frontal do documento</Typography>
            </Box>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Webcam
                    ref={webcamRef}
                    audio={false}
                    width={window.innerWidth}
                    screenshotFormat="image/png"
                    screenshotQuality={1}
                    forceScreenshotSourceSize
                    videoConstraints={videoConstraints}
                >
                </Webcam>
            </Box>
            <Box textAlign={'center'} paddingTop={'1rem'}>
                <Typography variant="h6">Encaixe o seu documento!</Typography>
            </Box>
            <Grid style={{ textAlign: "center", width: "100%", marginTop: "130px" }}>
                <img src={IconsSource.carteira}></img>
            </Grid>
            <Box textAlign={'-webkit-center'} paddingTop={'1rem'} display={'flex'} justifyContent={'space-around'} bottom={'3rem'} position={'absolute'} width={'-webkit-fill-available'}>
                {!!props.fileDocFront ? (
                    <Avatar className={classes.large}>
                        <DoneOutlined fontSize="large" style={{ color: 'white', height: '2rem', width: '2rem' }} />
                    </Avatar>
                ) : (
                    <IconButton onClick={capture}>
                        <div className={classes.div_circle01}>
                            <div className={classes.div_circle02} />
                        </div>
                    </IconButton>
                )}
            </Box>
        </>
    )
}
